import { BgColorsOutlined, DeleteOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import type { TabsProps } from 'antd';
import { Button, Drawer, Form, FormInstance, Input, Modal, Switch, Tabs, Tag } from 'antd';
import collect from 'collect.js';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SectionFormValuesInterface, SectionInterface } from '../../exports/Interfaces';
import getSectionName from '../../exports/Methods';
import useModals from '../../hooks/useModals';
import useScreenType from '../../hooks/useScreenType';
import SectionManager from '../../services/api/SectionManager';
import ActionConfirmation from '../popover/ActionConfirmation';
import ColorSelect from '../selects/ColorSelect';
import TypographyItem from '../typography/TypographyItem';
import FormItem from './structure/form-item';
import { SECTION_STYLE_ATTRIBUTES } from '../../exports/Enums';

interface Props {
    section: SectionInterface<any>;
    form?: FormInstance;
    children?: React.ReactNode;
    loading: boolean;
    disabled?: boolean;
    layout?: 'horizontal' | 'vertical';
    initialValues?: any;
    hideHeader?: boolean;
    styleContent?: JSX.Element;
    contentStyle?: JSX.Element;
    advancedContentTitle?: string;
    advancedContent?: JSX.Element;
    excludedStyles?: SECTION_STYLE_ATTRIBUTES[];
    onSave?: (values: Partial<SectionInterface<any>>) => void;
    saveAndClose?: (values: Partial<SectionInterface<any>>) => Promise<void>;
}

function SectionForm(props: Props): JSX.Element {
    const {
        section,
        form,
        children,
        loading,
        disabled,
        layout = 'vertical',
        initialValues,
        styleContent,
        contentStyle,
        advancedContentTitle,
        advancedContent,
        hideHeader = false,
        excludedStyles,
        onSave,
        saveAndClose,
    } = props;

    const { isDesktop } = useScreenType();

    const navigate = useNavigate();

    const { setSectionModal } = useModals();

    const [deleteClicked, setDeleteCLicked] = useState<boolean>(false);

    const [isHidden, setIsHidden] = useState<boolean>(initialValues?.is_hidden);

    const [showNameStyleModal, setShowNameStyleModal] = useState<boolean>(false);

    const removeUndefined = (obj: any): any =>
        collect(obj)
            .reject((value) => value === undefined)
            .all();

    const getData = (values: SectionFormValuesInterface): any => ({
        type: section?.type,
        is_hidden: isHidden,
        ...removeUndefined(values),
        content: removeUndefined(values?.content),
    });

    const beforeSave = (values: SectionFormValuesInterface): void => {
        if (onSave) {
            onSave(getData(values));
        }
    };

    const onDelete = async (): Promise<void> => {
        if (typeof section?.id !== 'number') {
            return;
        }

        setDeleteCLicked(false);

        await SectionManager.delete(section?.id);
        navigate(-1);
    };

    const close = async (values: SectionFormValuesInterface): Promise<void> => {
        if (saveAndClose) {
            await saveAndClose(getData(values));
        }
        setSectionModal({ open: false });
    };

    function renderStyle(): JSX.Element {
        return (
            <div>
                {collect(excludedStyles).doesntContain(SECTION_STYLE_ATTRIBUTES.HEADER_COLOR) && (
                    <Form.Item
                        name={SECTION_STYLE_ATTRIBUTES.HEADER_COLOR}
                        label="Header Color"
                        className="w-full mr-small flex-1 flex-shrink-0"
                        initialValue={initialValues?.header_color}
                    >
                        <ColorSelect
                            initialColor={initialValues?.header_color}
                            onSelect={(value) => form?.setFieldValue(SECTION_STYLE_ATTRIBUTES.HEADER_COLOR, value)}
                        />
                    </Form.Item>
                )}
                {collect(excludedStyles).doesntContain(SECTION_STYLE_ATTRIBUTES.DESCRIPTION_COLOR) && (
                    <Form.Item
                        name={SECTION_STYLE_ATTRIBUTES.DESCRIPTION_COLOR}
                        label="Description Color"
                        className="w-full mr-small flex-1 flex-shrink-0"
                        initialValue={initialValues?.description_color}
                    >
                        <ColorSelect
                            initialColor={initialValues?.description_color}
                            onSelect={(value) => form?.setFieldValue(SECTION_STYLE_ATTRIBUTES.DESCRIPTION_COLOR, value)}
                        />
                    </Form.Item>
                )}
                {collect(excludedStyles).doesntContain(SECTION_STYLE_ATTRIBUTES.BACKGROUND_COLOR) && (
                    <Form.Item
                        name="background_color"
                        label="Background Color"
                        className="w-full mr-small flex-1 flex-shrink-0"
                        initialValue={initialValues?.background_color}
                    >
                        <ColorSelect
                            initialColor={initialValues?.background_color}
                            onSelect={(value) => form?.setFieldValue(SECTION_STYLE_ATTRIBUTES.BACKGROUND_COLOR, value)}
                        />
                    </Form.Item>
                )}
                {collect(excludedStyles).doesntContain(SECTION_STYLE_ATTRIBUTES.IS_SKEW) && (
                    <Form.Item
                        name={SECTION_STYLE_ATTRIBUTES.IS_SKEW}
                        label="Skew"
                        className="w-full mr-small flex-1 flex-shrink-0"
                        initialValue={initialValues?.is_skew}
                    >
                        <Switch defaultChecked={initialValues?.is_skew} />
                    </Form.Item>
                )}
                {contentStyle}
            </div>
        );
    }

    const renderNameStyleModal = useMemo(
        () => (
            <Modal open={showNameStyleModal} onCancel={() => setShowNameStyleModal(false)} footer={null}>
                <Form.Item name="name_style" className="mb-none" initialValue={section?.name_style}>
                    <TypographyItem
                        title="Style"
                        initialValues={section?.name_style}
                        saveTitle="Apply"
                        hideCapital
                        hideColor
                        hideFontFamily
                        onSave={(values) => {
                            form?.setFieldValue('name_style', values);
                            setShowNameStyleModal(false);
                        }}
                    />
                </Form.Item>
            </Modal>
        ),
        [showNameStyleModal, form, section?.name_style],
    );

    const items: TabsProps['items'] = collect([
        {
            key: '1',
            label: 'Content',
            children,
        },
        {
            key: '2',
            label: 'Style',
            children: styleContent ?? renderStyle(),
        },
        {
            key: '3',
            label: advancedContentTitle ?? 'Items',
            children: advancedContent,
        },
    ])
        .filter((item) => !!item.children)
        .toArray();

    return (
        <Form form={form} onFinish={beforeSave} layout={layout} initialValues={initialValues}>
            <Drawer
                title={<Tag>{getSectionName(section?.type)}</Tag>}
                open
                onClose={() => close(form?.getFieldsValue())}
                width={window.innerWidth * (isDesktop ? 0.4 : 1)}
                bodyStyle={{ padding: 0 }}
                extra={
                    <div className="flex gap-3 items-center">
                        <Switch
                            defaultChecked={!isHidden}
                            checked={!isHidden}
                            onChange={(checked: boolean) => setIsHidden(!checked)}
                            checkedChildren={<EyeOutlined />}
                            unCheckedChildren={<EyeInvisibleOutlined />}
                        />
                        <ActionConfirmation
                            open={deleteClicked}
                            onOpenChange={setDeleteCLicked}
                            onConfirm={onDelete}
                            onCancel={() => setDeleteCLicked(false)}
                        >
                            <Form.Item className="mb-none">
                                <Button type="default" loading={loading} icon={<DeleteOutlined />} />
                            </Form.Item>
                        </ActionConfirmation>
                        {onSave && (
                            <Form.Item className="mb-none">
                                <Button
                                    type="primary"
                                    onClick={() => beforeSave(form?.getFieldsValue())}
                                    loading={loading}
                                    disabled={disabled}
                                >
                                    Save
                                </Button>
                            </Form.Item>
                        )}
                    </div>
                }
            >
                {!hideHeader && (
                    <div className="p-small border-b border-solid border-small border-t-none border-r-none border-l-none border-brand-inkGrey-grey_2">
                        <FormItem label="Title">
                            <div className="flex items-center">
                                <Form.Item name="name" className="mb-none w-full mr-small flex-1 flex-shrink-0">
                                    <Input placeholder="Name" />
                                </Form.Item>
                                <Button icon={<BgColorsOutlined />} onClick={() => setShowNameStyleModal(true)} />
                            </div>
                        </FormItem>
                    </div>
                )}
                <div className="p-small">
                    <Tabs defaultActiveKey="1" type="card" items={items} />
                </div>
            </Drawer>
            {renderNameStyleModal}
        </Form>
    );
}

export default SectionForm;
