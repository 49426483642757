interface Props {
    label: string;
    children: JSX.Element;
}

function FormItem(props: Props): JSX.Element {
    const { children, label } = props;
    return (
        <div className="flex flex-col w-full">
            <div className="mb-mini">
                <h6 className="mini semibold">{label}</h6>
            </div>
            {children}
        </div>
    );
}

export default FormItem;
