import { useState } from 'react';
import { Form, ColorPicker, Button, Switch } from 'antd';
import collect from 'collect.js';
import classnames from 'classnames';
import CustomForm from '../../forms/CustomForm';
import ThemeManager from '../../../services/api/ThemeManager';
import useRdxStore from '../../../hooks/useRdxStore';
import ThemeLayout from '../../layouts/ThemeLayout';
import { ColorVariablesInterface } from '../../../exports/Interfaces';
import { COLOR_VARIABLES } from '../../../exports/Enums';

function ColorItemForm({ variableKey, isDarkMode }: { variableKey: string; isDarkMode: boolean }): JSX.Element {
    const { store } = useRdxStore();

    const [form] = Form.useForm();

    const [loading, setLoading] = useState<boolean>(false);

    const onSave = async (): Promise<void> => {
        const values = form.getFieldsValue();
        setLoading(true);

        await ThemeManager.post({
            is_dark_mode: isDarkMode,
            [variableKey]: values,
        });

        setLoading(false);
    };

    const data = [
        {
            key: COLOR_VARIABLES.PRIMARY_COLOR,
            name: 'Primary',
        },
        {
            key: COLOR_VARIABLES.SECONDARY_COLOR,
            name: 'Secondary',
        },
        {
            key: COLOR_VARIABLES.BACKGROUND_COLOR,
            name: 'Primary Background',
        },
        {
            key: COLOR_VARIABLES.SECONDARY_BACKGROUND_COLOR,
            name: 'Secondary Background',
        },
        {
            key: COLOR_VARIABLES.BORDER_COLOR,
            name: 'Border',
        },
        {
            key: COLOR_VARIABLES.HEADER_COLOR,
            name: 'Header',
        },
        {
            key: COLOR_VARIABLES.DESCRIPTION_COLOR,
            name: 'Description',
        },
        {
            key: COLOR_VARIABLES.CARD_COLOR,
            name: 'Card',
        },
        {
            key: COLOR_VARIABLES.LIGHT_TEXT_COLOR,
            name: 'Light Text',
        },
        {
            key: COLOR_VARIABLES.DARK_TEXT_COLOR,
            name: 'Dark Text',
        },
    ];

    return (
        <Form
            form={form}
            initialValues={{
                ...(collect(store?.theme).get(variableKey) as ColorVariablesInterface),
            }}
        >
            {data.map((item) => (
                <div className="flex items-center mb-small">
                    <p
                        className={classnames('mini mr-mini', {
                            'text-white': isDarkMode,
                        })}
                    >
                        {item.name}:{' '}
                    </p>
                    <Form.Item name={item.key} className="mb-none">
                        <ColorPicker onChange={(color, hex) => form.setFieldValue(item.key, hex)} />
                    </Form.Item>
                </div>
            ))}
            <Form.Item className="mb-none">
                <Button type="primary" loading={loading} onClick={onSave}>
                    Save
                </Button>
            </Form.Item>
        </Form>
    );
}

function ColorsForm(): JSX.Element {
    const { store } = useRdxStore();

    const [isDarkMode, setIsDarkMode] = useState<boolean>(!!store?.theme?.is_dark_mode);

    return (
        <ThemeLayout title="Colors">
            <CustomForm
                className={classnames('lg:w-1/2 w-full', {
                    'bg-black': !!isDarkMode,
                })}
            >
                <div key={isDarkMode.toString()}>
                    <h4
                        className={classnames('mb-small', {
                            'text-white': isDarkMode,
                        })}
                    >
                        {isDarkMode ? 'Dark Mode' : 'Light Mode'}
                    </h4>
                    <div className="flex items-center mb-small">
                        <p
                            className={classnames('mini mr-mini', {
                                'text-white': isDarkMode,
                            })}
                        >
                            Dark Mode:{' '}
                        </p>

                        <Switch defaultChecked={isDarkMode} onChange={setIsDarkMode} />
                    </div>
                    <ColorItemForm
                        variableKey={isDarkMode ? 'dark_mode_color_variables' : 'color_variables'}
                        isDarkMode={isDarkMode}
                    />
                </div>
            </CustomForm>
        </ThemeLayout>
    );
}

export default ColorsForm;
