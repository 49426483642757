import { Form, Button, Input, Tabs, TabsProps } from 'antd';
import { useState } from 'react';
import { StoreInterface } from '../../../exports/Interfaces';
import useRdxStore from '../../../hooks/useRdxStore';
import CustomForm from '../../forms/CustomForm';
import SectionLayout from '../../layouts/SectionLayout';
import StoreManager from '../../../services/api/StoreManager';
import HeadScripts from './HeadScripts';

const { TextArea } = Input;

function InjectCodeForm(): JSX.Element {
    const { store } = useRdxStore();

    const [form1] = Form.useForm();

    const [form2] = Form.useForm();

    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = async (values: Partial<StoreInterface>): Promise<void> => {
        setLoading(true);
        await StoreManager.put(store?.id, values);
        setLoading(false);
    };

    const renderBodyNoScripts = (): JSX.Element => (
        <CustomForm title="Noscripts">
            <Form form={form2} initialValues={store} layout="vertical">
                <p className="text-brand-text-grey mb-small">Inject code immediately after opening body</p>
                <div className="mb-small">
                    <p>{'<noscripts>'}</p>
                    <Form.Item name="body_noscripts" className="mb-none">
                        <TextArea
                            rows={20}
                            placeholder='<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-AAA" height="0" width="0" style="display:none;visibility:hidden"></iframe> />'
                        />
                    </Form.Item>
                    <p>{'</noscripts>'}</p>
                </div>

                <Form.Item className="mb-none">
                    <Button type="primary" loading={loading} onClick={() => onFinish(form2.getFieldsValue())}>
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </CustomForm>
    );

    const renderGoogleSiteVerification = (): JSX.Element => (
        <CustomForm className="mb-large" title="Google Site verification">
            <Form form={form1} initialValues={store} layout="vertical">
                <div className="mb-small">
                    <Form.Item
                        name="google_site_verification"
                        label="Google Site Verification Code"
                        className="mb-none"
                    >
                        <Input placeholder="GTAHDAHGHDGS" />
                    </Form.Item>
                </div>
                <Form.Item className="mb-none">
                    <Button type="primary" loading={loading} onClick={() => onFinish(form1.getFieldsValue())}>
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </CustomForm>
    );

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Head Scripts',
            children: <HeadScripts />,
        },
        {
            key: '2',
            label: 'Body noscripts',
            children: renderBodyNoScripts(),
        },
        {
            key: '3',
            label: 'Google Site Verification',
            children: renderGoogleSiteVerification(),
        },
    ];

    return (
        <SectionLayout title="Inject Code">
            <Tabs items={items} />
        </SectionLayout>
    );
}

export default InjectCodeForm;
