import { useState } from 'react';
import { Input, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import collect from 'collect.js';
import { SectionInterface, YoutubeSectionInterface } from '../../../exports/Interfaces';
import SectionForm from '../../forms/SectionForm';
import SectionManager from '../../../services/api/SectionManager';
import useModals from '../../../hooks/useModals';
import FormGroup from '../../forms/structure/form-group';

interface Props {
    section: SectionInterface<YoutubeSectionInterface>;
}

function YoutubeSectionForm(props: Props): JSX.Element {
    const { section } = props;

    const { setSectionModal } = useModals();

    const content = section?.content;

    const [urls, setUrls] = useState<string[]>(content?.urls ?? ['']);

    const [loading, setLoading] = useState<boolean>(false);

    const onSave = async (values: Partial<SectionInterface<YoutubeSectionInterface>>): Promise<void> => {
        if (typeof section?.id !== 'number') {
            return;
        }
        await SectionManager.put<YoutubeSectionInterface>(section?.id, {
            ...values,
            content: {
                urls: collect(urls)
                    ?.filter((url) => !!url)
                    ?.toArray(),
            },
        });
        setLoading(false);
        setSectionModal({ open: false });
    };

    const updateUrl = (value: string, index: number): void => {
        const newUrls = [...urls];
        newUrls[index] = value;
        setUrls(newUrls);
    };

    const addInput = (): void => {
        const newUrls = [...urls];
        newUrls.push('');
        setUrls(newUrls);
    };

    return (
        <SectionForm section={section} onSave={onSave} loading={loading} initialValues={section}>
            <FormGroup label="Videos">
                <>
                    {urls?.map((url, index) => (
                        <Input
                            placeholder="Youtube id"
                            className="mb-small"
                            value={url}
                            onChange={(e) => updateUrl(e.target.value, index)}
                        />
                    ))}
                    <Button icon={<PlusOutlined />} onClick={addInput}>
                        Add
                    </Button>
                </>
            </FormGroup>
        </SectionForm>
    );
}

export default YoutubeSectionForm;
