import { Button } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import collect from 'collect.js';
import { useMemo, useState } from 'react';
import { onboardingRouter } from '../../../routes/onboarding/OnboardingRouter';
import CompleteOnboardingModal from '../../modals/CompleteOnboardingModal';

export interface OnboardingNavigationBarProps {
    nextTitle?: string;
    disableBack?: boolean;
    disableNext?: boolean;
    loadingNext?: boolean;
    onNext?: () => void;
}

function NavBar(props: OnboardingNavigationBarProps): JSX.Element {
    const { disableBack = false, disableNext = false, loadingNext = false, nextTitle, onNext } = props;

    const { pathname } = useLocation();

    const navigate = useNavigate();

    const routes = collect(collect(onboardingRouter).first().children);

    const currentIndex = routes.search((route: any) => route.path === pathname);

    const isLastRoute = useMemo(() => routes.last().path === pathname, [pathname, routes]);

    const [showCompleteOnboardingModal, setShowCompleteOnboardingModal] = useState<boolean>(false);

    const _onNext = (): void => {
        const nextRoute = routes.get(currentIndex + 1) as any;

        if (isLastRoute) {
            setShowCompleteOnboardingModal(true);
            return;
        }
        if (nextRoute) {
            navigate(nextRoute.path);
        }
    };

    const _onBack = (): void => {
        const previousRoute = routes.get(currentIndex - 1) as any;
        if (previousRoute) {
            navigate(previousRoute.path);
        }
    };

    const renderCompleteOnboardingModal = useMemo(
        () => <CompleteOnboardingModal open={showCompleteOnboardingModal} toggle={setShowCompleteOnboardingModal} />,
        [showCompleteOnboardingModal],
    );

    return (
        <div>
            <div
                className="px-large py-small border border-b border-solid border-brand-inkGrey-grey_2 flex justify-between items-center sticky top-0 bg-background-inkWhite-white_0"
                style={{ zIndex: 500 }}
            >
                <Button size="large" onClick={_onBack} disabled={disableBack}>
                    Back
                </Button>
                <h6 className="text-center text-brand-text-grey">
                    ( {currentIndex + 1} / {routes?.count()} )
                </h6>
                <Button
                    size="large"
                    onClick={() => (onNext ? onNext() : _onNext())}
                    disabled={disableNext}
                    loading={loadingNext}
                >
                    {nextTitle || (isLastRoute ? 'Finish' : 'Next')}
                </Button>
            </div>
            {renderCompleteOnboardingModal}
        </div>
    );
}

export default NavBar;
