import { Form, Input, Button, Drawer } from 'antd';
import { useState } from 'react';
import collect from 'collect.js';
import { ImpressumInterface, MediaInterface } from '../../exports/Interfaces';
import useScreenType from '../../hooks/useScreenType';
import ImpressumManager from '../../services/api/ImpressumManager';
import SelectedMediaList from '../media/SelectedMediaList';
import { DB_MODELS, MEDIA_TAGS, MEDIA_TYPES } from '../../exports/Enums';
import MediaManager from '../../services/api/MediaManager';
import InitialDataManager from '../../services/api/InitialDataManager';

interface Props {
    open: boolean;
    impressum?: ImpressumInterface | null;
    toggle: (open?: boolean) => void;
}

function ImpressumModal(props: Props): JSX.Element {
    const { open, impressum, toggle } = props;

    const isUpdate = !!impressum;

    const [form] = Form.useForm();

    const { isDesktop } = useScreenType();

    const [loading, setLoading] = useState<boolean>(false);

    const [mediaList, setMediaList] = useState<MediaInterface[]>([]);

    const onFinish = async (values: any): Promise<void> => {
        setLoading(true);
        const data = collect(values)
            ?.filter((value) => value !== undefined)
            .all() as Partial<ImpressumInterface>;

        let request;
        if (typeof impressum?.id === 'number') {
            request = await ImpressumManager.put(impressum?.id, data);
        } else {
            request = await ImpressumManager.post(data);
        }

        const { response, success } = request;

        if (success && typeof response?.data?.data?.id === 'number') {
            await MediaManager.post(DB_MODELS.IMPRESSUM, response.data.data.id, mediaList, MEDIA_TAGS.LOGO, '');
        }

        await InitialDataManager.get();

        setLoading(false);
        toggle(false);
    };

    return (
        <Drawer
            title={isUpdate ? 'Update Impressum' : 'Add Impressum'}
            open={open}
            onClose={() => toggle(false)}
            footer={null}
            width={isDesktop ? window.innerWidth * 0.3 : window.innerWidth}
            extra={
                <Button type="primary" loading={loading} onClick={() => onFinish(form.getFieldsValue())}>
                    Save
                </Button>
            }
        >
            <Form
                name="basic"
                initialValues={{
                    remember: true,
                    ...impressum,
                }}
                onFinish={onFinish}
                layout="vertical"
                form={form}
            >
                <div className="mb-small w-full">
                    <SelectedMediaList
                        uploadCount={1}
                        mediaType={MEDIA_TYPES.IMAGE}
                        defaultMediaList={impressum?.logo ? [impressum?.logo] : []}
                        mediaList={mediaList}
                        title="Logo"
                        setMediaList={setMediaList}
                    />
                </div>
                <Form.Item name="legal_name" label="Company Legal Name">
                    <Input placeholder="Enter Company Legal Name" />
                </Form.Item>
                <Form.Item name="owner" label="Owner">
                    <Input placeholder="Enter Owner" />
                </Form.Item>
                <Form.Item name="address" label="Address">
                    <Input placeholder="Enter Address" />
                </Form.Item>

                <Form.Item name="phone_number" label="Phone number">
                    <Input placeholder="Enter Phone Number" />
                </Form.Item>

                <Form.Item name="email" label="Email">
                    <Input placeholder="Enter Email Address" />
                </Form.Item>

                <Form.Item name="web" label="Website">
                    <Input placeholder="Enter Website" />
                </Form.Item>

                <Form.Item name="tax_id" label="Tax">
                    <Input placeholder="Enter Tax Id" />
                </Form.Item>

                <Form.Item name="supervisory_authority" label="Supervisory Authority">
                    <Input placeholder="Enter Supervisory Authority" />
                </Form.Item>

                <Form.Item name="grantor" label="Grantor">
                    <Input placeholder="Enter Grantor" />
                </Form.Item>
            </Form>
        </Drawer>
    );
}

export default ImpressumModal;
