import { message } from 'antd';
import { DataWrapper, Response, StoreInterface } from '../../exports/Interfaces';
import actionCreators from '../../store/actions';
import ReduxResource from '../resources/ReduxResource';
import BackendApiClient from './BackendApiClient';

export default class StoreManager {
    public static put = async (
        id: number,
        data?: Partial<StoreInterface>,
    ): Promise<Response<DataWrapper<DataWrapper<StoreInterface>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<StoreInterface>>>({
            method: 'PUT',
            url: `/stores/${id}`,
            data,
        });

        const { response, success } = request;

        if (response?.data?.data && success) {
            StoreManager.save(response?.data?.data);
            message.success('Updated');
        }

        return request;
    };

    private static save = (store: StoreInterface): void => {
        ReduxResource.dispatch(actionCreators.store.setAll(store));
    };
}
