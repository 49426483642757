import { GuestRangeInterface } from './Interfaces';

export const dummy = 1;

export const dummy1 = 2;

export const COOKIES = {
    AUTH_TOKEN: `AUTH_TOKEN_${import.meta.env.MODE}`,
    ON_BEHALF_OF_STORE: `ON_BEHALF_OF_STORE_${import.meta.env.MODE}`,
};

export const SEO_TITLE_MAX_LENGTH = 60;

export const SEO_DESCRIPTION_MAX_LENGTH = 160;

export const DAYS = [0, 1, 2, 3, 4, 5, 6];

export const DEFAULT_GUEST_RANGE: GuestRangeInterface[] = [
    { from: 0, to: 10 },
    { from: 10, to: 25 },
    { from: 25, to: 50 },
    { from: 50, to: 100 },
    { from: 100, to: 150 },
    { from: 150, to: null },
];
