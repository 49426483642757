import { Button, Form, Input } from 'antd';
import classnames from 'classnames';
import { SEO_TITLE_MAX_LENGTH } from '../../../exports/Constants';

function Count({ value, maxCount }: { value: string; maxCount: number }): JSX.Element {
    return (
        <p
            className={classnames('mini text-brand-danger', {
                'text-brand-text-grey': value?.length < maxCount,
            })}
        >
            {value?.length} / {maxCount}
        </p>
    );
}

interface Props {
    title: string;
    description: string;
    loading: boolean;
    setTitle: (value: string) => void;
    setDescription: (value: string) => void;
    setEditPreview: (value: boolean) => void;
    onSave: ({ title, description }: { title: string; description: string }) => void;
}

function SeoEditPreview(props: Props): JSX.Element {
    const { title, description, loading, setTitle, setDescription, setEditPreview, onSave } = props;

    return (
        <Form
            initialValues={{
                title,
                description,
            }}
            onFinish={(values) => {
                setTitle(values?.title);
                setDescription(values?.description);
                onSave(values);
            }}
        >
            <Form.Item name="title" label="Title">
                <Input placeholder="Enter Title" addonAfter={<Count value={title} maxCount={SEO_TITLE_MAX_LENGTH} />} />
            </Form.Item>

            <Form.Item name="description" label="Description">
                <Input.TextArea placeholder="Enter Description" showCount />
            </Form.Item>
            <div className="flex items-center">
                <Button type="primary" htmlType="submit" className="mr-small" loading={loading}>
                    Save
                </Button>
                <Button type="default" onClick={() => setEditPreview(false)} className="mr-small">
                    Close
                </Button>
            </div>
        </Form>
    );
}

export default SeoEditPreview;
