import { useState, useMemo } from 'react';
import { Form, Input, Button, Modal, message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CheckOutlined } from '@ant-design/icons';
import useScreenType from '../../hooks/useScreenType';

interface Props {
    toggle: (open: boolean) => void;
}

function NoTranslateModal(props: Props): JSX.Element | null {
    const { toggle } = props;

    const { isDesktop } = useScreenType();

    const [value, setValue] = useState<string>('Example');

    const [copied, setCopied] = useState<boolean>(false);

    const formattedValue = useMemo(() => {
        const text = `[${value.split('').join('_')}]`;
        return value ? text : '';
    }, [value]);

    const onCopy = (): void => {
        setCopied(true);
        message.success(`Copied Text ${formattedValue} to your clipboard`);
        toggle(false);
    };

    return (
        <Modal
            title="No Translate"
            open
            onCancel={() => toggle(false)}
            footer={null}
            width={isDesktop ? window.innerWidth * 0.4 : window.innerWidth}
        >
            <Form.Item label="Enter Text that will not get translated">
                <Input placeholder="Enter Text" value={value} onChange={(e) => setValue(e.target.value)} />
            </Form.Item>

            <Form.Item className="mb-none">
                <CopyToClipboard text={formattedValue} onCopy={onCopy}>
                    <Button type="primary" icon={copied ? <CheckOutlined /> : null}>
                        Copy {formattedValue}
                    </Button>
                </CopyToClipboard>
            </Form.Item>
        </Modal>
    );
}

export default NoTranslateModal;
