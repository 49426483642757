import { useState } from 'react';
import { Spin } from 'antd';
import ElementCard from '../../../../cards/ElementCard';
import useModals from '../../../../../hooks/useModals';
import ElementsWrapper from '../../tabs/ElementsWrapper';
import SectionManager from '../../../../../services/api/SectionManager';
import { SECTION_TYPES } from '../../../../../exports/Enums';

function IFrame(): JSX.Element {
    const { featureModal, setFeatureModal } = useModals();

    const [loading, setLoading] = useState<boolean>(false);

    const pageId = featureModal?.pageId;

    const onCreate = async (): Promise<void> => {
        if (typeof pageId !== 'number') {
            return;
        }

        setLoading(true);
        const { success } = await SectionManager.post(pageId, {
            type: SECTION_TYPES.IFRAME_SECTION,
            name: 'iFrame',
            is_hidden: true,
            content: {},
        });

        setLoading(false);

        if (success) {
            setFeatureModal({ open: false });
        }
    };

    if (loading) {
        return (
            <div className="w-full h-full flex justify-center items-center">
                <Spin />
            </div>
        );
    }

    return (
        <ElementsWrapper>
            <ElementCard title="Embed iFrame" isActive onClick={onCreate} />
        </ElementsWrapper>
    );
}

export default IFrame;
