/* eslint-disable class-methods-use-this */

class AppContextResource {
    public cookieContext: any;

    public setCookieContext = (context: any): void => {
        this.cookieContext = context;
    };
}

export default new AppContextResource();
