import { Form, Button, Switch, Select, InputNumber, Tooltip } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { FontInterface, TextInterface } from '../../exports/Interfaces';
import CustomForm from '../forms/CustomForm';
import FontPickerSearchInput from '../inputs/FontPickerSearchInput';
import { FONT_WEIGHT_TYPES } from '../../exports/Enums';
import ColorSelect from '../selects/ColorSelect';

interface Props {
    title?: string;
    initialValues?: TextInterface | null;
    loading?: boolean;
    hideColor?: boolean;
    hideFontFamily?: boolean;
    hideCapital?: boolean;
    saveTitle?: string;
    onSave: (values: any) => void;
}

function TypographyItem(props: Props): JSX.Element {
    const { title, initialValues, loading, hideColor = false, saveTitle, hideFontFamily, hideCapital, onSave } = props;

    const [form] = Form.useForm();

    const _onSave = async (): Promise<void> => {
        const payload = form.getFieldsValue();
        onSave(payload);
    };

    const FONT_WEIGHTS = [
        {
            label: 'Light',
            value: FONT_WEIGHT_TYPES.LIGHT,
        },
        {
            label: 'Regular',
            value: FONT_WEIGHT_TYPES.REGULAR,
        },
        {
            label: 'Medium',
            value: FONT_WEIGHT_TYPES.MEDIUM,
        },
        {
            label: 'SemiBold',
            value: FONT_WEIGHT_TYPES.SEMI_BOLD,
        },
        {
            label: 'Bold',
            value: FONT_WEIGHT_TYPES.BOLD,
        },
    ];

    return (
        <CustomForm>
            <Form
                form={form}
                name="theme"
                initialValues={{
                    remember: true,
                    ...initialValues,
                }}
            >
                <h6 className="mb-small">{title}</h6>
                {!hideCapital && (
                    <Form.Item name="all_capital" label="All Capital Letters">
                        <Switch
                            defaultChecked={initialValues?.all_capital}
                            onChange={(checked) => form.setFieldValue('all_capital', checked)}
                        />
                    </Form.Item>
                )}

                {!hideFontFamily && (
                    <Form.Item name="font" label="Font Family">
                        <FontPickerSearchInput
                            initialFont={initialValues?.font}
                            setFont={(font: FontInterface | null) => form.setFieldValue('font', font)}
                        />
                    </Form.Item>
                )}

                <Form.Item name="font_weight_type" label="Font Weight">
                    <div className="flex items-center">
                        <Select
                            options={FONT_WEIGHTS}
                            placeholder="Select font weight"
                            defaultValue={initialValues?.font_weight_type}
                            onChange={(value) => form.setFieldValue('font_weight_type', value)}
                        />
                        <Tooltip title="Some fonts do not allow certain font weights" className="ml-small">
                            <InfoCircleFilled />
                        </Tooltip>
                    </div>
                </Form.Item>
                <Form.Item name="letter_spacing" label="Letter Spacing">
                    <InputNumber
                        placeholder="Select letter spacing"
                        defaultValue={initialValues?.letter_spacing ?? 1}
                        onChange={(value) => form.setFieldValue('letter_spacing', value)}
                        className="w-full"
                        type="number"
                    />
                </Form.Item>
                <Form.Item name="size_multiplier" label="Font Size Multiplier">
                    <div className="flex items-center">
                        <InputNumber
                            placeholder="Enter font size multiplier"
                            defaultValue={initialValues?.size_multiplier ?? 1}
                            onChange={(value) => form.setFieldValue('size_multiplier', value)}
                            className="w-full"
                            type="number"
                        />
                        <Tooltip
                            title="The value entered in the font size multiplier field will be used to adjust the default font size. Multiplying this value with the default font size will either increase or decrease the resulting font size, depending on the value provided."
                            className="ml-small"
                        >
                            <InfoCircleFilled />
                        </Tooltip>
                    </div>
                </Form.Item>
                <Form.Item name="line_height_multiplier" label="Line Height Multiplier">
                    <div className="flex items-center">
                        <InputNumber
                            placeholder="Enter line height multiplier"
                            defaultValue={initialValues?.line_height_multiplier ?? 1}
                            onChange={(value) => form.setFieldValue('line_height_multiplier', value)}
                            className="w-full"
                            type="number"
                        />
                        <Tooltip
                            title="The value entered in the line height multiplier field will be used to adjust the default line height. Multiplying this value with the default line height will either increase or decrease the resulting line height, depending on the value provided."
                            className="ml-small"
                        >
                            <InfoCircleFilled />
                        </Tooltip>
                    </div>
                </Form.Item>
                {!hideColor && (
                    <Form.Item name="color" label="Color">
                        <ColorSelect
                            initialColor={initialValues?.color}
                            onSelect={(value) => form.setFieldValue('color', value)}
                        />
                    </Form.Item>
                )}
                <Form.Item className="mb-none">
                    <Button type="primary" loading={loading} onClick={_onSave}>
                        {saveTitle ?? 'Save'}
                    </Button>
                </Form.Item>
            </Form>
        </CustomForm>
    );
}

export default TypographyItem;
