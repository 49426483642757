import Content from '../Content';
import OrderMethodsForm from '../../../components/restaurantInfo/order-methods/OrderMethodsForm';

function OrderMethods(): JSX.Element {
    return (
        <Content title="What order methods do you require?">
            <OrderMethodsForm />
        </Content>
    );
}

export default OrderMethods;
