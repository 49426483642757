import { PayloadAction } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { RdxStoreInterface } from '../../exports/Interfaces';
import { rehydration, store } from '../../store/index';

export default class ReduxResource {
    public static dispatch = (action: PayloadAction): void => {
        store.dispatch(action);
    };

    public static getStore = (): RdxStoreInterface => store.getState();

    public static clearReduxStore = (): void => {
        store.dispatch({ type: 'RESET_STORE' });
    };

    /**
     * Dispatch multiple actions and cause a single rerender
     * @method batchActions
     */
    public static batchActions = (actionArray: any[]): void =>
        batch(() => {
            actionArray.forEach((action) => {
                store.dispatch(action);
            });
        });

    /**
     * Wait until Redux store is hydrated
     * @method rehydrated
     */
    public static rehydrated = (): Promise<boolean> => rehydration();
}
