import { useState } from 'react';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import LinkInputModal from '../modals/LinkInputModal';
import { LinkInputProps } from '../../exports/Interfaces';
import FormGroup from '../forms/structure/form-group';

function LinkInput(props: LinkInputProps): JSX.Element {
    const { label, link, disabled, extra, setLink } = props;

    const [open, setOpen] = useState<boolean>(false);

    return (
        <>
            <FormGroup
                label={link?.title ?? label ?? 'Edit Button'}
                onClick={() => setOpen(true)}
                headerExtra={
                    <div className="flex items-center gap-2">
                        <Button
                            icon={<DeleteOutlined />}
                            onClick={() => setLink(null)}
                            disabled={disabled}
                            className="ml-mini"
                        />
                        {extra}
                    </div>
                }
            />
            {open && <LinkInputModal open={open} toggle={setOpen} {...props} />}
        </>
    );
}

export default LinkInput;
