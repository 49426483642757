import moment from 'moment';
import { COOKIES } from '../../exports/Constants';
import { Response, AuthDataInterface, DataWrapper } from '../../exports/Interfaces';
import BackendApiClient from './BackendApiClient';
import AppContextResource from '../resources/AppContextResource';

export default class AuthManager {
    public static refreshToken = async (): Promise<Response<DataWrapper<DataWrapper<AuthDataInterface>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<AuthDataInterface>>>({
            method: 'GET',
            url: `/refresh-token`,
            baseURL: import.meta.env.VITE_SSO_API_BASE_URL,
        });

        const { response, success } = request;

        if (success) {
            AppContextResource?.cookieContext?.setCookie(COOKIES.AUTH_TOKEN, response.data.data.access_token ?? '', {
                expires: moment().add(399, 'days').toDate(),
            });
        }

        return request;
    };
}
