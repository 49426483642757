import { useState } from 'react';
import { Button, Form, Switch } from 'antd';
import collect from 'collect.js';
import CustomForm from '../../forms/CustomForm';
import useRdxStore from '../../../hooks/useRdxStore';
import StoreManager from '../../../services/api/StoreManager';
import { StoreInterface } from '../../../exports/Interfaces';

function OrderMethodsForm(): JSX.Element {
    const { store } = useRdxStore();

    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = async (values: any): Promise<void> => {
        setLoading(true);
        await StoreManager.put(
            store?.id,
            collect(values)
                ?.filter((value) => value !== undefined)
                .all() as Partial<StoreInterface>,
        );
        setLoading(false);
    };

    return (
        <CustomForm>
            <Form
                name="basic"
                initialValues={{
                    remember: true,
                    has_pickup: store?.has_pickup,
                    has_delivery: store?.has_delivery,
                }}
                onFinish={onFinish}
            >
                <Form.Item label="Pickup" name="has_pickup" valuePropName="checked">
                    <Switch />
                </Form.Item>

                <Form.Item label="Delivery" name="has_delivery" valuePropName="checked">
                    <Switch />
                </Form.Item>

                <Form.Item className="mb-none">
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </CustomForm>
    );
}

export default OrderMethodsForm;
