import useRdxStore from '../../../hooks/useRdxStore';
import Content from '../Content';
import SeoForm from '../../../components/restaurantInfo/seo/SeoForm';

function Seo(): JSX.Element {
    const { store } = useRdxStore();

    return (
        <Content title="Seo" disableBack>
            {store.home_page && <SeoForm pageId={store.home_page?.id} seo={store.home_page?.seo} />}
        </Content>
    );
}

export default Seo;
