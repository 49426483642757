import Unauthorized from '../../components/authorization/Unauthorized';
import { RouteGuardInterface } from '../../exports/Interfaces';
import useRdxStore from '../../hooks/useRdxStore';

function CateringGuard(props: RouteGuardInterface): JSX.Element {
    const { element } = props;

    const { store } = useRdxStore();

    if (!store?.has_catering) {
        return <Unauthorized />;
    }

    return element;
}

export default CateringGuard;
