import { useCallback } from 'react';
import { CURRENCY_TYPES } from '../exports/Enums';
import useRdxStore from './useRdxStore';

function useCurrency(): {
    currencySymbol: string;
    getPrice: (price?: number, precision?: number) => string;
} {
    const { store } = useRdxStore();

    const getCurrencySymbol = useCallback((): string => {
        switch (store?.currency) {
            case CURRENCY_TYPES.DOLLAR:
                return '$';
            case CURRENCY_TYPES.POUND:
                return '£';
            default:
                return '€';
        }
    }, [store?.currency]);

    const getPrice = (price = 0, precision = 2): string => `${getCurrencySymbol()}${price?.toFixed(precision)}`;

    return {
        currencySymbol: getCurrencySymbol(),
        getPrice,
    };
}

export default useCurrency;
