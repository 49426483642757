import { Outlet, useLocation } from 'react-router-dom';
import ContentLayout from '../components/layouts/ContentLayout';
import { PAGES, SUB_PAGES, SUB_PAGE_NAMES } from '../exports/Enums';
import GroupOption from '../components/cards/GroupOption';
import { GroupOptionCardInterface } from '../exports/Interfaces';

function MiddleContent(): JSX.Element {
    const data: GroupOptionCardInterface[] = [
        {
            type: 'group',
            groupTitle: SUB_PAGE_NAMES.CATERING,
            page: PAGES.PLUGINS,
            options: [
                {
                    title: SUB_PAGE_NAMES.SETTINGS,
                    page: `${SUB_PAGES.CATERING}${SUB_PAGES.SETTINGS}`,
                },
            ],
        },
        {
            type: 'group',
            groupTitle: SUB_PAGE_NAMES.EVENTS,
            page: PAGES.PLUGINS,
            options: [
                {
                    title: SUB_PAGE_NAMES.SETTINGS,
                    page: `${SUB_PAGES.EVENTS}${SUB_PAGES.SETTINGS}`,
                },
            ],
        },
        {
            type: 'individual',
            page: PAGES.PLUGINS,
            option: {
                title: SUB_PAGE_NAMES.POPUPS,
                page: `${SUB_PAGES.POPUPS}`,
            },
        },
    ];
    return (
        <div className="p-small">
            {data?.map((item) => (
                <div className="mb-small">
                    <GroupOption {...item} />
                </div>
            ))}
        </div>
    );
}

function PluginsPage(): JSX.Element {
    const { pathname } = useLocation();
    return (
        <ContentLayout title="Plugins" middleContent={<MiddleContent />}>
            <div className="w-full h-full">
                <Outlet key={pathname} />
            </div>
        </ContentLayout>
    );
}

export default PluginsPage;
