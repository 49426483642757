import { DataWrapper, PageContentInterface, PageDataInterface, Response } from '../../../exports/Interfaces';
import BackendApiClient from '../BackendApiClient';

export default class PageManager {
    public static get = async (id: number): Promise<Response<DataWrapper<DataWrapper<PageContentInterface>>>> =>
        BackendApiClient.request<DataWrapper<DataWrapper<PageContentInterface>>>({
            method: 'GET',
            url: `/pages/${id}`,
        });

    public static post = async (
        data: PageDataInterface,
    ): Promise<Response<DataWrapper<DataWrapper<PageContentInterface>>>> =>
        BackendApiClient.request<DataWrapper<DataWrapper<PageContentInterface>>>({
            method: 'POST',
            url: '/pages',
            data,
        });

    public static delete = async (id: number): Promise<Response<DataWrapper<DataWrapper<null>>>> =>
        BackendApiClient.request<DataWrapper<DataWrapper<null>>>({
            method: 'DELETE',
            url: `/pages/${id}`,
        });
}
