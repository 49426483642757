import SectionHeader from '../navigation/SectionHeader';

interface Props {
    children: React.ReactNode;
    title?: string | JSX.Element;
    to?: string;
    centerContent?: JSX.Element;
    rightContent?: JSX.Element;
    hideClose?: boolean;
}

function SectionLayout(props: Props): JSX.Element {
    const { children, title, to, centerContent, rightContent, hideClose } = props;
    return (
        <div className="w-full relative">
            <div className="sticky top-0 z-10">
                <SectionHeader
                    title={title ?? 'Edit'}
                    to={to}
                    centerContent={centerContent}
                    rightContent={rightContent}
                    hideClose={hideClose}
                />
            </div>
            <div className="w-full p-small scrollbar-hide h-screen overflow-y-auto bg-background-inkWhite-white_1">
                {children}
            </div>
        </div>
    );
}

export default SectionLayout;
