import { InfoCircleOutlined } from '@ant-design/icons';
import Content from '../Content';
import DraggableNavigationList from '../../../components/sections/draggableNavigationItems/DraggableNavigationList';

function Navigation(): JSX.Element {
    function renderGuide(): JSX.Element {
        const tabs = [
            'Reservations (if available)',
            'Catering (if available)',
            'Events (if available)',
            'About Us',
            'Location & Hours',
        ];
        return (
            <div className="w-full bg-background-inkWhite-white_0 h-full border-brand-inkGrey-grey_2 border-t-none border-r-none border-b-none border-solid px-small py-medium overscroll-contain">
                <h3 className="mb-small">
                    <InfoCircleOutlined /> Info
                </h3>
                <p className="text-brand-text-grey mini">Rearrange the navigation tabs to match the desired layout.</p>
                <div className="py-small">
                    {tabs.map((tab, index) => (
                        <div className="py-small border border-t-none border-r-none border-l-none border-solid border-brand-inkGrey-grey_2">
                            {index + 1}. {tab}
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    return (
        <Content title="Navigation Bar" rightColumn={renderGuide()}>
            <DraggableNavigationList />
        </Content>
    );
}

export default Navigation;
