import collect from 'collect.js';
import { useMemo } from 'react';
import { NavigationItemInterface } from '../exports/Interfaces';
import useRdxStore from './useRdxStore';

function usePages(): { navigationItems: NavigationItemInterface[] } {
    const { store } = useRdxStore();

    const navigationItems = useMemo(
        () => collect(store?.navigation_items).where('section_id', null).toArray(),
        [store?.pages, store?.navigation_items],
    ) as NavigationItemInterface[];

    return {
        navigationItems,
    };
}

export default usePages;
