import { useEffect, useMemo, useState } from 'react';
import { Alert, Button, Checkbox, Drawer, message, Spin, Tooltip, Upload } from 'antd';
import { ExclamationCircleOutlined, InfoCircleFilled, UploadOutlined } from '@ant-design/icons';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import collect from 'collect.js';
import classnames from 'classnames';
import {
    MediaConfigInterface,
    MediaContextInterface,
    MediaInterface,
    MediaContentInterface,
} from '../exports/Interfaces';
import MediaContext from '../context/MediaContext';
import GalleryManager from '../services/api/GalleryManager';
import MediaItem from '../components/media/MediaItem';
import useTheme from '../hooks/useTheme';
import InitialDataManager from '../services/api/InitialDataManager';
import { MEDIA_TYPES } from '../exports/Enums';
import MediaResource from '../services/resources/MediaResource';

function MediaProvider(props: { children: JSX.Element }): JSX.Element {
    const { children } = props;

    const theme = useTheme();

    const [shouldCompress, setShouldCompress] = useState<boolean>(true);

    const [loading, setLoading] = useState<boolean>(false);

    const [uploading, setUploading] = useState<boolean>(false);

    const [data, setData] = useState<MediaInterface[]>([]);

    const [selectedItems, setSelectedItems] = useState<MediaInterface[]>([]);

    const [config, setConfig] = useState<MediaConfigInterface | undefined>();

    const [currentTab, setCurrentTab] = useState<MEDIA_TYPES>(config?.mediaType ?? MEDIA_TYPES.IMAGE);

    const getGallery = async (): Promise<void> => {
        setLoading(true);
        const { response, success } = await GalleryManager.get();
        if (success && response?.data?.data) {
            setData(response?.data?.data);
        }
        setLoading(false);
    };

    const reset = (): void => {
        setConfig(undefined);
        setCurrentTab(MEDIA_TYPES.IMAGE);
        setSelectedItems([]);
    };

    const onSelect = (): void => {
        if (config?.onSelect) {
            config?.onSelect(selectedItems);
        }

        reset();
    };

    useEffect(() => {
        if (config?.open) {
            setTimeout(() => {
                getGallery();
            }, 800);
        }
    }, [config?.open]);

    const handleSelectItem = (item: MediaInterface): void => {
        if (config?.mediaType !== item.type) {
            return;
        }

        if (config?.maxSelect === 1) {
            setSelectedItems([item]);
            return;
        }

        if (typeof config?.maxSelect === 'number' && selectedItems?.length >= config?.maxSelect) {
            return;
        }

        setSelectedItems((prevItems) => [...prevItems, item]);
    };

    const handleDeselectItem = (item: MediaInterface): void => {
        setSelectedItems((prevItems) => prevItems.filter((prevItem) => prevItem.id !== item.id));
    };

    const onEdit = async (file: UploadFile<any>, id: string): Promise<void> => {
        setUploading(true);
        const formData = new FormData();
        formData.append('media', file.originFileObj as RcFile, file.name);
        formData.append('_method', 'PUT');

        const { response, success } = await GalleryManager.put(id, formData);
        if (success && response?.data?.data) {
            const index = collect(data)?.search((media) => media?.id === id);
            if (index !== undefined && index > -1) {
                const newData = [...data];
                newData[index] = response?.data?.data;
                setData(newData);
                InitialDataManager.get();
            }
        }

        setUploading(false);
    };

    const onDelete = async (id: string): Promise<void> => {
        setLoading(true);
        const { success } = await GalleryManager.delete(id);
        if (success) {
            setData(
                collect(data)
                    ?.filter((media) => media?.id !== id)
                    ?.toArray(),
            );
            InitialDataManager.get();
        }
        setLoading(false);
    };

    const uploadMedia = (e: UploadRequestOption<any>): void => {
        const formData = new FormData();
        formData.append('media', e.file as Blob, (e.file as File).name ?? e.filename);
        formData.append('skip_optimization', shouldCompress ? '0' : '1');

        setUploading(true);
        GalleryManager.post(formData)
            .then(({ success, response, status }): void => {
                if (!success && (!status || status === 413)) {
                    setUploading(false);

                    message.error('File is too big (max size is 300 MB)');
                    return undefined;
                }

                if (!success || !response?.data?.data) {
                    return undefined;
                }

                return setData([response?.data?.data, ...data]);
            })
            .finally(() =>
                setTimeout(() => {
                    setUploading(false);
                    getGallery();
                }, 200),
            );
    };

    const filteredMedia: MediaInterface[] = useMemo(() => {
        const collection = collect(data).where('type', currentTab);

        if (config?.mediaExtension) {
            collection.where('extension', config.mediaExtension);
        }

        return collection.toArray();
    }, [data, currentTab, config?.mediaExtension]);

    const renderLoading = useMemo(() => {
        if (uploading) {
            return (
                <div className="w-full h-full fixed inset-0 flex justify-center items-center">
                    <div className="flex flex-col justify-center items-center bg-white p-small rounded-md shadow">
                        <Spin size="large" className="mb-mini" />
                        <p className="text-brand-text-grey mini">Compressing & Uploading</p>
                    </div>
                </div>
            );
        }
        return loading ? (
            <div className="w-full h-full fixed inset-0 flex justify-center items-center">
                <div className="w-[80px] h-[80px] flex justify-center items-center bg-white p-small rounded-md shadow">
                    <Spin size="large" />
                </div>
            </div>
        ) : null;
    }, [loading, uploading]);

    function renderFooter(): JSX.Element {
        return (
            <div className="w-full flex lg:flex-row flex-col justify-between lg:items-center items-start bottom-0 border border-solid border-brand-inkGrey-grey_2 border-t border-l-none border-r-none border-b-none pt-small">
                <div>
                    <Upload
                        customRequest={uploadMedia}
                        fileList={[]}
                        accept={MediaResource.getFileType(config?.mediaType ?? currentTab)}
                        maxCount={20}
                        multiple
                        className="block w-fit"
                    >
                        <Button icon={<UploadOutlined />} loading={loading} className="w-full">
                            Click to upload
                        </Button>
                    </Upload>
                    <Checkbox
                        checked={shouldCompress}
                        onChange={(e) => setShouldCompress(e.target.checked)}
                        className="mt-xsmall w-full"
                    >
                        Compress and reduce size of image/ video for faster loading time
                        <Tooltip
                            placement="right"
                            title="Compression is recommended for images (above 500 KB) and videos (above 15 MB). You can turn off compression when the media is already compressed."
                        >
                            <InfoCircleFilled className="ml-mini" />
                        </Tooltip>
                    </Checkbox>
                    <p className="mt-xsmall text-left">Max upload size - 300 MB</p>
                </div>

                {config?.maxSelect && (
                    <p
                        className={classnames('mini', {
                            'text-brand-danger': selectedItems?.length === config?.maxSelect,
                        })}
                    >
                        Max Select ({selectedItems?.length ?? 0}/{config?.maxSelect})
                        {config?.mediaType && ` - ${config.mediaType}(s) only`}
                    </p>
                )}

                <div className="flex items-center lg:mt-none mt-small lg:w-fit w-full gap-2">
                    <Button key="back" onClick={() => setSelectedItems([])} className="lg:w-fit w-full">
                        Deselect All
                    </Button>

                    <Button
                        key="submit"
                        type="primary"
                        onClick={onSelect}
                        disabled={!config?.onSelect || selectedItems?.length === 0}
                        className="lg:w-fit w-full"
                    >
                        Select {selectedItems?.length > 0 ? `(${selectedItems?.length})` : ''}
                    </Button>
                </div>
            </div>
        );
    }

    const renderTabs = useMemo(() => {
        const tabs = [
            {
                title: 'Images',
                mediaType: MEDIA_TYPES.IMAGE,
            },
            {
                title: 'Videos',
                mediaType: MEDIA_TYPES.VIDEO,
            },
            {
                title: 'PDFs',
                mediaType: MEDIA_TYPES.PDF,
            },
            {
                title: 'Fonts',
                mediaType: MEDIA_TYPES.FONT,
            },
            {
                title: 'Documents',
                mediaType: MEDIA_TYPES.DOCUMENT,
            },
        ];

        return (
            <div>
                {tabs?.map((tab) => (
                    <Button
                        type="text"
                        className="rounded-none h-[60px] m-none flex justify-start items-center w-full border border-brand-inkGrey-grey_2 border-solid border-t-none border-r-none border-l-none bg-background-inkWhite-white_0 hover:bg-brand-inkGrey-grey_1"
                        onClick={() => setCurrentTab(tab.mediaType)}
                        disabled={config?.mediaType && config?.mediaType !== tab?.mediaType}
                        key={tab.mediaType}
                    >
                        <h6
                            className={classnames('text-brand-text-default', {
                                'text-brand-primary': currentTab === tab.mediaType,
                            })}
                        >
                            {tab.title}
                        </h6>
                    </Button>
                ))}
            </div>
        );
    }, [currentTab, config?.mediaType]);

    const renderEmptyList = (): JSX.Element | null => {
        const container = 'w-full h-full flex justify-center items-center';

        switch (currentTab) {
            case MEDIA_TYPES.IMAGE:
                return <div className={container}>No Images Uploaded</div>;
            case MEDIA_TYPES.VIDEO:
                return <div className={container}>No Videos Uploaded</div>;
            case MEDIA_TYPES.PDF:
                return <div className={container}>No PDFs Uploaded</div>;
            case MEDIA_TYPES.FONT:
                return <div className={container}>No Fonts Uploaded</div>;
            case MEDIA_TYPES.DOCUMENT:
                return <div className={container}>No Documents Uploaded</div>;
            default:
                return null;
        }
    };

    const renderContent = (content?: MediaContentInterface): JSX.Element => (
        <>
            <div className="col-span-3 w-full h-full">
                {filteredMedia?.length > 0 ? (
                    <div className="grid lg:grid-cols-3 grid-cols-1 gap-4 p-small h-full">
                        {filteredMedia?.map((item) => (
                            <MediaItem
                                item={item}
                                onSelect={() => handleSelectItem(item)}
                                onDeselect={() => handleDeselectItem(item)}
                                onEdit={onEdit}
                                onDelete={onDelete}
                                mediaType={item?.type}
                                selected={selectedItems?.some((selectedItem) => selectedItem.id === item.id)}
                                {...content?.mediaItem}
                            />
                        ))}
                    </div>
                ) : (
                    renderEmptyList()
                )}
            </div>
            {renderLoading}
        </>
    );

    const context: MediaContextInterface = useMemo(
        () => ({
            toggleGallery: (value?: MediaConfigInterface) => {
                if (value?.mediaType) {
                    setCurrentTab(value.mediaType);
                }
                setConfig(value);
            },
            loadGallery: getGallery,
            renderContent,
        }),
        [data, loading, uploading],
    );

    return (
        <MediaContext.Provider value={context}>
            {children}
            {config?.open && (
                <Drawer
                    title={
                        <div className="flex content-center">
                            <h5 className="my-auto">Media</h5>
                            {config?.mediaType && (
                                <Alert
                                    type="error"
                                    message={`All media files must be of type ${config.mediaType}`}
                                    className="my-auto ml-medium w-fit bg-red-500 text-white"
                                    showIcon
                                    icon={<ExclamationCircleOutlined style={{ color: 'white' }} />}
                                />
                            )}
                        </div>
                    }
                    open
                    onClose={reset}
                    width={window.innerWidth}
                    height={window.innerHeight}
                    bodyStyle={{
                        height: window.innerHeight * 0.75,
                        overflow: 'scroll',
                        paddingTop: theme.dimension.padding.small,
                        paddingBottom: theme.dimension.padding.small,
                    }}
                    placement="bottom"
                    footer={renderFooter()}
                    className="ant-drawer-header-title"
                >
                    <div className="lg:grid lg:grid-cols-4 w-full h-full">
                        <div className="col-span-1 lg:h-full border border-brand-inkGrey-grey_2 border-r border-l-none border-t-none border-b-none border-solid">
                            {renderTabs}
                        </div>
                        {renderContent()}
                    </div>
                </Drawer>
            )}
        </MediaContext.Provider>
    );
}

export default MediaProvider;
