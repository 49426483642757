import { message } from 'antd';
import { DataWrapper, EventSettingInterface, Response } from '../../../exports/Interfaces';
import BackendApiClient from '../BackendApiClient';
import ReduxResource from '../../resources/ReduxResource';
import actionCreators from '../../../store/actions';

export default class EventSettingsManager {
    public static post = async (
        companyId: number,
        data?: Partial<EventSettingInterface>,
    ): Promise<Response<DataWrapper<DataWrapper<EventSettingInterface>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<EventSettingInterface>>>({
            method: 'POST',
            url: `/event-settings/${companyId}`,
            data,
        });

        const { response, success } = request;

        if (response?.data?.data && success) {
            ReduxResource.dispatch(actionCreators.store.addEventSettings(companyId, response?.data?.data));
            message.success('Updated');
        } else {
            message.error('Update failed');
        }

        return request;
    };

    public static put = async (
        id: number,
        data?: Partial<EventSettingInterface>,
    ): Promise<Response<DataWrapper<DataWrapper<EventSettingInterface>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<EventSettingInterface>>>({
            method: 'PUT',
            url: `/event-settings/${id}`,
            data,
        });

        const { response, success } = request;

        if (response?.data?.data && success) {
            ReduxResource.dispatch(actionCreators.store.updateEventSettings(response?.data?.data));
            message.success('Updated');
        } else {
            message.error('Update failed');
        }

        return request;
    };
}
