import { useCookies } from 'react-cookie';
import { useMemo } from 'react';
import collect from 'collect.js';
import psl from 'psl';
import CookiesContext from '../context/CookiesContext';
import AppContextResource from '../services/resources/AppContextResource';
import { CookiesContextInterface } from '../exports/Interfaces';
import { COOKIES } from '../exports/Constants';
import useFocus from '../hooks/useFocus';
import CookiesResource from '../services/resources/CookiesResource';

interface Props {
    children: JSX.Element;
}

function CookiesProvider(props: Props): JSX.Element {
    const { children } = props;

    const [cookies, _setCookie, removeCookie] = useCookies(Object.values(COOKIES));

    const context = useMemo(
        () => ({
            cookies,
            getCookie: (key) => cookies[key],
            setCookie: (key, value, options) =>
                _setCookie(key, value, {
                    domain: (psl.parse(window.location.hostname) as psl.ParsedDomain).domain ?? 'localhost',
                    path: '/',
                    ...options,
                }),
            removeCookies: (keys) => {
                collect(keys)
                    ?.each((key) => removeCookie(key))
                    ?.all();
            },
        }),
        [cookies, document.cookie],
    ) as CookiesContextInterface;

    AppContextResource.setCookieContext(context);

    useFocus({
        onFocus: () => {
            if (
                context.getCookie(COOKIES.ON_BEHALF_OF_STORE) !== CookiesResource.getCookie(COOKIES.ON_BEHALF_OF_STORE)
            ) {
                window.location.reload();
            }
        },
    });

    return <CookiesContext.Provider value={context}>{children}</CookiesContext.Provider>;
}

export default CookiesProvider;
