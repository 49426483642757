import { useState } from 'react';
import TypographyItem from '../../typography/TypographyItem';
import { TextInterface } from '../../../exports/Interfaces';
import ThemeManager from '../../../services/api/ThemeManager';
import useRdxStore from '../../../hooks/useRdxStore';

function Typography(): JSX.Element {
    const { store } = useRdxStore();

    const [loading, setLoading] = useState<boolean>(false);

    const onSave = async (values: TextInterface): Promise<void> => {
        setLoading(true);
        await ThemeManager.post({
            button_text_style: values,
        });
        setLoading(false);
    };

    return (
        <TypographyItem initialValues={store?.theme?.button_text_style} loading={loading} hideColor onSave={onSave} />
    );
}

export default Typography;
