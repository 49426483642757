import Dev from './Dev.png';
import Staging from './Staging.png';
import Prod from './Prod.png';
import { ENVIRONMENTS } from '../../../exports/Enums';

function getIcon(): string {
    switch (import.meta.env.VITE_NODE_ENV) {
        case ENVIRONMENTS.DEV:
            return Dev;
        case ENVIRONMENTS.STAGING:
            return Staging;
        default:
            return Prod;
    }
}

const Favicon = getIcon();

export default Favicon;
