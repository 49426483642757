import { Menu } from 'antd';
import type { MenuProps } from 'antd';
import collect from 'collect.js';
import { useState } from 'react';
import { SECTION_TYPES } from '../../../../exports/Enums';
import getSectionName from '../../../../exports/Methods';
import Elements from '../elements';
import useScreenType from '../../../../hooks/useScreenType';
import { ELEMENT_TYPES } from '../../../../exports/types';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: ELEMENT_TYPES,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

function ElementsTab(): JSX.Element {
    const { isDesktop } = useScreenType();

    const [elementType, setElementType] = useState<ELEMENT_TYPES>(SECTION_TYPES.HERO_SECTION);

    const items: MenuProps['items'] = collect(SECTION_TYPES)
        .values()
        .map((value: any) => getItem(getSectionName(value), value))
        .push(getItem('Popups', 'Popup'))
        .toArray();

    const onClick: MenuProps['onClick'] = (e) => {
        setElementType(e.key as SECTION_TYPES);
    };

    return (
        <div className="flex lg:flex-row flex-col">
            <Menu
                selectedKeys={[elementType]}
                onClick={onClick}
                style={{ width: 256 }}
                defaultValue={elementType}
                mode={isDesktop ? 'vertical' : 'horizontal'}
                items={items}
            />

            <Elements type={elementType} />
        </div>
    );
}

export default ElementsTab;
