import { useMemo, useState } from 'react';
import { Input, Select, Form } from 'antd';
import collect from 'collect.js';
import {
    CompanyInterface,
    GoogleReviewSectionInterface,
    MediaInterface,
    SectionInterface,
} from '../../../exports/Interfaces';
import SectionForm from '../../forms/SectionForm';
import useRdxStore from '../../../hooks/useRdxStore';
import SectionManager from '../../../services/api/SectionManager';
import useModals from '../../../hooks/useModals';
import { DB_MODELS, GOOGLE_REVIEW_SECTION_TYPES, MEDIA_TAGS } from '../../../exports/Enums';
import MediaManager from '../../../services/api/MediaManager';
import SelectedMediaList from '../../media/SelectedMediaList';
import SectionSelect from '../../selects/SectionSelect';
import ButtonDesignSelect from '../../selects/ButtonDesignSelect';
import FormGroup from '../../forms/structure/form-group';
import FormItem from '../../forms/structure/form-item';

interface Props {
    section: SectionInterface<GoogleReviewSectionInterface>;
}

function GoogleReviewSectionForm(props: Props): JSX.Element {
    const { section } = props;

    const { content } = section;

    const { store } = useRdxStore();

    const { setSectionModal } = useModals();

    const [form] = Form.useForm();

    const [loading, setLoading] = useState<boolean>(false);

    const [mediaList, setMediaList] = useState<MediaInterface[]>([]);

    const onSave = async (values: Partial<SectionInterface<GoogleReviewSectionInterface>>): Promise<void> => {
        if (typeof section?.id !== 'number') {
            return;
        }
        setLoading(true);
        const { response, success } = await SectionManager.put<GoogleReviewSectionInterface>(section?.id, values);
        if (success) {
            if (typeof response?.data?.data?.content?.id === 'number') {
                await MediaManager.post(
                    DB_MODELS.GOOGLE_REVIEW_SECTION,
                    response?.data?.data?.content?.id,
                    mediaList,
                    MEDIA_TAGS.IMAGES,
                    '',
                );
            }
        }
        setLoading(false);
        setSectionModal({ open: false });
    };

    interface OptionInterface {
        value: string;
        label: string;
        company: CompanyInterface;
    }

    const options = useMemo(
        () =>
            collect(store?.companies)
                ?.map((company) => ({
                    value: company?.google_places_id,
                    label: `${company?.google_places_id} - (${company?.name})`,
                }))
                ?.toArray() as OptionInterface[],
        [store?.companies],
    );

    return (
        <SectionForm section={section} onSave={onSave} loading={loading} initialValues={section} form={form}>
            <Form.Item
                name={['content', 'type']}
                rules={[{ required: true, message: 'Please select a Layout!' }]}
                className="mb-none"
            >
                <SectionSelect
                    section={section}
                    onSelect={(_type: GOOGLE_REVIEW_SECTION_TYPES) => form.setFieldValue(['content', 'type'], _type)}
                />
            </Form.Item>
            <Form.Item
                name={['content', 'google_places_id']}
                rules={[{ required: true, message: 'Please select a Google Place ID!' }]}
            >
                <Select placeholder="Select Google Places Id" options={options} />
            </Form.Item>
            <FormGroup label="Media">
                <SelectedMediaList
                    uploadCount={1}
                    defaultMediaList={content?.images || []}
                    mediaList={mediaList}
                    setMediaList={setMediaList}
                />
            </FormGroup>
            <FormGroup label="Typography">
                <FormItem label="Title">
                    <Form.Item name={['content', 'title']}>
                        <Input placeholder="Title" />
                    </Form.Item>
                </FormItem>
            </FormGroup>
            <FormGroup label="Buttons">
                <Form.Item name={['content', 'google_review_button_props']}>
                    <ButtonDesignSelect
                        title="Google Review Button"
                        button={content?.google_review_button_props}
                        setButton={(button) => form.setFieldValue(['content', 'google_review_button_props'], button)}
                    />
                </Form.Item>
            </FormGroup>
        </SectionForm>
    );
}

export default GoogleReviewSectionForm;
