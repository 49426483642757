import { useLayoutEffect, useState, useRef, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../../navigation/onboarding/Sidebar';
import Header from '../../navigation/Header';
import useModals from '../../../hooks/useModals';
import EditSection from '../../sections/EditSection';
import EnvironmentBanner from '../../environment/EnvironmentBanner';

function AppLayout(): JSX.Element {
    const { sectionModal } = useModals();

    const sideBarRef = document.getElementById('sidebar');

    const sideBarWidth = sideBarRef?.getBoundingClientRect()?.width ?? 0;

    const headerRef = useRef<HTMLDivElement>(null);

    const mainContentWidth = window.innerWidth - sideBarWidth;

    const [mounted, setMounted] = useState<boolean>(false);

    useLayoutEffect(() => {
        setMounted(true);
    }, []);

    const renderSectionModal = useMemo(() => sectionModal?.open && <EditSection />, [sectionModal]);

    return (
        <>
            <div className="w-screen h-screen">
                <div ref={headerRef} className="fixed top-0 w-full z-20" id="header">
                    <EnvironmentBanner />
                    <Header />
                </div>
                <div className="h-full w-screen flex">
                    <div
                        id="sidebar"
                        className="h-screen bg-background-inkWhite-white_0 shadow w-1/4 overscroll-contain fixed top-0"
                        style={{ marginTop: headerRef.current?.getBoundingClientRect()?.height || 0 }}
                    >
                        <Sidebar />
                    </div>
                    <div className="h-full w-full fixed" id="details" style={{ marginLeft: sideBarWidth }}>
                        {mounted && (
                            <div
                                className="h-full"
                                style={{
                                    width: mainContentWidth,
                                    marginTop: headerRef.current?.getBoundingClientRect()?.height || 0,
                                }}
                            >
                                <Outlet />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {renderSectionModal}
        </>
    );
}

export default AppLayout;
