import RestaurantInfoLayout from '../../layouts/RestaurantInfoLayout';
import SettingsForm from './OrderMethodsForm';

function OrderMethods(): JSX.Element {
    return (
        <RestaurantInfoLayout title="Order Methods">
            <SettingsForm />
        </RestaurantInfoLayout>
    );
}

export default OrderMethods;
