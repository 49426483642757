import { DataWrapper, PageContentInterface, Response, StaticPagePayload } from '../../../exports/Interfaces';
import BackendApiClient from '../BackendApiClient';

export default class StaticPageManager {
    public static post = async (
        data: StaticPagePayload,
    ): Promise<Response<DataWrapper<DataWrapper<PageContentInterface>>>> =>
        BackendApiClient.request<DataWrapper<DataWrapper<PageContentInterface>>>({
            method: 'POST',
            url: '/static-pages',
            data,
        });
}
