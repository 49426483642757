import collect from 'collect.js';
import { useEffect } from 'react';
import SectionItem from '../../../components/list/SectionItem';
import useRdxStore from '../../../hooks/useRdxStore';
import Content from '../Content';
import { SECTION_TYPES } from '../../../exports/Enums';
import useModals from '../../../hooks/useModals';
import InitialDataManager from '../../../services/api/InitialDataManager';

function Menus(): JSX.Element {
    const { store } = useRdxStore();

    const { sectionModal } = useModals();

    const section = collect(store?.home_page?.sections).firstWhere('type', SECTION_TYPES.MENU_SECTION);

    const requestData = async (): Promise<void> => {
        await InitialDataManager.get();
    };

    useEffect(() => {
        if (!sectionModal?.open) {
            requestData();
        }
    }, [sectionModal?.open]);

    return (
        <Content title="Do you want to upload menus?">
            <div key={JSON.stringify(section)}>
                <SectionItem section={section} />
            </div>
        </Content>
    );
}

export default Menus;
