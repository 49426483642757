import classnames from 'classnames';

interface Props {
    children: React.ReactNode;
    title?: string;
    className?: string;
}

function CustomForm(props: Props): JSX.Element {
    const { children, title, className } = props;

    return (
        <div className={classnames('shadow bg-background-inkWhite-white_0 rounded-md', className)}>
            {title && (
                <div className="p-small border-b border-t-none border-l-none border-r-none border-solid border-brand-inkGrey-grey_2">
                    <h6>{title}</h6>
                </div>
            )}
            <div className="p-small overscroll-x-auto overflow-scroll">{children}</div>
        </div>
    );
}

export default CustomForm;
