import { DropResult } from 'react-beautiful-dnd';

export default class DragNDropHelpers {
    public static onDragEnd(list: any[], result: DropResult, callback: (list: any) => any): any {
        if (!result.destination) {
            return null;
        }

        if (result.destination.index === result.source.index) {
            return null;
        }

        return callback(DragNDropHelpers.reorder(list, result.source.index, result.destination.index));
    }

    public static reorder(list: any[], startIndex: number, endIndex: number): any[] {
        try {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);

            return result;
        } catch (error) {
            return list;
        }
    }
}
