import GoogleReviewsForm from '../../../components/restaurantInfo/google-reviews/GoogleReviewsForm';
import Content from '../Content';

function GoogleReviews(): JSX.Element {
    return (
        <Content title="Edit Google Reviews">
            <GoogleReviewsForm />
        </Content>
    );
}

export default GoogleReviews;
