import { message } from 'antd';
import { DataWrapper, PopupInterface, Response } from '../../exports/Interfaces';
import BackendApiClient from './BackendApiClient';
import actionCreators from '../../store/actions';
import ReduxResource from '../resources/ReduxResource';

export default class PopupManager {
    public static post = async (
        data?: Partial<PopupInterface>,
    ): Promise<Response<DataWrapper<DataWrapper<PopupInterface>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<PopupInterface>>>({
            method: 'POST',
            url: `/popups`,
            data,
        });

        const { response, success } = request;

        if (response?.data?.data && success) {
            message.success('Saved');
        }

        return request;
    };

    public static put = async (
        id: number,
        data?: Partial<PopupInterface>,
    ): Promise<Response<DataWrapper<DataWrapper<PopupInterface>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<PopupInterface>>>({
            method: 'PUT',
            url: `/popups/${id}`,
            data,
        });

        const { response, success } = request;

        if (response?.data?.data && success) {
            message.success('Updated');
            ReduxResource.dispatch(actionCreators.store.updatePopup(response?.data?.data));
        }

        return request;
    };

    public static delete = async (id: number): Promise<Response<DataWrapper<DataWrapper<void>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<void>>>({
            method: 'DELETE',
            url: `/popups/${id}`,
        });

        const { success } = request;

        if (success) {
            message.success('Deleted');
            ReduxResource.dispatch(actionCreators.store.deletePopup(id));
        }

        return request;
    };
}
