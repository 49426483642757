/* eslint-disable no-nested-ternary */
import { Button, Spin, Modal } from 'antd';
import { useMemo, useState } from 'react';
import classnames from 'classnames';
import { CheckOutlined } from '@ant-design/icons';
import useRdxStore from '../../hooks/useRdxStore';
import useScreenType from '../../hooks/useScreenType';
import { STORE_SETUP_STATUS_TYPES } from '../../exports/Enums';
import StoreManager from '../../services/api/StoreManager';
import useTheme from '../../hooks/useTheme';

interface Props {
    open: boolean;
    toggle: (open?: boolean) => void;
}

function StoreSetupModal(props: Props): JSX.Element {
    const { open, toggle } = props;

    const { store } = useRdxStore();

    const { isDesktop } = useScreenType();

    const theme = useTheme();

    const [loading, setLoading] = useState<boolean>(false);

    const completeGalleryUpload = async (): Promise<void> => {
        setLoading(true);
        await StoreManager.put(store.id, {
            setup_status: STORE_SETUP_STATUS_TYPES.STORE_SETUP_COMPLETED,
        });
        setLoading(false);
    };

    const renderSetupStatus = useMemo(() => {
        const status = store?.setup_status ?? 0;

        const data = [
            {
                title: 'Content creation',
                description: 'Websites are created here based on your business data.',
                completed: status >= STORE_SETUP_STATUS_TYPES.CONTENT_CREATION_COMPLETED,
                loading: true,
            },
            {
                title: 'Layout creation',
                description: 'Setting up the website with standard sections.',
                completed: status >= STORE_SETUP_STATUS_TYPES.LAYOUT_CREATION_COMPLETED,
                loading: status === STORE_SETUP_STATUS_TYPES.CONTENT_CREATION_COMPLETED,
            },
            {
                title: 'Galerie-Upload',
                description:
                    'Here, all the relevant media of your company will be imported. This may take 3-5 minutes. Please be patient.',
                completed: status === STORE_SETUP_STATUS_TYPES.STORE_SETUP_COMPLETED,
                loading: status === STORE_SETUP_STATUS_TYPES.LAYOUT_CREATION_COMPLETED,
            },
        ];

        switch (store?.setup_status) {
            case STORE_SETUP_STATUS_TYPES.STORE_SETUP_COMPLETED:
                return null;
            case STORE_SETUP_STATUS_TYPES.STORE_SETUP_FAILED:
                return (
                    <div className="w-full">
                        <h6>The creation of the website did not complete successfully.</h6>
                        <p className="mini mt-mini">Error: {store?.setup_status_error}</p>
                        <p className="mini mt-mini">
                            Please delete the page and try again. If the problem persists, please contact our support.
                            Thank you for your understanding!
                        </p>
                    </div>
                );
            default:
                return (
                    <div className="w-full">
                        {data.map((item, index) => (
                            <div
                                className={classnames('flex items-center py-small', {
                                    'border-b border-companyBrand-borderColor': index !== data.length - 1,
                                })}
                            >
                                <div className="w-[50px] h-[50px] rounded-full flex-none overflow-hidden relative">
                                    {item?.completed ? (
                                        <div className="h-full w-full flex justify-center items-center bg-brand-primary">
                                            <CheckOutlined color={theme.color.text.light} />
                                        </div>
                                    ) : item?.loading ? (
                                        <div className="h-full w-full flex justify-center items-center bg-brand-warning">
                                            <Spin />
                                        </div>
                                    ) : null}
                                </div>
                                <div className="px-small flex-shrink w-full">
                                    <h6>{item.title}</h6>
                                    <p className="mini mt-mini">{item.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                );
        }
    }, [store?.setup_status]);

    return (
        <Modal
            title="Domain"
            open={open}
            onCancel={() => toggle(false)}
            footer={null}
            width={isDesktop ? window.innerWidth * 0.5 : window.innerWidth}
        >
            <div>
                {renderSetupStatus}
                <Button
                    className="mr-small"
                    type="primary"
                    onClick={completeGalleryUpload}
                    loading={loading}
                    disabled={loading}
                >
                    All steps completed
                </Button>
            </div>
        </Modal>
    );
}

export default StoreSetupModal;
