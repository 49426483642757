import { useMemo, useState } from 'react';
import { Alert, Button, Form, Input, Select, Switch } from 'antd';
import collect from 'collect.js';
import { ExclamationCircleOutlined, EyeInvisibleOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import {
    DB_MODELS,
    HERO_SECTION_TYPES,
    LINK_IDENTIFIERS,
    MEDIA_TAGS,
    MEDIA_TYPES,
    RESERVATION_BUTTON_POSITION,
} from '../../../exports/Enums';
import {
    HeroSectionInterface,
    HeroSectionItemInterface,
    LinkInterface,
    MediaInterface,
    SectionInterface,
} from '../../../exports/Interfaces';
import SectionForm from '../../forms/SectionForm';
import SectionManager from '../../../services/api/SectionManager';
import SelectedMediaList from '../../media/SelectedMediaList';
import MediaManager from '../../../services/api/MediaManager';
import LinkManager from '../../../services/api/LinkManager';
import LinkInput from '../../links/LinkInput';
import useModals from '../../../hooks/useModals';
import HeroSectionItemManager from '../../../services/api/hero-section-item/HeroSectionItemManager';
import HeroSectionItem from './heroSectionItem/HeroSectionItem';
import SectionSelect from '../../selects/SectionSelect';
import ButtonDesignSelect from '../../selects/ButtonDesignSelect';
import FormGroup from '../../forms/structure/form-group';
import FormItem from '../../forms/structure/form-item';

interface Props {
    section: SectionInterface<HeroSectionInterface>;
}

function HeroSectionForm(props: Props): JSX.Element {
    const { section } = props;

    const content = section?.content;

    const { setSectionModal } = useModals();

    const [form] = Form.useForm<HeroSectionInterface>();

    const { type } = Form.useWatch('content', form) ?? { type: HERO_SECTION_TYPES.ONE };

    const [loading, setLoading] = useState<boolean>(false);

    const [mediaList, setMediaList] = useState<MediaInterface[]>([
        ...(content?.images || []),
        ...(content?.videos || []),
    ]);

    const [secondButtonLink, setSecondButtonLink] = useState<LinkInterface | null>(
        collect(section?.links)?.firstWhere('identifier', LINK_IDENTIFIERS.SECOND),
    );

    const [orderOnlineButtonVisible, setOrderOnlineButtonVisible] = useState(!content?.order_online_button_hidden);

    const [items, setItems] = useState<HeroSectionItemInterface[]>(section?.content?.items || []);

    const [adding, setAdding] = useState<boolean>(false);

    const maxItems = 2;

    const getMediaUploadCount = (): number => {
        switch (type) {
            case HERO_SECTION_TYPES.FOUR:
            case HERO_SECTION_TYPES.THIRTEEN:
                return 2;
            case HERO_SECTION_TYPES.FOURTEEN:
            case HERO_SECTION_TYPES.FIFTEEN:
                return 3;
            case HERO_SECTION_TYPES.SIX:
            case HERO_SECTION_TYPES.TEN:
            case HERO_SECTION_TYPES.SIXTEEN:
                return 4;
            default:
                return 1;
        }
    };

    const getMediaUploadType = (): MEDIA_TYPES => {
        switch (type) {
            case HERO_SECTION_TYPES.FIVE:
            case HERO_SECTION_TYPES.TWELVE:
            case HERO_SECTION_TYPES.EIGHTEEN:
                return MEDIA_TYPES.VIDEO;
            default:
                return MEDIA_TYPES.IMAGE;
        }
    };

    const getMediaUploadTag = (): MEDIA_TAGS => {
        switch (type) {
            case HERO_SECTION_TYPES.FIVE:
            case HERO_SECTION_TYPES.TWELVE:
            case HERO_SECTION_TYPES.EIGHTEEN:
                return MEDIA_TAGS.VIDEOS;
            default:
                return MEDIA_TAGS.IMAGES;
        }
    };

    const onSave = async (values: Partial<SectionInterface<HeroSectionInterface>>): Promise<void> => {
        if (typeof section?.id !== 'number') {
            return;
        }
        setLoading(true);

        if (values.content) {
            values.content.order_online_button_hidden = !orderOnlineButtonVisible;
        }

        const { response, success } = await SectionManager.put<HeroSectionInterface>(section?.id, values);
        if (success) {
            if (typeof response?.data?.data?.content?.id === 'number') {
                await MediaManager.post(
                    DB_MODELS.HERO_SECTION,
                    response?.data?.data?.content?.id,
                    mediaList,
                    getMediaUploadTag(),
                    '',
                );
            }
            await LinkManager.post(
                DB_MODELS.SECTION,
                response?.data?.data?.id,
                collect([secondButtonLink])
                    ?.filter((value) => !!value?.title)
                    ?.toArray() as LinkInterface[],
            );
        }

        setLoading(false);
        setSectionModal({ open: false });
    };

    const addItem = async (): Promise<void> => {
        if (items?.length === maxItems) {
            return;
        }
        setAdding(true);
        const { response, success } = await HeroSectionItemManager.post(section.content.id, {});
        if (success) {
            const newItems = [...items];
            newItems.push(response.data.data);
            setItems(newItems);
        }
        setAdding(false);
    };

    const removeItem = (id: number): void => {
        setItems(collect(items).where('id', '!=', id).toArray());
    };

    const updateItem = (_item: HeroSectionItemInterface): void => {
        const newItems = [...items];
        const index = collect(newItems).search((item) => item.id === _item.id);
        if (typeof index === 'number') {
            newItems[index] = _item;
            setItems(newItems);
        }
    };

    const disabled = useMemo(
        () => !collect(mediaList).every((item) => item.type === getMediaUploadType()),
        [mediaList, type],
    );

    const withParallax = useMemo(() => {
        switch (type) {
            case HERO_SECTION_TYPES.ONE:
            case HERO_SECTION_TYPES.TWO:
            case HERO_SECTION_TYPES.SEVEN:
                return true;
            default:
                return false;
        }
    }, [type]);

    function renderAdvanced(): JSX.Element | undefined {
        switch (type) {
            case HERO_SECTION_TYPES.NINE:
            case HERO_SECTION_TYPES.SEVENTEEN:
                return (
                    <div>
                        {items?.map((item) => (
                            <div key={item.id.toString()}>
                                <HeroSectionItem item={item} onRemove={removeItem} onUpdate={updateItem} />
                            </div>
                        ))}
                        <Button
                            loading={adding}
                            icon={<PlusOutlined />}
                            onClick={addItem}
                            disabled={collect(items).count() === maxItems}
                        >
                            Add Menu
                        </Button>
                    </div>
                );

            default:
                return undefined;
        }
    }

    const reservationButtonOptions = [
        {
            label: 'Standard',
            value: RESERVATION_BUTTON_POSITION.STANDARD,
        },
        {
            label: 'Fixed on side',
            value: RESERVATION_BUTTON_POSITION.FIXED_ON_SIDE,
        },
    ];

    return (
        <SectionForm
            form={form}
            section={section}
            onSave={onSave}
            loading={loading}
            disabled={disabled}
            initialValues={section}
            advancedContent={renderAdvanced()}
            layout="vertical"
        >
            <Form.Item
                name={['content', 'type']}
                rules={[{ required: true, message: 'Please select a Layout!' }]}
                className="mb-none"
            >
                <SectionSelect
                    section={section}
                    onSelect={(_type: HERO_SECTION_TYPES) => form.setFieldValue(['content', 'type'], _type)}
                />
            </Form.Item>

            <FormGroup label="Media">
                <>
                    <SelectedMediaList
                        uploadCount={getMediaUploadCount()}
                        mediaType={getMediaUploadType()}
                        defaultMediaList={[...(content?.images || []), ...(content?.videos || [])]}
                        mediaList={mediaList}
                        setMediaList={setMediaList}
                    />
                    {disabled && (
                        <Alert
                            type="error"
                            message={`All media files must be of type ${getMediaUploadType()}`}
                            className="mb-medium bg-red-500 text-white"
                            showIcon
                            icon={<ExclamationCircleOutlined style={{ color: 'white' }} />}
                        />
                    )}
                </>
            </FormGroup>
            <FormGroup label="Typography">
                <>
                    <FormItem label="Sub title">
                        <Form.Item name={['content', 'sub_title']}>
                            <Input placeholder="Subtitle" />
                        </Form.Item>
                    </FormItem>
                    <FormItem label="Title">
                        <Form.Item name={['content', 'title']}>
                            <Input placeholder="Title" />
                        </Form.Item>
                    </FormItem>
                    <FormItem label="Description">
                        <Form.Item name={['content', 'description']}>
                            <Input.TextArea rows={6} placeholder="Description" />
                        </Form.Item>
                    </FormItem>
                </>
            </FormGroup>
            <FormGroup label="Buttons">
                <>
                    <Form.Item name={['content', 'order_online_button_props']}>
                        <ButtonDesignSelect
                            title="Order Online Button"
                            button={content?.order_online_button_props}
                            setButton={(button) => form.setFieldValue(['content', 'order_online_button_props'], button)}
                            headerExtra={
                                <Form.Item
                                    name={['content', 'order_online_button_hidden']}
                                    initialValue={content?.order_online_button_hidden}
                                    className="m-none p-none flex justify-center items-center h-[23px]"
                                >
                                    <Switch
                                        checkedChildren={<EyeOutlined />}
                                        unCheckedChildren={<EyeInvisibleOutlined />}
                                        defaultChecked={orderOnlineButtonVisible}
                                        checked={orderOnlineButtonVisible}
                                        onChange={setOrderOnlineButtonVisible}
                                        size="small"
                                    />
                                </Form.Item>
                            }
                        />
                    </Form.Item>
                    <div className="mb-small flex items-end">
                        <LinkInput
                            label="Second Button"
                            identifier={LINK_IDENTIFIERS.SECOND}
                            link={secondButtonLink}
                            setLink={setSecondButtonLink}
                            extra={
                                <Form.Item name={['content', 'reservation_button_position']} className="mb-none">
                                    <Select options={reservationButtonOptions} placeholder="Select position" />
                                </Form.Item>
                            }
                        />
                    </div>
                </>
            </FormGroup>
            <FormGroup label="Settings">
                {withParallax ? (
                    <Form.Item name={['content', 'has_parallax']} label="Parallax">
                        <Switch defaultChecked={content?.has_parallax} />
                    </Form.Item>
                ) : undefined}
            </FormGroup>
        </SectionForm>
    );
}

export default HeroSectionForm;
