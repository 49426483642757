import { useState } from 'react';
import { Cascader, Input, Select, Modal, Button, Form, message, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import collect from 'collect.js';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { LinkInputProps } from '../../exports/Interfaces';
import {
    LINK_TARGET_TYPES,
    LINK_TYPES,
    STOREFRONT_PAGES,
    MEDIA_TYPES,
    STORE_VARIABLES,
    BUTTON_VARIANT,
    BUTTON_SIZE,
} from '../../exports/Enums';
import useRdxStore from '../../hooks/useRdxStore';
import getSectionName from '../../exports/Methods';
import useScreenType from '../../hooks/useScreenType';
import useGallery from '../../hooks/useGallery';
import ButtonSelect from '../selects/ButtonSelect';
import usePages from '../../hooks/usePages';

interface Props extends LinkInputProps {
    open: boolean;
    toggle: (open: boolean) => void;
}

function LinkInputModal(props: Props): JSX.Element {
    const { open, identifier, link, disabled, validation, setLink, toggle } = props;

    const { store } = useRdxStore();

    const { navigationItems } = usePages();

    const { isDesktop } = useScreenType();

    const { toggleGallery } = useGallery();

    const [title, setTitle] = useState<string>(link?.title ?? '');

    const [target, setTarget] = useState<string>(link?.target ?? '');

    const [variant, setVariant] = useState<BUTTON_VARIANT | null>(link?.button_props?.variant ?? null);

    const [size, setSize] = useState<BUTTON_SIZE | null>(link?.button_props?.size ?? null);

    const defaultNavigationValue = collect([link?.type, link?.href])
        ?.filter((value) => !!value)
        ?.toArray() as string[];

    const [navigationValue, setNavigationValue] = useState(
        defaultNavigationValue?.length > 1 ? defaultNavigationValue : [],
    );

    const [customHref, setCustomHref] = useState<string>(defaultNavigationValue?.length < 2 ? link?.href ?? '' : '');

    const href = navigationValue?.length > 1 ? collect(navigationValue)?.last() : customHref || null;

    interface Option {
        value: string | number;
        label: string;
        children?: Option[];
    }

    const options: Option[] = [
        {
            value: LINK_TYPES.PAGE,
            label: 'Page',
            children: [
                {
                    value: STOREFRONT_PAGES.HOME,
                    label: 'Home',
                },
                {
                    value: STOREFRONT_PAGES.RESERVATIONS,
                    label: 'Reservations',
                },
                {
                    value: STOREFRONT_PAGES.CATERING,
                    label: 'Catering',
                },
                {
                    value: STOREFRONT_PAGES.EVENTS,
                    label: 'Events',
                },
                {
                    value: STOREFRONT_PAGES.IMPRESSUM,
                    label: 'Impressum',
                },
                {
                    value: STOREFRONT_PAGES.PRIVACY_POLICY,
                    label: 'Privacy Policy',
                },
                {
                    value: STOREFRONT_PAGES.COOKIE_POLICY,
                    label: 'Cookie Policy',
                },
                ...(collect(navigationItems)
                    .map((item) => ({
                        value: item?.page?.href,
                        label: item?.name,
                    }))
                    .toArray() as Option[]),
            ],
        },
        {
            value: LINK_TYPES.ANCHOR_TAG,
            label: 'Anchor Tags',
            children: [
                ...[
                    {
                        value: `#header`,
                        label: 'Header',
                    },
                    {
                        value: `#footer`,
                        label: 'Footer',
                    },
                ],
                ...(collect(store?.home_page?.sections)
                    ?.map((section) => ({
                        value: `#section-id-${section?.id}`,
                        label: getSectionName(section?.type),
                    }))
                    ?.toArray() as Option[]),
            ],
        },
        {
            value: LINK_TYPES.VARIABLE,
            label: 'Variables',
            children: [
                {
                    value: STORE_VARIABLES.ONLINE_SHOP,
                    label: 'Online Shop',
                },
                {
                    value: STORE_VARIABLES.INSTAGRAM,
                    label: 'Instagram',
                },
                {
                    value: STORE_VARIABLES.FACEBOOK,
                    label: 'Facebook',
                },
                {
                    value: STORE_VARIABLES.YOUTUBE,
                    label: 'Youtube',
                },
                {
                    value: STORE_VARIABLES.TIK_TOK,
                    label: 'TikTok',
                },
                {
                    value: STORE_VARIABLES.YELP,
                    label: 'Yelp',
                },
            ],
        },
    ];

    const onFinish = (): void => {
        if (!href && !validation?.hrefOptional) {
            message.error('Please add link to the Button');
            return;
        }
        setLink({
            title: title || null,
            href,
            target: target || null,
            identifier: identifier || null,
            type: navigationValue?.length > 1 ? (collect(navigationValue)?.first() as LINK_TYPES) : null,
            button_props: {
                variant,
                size,
            },
        });
        toggle(false);
    };

    function renderCustomHrefValidationIcon(): JSX.Element | null {
        if (customHref?.length === 0) {
            return <CheckCircleFilled className="text-brand-inkGrey-grey_3" />;
        }
        if (!customHref?.includes('https://' || 'http://')) {
            return <CloseCircleFilled className="text-brand-danger" />;
        }
        return <CheckCircleFilled className="text-brand-success" />;
    }

    function onChangeCustomHref(value: string): void {
        setCustomHref(value);
        setNavigationValue([]);
    }

    function renderOverview(): JSX.Element {
        return (
            <>
                <div className="mb-small">
                    <h6 className="tiny semibold ">Title</h6>
                    <div className="flex mt-mini">
                        <Input
                            placeholder="Title"
                            defaultValue={title}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                </div>
                <div className="mb-small">
                    <div className="mb-mini">
                        <h6 className="tiny semibold">Target</h6>
                    </div>
                    <Select
                        placeholder="Open in tab"
                        defaultValue={target}
                        onChange={setTarget}
                        disabled={disabled}
                        options={[
                            { value: LINK_TARGET_TYPES.CURRENT_TAB, label: 'Open in current tab' },
                            { value: LINK_TARGET_TYPES.NEW_TAB, label: 'Open in new tab' },
                        ]}
                    />
                </div>

                <div className="mb-small">
                    <div className="mb-mini">
                        <h6 className="tiny semibold">Page/Anchor Tag/Variables</h6>
                    </div>
                    <Cascader
                        options={options}
                        onChange={(values) => {
                            setNavigationValue(values as string[]);
                            setCustomHref('');
                        }}
                        value={navigationValue}
                        placeholder="Navigate to"
                        className="w-full"
                        disabled={disabled}
                    />
                </div>

                <div className="mb-small">
                    <div className="mb-mini">
                        <h6 className="tiny semibold ">Url</h6>
                    </div>
                    <div className="grid lg:grid-cols-4 grid-cols-1 gap-2">
                        <Input
                            placeholder="https://www.example.com"
                            defaultValue={customHref}
                            value={customHref}
                            onChange={(e) => onChangeCustomHref(e.target.value)}
                            className="lg:col-span-3 col-span-1"
                            addonAfter={renderCustomHrefValidationIcon()}
                            disabled={disabled}
                        />
                        <Button
                            className="col-span-1"
                            onClick={() =>
                                toggleGallery({
                                    open: true,
                                    maxSelect: 1,
                                    mediaType: MEDIA_TYPES.PDF,
                                    onSelect: (media) => onChangeCustomHref(collect(media).first()?.url),
                                })
                            }
                        >
                            PDF
                        </Button>
                    </div>
                </div>
            </>
        );
    }

    interface OptionInterface<T> {
        label: T;
        value: T;
    }

    function renderStyle(): JSX.Element {
        return (
            <div>
                <div className="mb-small">
                    <div className="mb-mini">
                        <h6 className="tiny semibold ">Variant</h6>
                    </div>
                    <ButtonSelect initialVariant={variant} setVariant={setVariant} />
                </div>

                <div className="mb-small">
                    <div className="mb-mini">
                        <h6 className="tiny semibold ">Size</h6>
                    </div>
                    <Select
                        options={
                            collect(BUTTON_SIZE)
                                .values()
                                .map((value) => ({
                                    label: value,
                                    value,
                                }))
                                .toArray() as OptionInterface<number>[]
                        }
                        defaultValue={size}
                        value={size}
                        onChange={setSize}
                        className="w-full"
                        placeholder="Select size"
                    />
                </div>
            </div>
        );
    }

    const tabs: TabsProps['items'] = [
        {
            key: '1',
            label: 'Overview',
            children: renderOverview(),
        },
        {
            key: '2',
            label: 'Style',
            children: renderStyle(),
        },
    ];

    return (
        <Modal
            title="Edit Button"
            open={open}
            onCancel={() => toggle(false)}
            footer={null}
            width={isDesktop ? window.innerWidth * 0.4 : window.innerWidth}
        >
            <Form onFinish={onFinish}>
                <Tabs defaultActiveKey="1" items={tabs} />

                <Form.Item className="mb-none">
                    <Button type="primary" htmlType="submit">
                        Apply
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default LinkInputModal;
