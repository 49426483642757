import { DataWrapper, DomainConfigInterface, Response, StoreInterface } from '../../../exports/Interfaces';
import actionCreators from '../../../store/actions';
import ReduxResource from '../../resources/ReduxResource';
import BackendApiClient from '../BackendApiClient';

export default class DomainManager {
    public static getConfig = async (
        domain: string,
    ): Promise<Response<DataWrapper<DataWrapper<DomainConfigInterface>>>> =>
        BackendApiClient.request({
            method: 'GET',
            url: `/domains/${domain}/config`,
        });

    public static post = async (data: {
        domain: string;
    }): Promise<Response<DataWrapper<DataWrapper<StoreInterface>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<StoreInterface>>>({
            method: 'POST',
            url: `/domains`,
            data,
        });

        const { response, success } = request;

        if (response?.data?.data && success) {
            ReduxResource.dispatch(actionCreators.store.setAll(response?.data?.data));
        }

        return request;
    };
}
