import { useMemo, useState } from 'react';
import { Button, Card, Descriptions } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import useRdxStore from '../../../hooks/useRdxStore';
import { ImpressumInterface } from '../../../exports/Interfaces';
import RestaurantInfoLayout from '../../layouts/RestaurantInfoLayout';
import ImpressumModal from '../../modals/ImpressumModal';
import ImpressumManager from '../../../services/api/ImpressumManager';
import ActionConfirmation from '../../popover/ActionConfirmation';

function ImpressumForm(): JSX.Element {
    const { store } = useRdxStore();

    const [deleteId, setDeleteId] = useState<number | null>(null);

    const [deleteClickedId, setDeleteCLickedId] = useState<number | null>(null);

    const [impressumModal, setImpressumModal] = useState<{
        open: boolean;
        impressum: ImpressumInterface | null;
    }>({
        open: false,
        impressum: null,
    });

    const renderImpressumModal = useMemo(
        () =>
            impressumModal.open && (
                <ImpressumModal
                    open
                    impressum={impressumModal.impressum}
                    toggle={() => setImpressumModal({ open: false, impressum: null })}
                />
            ),
        [impressumModal],
    );

    const onDelete = async (id: number): Promise<void> => {
        setDeleteId(id);
        await ImpressumManager.delete(id);
        setDeleteId(null);
    };

    return (
        <>
            <RestaurantInfoLayout title="Impressums">
                <Button
                    icon={<PlusOutlined />}
                    type="primary"
                    className="mb-small"
                    onClick={() => setImpressumModal({ open: true, impressum: null })}
                >
                    Add
                </Button>
                {store?.impressums?.map((impressum) => (
                    <Card
                        className="shadow mb-large"
                        title={impressum?.legal_name ?? store?.name}
                        extra={
                            <div className="flex gap-2">
                                <Button
                                    onClick={() => setImpressumModal({ open: true, impressum })}
                                    icon={<EditOutlined />}
                                />

                                <ActionConfirmation
                                    open={deleteClickedId === impressum.id}
                                    onOpenChange={() => setDeleteCLickedId(impressum.id)}
                                    onConfirm={() => onDelete(impressum.id)}
                                    onCancel={() => setDeleteCLickedId(null)}
                                >
                                    <Button
                                        loading={deleteId === impressum.id}
                                        disabled={deleteId === impressum.id}
                                        icon={<DeleteOutlined />}
                                        danger
                                    />
                                </ActionConfirmation>
                            </div>
                        }
                    >
                        <Descriptions>
                            <Descriptions.Item label="Address">{impressum?.address}</Descriptions.Item>
                            <Descriptions.Item label="Phone number">{impressum?.phone_number}</Descriptions.Item>
                            <Descriptions.Item label="Email">{impressum?.email}</Descriptions.Item>
                            <Descriptions.Item label="Website">{impressum?.web}</Descriptions.Item>
                            <Descriptions.Item label="Tax">{impressum?.tax_id}</Descriptions.Item>
                            <Descriptions.Item label="Supervisory Authority">
                                {impressum?.supervisory_authority}
                            </Descriptions.Item>
                            <Descriptions.Item label="Grantor">{impressum?.grantor}</Descriptions.Item>
                        </Descriptions>
                    </Card>
                ))}
            </RestaurantInfoLayout>
            {renderImpressumModal}
        </>
    );
}

export default ImpressumForm;
