import { useState } from 'react';
import { Button, Form } from 'antd';
import collect from 'collect.js';
import useRdxStore from '../../../hooks/useRdxStore';
import CustomForm from '../../forms/CustomForm';
import useGallery from '../../../hooks/useGallery';
import { MediaInterface } from '../../../exports/Interfaces';
import MediaManager from '../../../services/api/MediaManager';
import { DB_MODELS, MEDIA_TAGS, MEDIA_TYPES } from '../../../exports/Enums';
import InitialDataManager from '../../../services/api/InitialDataManager';
import ThemeLayout from '../../layouts/ThemeLayout';

function LogoForm(): JSX.Element {
    const { store } = useRdxStore();

    const { toggleGallery } = useGallery();

    const [loading, setLoading] = useState<boolean>(false);

    const [logoFile, setLogoFile] = useState<MediaInterface | null>(store?.logo ?? null);

    const onFinish = async (): Promise<void> => {
        setLoading(true);
        if (logoFile) {
            await MediaManager.post(DB_MODELS.STORE, store?.id, [logoFile], MEDIA_TAGS.LOGO);
            await InitialDataManager.get();
        }
        setLoading(false);
    };

    const onSelect = (media: MediaInterface[]): void => {
        setLogoFile(collect(media)?.first());
    };

    return (
        <ThemeLayout title="Logo">
            <CustomForm>
                <Form onFinish={onFinish} name="logo">
                    <Form.Item>
                        <Button
                            className="rounded-full shadow p-none overflow-hidden border-[5px] border-solid border-white relative"
                            type="ghost"
                            onClick={() =>
                                toggleGallery({
                                    open: true,
                                    maxSelect: 1,
                                    mediaType: MEDIA_TYPES.IMAGE,
                                    onSelect,
                                })
                            }
                            style={{ width: 100, height: 100 }}
                        >
                            <img src={logoFile?.url} alt="logo" className="w-full h-full object-cover" />
                        </Button>
                    </Form.Item>
                    <Form.Item className="mb-none">
                        <Button type="primary" onClick={onFinish} htmlType="button" loading={loading}>
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </CustomForm>
        </ThemeLayout>
    );
}

export default LogoForm;
