import { useLocation, useNavigate } from 'react-router-dom';
import { GroupOptionCardInterface } from '../../exports/Interfaces';
import OptionCard from './OptionCard';

function GroupOption(props: GroupOptionCardInterface): JSX.Element {
    const { type, groupTitle, page, options, option } = props;

    const { pathname } = useLocation();

    const navigate = useNavigate();

    if (type === 'individual') {
        return (
            <OptionCard
                {...option}
                checked={pathname.includes(option?.page || '')}
                onClick={() => navigate(`${page}${option?.page}`)}
            />
        );
    }

    return (
        <div className="mb-large border border-solid border-t-none border-r-none border-l-none border-brand-inkGrey-grey_2 pb-large">
            <h6 className="mb-small text-brand-text-grey text-mini">{groupTitle}</h6>
            <div>
                {options?.map((_option) => (
                    <OptionCard
                        {..._option}
                        checked={pathname.includes(_option?.page || '')}
                        onClick={() => navigate(`${page}${_option?.page}`)}
                    />
                ))}
            </div>
        </div>
    );
}

export default GroupOption;
