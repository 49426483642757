import { Button } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { HeroSectionItemProps } from '../../../../exports/Interfaces';
import EditHeroSectionItem from './EditHeroSectionItem';
import HeroSectionItemManager from '../../../../services/api/hero-section-item/HeroSectionItemManager';

function HeroSectionItem(props: HeroSectionItemProps): JSX.Element {
    const { item, onRemove } = props;

    const [showEdit, setShowEdit] = useState<boolean>(false);

    const [deleting, setDeleting] = useState<boolean>(false);

    const remove = async (): Promise<void> => {
        setDeleting(true);
        const { success } = await HeroSectionItemManager.delete(item.id);
        setDeleting(false);
        if (success) {
            onRemove(item.id);
        }
    };

    function renderFooter(): JSX.Element {
        return (
            <div className="px-small py-mini flex items-center justify-between border border-solid border-r-none border-l-none border-b-none border-brand-inkGrey-grey_2">
                <div className="flex items-center gap-4">
                    <Button loading={deleting} icon={<EditOutlined />} onClick={() => setShowEdit(true)}>
                        Edit
                    </Button>
                </div>
                <div className="flex items-center gap-4">
                    <Button loading={deleting} danger icon={<DeleteOutlined />} onClick={remove}>
                        Delete
                    </Button>
                </div>
            </div>
        );
    }

    if (showEdit) {
        return <EditHeroSectionItem {...props} toggleEdit={() => setShowEdit(false)} />;
    }

    return (
        <div className="mb-medium w-full rounded-md border-brand-inkGrey-grey_2 border-small border-solid bg-background-inkWhite-white_0">
            <div className="flex p-small">
                <div className="flex-1">
                    <h6>{item?.title ?? 'Title'}</h6>
                    <p className="mini text-brand-text-grey">{item?.description ?? 'Description'}</p>
                </div>
                {item.image?.url && (
                    <div className="overflow-hidden h-[60px] aspect-square rounded-lg relative">
                        <img
                            src={item.image.url}
                            alt="hero_section_item_image"
                            className="object-cover h-full w-full"
                        />
                    </div>
                )}
            </div>

            {renderFooter()}
        </div>
    );
}

export default HeroSectionItem;
