import { useState, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import collect from 'collect.js';
import useRdxStore from '../../../hooks/useRdxStore';
import { NavigationItemInterface } from '../../../exports/Interfaces';
import DragNDropHelpers from '../../../helpers/DragNDropHelpers';
import NavigationItemOrderManager from '../../../services/api/NavigationItemOrderManager';
import DraggableNavigationItem from '../../list/DraggableNavigationItem';
import InitialDataManager from '../../../services/api/InitialDataManager';
import NavigationItemManager from '../../../services/api/NavigationItemManager';

function DraggableNavigationList(): JSX.Element {
    const { store } = useRdxStore();

    const [navigationItems, setNavigationItem] = useState<NavigationItemInterface[]>(store?.navigation_items);

    useEffect(() => {
        setNavigationItem(store?.navigation_items || []);
    }, [store?.navigation_items]);

    const updateNavigationItems = async (list: NavigationItemInterface[]): Promise<void> => {
        setNavigationItem(list);
        await NavigationItemOrderManager.put(collect(list)?.pluck('id')?.toArray() as number[]);
        InitialDataManager.get();
    };

    const onDragEnd = (result: DropResult): void =>
        DragNDropHelpers.onDragEnd(navigationItems, result, (res) => updateNavigationItems(res));

    const toggleVisibility = async (id: number, value: boolean): Promise<void> => {
        const newNavigationItems = [...navigationItems];
        const index = collect(newNavigationItems).search((navigationItem) => navigationItem.id === id);
        if (index !== undefined) {
            newNavigationItems[index] = {
                ...newNavigationItems[index],
                is_hidden: value,
            };

            setNavigationItem(newNavigationItems);
        }
        await NavigationItemManager.put(id, { is_hidden: value });
        InitialDataManager.get();
    };

    return (
        <div className="w-full h-full py-small">
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="list">
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {navigationItems?.map((navigationItem, index) => (
                                <Draggable
                                    draggableId={`navigation-item-${navigationItem?.id}`}
                                    index={index}
                                    key={`navigation-item-${navigationItem?.id}`}
                                >
                                    {(_provided) => (
                                        <div ref={_provided.innerRef} {..._provided.draggableProps}>
                                            <span {..._provided.dragHandleProps}>
                                                <div className="mb-small">
                                                    <DraggableNavigationItem
                                                        navigationItem={navigationItem}
                                                        toggleVisibility={toggleVisibility}
                                                    />
                                                </div>
                                            </span>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
}

export default DraggableNavigationList;
