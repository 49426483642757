import hexRgb from 'hex-rgb';

export default class ColorResource {
    public static getRgbValue(hex: string): string {
        if (!hex) {
            return '';
        }
        const rbaArray: number[] = hexRgb(hex, { format: 'array' })?.slice(0, 3);
        let rba = '';
        rbaArray?.forEach((value: number, index: number) => {
            if (index === (rbaArray?.length ?? 0) - 1) {
                rba += value;
            } else {
                rba += `${value} `;
            }
        });
        return rba;
    }
}
