import { useMemo, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import collect from 'collect.js';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import DragNDropHelpers from '../../../helpers/DragNDropHelpers';
import { MENU_SECTION_TYPES } from '../../../exports/Enums';
import { MenuInterface, MenuSectionInterface, SectionInterface } from '../../../exports/Interfaces';
import SectionForm from '../../forms/SectionForm';
import useModals from '../../../hooks/useModals';
import MenuSectionItem from './MenuSectionItem';
import SectionManager from '../../../services/api/SectionManager';
import MenuSectionItemManager from '../../../services/api/menu-section-item/MenuSectionItemManager';
import MenuSectionItemOrderManager from '../../../services/api/menu-section-item/order/MenuSectionItemOrderManager';
import SectionSelect from '../../selects/SectionSelect';
import FormGroup from '../../forms/structure/form-group';
import FormItem from '../../forms/structure/form-item';

interface Props {
    section: SectionInterface<MenuSectionInterface>;
}

function MenuSectionForm(props: Props): JSX.Element {
    const { section } = props;

    const { setSectionModal } = useModals();

    const [form] = Form.useForm<MenuSectionInterface>();

    const { type } = Form.useWatch('content', form) ?? { type: MENU_SECTION_TYPES.ONE };

    const [loading, setLoading] = useState<boolean>(false);

    const [adding, setAdding] = useState<boolean>(false);

    const [menuItems, setMenuItems] = useState<MenuInterface[]>(section?.content?.menu_items || []);

    const maxItems = 3;

    const onSave = async (values: Partial<SectionInterface<MenuSectionInterface>>): Promise<any> => {
        if (typeof section?.content?.id !== 'number') {
            return;
        }
        setLoading(true);

        const request = await SectionManager.put<MenuSectionInterface>(section.id, {
            ...values,
            content: {
                ...values?.content,
                type,
            },
        });

        const { success } = request;

        setLoading(false);
        if (success) {
            setSectionModal({ open: false });
        }

        // eslint-disable-next-line consistent-return
        return request;
    };

    const updateOrder = async (list: MenuInterface[]): Promise<void> => {
        setMenuItems(list);
        MenuSectionItemOrderManager.put(collect(list)?.pluck('id')?.toArray() as number[]);
    };

    const onDragEnd = (result: DropResult): void =>
        DragNDropHelpers.onDragEnd(menuItems, result, (res: MenuInterface[]) => updateOrder(res));

    const addMenuItem = async (): Promise<void> => {
        setAdding(true);
        const { response, success } = await MenuSectionItemManager.post(section.content.id, {});
        if (success) {
            const newMenuItems = [...menuItems];
            newMenuItems.push(response.data.data);
            setMenuItems(newMenuItems);
        }
        setAdding(false);
    };

    const removeMenuItem = (id: number): void => {
        setMenuItems(collect(menuItems).where('id', '!=', id).toArray());
    };

    const updateMenuItem = (menu: MenuInterface): void => {
        const newMenuItems = [...menuItems];
        const index = collect(newMenuItems).search((menuItem) => menuItem.id === menu.id);
        if (typeof index === 'number') {
            newMenuItems[index] = menu;
            setMenuItems(newMenuItems);
        }
    };

    const addDisabled = useMemo(() => {
        switch (Number(type)) {
            case MENU_SECTION_TYPES.FOUR:
            case MENU_SECTION_TYPES.EIGHT:
            case MENU_SECTION_TYPES.NINE:
                return false;
            default:
                return menuItems?.length === maxItems;
        }
    }, [type]);

    return (
        <SectionForm
            section={section}
            onSave={onSave}
            loading={loading}
            initialValues={{
                ...section,
                content: {
                    ...section?.content,
                    type: section?.content?.type ?? MENU_SECTION_TYPES.ONE,
                },
            }}
            form={form}
            advancedContentTitle="Menu Items"
            advancedContent={
                <>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="list">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {menuItems?.map((menuItem, index) => (
                                        <Draggable
                                            draggableId={`menu-item-${menuItem?.id}`}
                                            index={index}
                                            key={`menu-item-${menuItem?.id}`}
                                        >
                                            {(_provided) => (
                                                <div ref={_provided.innerRef} {..._provided.draggableProps}>
                                                    <span {..._provided.dragHandleProps}>
                                                        <div className="mb-small">
                                                            <div key={menuItem.id.toString()}>
                                                                <MenuSectionItem
                                                                    type={type}
                                                                    menu={menuItem}
                                                                    onRemove={removeMenuItem}
                                                                    onUpdate={updateMenuItem}
                                                                />
                                                            </div>
                                                        </div>
                                                    </span>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <Button loading={adding} icon={<PlusOutlined />} onClick={addMenuItem} disabled={addDisabled}>
                        Add Menu
                    </Button>
                </>
            }
        >
            <Form.Item
                name={['content', 'type']}
                rules={[{ required: true, message: 'Please select a Layout!' }]}
                className="mb-none"
            >
                <SectionSelect
                    section={section}
                    onSelect={(_type: MENU_SECTION_TYPES) => form.setFieldValue(['content', 'type'], _type)}
                />
            </Form.Item>
            <FormGroup label="Typography">
                <>
                    <FormItem label="Title">
                        <Form.Item name={['content', 'title']}>
                            <Input placeholder="Title" />
                        </Form.Item>
                    </FormItem>
                    <FormItem label="Description">
                        <Form.Item name={['content', 'description']}>
                            <Input.TextArea rows={6} placeholder="Description" />
                        </Form.Item>
                    </FormItem>
                </>
            </FormGroup>
        </SectionForm>
    );
}

export default MenuSectionForm;
