import collect from 'collect.js';
import { useState, useEffect, useRef } from 'react';
import { STOREFRONT_PAGES } from '../../../../exports/Enums';
import { PageContentInterface } from '../../../../exports/Interfaces';
import useRdxStore from '../../../../hooks/useRdxStore';
import PageManager from '../../../../services/api/page/PageManager';
import Content from '../../Content';
import SectionList from '../../../../components/sections/SectionList';
import useModals from '../../../../hooks/useModals';

function Home(): JSX.Element {
    const { store } = useRdxStore();

    const { sectionModal } = useModals();

    const [page, setPage] = useState<PageContentInterface | null>(null);

    const [loading, setLoading] = useState(true);

    const [mounted, setMounted] = useState<boolean>(false);

    const mountedRef = useRef(mounted);
    mountedRef.current = mounted;

    const requestPage = async (showLoading = true): Promise<void> => {
        if (showLoading) {
            setLoading(true);
        }
        const { response, success } = await PageManager.get(
            collect(store?.pages).firstWhere('href', STOREFRONT_PAGES.HOME)?.id,
        );
        setLoading(false);
        setMounted(true);
        if (success) {
            setPage(response?.data?.data);
        }
    };

    useEffect(() => {
        requestPage();
    }, []);

    useEffect(() => {
        if (!mountedRef.current) {
            return;
        }
        if (!sectionModal?.open) {
            requestPage(false);
        }
    }, [sectionModal?.open]);

    return (
        <Content title="Home Page" description="To confirm/ edit images / copy" loading={loading}>
            <SectionList sections={page?.sections || []} />
        </Content>
    );
}

export default Home;
