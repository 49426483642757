import { Collapse, Form, Select, Switch } from 'antd';
import { useEffect } from 'react';
import { DefaultOptionType } from 'antd/es/select';
import collect from 'collect.js';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { BUTTON_SIZE } from '../../exports/Enums';
import ButtonSelect from './ButtonSelect';
import { ButtonInterface } from '../../exports/Interfaces';

interface Props {
    title: string;
    button?: ButtonInterface | null;
    headerExtra?: JSX.Element;
    setButton: (button: ButtonInterface) => void;
}

function ButtonDesignSelect(props: Props): JSX.Element {
    const { title, button, headerExtra, setButton } = props;

    const [form] = Form.useForm();

    const allValues = Form.useWatch([], form);

    useEffect(() => {
        form.validateFields();
        setButton(allValues);
    }, [allValues]);

    const renderContent = (): JSX.Element => (
        <Form
            form={form}
            initialValues={{
                remember: true,
                ...button,
                sub_text_hidden: !button?.sub_text_hidden,
            }}
            className="w-full"
        >
            <Form.Item name="variant" label="Variant">
                <ButtonSelect
                    initialVariant={button?.variant}
                    setVariant={(variant) => form.setFieldValue('variant', variant)}
                />
            </Form.Item>
            <Form.Item name="size" label="Size">
                <Select
                    options={
                        collect(BUTTON_SIZE)
                            .values()
                            .map((value) => ({
                                label: value,
                                value,
                            }))
                            .toArray() as DefaultOptionType[]
                    }
                    defaultValue={button?.size}
                    className="w-full"
                    placeholder="Select size"
                />
            </Form.Item>
            <Form.Item name="sub_text_hidden" label="Subtext">
                <Switch
                    defaultChecked={!button?.sub_text_hidden}
                    onChange={(checked) => form.setFieldValue('sub_text_hidden', !checked)}
                    checkedChildren={<EyeOutlined />}
                    unCheckedChildren={<EyeInvisibleOutlined />}
                />
            </Form.Item>
        </Form>
    );

    return (
        <Collapse expandIconPosition="end" className="w-full">
            <Collapse.Panel key="1" header={title} extra={headerExtra} className="w-full">
                {renderContent()}
            </Collapse.Panel>
        </Collapse>
    );
}

export default ButtonDesignSelect;
