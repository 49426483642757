import { useState } from 'react';
import { Button, Form, Input, Select } from 'antd';
import collect from 'collect.js';
import CustomForm from '../../forms/CustomForm';
import useRdxStore from '../../../hooks/useRdxStore';
import StoreManager from '../../../services/api/StoreManager';
import { StoreInterface } from '../../../exports/Interfaces';
import RestaurantInfoLayout from '../../layouts/RestaurantInfoLayout';
import { CURRENCY_TYPES } from '../../../exports/Enums';

function GeneralInfoForm(): JSX.Element {
    const { store } = useRdxStore();

    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = async (values: any): Promise<void> => {
        setLoading(true);
        await StoreManager.put(
            store?.id,
            collect(values)
                ?.filter((value) => value !== undefined)
                .all() as Partial<StoreInterface>,
        );
        setLoading(false);
    };

    return (
        <RestaurantInfoLayout title="General Information">
            <CustomForm>
                <Form
                    name="basic"
                    initialValues={{
                        remember: true,
                        ...store,
                        currency: store?.currency ?? CURRENCY_TYPES.EURO,
                    }}
                    onFinish={onFinish}
                    layout="vertical"
                >
                    <Form.Item name="name" label="Company Name">
                        <Input placeholder="Enter Company Name" />
                    </Form.Item>

                    <Form.Item name="phone_number" label="Phone number">
                        <Input placeholder="Enter Phone Number" />
                    </Form.Item>

                    <Form.Item name="email" label="Email">
                        <Input placeholder="Enter Email Address" />
                    </Form.Item>

                    <div className="grid lg:grid-cols-4 grid-cols-1 gap-2">
                        <Form.Item name="address" label="Address" className="lg:col-span-2">
                            <Input placeholder="Enter Address" />
                        </Form.Item>

                        <Form.Item name="zip_code" label="Zip Code" className="lg:col-span-1">
                            <Input placeholder="Enter Zip Code" />
                        </Form.Item>

                        <Form.Item name="city" label="City" className="lg:col-span-1">
                            <Input placeholder="Enter City" />
                        </Form.Item>
                    </div>

                    <Form.Item name="currency" label="Currency">
                        <Select
                            placeholder="Select Layout"
                            options={[
                                { value: CURRENCY_TYPES.EURO, label: '€ Euro ' },
                                {
                                    value: CURRENCY_TYPES.POUND,
                                    label: '£ Pound',
                                },
                                { value: CURRENCY_TYPES.DOLLAR, label: '$ Dollar' },
                            ]}
                        />
                    </Form.Item>

                    <Form.Item className="mb-none">
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </CustomForm>
        </RestaurantInfoLayout>
    );
}

export default GeneralInfoForm;
