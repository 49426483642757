import { useState } from 'react';
import { Spin } from 'antd';
import { ANIMATED_SECTION_TYPES, COLOR_VARIABLES, SECTION_TYPES } from '../../../../../exports/Enums';
import ElementCard from '../../../../cards/ElementCard';
import SectionManager from '../../../../../services/api/SectionManager';
import useCustomTranslation from '../../../../../hooks/useCustomTranslation';
import useModals from '../../../../../hooks/useModals';
import ElementsWrapper from '../../tabs/ElementsWrapper';
import { AnimatedSectionInterface } from '../../../../../exports/Interfaces';

function Animated(): JSX.Element {
    const { featureModal, setFeatureModal } = useModals();

    const pageId = featureModal?.pageId;

    const section = featureModal?.section;

    const contentType = featureModal?.contentType;

    const { t } = useCustomTranslation({ keyPrefix: 'Components:Sections:Features:FeaturesList' });

    const [loading, setLoading] = useState<boolean>(false);

    const isUpdate = !!section;

    const s3Url = (type: number): string => `${import.meta.env.VITE_S3_PROD_BASE_URL}/sections/animated/${type}.png`;

    function getData(_contentType: ANIMATED_SECTION_TYPES): any {
        switch (_contentType) {
            case ANIMATED_SECTION_TYPES.ONE:
                return {
                    items: [
                        { title: '#Dynamic#Animations#Showcase', color: COLOR_VARIABLES.PRIMARY_COLOR },
                        { title: '#Engaging#Visual#Experience', color: COLOR_VARIABLES.SECONDARY_COLOR },
                    ],
                };
            default:
                return null;
        }
    }

    const create = async (_contentType: ANIMATED_SECTION_TYPES): Promise<void> => {
        if (typeof pageId !== 'number') {
            return;
        }

        setLoading(true);
        const { success } = await SectionManager.post<AnimatedSectionInterface>(pageId, {
            parent_id: featureModal?.parentSection?.id ?? null,
            type: SECTION_TYPES.ANIMATED_SECTION,
            name: t('sections.animated.name'),
            content: {
                type: _contentType,
                data: getData(_contentType),
            },
        });

        setLoading(false);

        if (success) {
            setFeatureModal({ open: false });
        }
    };

    const _onSelect = (_contentType: ANIMATED_SECTION_TYPES): void => {
        if (!isUpdate) {
            create(_contentType);
            return;
        }
        if (featureModal?.onSelect) {
            featureModal.onSelect(_contentType);
        }

        setFeatureModal({ open: false });
    };

    if (loading) {
        return (
            <div className="w-full h-full flex justify-center items-center">
                <Spin />
            </div>
        );
    }

    const data = [
        {
            type: ANIMATED_SECTION_TYPES.ONE,
            title: 'Moving Text Banner',
        },
    ];

    return (
        <ElementsWrapper>
            {data.map((item) => (
                <ElementCard
                    title={item.title}
                    url={s3Url(item.type)}
                    isActive={item.type === contentType}
                    onClick={() => _onSelect(item.type)}
                    isUpdate={isUpdate}
                />
            ))}
        </ElementsWrapper>
    );
}

export default Animated;
