import { useState } from 'react';
import { Form, Input, message } from 'antd';
import { DB_MODELS, GALLERY_SECTION_TYPES, MEDIA_TAGS } from '../../../exports/Enums';
import { GallerySectionInterface, MediaInterface, SectionInterface } from '../../../exports/Interfaces';
import SectionForm from '../../forms/SectionForm';
import SectionManager from '../../../services/api/SectionManager';
import MediaManager from '../../../services/api/MediaManager';
import SelectedMediaList from '../../media/SelectedMediaList';
import useModals from '../../../hooks/useModals';
import SectionSelect from '../../selects/SectionSelect';
import FormGroup from '../../forms/structure/form-group';
import FormItem from '../../forms/structure/form-item';

interface Props {
    section: SectionInterface<GallerySectionInterface>;
}

function GallerySectionForm(props: Props): JSX.Element {
    const { section } = props;

    const content = section?.content;

    const { setSectionModal } = useModals();

    const [form] = Form.useForm();

    const contentType = Form.useWatch(['content', 'type'], form);

    const [loading, setLoading] = useState<boolean>(false);

    const [mediaList, setMediaList] = useState<MediaInterface[]>([]);

    const onSave = async (values: Partial<SectionInterface<GallerySectionInterface>>): Promise<void> => {
        if (typeof section?.id !== 'number') {
            return;
        }

        if (values?.content?.type === GALLERY_SECTION_TYPES.TWO && mediaList.length < 3) {
            const missingImages = 3 - (mediaList?.length ?? 0);
            message.error(
                `Please add ${missingImages} more ${
                    missingImages === 1 ? 'image' : 'images'
                }. A minimum of 3 images are required.`,
            );
            return;
        }

        const { response, success } = await SectionManager.put<GallerySectionInterface>(section?.id, values);

        if (success && typeof response?.data?.data?.content?.id === 'number') {
            await MediaManager.post(
                DB_MODELS.GALLERY_SECTION,
                response?.data?.data?.content?.id,
                mediaList,
                MEDIA_TAGS.IMAGES,
                '',
            );
        }
        setLoading(false);
        setSectionModal({ open: false });
    };

    return (
        <SectionForm section={section} onSave={onSave} loading={loading} initialValues={section} form={form}>
            <Form.Item
                name={['content', 'type']}
                rules={[{ required: true, message: 'Please select a Layout!' }]}
                className="mb-none"
            >
                <SectionSelect
                    section={section}
                    onSelect={(_type: GALLERY_SECTION_TYPES) => form.setFieldValue(['content', 'type'], _type)}
                />
            </Form.Item>
            <FormGroup label="Media">
                <SelectedMediaList
                    uploadCount={9}
                    defaultMediaList={[...(content?.images ?? []), ...(content?.videos ?? [])]}
                    mediaList={mediaList}
                    setMediaList={setMediaList}
                    description={
                        contentType === GALLERY_SECTION_TYPES.TWO && mediaList?.length < 3
                            ? '* Minimum 3 images are required.'
                            : ''
                    }
                />
            </FormGroup>
            <FormGroup label="Typography">
                <FormItem label="Description">
                    <Form.Item name={['content', 'description']}>
                        <Input.TextArea rows={6} placeholder="Description" />
                    </Form.Item>
                </FormItem>
            </FormGroup>
        </SectionForm>
    );
}

export default GallerySectionForm;
