import { Drawer } from 'antd';
import DraggableNavigationList from '../sections/draggableNavigationItems/DraggableNavigationList';
import useModals from '../../hooks/useModals';

function NavigationItemsModal(): JSX.Element {
    const { navigationItemModal, setNavigationItemModal } = useModals();

    const { open } = navigationItemModal;

    return (
        <Drawer
            title="Navigation Bar"
            open={open}
            onClose={() => setNavigationItemModal({ open: false })}
            footer={null}
            placement="left"
        >
            <div className="pb-[100px]">
                <DraggableNavigationList />
            </div>
        </Drawer>
    );
}

export default NavigationItemsModal;
