import { message } from 'antd';
import { DataWrapper, Response, StoreThemeInterface } from '../../exports/Interfaces';
import actionCreators from '../../store/actions';
import ReduxResource from '../resources/ReduxResource';
import BackendApiClient from './BackendApiClient';

export default class ThemeManager {
    public static post = async (
        data: Partial<StoreThemeInterface>,
        showMessage = true,
    ): Promise<Response<DataWrapper<DataWrapper<StoreThemeInterface>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<StoreThemeInterface>>>({
            method: 'POST',
            url: `/theme`,
            data,
        });

        const { response, success } = request;

        if (response?.data?.data && success) {
            ThemeManager.save(response?.data?.data);
            if (showMessage) {
                message.success('Saved');
            }
        }

        return request;
    };

    private static save = (theme: StoreThemeInterface): void => {
        ReduxResource.dispatch(actionCreators.store.setTheme(theme));
    };
}
