import { createContext } from 'react';
import { MediaContextInterface } from '../exports/Interfaces';

const MediaContext = createContext<MediaContextInterface>({
    toggleGallery: () => null,
    loadGallery: () => Promise.resolve(),
    renderContent: () => null,
});

export default MediaContext;
