import { message } from 'antd';
import { DataWrapper, NavigationItemInterface, Response } from '../../exports/Interfaces';
import BackendApiClient from './BackendApiClient';

export default class NavigationItemOrderManager {
    public static put = async (
        data?: number[],
    ): Promise<Response<DataWrapper<DataWrapper<NavigationItemInterface[]>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<NavigationItemInterface[]>>>({
            method: 'PUT',
            url: `/navigation-item-order`,
            params: {
                ids: data,
            },
        });

        const { response, success } = request;

        if (response?.data?.data && success) {
            message.success('Updated');
        }

        return request;
    };
}
