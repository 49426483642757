import { useState } from 'react';
import { Spin } from 'antd';
import collect from 'collect.js';
import { SECTION_TYPES, TIME_LINE_SECTION_TYPES } from '../../../../../exports/Enums';
import ElementCard from '../../../../cards/ElementCard';
import { TimeLineSectionInterface } from '../../../../../exports/Interfaces';
import SectionManager from '../../../../../services/api/SectionManager';
import useCustomTranslation from '../../../../../hooks/useCustomTranslation';
import useModals from '../../../../../hooks/useModals';
import ElementsWrapper from '../../tabs/ElementsWrapper';

function TimeLine(): JSX.Element {
    const { featureModal, setFeatureModal } = useModals();

    const pageId = featureModal?.pageId;

    const section = featureModal?.section;

    const contentType = featureModal?.contentType;

    const isUpdate = !!section;

    const { t } = useCustomTranslation({ keyPrefix: 'Components:Sections:Features:FeaturesList' });

    const [loading, setLoading] = useState<boolean>(false);

    const s3Url = (type: TIME_LINE_SECTION_TYPES): string =>
        `${import.meta.env.VITE_S3_PROD_BASE_URL}/sections/time-line/${type}.png`;

    const create = async (_contentType: TIME_LINE_SECTION_TYPES): Promise<void> => {
        if (typeof pageId !== 'number') {
            return;
        }

        setLoading(true);
        const { success } = await SectionManager.post<TimeLineSectionInterface>(pageId, {
            type: SECTION_TYPES.TIME_LINE_SECTION,
            name: t('sections.time_line.name'),
            is_hidden: true,
            content: {
                type: _contentType,
            },
        });

        setLoading(false);

        if (success) {
            setFeatureModal({ open: false });
        }
    };

    const _onSelect = (_contentType: TIME_LINE_SECTION_TYPES): void => {
        if (!isUpdate) {
            create(_contentType);
            return;
        }
        if (featureModal?.onSelect) {
            featureModal.onSelect(_contentType);
        }

        setFeatureModal({ open: false });
    };

    interface ItemInterface {
        type: TIME_LINE_SECTION_TYPES;
    }

    const data = collect(TIME_LINE_SECTION_TYPES)
        .values()
        .filter((value) => !Number.isNaN(Number(value)))
        .map((value) => ({
            type: value,
        }))
        .toArray() as ItemInterface[];

    if (loading) {
        return (
            <div className="w-full h-full flex justify-center items-center">
                <Spin />
            </div>
        );
    }

    return (
        <ElementsWrapper>
            {data.map((item) => (
                <ElementCard
                    title={item.type.toString()}
                    url={s3Url(item.type)}
                    isActive={item.type === contentType}
                    onClick={() => _onSelect(item.type)}
                    isUpdate={isUpdate}
                />
            ))}
        </ElementsWrapper>
    );
}

export default TimeLine;
