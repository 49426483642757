import RestaurantInfoLayout from '../../../layouts/RestaurantInfoLayout';
import EventSettingsForm from './EventSettingsForm';

function EventSettings(): JSX.Element {
    return (
        <RestaurantInfoLayout title="Settings">
            <EventSettingsForm />
        </RestaurantInfoLayout>
    );
}

export default EventSettings;
