import { useEffect, useState } from 'react';
import collect from 'collect.js';
import dayjs from 'dayjs';
import { PopupInterface } from '../exports/Interfaces';
import useRdxStore from './useRdxStore';

function usePopup(): {
    visiblePopup: PopupInterface | null;
} {
    const { store } = useRdxStore();

    const [visiblePopup, setVisiblePopup] = useState<PopupInterface | null>(null);

    function isPopupVisible(popup: PopupInterface): boolean {
        try {
            const currentDate = dayjs()?.startOf('day');
            const startDate = popup?.start_date ? dayjs(popup.start_date)?.startOf('day') : null;
            const expirationDate = popup?.expiration_date ? dayjs(popup.expiration_date)?.startOf('day') : null;

            if (startDate && currentDate?.isBefore(startDate)) {
                return false;
            }

            if (expirationDate && currentDate?.isAfter(expirationDate)) {
                return false;
            }

            return true;
        } catch (error) {
            return false;
        }
    }

    useEffect(() => {
        try {
            setVisiblePopup(
                collect(store?.popups)
                    .filter((popup) => !popup?.is_hidden && isPopupVisible(popup))
                    ?.first() ?? null,
            );
        } catch (error) {}
    }, [store?.popups]);

    return {
        visiblePopup,
    };
}

export default usePopup;
