import RestaurantInfoLayout from '../../layouts/RestaurantInfoLayout';
import GoogleReviewsForm from './GoogleReviewsForm';

function GoogleReviews(): JSX.Element {
    return (
        <RestaurantInfoLayout title="Google Reviews">
            <GoogleReviewsForm />
        </RestaurantInfoLayout>
    );
}

export default GoogleReviews;
