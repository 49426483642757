import SectionLayout from '../../layouts/SectionLayout';
import DraggableNavigationList from '../../sections/draggableNavigationItems/DraggableNavigationList';

function NavigationItemsPage(): JSX.Element {
    return (
        <SectionLayout title="Navigation Bar" hideClose>
            <DraggableNavigationList />
        </SectionLayout>
    );
}

export default NavigationItemsPage;
