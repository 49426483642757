import { createContext } from 'react';
import { CookiesContextInterface } from '../exports/Interfaces';

const CookiesContext = createContext<CookiesContextInterface>({
    cookies: [],
    setCookie: () => null,
    removeCookies: () => null,
    getCookie: () => null,
});

export default CookiesContext;
