import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import ThemeLayout from '../../layouts/ThemeLayout';
import Typography from './Typography';
import Design from './Design';

function ButtonForm(): JSX.Element {
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Typography',
            children: <Typography />,
        },
        {
            key: '2',
            label: 'Design',
            children: <Design />,
        },
    ];

    return (
        <ThemeLayout title="Buttons">
            <Tabs defaultActiveKey="1" items={items} />
        </ThemeLayout>
    );
}

export default ButtonForm;
