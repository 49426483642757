import { Button } from 'antd';
import { AppstoreOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import collect from 'collect.js';
import { useMemo, useState } from 'react';
import { EditSectionItemProps, FAQItemInterface } from '../../../exports/Interfaces';
import EditFAQSectionItem from './EditFAQSectionItem';
import { FAQ_SECTION_TYPES } from '../../../exports/Enums';
import FAQSectionItemManager from '../../../services/api/faq-section-item/FAQSectionItemManager';

function FAQSectionItem(props: EditSectionItemProps<FAQ_SECTION_TYPES, FAQItemInterface>): JSX.Element {
    const { item, onRemove } = props;

    const isEmpty = collect([!!item?.title, !!item?.description]).every((value) => value === false);

    const [showEdit, setShowEdit] = useState<boolean>(isEmpty);

    const [deleting, setDeleting] = useState<boolean>(false);

    const remove = async (): Promise<void> => {
        setDeleting(true);
        const { success } = await FAQSectionItemManager.delete(item.id);
        setDeleting(false);
        if (success) {
            onRemove(item.id);
        }
    };

    function renderHeader(): JSX.Element {
        return (
            <div className="flex items-center justify-between mb-small">
                <AppstoreOutlined className="mr-mini" />
                <div className="flex items-center gap-4">
                    <Button loading={deleting} danger icon={<DeleteOutlined />} onClick={remove} />
                </div>
            </div>
        );
    }

    const renderEditFAQSectionItem = useMemo(
        () => showEdit && <EditFAQSectionItem {...props} toggleEdit={() => setShowEdit(false)} onRemove={remove} />,
        [showEdit],
    );

    return (
        <>
            <div className="mb-medium w-full rounded-md border-brand-inkGrey-grey_2 border-small border-solid bg-background-inkWhite-white_0 shadow-section">
                <div className="p-small">
                    {renderHeader()}
                    <div>
                        <div className="h-[100px] w-full flex items-center justify-between bg-brand-inkGrey-grey_0 rounded-lg mr-small overflow-hidden">
                            <div className="w-full h-full flex items-center">
                                <div className="h-full w-full flex flex-col justify-center p-small">
                                    {item?.title && <h6>{item?.title}</h6>}
                                    {item?.description && (
                                        <p className="mini text-brand-text-grey">{item?.description}</p>
                                    )}
                                </div>
                            </div>
                            <div className="p-small">
                                <Button icon={<EditOutlined />} onClick={() => setShowEdit(true)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {renderEditFAQSectionItem}
        </>
    );
}

export default FAQSectionItem;
