import { Button, Form, Input } from 'antd';
import collect from 'collect.js';
import { DeleteOutlined } from '@ant-design/icons';
import { MediaInterface } from '../../../exports/Interfaces';
import useGallery from '../../../hooks/useGallery';
import { MEDIA_TYPES } from '../../../exports/Enums';

interface Props {
    socialTitle: string;
    socialDescription: string;
    socialImageFile: MediaInterface | null;
    loading: boolean;
    setEditSocialPreview: (value: boolean) => void;
    setSocialTitle: (value: string) => void;
    setSocialDescription: (value: string) => void;
    setSocialImageFile: (value: MediaInterface | null) => void;
    onSave: ({ social_title, social_description }: { social_title: string; social_description: string }) => void;
    onCopy: () => void;
}

function SeoSocialEditPreview(props: Props): JSX.Element {
    const {
        socialTitle,
        socialDescription,
        socialImageFile,
        loading,
        setEditSocialPreview,
        setSocialTitle,
        setSocialDescription,
        setSocialImageFile,
        onSave,
        onCopy,
    } = props;

    const [form] = Form.useForm();

    const { toggleGallery } = useGallery();

    const onSelectSocialImage = (media: MediaInterface[]): void => {
        setSocialImageFile(collect(media)?.first());
    };

    return (
        <Form
            form={form}
            onFinish={(values) => {
                setSocialTitle(values?.social_title);
                setSocialDescription(values?.social_description);
                onSave({
                    social_title: values?.social_title,
                    social_description: values?.social_description,
                });
            }}
            initialValues={{
                social_title: socialTitle,
                social_description: socialDescription,
            }}
        >
            <Form.Item label="Social Image">
                <div className="flex flex-col">
                    <Button
                        className="shadow p-none overflow-hidden border-[5px] border-solid border-white relative lg:w-[400px] w-full h-[200px]"
                        type="ghost"
                        onClick={() =>
                            toggleGallery({
                                open: true,
                                maxSelect: 1,
                                mediaType: MEDIA_TYPES.IMAGE,
                                onSelect: onSelectSocialImage,
                            })
                        }
                        // style={{ width: 400, height: 200 }}
                    >
                        <img src={socialImageFile?.url ?? ''} alt="social" className="w-full h-full object-cover" />
                    </Button>
                    {socialImageFile && (
                        <Button
                            className="w-fit mt-small"
                            icon={<DeleteOutlined />}
                            onClick={() => setSocialImageFile(null)}
                        >
                            Remove
                        </Button>
                    )}
                </div>
            </Form.Item>
            <Form.Item name="social_title" label="Social Title">
                <Input placeholder="Enter Social Title" />
            </Form.Item>

            <Form.Item name="social_description" label="Social Description">
                <Input.TextArea placeholder="Enter Social Description" />
            </Form.Item>

            <div className="items-center grid lg:grid-cols-2 grid-cols-1 gap-4">
                <div className="flex items-center">
                    <Button type="primary" htmlType="submit" className="mr-small lg:w-fit w-full" loading={loading}>
                        Save
                    </Button>
                    <Button type="default" onClick={() => setEditSocialPreview(false)} className="lg:w-fit w-full">
                        Close
                    </Button>
                </div>
                <div className="w-full flex justify-end items-center">
                    <Button
                        type="default"
                        onClick={() => {
                            onCopy();
                            form.resetFields();
                        }}
                        className="lg:w-fit w-full"
                    >
                        Copy from General
                    </Button>
                </div>
            </div>
        </Form>
    );
}

export default SeoSocialEditPreview;
