import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useEffect, useMemo, useState } from 'react';
import collect from 'collect.js';
import { SectionInterface } from '../../exports/Interfaces';
import SectionItem from '../list/SectionItem';
import DragNDropHelpers from '../../helpers/DragNDropHelpers';
import SectionOrderManager from '../../services/api/section/order/SectionOrderManager';
import SectionManager from '../../services/api/SectionManager';
import useModals from '../../hooks/useModals';
import EditSection from './EditSection';

interface Props {
    sections: SectionInterface<any>[];
}

function SectionList(props: Props): JSX.Element {
    const { sections: _sections } = props;

    const { sectionModal } = useModals();

    const [sections, setSections] = useState<SectionInterface<any>[]>([]);

    useEffect(() => {
        setSections(_sections);
    }, [_sections]);

    const updateSections = async (list: SectionInterface[]): Promise<void> => {
        setSections(list);
        SectionOrderManager.put(collect(list)?.pluck('id')?.toArray() as number[]);
    };

    const onDragEnd = (result: DropResult): void =>
        DragNDropHelpers.onDragEnd(sections, result, (res) => updateSections(res));

    const onRemove = (id: number): void => {
        setSections(
            collect(sections)
                .reject((section) => section.id === id)
                .map((section) => {
                    if (section?.child?.id === id) {
                        section.child = null;
                    }
                    return section;
                })
                .toArray(),
        );
    };

    const onUpdateSection = (updatedSection: SectionInterface): void => {
        setSections(
            collect(sections)
                .map((section) => {
                    if (section.id === updatedSection.id) {
                        return updatedSection;
                    }
                    return section;
                })
                .toArray(),
        );
    };

    const toggleVisibility = (id: number, value: boolean): void => {
        SectionManager.put(id, {
            is_hidden: value,
        });
        const newSections = [...sections];

        const index = collect(newSections).search((section) => section.id === id);

        if (index !== undefined) {
            newSections[index].is_hidden = value;
            setSections(newSections);
        }
    };

    const renderSectionModal = useMemo(() => sectionModal?.open && <EditSection />, [sectionModal]);

    return (
        <>
            <div className="w-full h-full" key={JSON.stringify(sections)}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="list">
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {sections?.map((section, index) => (
                                    <Draggable
                                        draggableId={`section-${section?.id}`}
                                        index={index}
                                        key={`section-${section?.id}`}
                                    >
                                        {(_provided) => (
                                            <div ref={_provided.innerRef} {..._provided.draggableProps}>
                                                <span {..._provided.dragHandleProps}>
                                                    <div className="mb-small">
                                                        <SectionItem
                                                            isHidable
                                                            isDeletable
                                                            isDraggable
                                                            section={section}
                                                            onRemove={onRemove}
                                                            updateSection={onUpdateSection}
                                                            toggleVisibility={toggleVisibility}
                                                        />
                                                    </div>
                                                </span>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            {renderSectionModal}
        </>
    );
}

export default SectionList;
