import { useState } from 'react';
import { Button, Form, Input, Switch } from 'antd';
import collect from 'collect.js';
import { AppstoreOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { DB_MODELS, LINK_IDENTIFIERS, MEDIA_TAGS, MEDIA_TYPES } from '../../../../exports/Enums';
import { HeroSectionItemProps, LinkInterface, MediaInterface } from '../../../../exports/Interfaces';
import SelectedMediaList from '../../../media/SelectedMediaList';
import LinkInput from '../../../links/LinkInput';
import MediaManager from '../../../../services/api/MediaManager';
import LinkManager from '../../../../services/api/LinkManager';
import HeroSectionItemManager from '../../../../services/api/hero-section-item/HeroSectionItemManager';
import ColorSelect from '../../../selects/ColorSelect';

function EditHeroSectionItem(props: HeroSectionItemProps): JSX.Element {
    const { item, onUpdate, toggleEdit } = props;

    const [form] = Form.useForm();

    const [updating, setUpdating] = useState<boolean>(false);

    const [mediaList, setMediaList] = useState<MediaInterface[]>(item?.image ? [item?.image] : []);

    const [buttonLink, setButtonLink] = useState<LinkInterface | null>(collect(item?.links).first() ?? null);

    const update = async (): Promise<void> => {
        setUpdating(true);
        const media = MediaManager.post(DB_MODELS.HERO_SECTION_ITEM, item?.id, mediaList, MEDIA_TAGS.IMAGES, '');

        const link = LinkManager.post(
            DB_MODELS.HERO_SECTION_ITEM,
            item?.id,
            collect([buttonLink])
                ?.filter((value) => !!value?.href)
                .toArray(),
        );
        await Promise.all([media, link]);

        const values = form.getFieldsValue();
        const { response, success } = await HeroSectionItemManager.put(item.id, values);
        if (success) {
            onUpdate(response.data.data);
            if (toggleEdit) {
                toggleEdit();
            }
        }
        setUpdating(false);
    };

    function renderFooter(): JSX.Element {
        return (
            <div className="flex items-center gap-4 px-small py-xsmall border-brand-inkGrey-grey_2 border-small border-solid border-b-none border-r-none border-l-none">
                <Button type="primary" onClick={update} loading={updating}>
                    Update
                </Button>
                <Button onClick={() => toggleEdit && toggleEdit()}>Close</Button>
            </div>
        );
    }

    function renderHeader(): JSX.Element {
        return (
            <div className="flex items-center mb-small">
                <AppstoreOutlined className="mr-mini" />
            </div>
        );
    }

    return (
        <Form form={form} initialValues={item}>
            <div className="mb-medium w-full rounded-md border-brand-inkGrey-grey_2 border-small border-solid bg-background-inkWhite-white_0">
                <div className="p-small">
                    {renderHeader()}
                    <Form.Item label="Subtitle" name="sub_title">
                        <Input placeholder="Enter Subtitle" />
                    </Form.Item>
                    <Form.Item label="Title" name="title">
                        <Input placeholder="Enter Title" />
                    </Form.Item>
                    <Form.Item label="Description" name="description">
                        <Input.TextArea rows={3} placeholder="Enter Description" />
                    </Form.Item>
                    <Form.Item label="Background color" name="background_color">
                        <ColorSelect
                            initialColor={item?.background_color}
                            onSelect={(color) => form.setFieldValue('background_color', color)}
                        />
                    </Form.Item>
                    <Form.Item label="Header Color" name="header_color">
                        <ColorSelect
                            initialColor={item?.header_color}
                            onSelect={(color) => form.setFieldValue('header_color', color)}
                        />
                    </Form.Item>
                    <Form.Item label="Description Color" name="description_color">
                        <ColorSelect
                            initialColor={item?.description_color}
                            onSelect={(color) => form.setFieldValue('description_color', color)}
                        />
                    </Form.Item>

                    <Form.Item
                        name="opening_hours_hidden"
                        label="Opening Hours"
                        className="mb-small"
                        initialValue={typeof item.opening_hours_hidden === 'boolean' ? item.opening_hours_hidden : true}
                    >
                        <Switch
                            checkedChildren={<EyeOutlined />}
                            unCheckedChildren={<EyeInvisibleOutlined />}
                            defaultChecked={!item.opening_hours_hidden}
                            onChange={(checked) => form.setFieldValue('opening_hours_hidden', !checked)}
                        />
                    </Form.Item>
                    <div className="mb-small">
                        <SelectedMediaList
                            title="Select Background Image"
                            uploadCount={1}
                            mediaType={MEDIA_TYPES.IMAGE}
                            defaultMediaList={item?.image ? [item?.image] : []}
                            mediaList={mediaList}
                            setMediaList={setMediaList}
                        />
                    </div>

                    <LinkInput
                        label="Button"
                        identifier={LINK_IDENTIFIERS.FIRST}
                        link={buttonLink}
                        setLink={setButtonLink}
                    />
                </div>
                {renderFooter()}
            </div>
        </Form>
    );
}

export default EditHeroSectionItem;
