import { useCallback, useEffect, useState, useMemo } from 'react';
import NoTranslateModal from '../components/modals/NoTranslateModal';

interface Props {
    children: JSX.Element;
}
function EventProvider(props: Props): JSX.Element {
    const { children } = props;

    const [showNoTranslateModal, setShowNoTranslateModal] = useState<boolean>(false);

    const handleKeyPress = useCallback((event: KeyboardEvent) => {
        if (event.ctrlKey && event.key === 't') {
            setShowNoTranslateModal(true);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    const renderNoTranslateModal = useMemo(
        () => showNoTranslateModal && <NoTranslateModal toggle={setShowNoTranslateModal} />,
        [showNoTranslateModal],
    );

    return (
        <>
            {children}
            {renderNoTranslateModal}
        </>
    );
}

export default EventProvider;
