import { useState } from 'react';
import { Form, Input, Tabs } from 'antd';
import { DesktopOutlined, MobileOutlined } from '@ant-design/icons';
import { IFrameSectionInterface, SectionInterface } from '../../../exports/Interfaces';
import SectionForm from '../../forms/SectionForm';
import SectionManager from '../../../services/api/SectionManager';
import useModals from '../../../hooks/useModals';
import FormGroup from '../../forms/structure/form-group';
import FormItem from '../../forms/structure/form-item';

interface Props {
    section: SectionInterface<IFrameSectionInterface>;
}

function IFrameSectionForm(props: Props): JSX.Element {
    const { section } = props;

    const { setSectionModal } = useModals();

    const [form] = Form.useForm();

    const [loading, setLoading] = useState<boolean>(false);

    const onSave = async (values: Partial<SectionInterface<IFrameSectionInterface>>): Promise<void> => {
        if (typeof section?.id !== 'number') {
            return;
        }

        const { success } = await SectionManager.put<IFrameSectionInterface>(section?.id, values);

        setLoading(false);
        if (success) {
            setSectionModal({ open: false });
        }
    };

    const TABS = [
        {
            title: 'Desktop',
            key: 'desktop',
            icon: <DesktopOutlined />,
        },
        {
            title: 'Mobile',
            key: 'mobile',
            icon: <MobileOutlined />,
        },
    ];

    return (
        <SectionForm form={form} section={section} onSave={onSave} loading={loading} initialValues={section}>
            <FormGroup label="Typography">
                <>
                    <FormItem label="Title">
                        <Form.Item name={['content', 'title']}>
                            <Input placeholder="Enter title" />
                        </Form.Item>
                    </FormItem>
                    <FormItem label="Description">
                        <Form.Item name={['content', 'description']}>
                            <Input placeholder="Enter description" />
                        </Form.Item>
                    </FormItem>
                </>
            </FormGroup>
            <FormGroup label="Settings">
                <>
                    <FormItem label="Url">
                        <Form.Item name={['content', 'url']}>
                            <Input placeholder="https://example.com" />
                        </Form.Item>
                    </FormItem>
                    <FormItem label="Html">
                        <Form.Item name={['content', 'src_doc']}>
                            <Input placeholder="Enter html" />
                        </Form.Item>
                    </FormItem>
                    <Tabs defaultActiveKey={TABS[0].key}>
                        {TABS.map((tab) => (
                            <Tabs.TabPane
                                key={tab.key}
                                tab={
                                    <>
                                        {tab.icon} {tab.title}
                                    </>
                                }
                            >
                                <Form.Item name={['content', 'style', tab.key, 'height']} label="Height (px)">
                                    <Input type="number" min={1} placeholder="300" />
                                </Form.Item>
                            </Tabs.TabPane>
                        ))}
                    </Tabs>
                </>
            </FormGroup>
        </SectionForm>
    );
}

export default IFrameSectionForm;
