import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Spin } from 'antd';
import SectionLayout from '../../layouts/SectionLayout';
import SeoForm from '../../restaurantInfo/seo/SeoForm';
import PageManager from '../../../services/api/page/PageManager';
import { SeoInterface } from '../../../exports/Interfaces';

function SeoPage(): JSX.Element {
    const { page: pageId } = useParams();

    const [loading, setLoading] = useState<boolean>(true);

    const [seo, setSeo] = useState<SeoInterface | null>(null);

    const requestPage = async (): Promise<void> => {
        if (typeof pageId !== 'string') {
            return;
        }

        setLoading(true);
        const { response, success } = await PageManager.get(Number(pageId));
        if (success) {
            setSeo(response?.data?.data?.seo);
        }
        setLoading(false);
    };

    useEffect(() => {
        requestPage();
    }, []);

    return (
        <SectionLayout title="SEO">
            {loading ? <Spin size="large" /> : <SeoForm pageId={Number(pageId)} seo={seo} />}
        </SectionLayout>
    );
}

export default SeoPage;
