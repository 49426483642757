import { DataWrapper, NavigationItemInterface, Response } from '../../exports/Interfaces';
import BackendApiClient from './BackendApiClient';

export default class NavigationItemManager {
    public static put = async (
        id: number,
        data?: Partial<NavigationItemInterface>,
    ): Promise<Response<DataWrapper<DataWrapper<NavigationItemInterface>>>> =>
        BackendApiClient.request<DataWrapper<DataWrapper<NavigationItemInterface>>>({
            method: 'PUT',
            url: `/navigation-items/${id}`,
            data,
        });
}
