/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ArrowRightOutlined } from '@ant-design/icons';

interface Props {
    label: string;
    children?: JSX.Element;
    headerExtra?: JSX.Element;
    onClick?: () => void;
}

function FormGroup(props: Props): JSX.Element {
    const { children, label, headerExtra, onClick } = props;

    if (onClick) {
        return (
            <div className="flex gap-2 px-small py-xsmall rounded-lg border-[1px] bg-white border-brand-inkGrey-grey_2 border-solid  mb-small shadow-section">
                <div className="flex items-center justify-between flex-1">
                    <div onClick={() => onClick()} className="cursor-pointer w-full flex flex-shrink">
                        <h6>{label}</h6>
                    </div>
                    <div className="shrink-0">{headerExtra}</div>
                </div>
                <ArrowRightOutlined />
            </div>
        );
    }

    return (
        <div className="rounded-lg border bg-white border-brand-inkGrey-grey_2 border-solid  mb-small overflow-hidden shadow-section">
            <div className="flex items-center justify-between px-small py-xsmall border-b-[1px] border-solid border-t-none border-l-none border-r-none  border-brand-inkGrey-grey_2 bg-white">
                <div className="w-full flex flex-shrink">
                    <h6>{label}</h6>
                </div>
                <div className="shrink-0">{headerExtra}</div>
            </div>
            <div className="p-small">{children}</div>
        </div>
    );
}

export default FormGroup;
