import { Button } from 'antd';
import { AppstoreOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import collect from 'collect.js';
import { useMemo, useState } from 'react';
import { MenuItemProps } from '../../../exports/Interfaces';
import EditMenuSectionItem from './EditMenuSectionItem';
import MenuSectionItemManager from '../../../services/api/menu-section-item/MenuSectionItemManager';

function MenuSectionItem(props: MenuItemProps): JSX.Element {
    const { menu, onRemove } = props;

    const isEmpty = collect([!!menu?.title, !!menu?.description, !!menu?.links?.length]).every(
        (value) => value === false,
    );

    const [showEdit, setShowEdit] = useState<boolean>(isEmpty);

    const [deleting, setDeleting] = useState<boolean>(false);

    const remove = async (): Promise<void> => {
        setDeleting(true);
        const { success } = await MenuSectionItemManager.delete(menu.id);
        setDeleting(false);
        if (success) {
            onRemove(menu.id);
        }
    };

    function renderHeader(): JSX.Element {
        return (
            <div className="flex items-center justify-between mb-small">
                <AppstoreOutlined className="mr-mini" />
                <div className="flex items-center gap-4">
                    <Button loading={deleting} danger icon={<DeleteOutlined />} onClick={remove} />
                </div>
            </div>
        );
    }

    const renderEditMenuSectionItem = useMemo(
        () => showEdit && <EditMenuSectionItem {...props} toggleEdit={() => setShowEdit(false)} onRemove={remove} />,
        [showEdit],
    );

    return (
        <>
            <div className="mb-medium w-full rounded-md border-brand-inkGrey-grey_2 border-small border-solid bg-background-inkWhite-white_0 shadow-section">
                <div className="p-small">
                    {renderHeader()}
                    <div>
                        <div className="h-[100px] w-full flex items-center justify-between bg-brand-inkGrey-grey_0 rounded-lg mr-small overflow-hidden">
                            <div className="w-full h-full flex items-center">
                                {menu?.image?.url && (
                                    <div className="h-full aspect-square">
                                        <img alt="menu" src={menu.image.url} className="w-full h-full object-cover" />
                                    </div>
                                )}

                                <div className="h-full w-full flex flex-col justify-center p-small">
                                    {menu?.title && <h6>{menu?.title}</h6>}
                                    {menu?.description && (
                                        <p className="mini text-brand-text-grey">{menu?.description}</p>
                                    )}
                                    {collect(menu?.links).count() > 0 && (
                                        <div className="mt-mini">
                                            <Button type="dashed" onClick={() => setShowEdit(true)}>
                                                {collect(menu?.links).first()?.title ?? 'Menu'}
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="p-small">
                                <Button icon={<EditOutlined />} onClick={() => setShowEdit(true)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {renderEditMenuSectionItem}
        </>
    );
}

export default MenuSectionItem;
