import { useMemo, useState } from 'react';
import { Input, Form, Alert, Switch, Select, Button, Popover } from 'antd';
import { ExclamationCircleOutlined, EyeInvisibleOutlined, EyeOutlined, SettingOutlined } from '@ant-design/icons';
import collect from 'collect.js';
import { DefaultOptionType } from 'antd/es/select';
import {
    CUSTOMIZABLE_SECTION_TYPES,
    DB_MODELS,
    LINK_IDENTIFIERS,
    MEDIA_TAGS,
    MEDIA_TYPES,
    OPENING_HOURS_TYPES,
} from '../../../exports/Enums';
import {
    CustomizableSectionInterface,
    LinkInterface,
    MediaInterface,
    SectionInterface,
} from '../../../exports/Interfaces';
import SectionForm from '../../forms/SectionForm';
import SectionManager from '../../../services/api/SectionManager';
import SelectedMediaList from '../../media/SelectedMediaList';
import MediaManager from '../../../services/api/MediaManager';
import LinkInput from '../../links/LinkInput';
import LinkManager from '../../../services/api/LinkManager';
import useTheme from '../../../hooks/useTheme';
import useModals from '../../../hooks/useModals';
import SectionSelect from '../../selects/SectionSelect';
import ColorSelect from '../../selects/ColorSelect';
import LottieSelect from '../../selects/LottieSelect';
import FormItem from '../../forms/structure/form-item';
import FormGroup from '../../forms/structure/form-group';

interface Props {
    section: SectionInterface<CustomizableSectionInterface>;
}

function CustomizableSectionForm(props: Props): JSX.Element {
    const { section } = props;

    const { setSectionModal } = useModals();

    const theme = useTheme();

    const content = section?.content;

    const [form] = Form.useForm<CustomizableSectionInterface>();

    const { type } = Form.useWatch('content', form) ?? {
        type: section?.content?.type ?? CUSTOMIZABLE_SECTION_TYPES.ONE,
    };

    const [loading, setLoading] = useState<boolean>(false);

    const [mediaList, setMediaList] = useState<MediaInterface[]>([]);

    const [firstButtonLink, setFirstButtonLink] = useState<LinkInterface | null>(
        collect(section?.links)?.firstWhere('identifier', LINK_IDENTIFIERS.FIRST),
    );

    const getColor = (value: any): string => (typeof value === 'string' ? value : value?.toHexString());

    const allowedContentBackground = useMemo(() => {
        switch (Number(type)) {
            case CUSTOMIZABLE_SECTION_TYPES.FOUR:
            case CUSTOMIZABLE_SECTION_TYPES.FIVE:
            case CUSTOMIZABLE_SECTION_TYPES.NINETEEN:
            case CUSTOMIZABLE_SECTION_TYPES.TWENTY:
            case CUSTOMIZABLE_SECTION_TYPES.TWENTY_ONE:
            case CUSTOMIZABLE_SECTION_TYPES.TWENTY_TWO:
                return true;
            default:
                return false;
        }
    }, [type]);

    function getMediaType(): MEDIA_TYPES {
        switch (Number(type)) {
            case CUSTOMIZABLE_SECTION_TYPES.THREE:
            case CUSTOMIZABLE_SECTION_TYPES.TWENTY_NINE:
            case CUSTOMIZABLE_SECTION_TYPES.THIRTY:
                return MEDIA_TYPES.VIDEO;
            default:
                return MEDIA_TYPES.IMAGE;
        }
    }

    const onSave = async (values: Partial<SectionInterface<CustomizableSectionInterface>>): Promise<void> => {
        if (typeof section?.id !== 'number') {
            return;
        }

        const { response, success } = await SectionManager.put<CustomizableSectionInterface>(section?.id, {
            ...values,
            content: {
                ...values.content,
                background_color: allowedContentBackground ? getColor(values?.content?.background_color) : null,
            },
        });

        if (success) {
            if (typeof response?.data?.data?.content?.id === 'number') {
                if (getMediaType() === MEDIA_TYPES.VIDEO) {
                    await MediaManager.post(
                        DB_MODELS.CUSTOMIZABLE_SECTION,
                        response?.data?.data?.content?.id,
                        mediaList,
                        MEDIA_TAGS.VIDEOS,
                        '',
                    );
                } else {
                    await MediaManager.post(
                        DB_MODELS.CUSTOMIZABLE_SECTION,
                        response?.data?.data?.content?.id,
                        mediaList,
                        MEDIA_TAGS.IMAGES,
                        '',
                    );
                }
            }
            await LinkManager.post(
                DB_MODELS.SECTION,
                response?.data?.data?.id,
                collect([firstButtonLink])
                    ?.filter((value) => !!value?.title)
                    ?.toArray() as LinkInterface[],
            );
        }
        setSectionModal({ open: false });
        setLoading(false);
    };

    const disabled = useMemo(
        () => !collect(mediaList).every((item) => item.type === getMediaType()),
        [mediaList, type],
    );

    const withParallax = useMemo(() => {
        switch (type) {
            case CUSTOMIZABLE_SECTION_TYPES.ONE:
            case CUSTOMIZABLE_SECTION_TYPES.SEVENTEEN:
                return true;
            default:
                return false;
        }
    }, [type]);

    const openingHoursOptions = useMemo(
        () =>
            collect(OPENING_HOURS_TYPES)
                .values()
                .filter((value) => !Number.isNaN(Number(value)))
                .map((value) => ({
                    value,
                    label: `Option ${value}`,
                }))
                .prepend({
                    value: null,
                    label: `Hidden`,
                })
                .toArray() as DefaultOptionType[],
        [],
    );

    const openingHoursContent = (
        <div>
            <Form.Item
                label="Background Color"
                name={['content', 'opening_hours_props', 'background_color']}
                initialValue={section?.content?.opening_hours_props?.background_color}
            >
                <ColorSelect
                    initialColor={section?.content?.opening_hours_props?.background_color}
                    onSelect={(value) =>
                        form.setFieldValue(['content', 'opening_hours_props', 'background_color'], value)
                    }
                />
            </Form.Item>
            <Form.Item
                label="Text Color"
                name={['content', 'opening_hours_props', 'text_color']}
                initialValue={section?.content?.opening_hours_props?.text_color}
            >
                <ColorSelect
                    initialColor={section?.content?.opening_hours_props?.text_color}
                    onSelect={(value) => form.setFieldValue(['content', 'opening_hours_props', 'text_color'], value)}
                />
            </Form.Item>
        </div>
    );

    return (
        <SectionForm
            form={form}
            section={section}
            onSave={onSave}
            loading={loading}
            initialValues={{
                ...section,
                content: {
                    ...content,
                    background_color: content?.background_color ?? theme.color.companyBrand.primary,
                },
            }}
            disabled={disabled}
            contentStyle={
                allowedContentBackground ? (
                    <Form.Item
                        name={['content', 'background_color']}
                        label="Content Background Color"
                        initialValue={section?.content?.background_color}
                    >
                        <ColorSelect
                            initialColor={section?.content?.background_color}
                            onSelect={(value) => form?.setFieldValue(['content', 'background_color'], value)}
                        />
                    </Form.Item>
                ) : undefined
            }
        >
            <Form.Item
                name={['content', 'type']}
                rules={[{ required: true, message: 'Please select a Layout!' }]}
                className="mb-none"
            >
                <SectionSelect
                    section={section}
                    onSelect={(_type: CUSTOMIZABLE_SECTION_TYPES) => form.setFieldValue(['content', 'type'], _type)}
                />
            </Form.Item>

            <FormGroup label="Media">
                <>
                    <SelectedMediaList
                        uploadCount={1}
                        defaultMediaList={[...(content?.videos ?? []), ...(content?.images ?? [])]}
                        mediaList={mediaList}
                        setMediaList={setMediaList}
                        mediaType={getMediaType()}
                    />
                    {disabled && (
                        <Alert
                            type="error"
                            message={`All media files must be of type ${getMediaType()}`}
                            className="mb-medium bg-red-500 text-white"
                            showIcon
                            icon={<ExclamationCircleOutlined style={{ color: 'white' }} />}
                        />
                    )}
                </>
            </FormGroup>
            <Form.Item name={['content', 'lottie_props']} className="mb-none">
                <LottieSelect
                    lottieProps={content?.lottie_props}
                    setLottieProps={(values) => form.setFieldValue(['content', 'lottie_props'], values)}
                />
            </Form.Item>
            <FormGroup label="Typography">
                <>
                    <FormItem label="Title">
                        <Form.Item name={['content', 'title']}>
                            <Input placeholder="Title" />
                        </Form.Item>
                    </FormItem>
                    <FormItem label="Description">
                        <Form.Item name={['content', 'description']}>
                            <Input.TextArea rows={6} placeholder="Description" />
                        </Form.Item>
                    </FormItem>
                </>
            </FormGroup>
            <FormGroup label="Buttons">
                <LinkInput identifier={LINK_IDENTIFIERS.FIRST} link={firstButtonLink} setLink={setFirstButtonLink} />
            </FormGroup>
            <FormGroup label="Settings">
                <>
                    {withParallax && (
                        <Form.Item name={['content', 'has_parallax']} label="Parallax">
                            <Switch defaultChecked={content?.has_parallax} />
                        </Form.Item>
                    )}
                    <Form.Item name={['content', 'social_media_icons_hidden']} label="Social Media">
                        <Switch
                            defaultChecked={!content?.social_media_icons_hidden}
                            onChange={(checked) =>
                                form.setFieldValue(['content', 'social_media_icons_hidden'], !checked)
                            }
                            checkedChildren={<EyeOutlined />}
                            unCheckedChildren={<EyeInvisibleOutlined />}
                        />
                    </Form.Item>
                    <div className="flex gap-2">
                        <Form.Item name={['content', 'opening_hours_type']} label="Opening Hours">
                            <Select options={openingHoursOptions} />
                        </Form.Item>
                        <Popover content={openingHoursContent}>
                            <Button icon={<SettingOutlined />} />
                        </Popover>
                    </div>
                </>
            </FormGroup>
        </SectionForm>
    );
}

export default CustomizableSectionForm;
