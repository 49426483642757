import { ReactElement } from 'react';
import { Button, Popover, Space } from 'antd';
import { CheckOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { TooltipPlacement } from 'antd/es/tooltip';

interface Props {
    open: boolean;
    children?: any;
    title?: string;
    placement?: TooltipPlacement;
    onOpenChange: (param?: any) => void;
    onConfirm: () => void;
    onCancel: () => void;
}

export default function ActionConfirmation({
    open,
    children,
    title,
    placement,
    onOpenChange,
    onConfirm,
    onCancel,
}: Props): ReactElement {
    return (
        <Popover
            placement={placement ?? 'bottom'}
            content={
                <Space>
                    <Button type="primary" shape="circle" onClick={() => onConfirm()} danger icon={<CheckOutlined />} />
                    <Button type="primary" shape="circle" onClick={() => onCancel()} icon={<CloseOutlined />} />
                </Space>
            }
            title={title ?? 'Are you sure?'}
            trigger="click"
            open={open}
            onOpenChange={onOpenChange}
        >
            {children ?? (
                <Button type="ghost" className="mr-mini">
                    <DeleteOutlined className="text-brand-danger" />
                </Button>
            )}
        </Popover>
    );
}
