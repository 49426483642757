import { Select } from 'antd';
import { BUTTON_VARIANT } from '../../exports/Enums';

interface Props {
    initialVariant?: BUTTON_VARIANT | null;
    setVariant: (variant: BUTTON_VARIANT | null) => void;
}

function ButtonSelect(props: Props): JSX.Element {
    const { initialVariant, setVariant } = props;

    const OPTIONS = [
        {
            label: 'Primary',
            value: BUTTON_VARIANT.PRIMARY_FILL,
        },
        {
            label: 'Secondary',
            value: BUTTON_VARIANT.SECONDARY_FILL,
        },
        {
            label: 'Light Outline',
            value: BUTTON_VARIANT.LIGHT_OUTLINE,
        },
        {
            label: 'Dark Outline',
            value: BUTTON_VARIANT.DARK_OUTLINE,
        },
    ];

    return (
        <Select
            placeholder="Select Variant"
            className="w-full"
            options={OPTIONS}
            defaultValue={initialVariant}
            onChange={setVariant}
        />
    );
}

export default ButtonSelect;
