import { useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import collect from 'collect.js';
import { DB_MODELS, MEDIA_TAGS, MEDIA_TYPES } from '../../../exports/Enums';
import { MediaInterface, TimeLineItemProps } from '../../../exports/Interfaces';
import SelectedMediaList from '../../media/SelectedMediaList';
import MediaManager from '../../../services/api/MediaManager';
import TimeLineSectionItemManager from '../../../services/api/time-line-section-item/TimeLineSectionItemManager';

function EditTimeLineSectionItem(props: TimeLineItemProps): JSX.Element {
    const { timeLine, onUpdate, toggleEdit, onRemove } = props;

    const [form] = Form.useForm();

    const title = Form.useWatch('title', form);

    const description = Form.useWatch('description', form);

    const milestone = Form.useWatch('milestone', form);

    const [updating, setUpdating] = useState<boolean>(false);

    const [mediaList, setMediaList] = useState<MediaInterface[]>(timeLine?.image ? [timeLine?.image] : []);

    const isEmpty = collect([!!title, !!description, !!milestone, !!mediaList.length]).every(
        (value) => value === false,
    );

    const update = async (): Promise<void> => {
        setUpdating(true);
        const media = MediaManager.post(
            DB_MODELS.TIME_LINE_SECTION_ITEM,
            timeLine?.id,
            mediaList,
            MEDIA_TAGS.IMAGES,
            '',
        );

        await Promise.all([media]);

        const values = form.getFieldsValue();
        const { response, success } = await TimeLineSectionItemManager.put(timeLine.id, values);

        if (success) {
            onUpdate(response.data.data);
            if (toggleEdit && !isEmpty) {
                toggleEdit();
            }
        }
        setUpdating(false);
    };

    return (
        <>
            <div className="fixed inset-0 blur-background z-100" style={{ zIndex: 1000 }} />
            <Modal
                onCancel={() => toggleEdit && toggleEdit()}
                open
                footer={
                    <div className="flex items-center justify-between pt-xsmall border-brand-inkGrey-grey_2 border-small border-solid border-b-none border-r-none border-l-none">
                        <div className="flex items-center gap-4 ">
                            <Button type="primary" onClick={update} loading={updating}>
                                Save
                            </Button>
                        </div>
                        <Button onClick={() => onRemove && onRemove(timeLine.id)} danger>
                            Delete
                        </Button>
                    </div>
                }
            >
                <Form
                    form={form}
                    initialValues={{
                        remember: true,
                        ...timeLine,
                    }}
                >
                    <div>
                        <Form.Item label="Milestone" name="milestone">
                            <Input placeholder="Enter milestone" />
                        </Form.Item>
                        <Form.Item label="Title" name="title">
                            <Input placeholder="Enter Title" />
                        </Form.Item>
                        <Form.Item label="Description" name="description">
                            <Input.TextArea rows={3} placeholder="Enter Description" />
                        </Form.Item>
                        <div className="mb-small">
                            <SelectedMediaList
                                title="Select Image"
                                uploadCount={1}
                                mediaType={MEDIA_TYPES.IMAGE}
                                defaultMediaList={timeLine?.image ? [timeLine?.image] : []}
                                mediaList={mediaList}
                                setMediaList={setMediaList}
                            />
                        </div>
                    </div>
                </Form>
            </Modal>
        </>
    );
}

export default EditTimeLineSectionItem;
