import { useState } from 'react';
import { Button, Form, Input } from 'antd';
import CustomForm from '../../forms/CustomForm';
import useRdxStore from '../../../hooks/useRdxStore';
import StoreManager from '../../../services/api/StoreManager';

function LinksForm(): JSX.Element {
    const { store } = useRdxStore();

    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = async (values: any): Promise<void> => {
        setLoading(true);
        await StoreManager.put(store?.id, values);

        setLoading(false);
    };

    return (
        <CustomForm>
            <Form
                name="basic"
                initialValues={{
                    remember: true,
                    ...store,
                }}
                onFinish={onFinish}
                layout="vertical"
            >
                <Form.Item name="preview_domain" label="Preview Domain">
                    <Input placeholder="www.example.com" />
                </Form.Item>
                <Form.Item name="web_shop_link" label="Web-Shop Link">
                    <Input placeholder="Enter Web Shop Link" />
                </Form.Item>
                <Form.Item name="instagram_link" label="Instagram">
                    <Input placeholder="Enter Instagram Link" />
                </Form.Item>
                <Form.Item name="youtube_link" label="Youtube">
                    <Input placeholder="Enter Youtube Link" />
                </Form.Item>
                <Form.Item name="facebook_link" label="Facebook">
                    <Input placeholder="Enter Facebook Link" />
                </Form.Item>
                <Form.Item name="tik_tok_link" label="Tik Tok">
                    <Input placeholder="Enter Tik Tok Link" />
                </Form.Item>
                <Form.Item name="yelp_link" label="Yelp">
                    <Input placeholder="Enter Yelp Link" />
                </Form.Item>
                <Form.Item name="uber_link" label="Uber">
                    <Input placeholder="Enter Uber Link" />
                </Form.Item>
                <Form.Item name="doordash_link" label="Doordash">
                    <Input placeholder="Enter Doordash Link" />
                </Form.Item>
                <Form.Item name="grubhub_link" label="Grubhub">
                    <Input placeholder="Enter Grubhub Link" />
                </Form.Item>
                <Form.Item className="mb-none">
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </CustomForm>
    );
}

export default LinksForm;
