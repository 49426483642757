import { Button, ColorPicker, Modal } from 'antd';
import { useMemo, useState } from 'react';
import classnames from 'classnames';
import { CloseOutlined } from '@ant-design/icons';
import useColors from '../../hooks/useColors';
import { COLOR_VARIABLES } from '../../exports/Enums';

interface Props {
    initialColor?: string | null;
    onSelect: (color: string | null) => void;
}

function ColorSelect(props: Props): JSX.Element {
    const { initialColor, onSelect } = props;

    const { getColor } = useColors();

    const [open, setOpen] = useState<boolean>(false);

    const [selectedColor, setSelectedColor] = useState<string | null>(initialColor ?? null);

    const _onSelect = (color?: string | null): void => {
        setOpen(false);
        if (color !== undefined) {
            setSelectedColor(color);
            onSelect(color);
            return;
        }
        onSelect(selectedColor);
    };

    const structure = [
        {
            name: 'Main Colors',
            colors: [COLOR_VARIABLES.PRIMARY_COLOR, COLOR_VARIABLES.SECONDARY_COLOR],
        },
        {
            name: 'Background Colors',
            colors: [COLOR_VARIABLES.BACKGROUND_COLOR, COLOR_VARIABLES.SECONDARY_BACKGROUND_COLOR],
        },
        {
            name: 'Border Colors',
            colors: [COLOR_VARIABLES.BORDER_COLOR],
        },
        {
            name: 'Text Colors',
            colors: [COLOR_VARIABLES.HEADER_COLOR, COLOR_VARIABLES.DESCRIPTION_COLOR],
        },
        {
            name: 'Card Colors',
            colors: [COLOR_VARIABLES.CARD_COLOR],
        },
        {
            name: 'Contrast Colors',
            colors: [COLOR_VARIABLES.LIGHT_TEXT_COLOR, COLOR_VARIABLES.DARK_TEXT_COLOR],
        },
        {
            name: 'Custom',
            colors: null,
        },
    ];

    const renderModal = useMemo(
        () => (
            <Modal
                title="Colors"
                open={open}
                onCancel={() => _onSelect(initialColor ?? null)}
                onOk={() => _onSelect()}
                okText="Apply"
                centered
            >
                <div>
                    {structure?.map((group, index) => (
                        <div
                            className={classnames('py-mini', {
                                'border-b border-solid border-t-none border-r-none border-l-none border-brand-inkGrey-grey_2':
                                    structure.length - 1 !== index,
                            })}
                        >
                            <h6 className="mini">{group.name}</h6>
                            <div className={classnames('grid grid-cols-3 gap-2 mt-mini')}>
                                {group.colors ? (
                                    group.colors?.map((color) => (
                                        <Button
                                            className="m-none p-none w-fit"
                                            type="ghost"
                                            onClick={() => setSelectedColor(color)}
                                        >
                                            <div
                                                className={classnames(
                                                    'w-[33px] h-[33px] rounded-md shadow border-[1px] border-solid border-brand-inkGrey-grey_2 overflow-hidden flex justify-center items-center bg-white',
                                                    {
                                                        'border-brand-primary': selectedColor === color,
                                                    },
                                                )}
                                            >
                                                <div
                                                    className="w-[25px] h-[25px] rounded-[4px] border-[1px] border-solid border-brand-inkGrey-grey_2"
                                                    style={{ backgroundColor: getColor(color) }}
                                                />
                                            </div>
                                        </Button>
                                    ))
                                ) : (
                                    <ColorPicker onChange={(color, hex) => setSelectedColor(hex)} />
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </Modal>
        ),
        [open, selectedColor],
    );

    return (
        <>
            <div className="flex items-center w-fit ">
                <Button
                    onClick={() => setOpen(true)}
                    type="ghost"
                    className="m-none p-none flex justify-center items-center"
                >
                    <div className="w-[33px] h-[33px] rounded-md shadow border-[1px] border-solid border-brand-inkGrey-grey_2 overflow-hidden flex justify-center items-center bg-white">
                        <div
                            className="w-[25px] h-[25px] rounded-[4px] border-[1px] border-solid border-brand-inkGrey-grey_2 relative flex justify-center items-center"
                            style={{ backgroundColor: getColor(selectedColor) }}
                        >
                            {!selectedColor && <div className="absolute inset-0 mx-auto w-[2px] bg-black rotate-45" />}
                        </div>
                    </div>
                </Button>
                {selectedColor && (
                    <Button onClick={() => _onSelect(null)} icon={<CloseOutlined />} className="ml-mini" />
                )}
            </div>
            {renderModal}
        </>
    );
}

export default ColorSelect;
