import { Button, Drawer, Form, Select, Switch } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import collect from 'collect.js';
import { useState } from 'react';
import { BORDER_RADIUS_TYPES, BORDER_WIDTH_TYPES, BUTTON_VARIANT } from '../../exports/Enums';
import { ButtonThemeInterface } from '../../exports/Interfaces';
import ButtonManager from '../../services/api/button/ButtonManager';
import ColorSelect from '../selects/ColorSelect';

interface Props {
    open: boolean;
    toggle: (open?: boolean) => void;
    button: ButtonThemeInterface;
}

function ButtonDesignModal(props: Props): JSX.Element {
    const { open, button, toggle } = props;

    const { id, variant } = button;

    const [form] = Form.useForm();

    const [loading, setLoading] = useState<boolean>(false);

    const onSave = async (): Promise<void> => {
        const payload = form.getFieldsValue();
        setLoading(true);
        const { success } = await ButtonManager.put(id, payload);
        setLoading(false);
        if (success) {
            toggle(false);
        }
    };

    const getTitle = (): string => {
        switch (variant) {
            case BUTTON_VARIANT.SECONDARY_FILL:
                return 'Secondary Fill';
            case BUTTON_VARIANT.LIGHT_OUTLINE:
                return 'Light Outline';
            case BUTTON_VARIANT.DARK_OUTLINE:
                return 'Dark Outline';
            default:
                return 'Primary Fill';
        }
    };

    const getDescription = (): string | null => {
        switch (variant) {
            case BUTTON_VARIANT.LIGHT_OUTLINE:
                return 'The Light Outline button is suitable on darker backgrounds.';
            case BUTTON_VARIANT.DARK_OUTLINE:
                return 'The Dark Outline button is suitable on lighter backgrounds.';
            default:
                return null;
        }
    };

    const capitalizeFirstLetter = (text: string): string => text.charAt(0).toUpperCase() + text.slice(1);

    return (
        <Drawer
            title={getTitle()}
            open={open}
            onClose={() => toggle(false)}
            footer={null}
            placement="right"
            extra={
                <Button type="primary" loading={loading} onClick={onSave}>
                    Save
                </Button>
            }
        >
            <div className="pb-[100px]">
                <Form
                    form={form}
                    initialValues={{
                        remember: true,
                        ...button,
                    }}
                >
                    {getDescription() && (
                        <div className="mb-small">
                            <p className="mini  text-brand-text-grey">{getDescription()}</p>
                        </div>
                    )}

                    <Form.Item name="color" label="Text Color">
                        <ColorSelect
                            initialColor={button.color}
                            onSelect={(value) => form.setFieldValue('color', value)}
                        />
                    </Form.Item>
                    <Form.Item name="background_color" label="Background Color">
                        <ColorSelect
                            initialColor={button.background_color}
                            onSelect={(value) => form.setFieldValue('background_color', value)}
                        />
                    </Form.Item>
                    <Form.Item name="border_color" label="Border Color">
                        <ColorSelect
                            initialColor={button.border_color}
                            onSelect={(value) => form.setFieldValue('border_color', value)}
                        />
                    </Form.Item>
                    <Form.Item name="transition_color" label="Hover Text Color">
                        <ColorSelect
                            initialColor={button.transition_color}
                            onSelect={(value) => form.setFieldValue('transition_color', value)}
                        />
                    </Form.Item>
                    <Form.Item name="transition_background_color" label="Hover Background Color">
                        <ColorSelect
                            initialColor={button.transition_background_color}
                            onSelect={(value) => form.setFieldValue('transition_background_color', value)}
                        />
                    </Form.Item>
                    <Form.Item name="transition_border_color" label="Hover Border Color">
                        <ColorSelect
                            initialColor={button.transition_border_color}
                            onSelect={(value) => form.setFieldValue('transition_border_color', value)}
                        />
                    </Form.Item>
                    <Form.Item name="border_radius_type" label="Border Radius">
                        <Select
                            options={
                                collect(BORDER_RADIUS_TYPES)
                                    .values()
                                    .map((value) => ({
                                        label: capitalizeFirstLetter(value as string),
                                        value,
                                    }))
                                    .toArray() as DefaultOptionType[]
                            }
                            placeholder="Select border radius"
                            onChange={(value) => form.setFieldValue('border_radius_type', value)}
                        />
                    </Form.Item>
                    <Form.Item name="border_width_type" label="Border Width">
                        <Select
                            options={
                                collect(BORDER_WIDTH_TYPES)
                                    .values()
                                    .map((value) => ({
                                        label: capitalizeFirstLetter(value as string),
                                        value,
                                    }))
                                    .toArray() as DefaultOptionType[]
                            }
                            placeholder="Select border width"
                            onChange={(value) => form.setFieldValue('border_width_type', value)}
                        />
                    </Form.Item>
                    <Form.Item name="has_shadow" label="Shadow">
                        <Switch
                            defaultChecked={button?.has_shadow}
                            onChange={(checked) => form.setFieldValue('has_shadow', checked)}
                        />
                    </Form.Item>
                </Form>
            </div>
        </Drawer>
    );
}

export default ButtonDesignModal;
