import { Button } from 'antd';
import classnames from 'classnames';
import { useMemo } from 'react';
import collect from 'collect.js';
import { TemplateDataInterface } from '../../exports/Interfaces';
import { TEMPLATE_TYPES } from '../../exports/Enums';

interface Props {
    item: TemplateDataInterface;
    isActive: boolean;
    setTemplateType: (type: TEMPLATE_TYPES) => void;
}

function TemplateItem(props: Props): JSX.Element {
    const { item, isActive, setTemplateType } = props;

    const colorVariables = useMemo(
        () =>
            collect(item.theme?.is_dark_mode ? item.theme?.dark_mode_color_variables : item.theme?.color_variables)
                .values()
                .toArray() as string[],
        [],
    );

    const getName = (): string => {
        switch (item?.type) {
            case TEMPLATE_TYPES.ELEGANT:
                return 'Elegant';
            case TEMPLATE_TYPES.MODERN:
                return 'Modern';
            case TEMPLATE_TYPES.SIMPLE:
                return 'Simple and Black';
            case TEMPLATE_TYPES.MINIMALIST:
                return 'Minimalist';
            case TEMPLATE_TYPES.COOL:
                return 'Cool';
            case TEMPLATE_TYPES.BOLD:
                return 'Bold';
            case TEMPLATE_TYPES.ASIAN:
                return 'Asian';
            case TEMPLATE_TYPES.PERSIAN:
                return 'Persian';
            case TEMPLATE_TYPES.VIBRANT:
                return 'Vibrant';
            case TEMPLATE_TYPES.CHIC:
                return 'Chic';
            default:
                return 'Standard';
        }
    };

    function renderColors(): JSX.Element {
        return (
            <div className="my-small">
                <div className="flex gap-1">
                    {colorVariables.map((color) => (
                        <div
                            style={{ backgroundColor: color }}
                            className="w-[30px] h-[30px] border border-solid border-brand-inkGrey-grey_2"
                        />
                    ))}
                </div>
            </div>
        );
    }

    return (
        <div
            className={classnames(
                'bg-background-inkWhite-white_0 shadow-section rounded-lg overflow-hidden border border-solid border-brand-inkGrey-grey_2 relative',
                {
                    'border-brand-primary': isActive,
                },
            )}
        >
            <div className="py-xsmall px-small">
                <h5>{getName()}</h5>
                {renderColors()}
                <div className="grid grid-cols-2 gap-4 mt-mini">
                    <Button onClick={() => setTemplateType(item.type)}>Select</Button>
                </div>
            </div>
        </div>
    );
}

export default TemplateItem;
