import { useState } from 'react';
import { Spin } from 'antd';
import { HERO_SECTION_TYPES, MEDIA_TYPES, SECTION_TYPES } from '../../../../../exports/Enums';
import ElementCard from '../../../../cards/ElementCard';
import { HeroSectionInterface } from '../../../../../exports/Interfaces';
import SectionManager from '../../../../../services/api/SectionManager';
import useCustomTranslation from '../../../../../hooks/useCustomTranslation';
import useModals from '../../../../../hooks/useModals';
import ElementsWrapper from '../../tabs/ElementsWrapper';

function Hero(): JSX.Element {
    const { featureModal, setFeatureModal } = useModals();

    const pageId = featureModal?.pageId;

    const section = featureModal?.section;

    const contentType = featureModal?.contentType;

    const isUpdate = !!section;

    const { t } = useCustomTranslation({ keyPrefix: 'Components:Sections:Features:FeaturesList' });

    const [loading, setLoading] = useState<boolean>(false);

    const s3Url = (type: HERO_SECTION_TYPES): string =>
        `${import.meta.env.VITE_S3_PROD_BASE_URL}/sections/hero/${type}.png`;

    const create = async (_contentType: HERO_SECTION_TYPES): Promise<void> => {
        if (typeof pageId !== 'number') {
            return;
        }

        setLoading(true);
        const { success } = await SectionManager.post<HeroSectionInterface>(pageId, {
            type: SECTION_TYPES.HERO_SECTION,
            name: 'Hero',
            content: {
                type: _contentType,
                title: t('sections.hero.content.title'),
                description: t('sections.hero.content.description'),
            },
        });

        setLoading(false);

        if (success) {
            setFeatureModal({ open: false });
        }
    };

    const _onSelect = (_contentType: HERO_SECTION_TYPES): void => {
        if (!isUpdate) {
            create(_contentType);
            return;
        }
        if (featureModal?.onSelect) {
            featureModal.onSelect(_contentType);
        }

        setFeatureModal({ open: false });
    };

    const data = [
        {
            type: HERO_SECTION_TYPES.ONE,
            tag: MEDIA_TYPES.IMAGE,
        },
        {
            type: HERO_SECTION_TYPES.TWO,
            tag: MEDIA_TYPES.IMAGE,
        },
        {
            type: HERO_SECTION_TYPES.THREE,
            tag: MEDIA_TYPES.IMAGE,
        },
        {
            type: HERO_SECTION_TYPES.FOUR,
            tag: MEDIA_TYPES.IMAGE,
        },
        {
            type: HERO_SECTION_TYPES.FIVE,
            tag: MEDIA_TYPES.VIDEO,
        },
        {
            type: HERO_SECTION_TYPES.SIX,
            tag: MEDIA_TYPES.IMAGE,
        },
        {
            type: HERO_SECTION_TYPES.SEVEN,
            tag: MEDIA_TYPES.IMAGE,
        },
        {
            type: HERO_SECTION_TYPES.EIGHT,
            tag: MEDIA_TYPES.IMAGE,
        },
        {
            type: HERO_SECTION_TYPES.NINE,
            tag: MEDIA_TYPES.IMAGE,
        },
        {
            type: HERO_SECTION_TYPES.TEN,
            tag: MEDIA_TYPES.IMAGE,
        },
        {
            type: HERO_SECTION_TYPES.ELEVEN,
            tag: MEDIA_TYPES.IMAGE,
        },
        {
            type: HERO_SECTION_TYPES.TWELVE,
            tag: MEDIA_TYPES.VIDEO,
        },
        {
            type: HERO_SECTION_TYPES.THIRTEEN,
            tag: MEDIA_TYPES.IMAGE,
        },
        {
            type: HERO_SECTION_TYPES.FOURTEEN,
            tag: MEDIA_TYPES.IMAGE,
        },
        {
            type: HERO_SECTION_TYPES.FIFTEEN,
            tag: MEDIA_TYPES.IMAGE,
        },
        {
            type: HERO_SECTION_TYPES.SIXTEEN,
            tag: MEDIA_TYPES.IMAGE,
        },
        {
            type: HERO_SECTION_TYPES.SEVENTEEN,
            tag: MEDIA_TYPES.IMAGE,
        },
        {
            type: HERO_SECTION_TYPES.EIGHTEEN,
            tag: MEDIA_TYPES.VIDEO,
        },
    ];

    if (loading) {
        return (
            <div className="w-full h-full flex justify-center items-center">
                <Spin />
            </div>
        );
    }

    return (
        <ElementsWrapper>
            {data.map((item) => (
                <ElementCard
                    title={item.type.toString()}
                    url={s3Url(item.type)}
                    tag={item.tag}
                    isActive={item.type === contentType}
                    onClick={() => _onSelect(item.type)}
                    isUpdate={isUpdate}
                />
            ))}
        </ElementsWrapper>
    );
}

export default Hero;
