import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Tabs, TabsProps } from 'antd';
import { useState } from 'react';
import collect from 'collect.js';
import { DefaultOptionType } from 'antd/es/select';
import CustomForm from '../../forms/CustomForm';
import StoreManager from '../../../services/api/StoreManager';
import useRdxStore from '../../../hooks/useRdxStore';
import SectionLayout from '../../layouts/SectionLayout';
import useTheme from '../../../hooks/useTheme';
import ColorSelect from '../../selects/ColorSelect';
import ThemeManager from '../../../services/api/ThemeManager';
import { NEWS_BANNER_TYPES } from '../../../exports/Enums';

const { TextArea } = Input;

function NewsForm(): JSX.Element {
    const { store } = useRdxStore();

    const theme = useTheme();

    const [form] = Form.useForm();

    const type = Form.useWatch(['news_banner_props', 'type'], form);

    const [loading, setLoading] = useState<boolean>(false);

    const getColor = (value: any): string => (typeof value === 'string' ? value : value?.toHexString());

    const onFinish = async (values: any): Promise<void> => {
        setLoading(true);

        const updateStorePromise = StoreManager.put(store?.id, {
            news_banner_color: getColor(values?.news_banner_color),
            news: values?.news,
        });

        const updateThemePromise = ThemeManager.post({
            news_banner_props: values?.news_banner_props,
        });

        await Promise.all([updateStorePromise, updateThemePromise]);

        setLoading(false);
    };

    const options = collect(NEWS_BANNER_TYPES)
        .values()
        .filter((value) => !Number.isNaN(Number(value)))
        .map((value) => ({
            value,
            label: `Option ${value}`,
        }))
        .toArray() as DefaultOptionType[];

    function renderOverview(): JSX.Element {
        return (
            <>
                <Form.Item
                    name={['news_banner_props', 'type']}
                    label="Layout"
                    initialValue={store?.theme?.news_banner_props?.type}
                >
                    <Select options={options} className="w-fit" placeholder="Select layout" />
                </Form.Item>
                {Number(type) === NEWS_BANNER_TYPES.ONE && (
                    <Form.List name="news" initialValue={store?.news}>
                        {(fields, { add, remove }, { errors }) => (
                            <>
                                {fields.map((field) => (
                                    <div className="flex w-full items-center justify-center mb-medium">
                                        <Form.Item required={false} key={field.key} className="w-full mr-small mb-none">
                                            <Form.Item
                                                {...field}
                                                validateTrigger={['onChange', 'onBlur']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        whitespace: true,
                                                        message: 'Please add text or delete this field.',
                                                    },
                                                ]}
                                                noStyle
                                                className="w-fit"
                                            >
                                                <TextArea
                                                    placeholder="Enter news"
                                                    maxLength={300}
                                                    showCount
                                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                                />
                                            </Form.Item>
                                        </Form.Item>
                                        {fields.length > 0 ? (
                                            <div className="h-full">
                                                <MinusCircleOutlined
                                                    className="dynamic-delete-button mt-none"
                                                    onClick={() => remove(field.name)}
                                                />
                                            </div>
                                        ) : null}
                                    </div>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                                        Add News
                                    </Button>
                                    <Form.ErrorList errors={errors} />
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                )}
            </>
        );
    }

    function renderStyle(): JSX.Element {
        return (
            <div>
                <Form.Item
                    name={['news_banner_props', 'background_color']}
                    label="Background Color"
                    className="w-full mr-small flex-1 flex-shrink-0"
                    initialValue={store?.theme?.news_banner_props?.background_color}
                >
                    <ColorSelect
                        initialColor={store?.theme?.news_banner_props?.background_color}
                        onSelect={(value) => form?.setFieldValue(['news_banner_props', 'background_color'], value)}
                    />
                </Form.Item>
                <Form.Item
                    name={['news_banner_props', 'color']}
                    label="Text Color"
                    className="w-full mr-small flex-1 flex-shrink-0"
                    initialValue={store?.theme?.news_banner_props?.color}
                >
                    <ColorSelect
                        initialColor={store?.theme?.news_banner_props?.color}
                        onSelect={(value) => form?.setFieldValue(['news_banner_props', 'color'], value)}
                    />
                </Form.Item>
            </div>
        );
    }

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Overview',
            children: renderOverview(),
        },
        {
            key: '2',
            label: 'Style',
            children: renderStyle(),
        },
    ];

    return (
        <SectionLayout title="News">
            <CustomForm>
                <Form
                    name="dynamic_form_item"
                    onFinish={onFinish}
                    layout="horizontal"
                    initialValues={{
                        ...store,
                        news_banner_color: store?.news_banner_color ?? theme?.color?.companyBrand?.primary,
                    }}
                    form={form}
                >
                    <Tabs defaultActiveKey="1" items={items} />
                    <Form.Item className="mb-none">
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </CustomForm>
        </SectionLayout>
    );
}

export default NewsForm;
