import { createGlobalStyle } from 'styled-components';
import { FontInterface, ThemeInterface } from '../exports/Interfaces';
import ColorResource from '../services/resources/ColorResource';

const padPixel = (type: any): string => `${type}px`;

function generateFontFaces(customFonts: FontInterface[]): string {
    return customFonts
        ?.map(
            (font) => `
      @font-face {
          font-family: ${font?.name};
          src: url(${font?.url});
      }
      `,
        )
        .join('');
}

const GlobalStyles = createGlobalStyle<{ theme: ThemeInterface }>`
${({ theme }) => generateFontFaces(theme.fonts?.customFonts ?? [])}
	:root {
	--primary-color: ${({ theme }) => theme.color.brand?.primary};
  --primary-color-rgb: ${({ theme }) => ColorResource.getRgbValue(theme.color.brand?.primary)};
	--secondary-color: ${({ theme }) => theme.color.brand?.secondary};
  --secondary-color-rgb: ${({ theme }) => ColorResource.getRgbValue(theme.color.brand?.secondary)};
  --primary-gradient-rgb-color-1:  ${({ theme }) => ColorResource.getRgbValue(theme.color.brand.gradients?.primary[0])};
  --primary-gradient-rgb-color-2:  ${({ theme }) => ColorResource.getRgbValue(theme.color.brand.gradients?.primary[1])};
	}
    cursor:'pointer'; 
    body, html{
      overflow-x: clip;
      overflow: visible;
    }
    body {
        margin: 0;
        padding: 0;
        background-color: ${({ theme }) => theme.color.background.inkWhite.white_1};
        font-family: Inter;
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        outline: none !important;
        position: relative;
    }
    header {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 50;
    }
    select {
      outline: none !important;
    }
    button {
      user-select: none;
      position:relative;
      cursor: pointer;

    }
    button:after {
      content:'';
      position:absolute;
      top:-5px; bottom:-5px; 
      left:-5px; right:-5px; 
    }
    label {
      user-select: none;
      position:relative;
    }
    label:after {
      content:'';
      position:absolute;
      top:-2px; bottom:-2px; 
      left:-2px; right:-2px; 
    }
    a {
      user-select: none;
      position: relative;
  }
    a:hover {
        color: var(--primary-color);
        text-decoration: underline solid var(--primary-color);;
        text-underline-offset: 8px;
    }
    a:after {
        content: '';
        position: absolute;
        top: -2px;
        bottom: -2px;
        left: -2px;
        right: -2px;
    }
    .bg-scrolling-element-when-modal-active {
      /* when modal active */
      touch-action: none;
      -webkit-overflow-scrolling: none;
      overflow: hidden;
      /* Other browsers */
      overscroll-behavior: none;
  }
    h1 {
      font-family: ${({ theme }) => theme.fonts.fontFamily.inter};
      font-size: ${({ theme }) => padPixel(theme.fonts.fontSizes.h1)};
      font-weight: ${({ theme }) => theme.fonts.fontWeights.semiBold};
      line-height: ${({ theme }) => padPixel(theme.fonts.fontLineHeight.h1)};
      margin-bottom: 0px;
      padding-bottom: 0px;
  }
    h2 {
      font-family: ${({ theme }) => theme.fonts.fontFamily.inter};
      font-size: ${({ theme }) => padPixel(theme.fonts.fontSizes.h2)};
      font-weight: ${({ theme }) => theme.fonts.fontWeights.semiBold};
      line-height: ${({ theme }) => padPixel(theme.fonts.fontLineHeight.h2)};
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
    h3 {
        font-family: ${({ theme }) => theme.fonts.fontFamily.inter};
        font-size: ${({ theme }) => padPixel(theme.fonts.fontSizes.h3)};
        font-weight: ${({ theme }) => theme.fonts.fontWeights.semiBold};
        line-height: ${({ theme }) => padPixel(theme.fonts.fontLineHeight.h3)};
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    h4 {
        font-family: ${({ theme }) => theme.fonts.fontFamily.inter};
        font-size: ${({ theme }) => padPixel(theme.fonts.fontSizes.h4)};
        font-weight: ${({ theme }) => theme.fonts.fontWeights.semiBold};
        line-height: ${({ theme }) => padPixel(theme.fonts.fontLineHeight.h4)};
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    h5 {
        font-family: ${({ theme }) => theme.fonts.fontFamily.inter};
        font-size: ${({ theme }) => padPixel(theme.fonts.fontSizes.h5)};
        font-weight: ${({ theme }) => theme.fonts.fontWeights.semiBold};
        line-height: ${({ theme }) => padPixel(theme.fonts.fontLineHeight.h5)};
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    h6 {
      font-family: ${({ theme }) => theme.fonts.fontFamily.inter};
      font-size: ${({ theme }) => padPixel(theme.fonts.fontSizes.h6)};
      font-weight: ${({ theme }) => theme.fonts.fontWeights.semiBold};
      line-height: ${({ theme }) => padPixel(theme.fonts.fontLineHeight.h6)};
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
    p {
        font-family: ${({ theme }) => theme.fonts.fontFamily.inter};
        font-size: ${({ theme }) => padPixel(theme.fonts.fontSizes.p)};
        font-weight: ${({ theme }) => theme.fonts.fontWeights.regular};
        line-height: ${({ theme }) => padPixel(theme.fonts.fontLineHeight.p)};
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    .mini {
      font-family: ${({ theme }) => theme.fonts.fontFamily.inter};
      font-size: ${({ theme }) => padPixel(theme.fonts.fontSizes.mini)};
      font-weight: ${({ theme }) => theme.fonts.fontWeights.regular};
      line-height: ${({ theme }) => padPixel(theme.fonts.fontLineHeight.mini)};
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
    .tiny {
      font-family: ${({ theme }) => theme.fonts.fontFamily.inter};
      font-size: ${({ theme }) => padPixel(theme.fonts.fontSizes.tiny)}; 
      font-weight: ${({ theme }) => theme.fonts.fontWeights.regular};
      line-height: ${({ theme }) => padPixel(theme.fonts.fontLineHeight.tiny)};
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
    .nano {
      font-family: ${({ theme }) => theme.fonts.fontFamily.inter};
      font-size: ${({ theme }) => padPixel(theme.fonts.fontSizes.nano)}; 
      font-weight: ${({ theme }) => theme.fonts.fontWeights.regular};
      line-height: ${({ theme }) => padPixel(theme.fonts.fontLineHeight.nano)};
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
    .bold {
      font-weight: ${({ theme }) => theme.fonts.fontWeights.bold}; 
    }
    .semibold {
      font-weight: ${({ theme }) => theme.fonts.fontWeights.semiBold}; 
    }
    .medium {
      font-weight: ${({ theme }) => theme.fonts.fontWeights.medium};
    }
    .regular {
      font-weight: ${({ theme }) => theme.fonts.fontWeights.regular};
    }
    .touchable-highlight {
      -webkit-tap-highlight-color: white;
    }
`;

export default GlobalStyles;
