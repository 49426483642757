import { useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import collect from 'collect.js';
import { FAQ_SECTION_TYPES } from '../../../exports/Enums';
import { EditSectionItemProps, FAQItemInterface } from '../../../exports/Interfaces';
import FAQSectionItemManager from '../../../services/api/faq-section-item/FAQSectionItemManager';

function EditFAQSectionItem(props: EditSectionItemProps<FAQ_SECTION_TYPES, FAQItemInterface>): JSX.Element {
    const { item, onUpdate, toggleEdit, onRemove } = props;

    const [form] = Form.useForm();

    const title = Form.useWatch('title', form);

    const description = Form.useWatch('description', form);

    const [updating, setUpdating] = useState<boolean>(false);

    const isEmpty = collect([!!title, !!description]).every((value) => value === false);

    const update = async (): Promise<void> => {
        setUpdating(true);

        const values = form.getFieldsValue();

        const { response, success } = await FAQSectionItemManager.put(item.id, values);

        if (success) {
            onUpdate(response.data.data);
            if (toggleEdit && !isEmpty) {
                toggleEdit();
            }
        }
        setUpdating(false);
    };

    function renderOverview(): JSX.Element {
        return (
            <div>
                <Form.Item label="Title" name="title">
                    <Input placeholder="Enter Title" />
                </Form.Item>
                <Form.Item label="Description" name="description">
                    <Input.TextArea rows={3} placeholder="Enter Description" />
                </Form.Item>
            </div>
        );
    }

    return (
        <>
            <div className="fixed inset-0 blur-background z-100" style={{ zIndex: 1000 }} />
            <Modal
                title="FAQ Item"
                onCancel={() => toggleEdit && toggleEdit()}
                open
                footer={
                    <div className="flex items-center justify-between pt-xsmall border-brand-inkGrey-grey_2 border-small border-solid border-b-none border-r-none border-l-none">
                        <div className="flex items-center gap-4 ">
                            <Button type="primary" onClick={update} loading={updating}>
                                Save
                            </Button>
                        </div>
                        <Button onClick={() => onRemove && onRemove(item.id)} danger>
                            Delete
                        </Button>
                    </div>
                }
            >
                <Form
                    form={form}
                    initialValues={{
                        remember: true,
                        ...item,
                    }}
                >
                    {renderOverview()}
                </Form>
            </Modal>
        </>
    );
}

export default EditFAQSectionItem;
