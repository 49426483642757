export default {
    sections: {
        menu: {
            name: 'Menu',
        },
        hero: {
            content: {
                title: '"Best Taco Restaurant in Bonn"',
                description:
                    'Order directly from our site to save money and support local business. Get the best price, fastest service and free food via loyalty points.',
            },
        },
        customizable: {
            content: {
                title: 'Order directly on our website',
                description: 'Get loyalty points, save money, get your food faster and support our business.',
                button_text: 'Order Now',
            },
        },
        google_review: {
            content: {
                title: 'Some Things Happy Customers Have Said',
            },
        },
        location_tracker: {
            name: 'Location',
            locations: 'Our Locations',
        },
        products: {
            name: 'Top Picks',
        },
        opening_hours: {
            name: 'Opening Hours',
        },
        animated: {
            name: 'Animated',
        },
        time_line: {
            name: 'Timeline',
        },
        faq: {
            name: 'Frequently Asked Questions',
        },
    },
};
