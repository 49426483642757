import { Form, Button, Select, Tabs, Switch, Alert, TabsProps, Tooltip, Input } from 'antd';
import { useState } from 'react';
import collect from 'collect.js';
import { DefaultOptionType } from 'antd/es/select';
import { FooterInterface, MediaInterface } from '../../../exports/Interfaces';
import useRdxStore from '../../../hooks/useRdxStore';
import ThemeManager from '../../../services/api/ThemeManager';
import CustomForm from '../../forms/CustomForm';
import SectionLayout from '../../layouts/SectionLayout';
import ColorSelect from '../../selects/ColorSelect';
import { DB_MODELS, FOOTER_TYPES, MEDIA_TAGS, MEDIA_TYPES, PAGES, SUB_PAGES } from '../../../exports/Enums';
import SelectedMediaList from '../../media/SelectedMediaList';
import MediaManager from '../../../services/api/MediaManager';
import ButtonDesignSelect from '../../selects/ButtonDesignSelect';

function FooterForm(): JSX.Element {
    const { store } = useRdxStore();

    const [form] = Form.useForm();

    const type = Form.useWatch('type', form);

    const [loading, setLoading] = useState<boolean>(false);

    const footerProps = store?.theme?.footer_props;

    const [mediaList, setMediaList] = useState<MediaInterface[]>(footerProps?.image ? [footerProps?.image] : []);

    const onFinish = async (values: FooterInterface): Promise<void> => {
        setLoading(true);
        const { success, response } = await ThemeManager.post({
            footer_props: {
                ...store?.theme?.footer_props,
                ...values,
            },
        });

        if (success) {
            if (typeof response?.data?.data?.id === 'number') {
                await MediaManager.post(DB_MODELS.THEME, response?.data?.data?.id, mediaList, MEDIA_TAGS.FOOTER, '');
            }
        }
        setLoading(false);
    };

    const s3Url = (id: number): string => `${import.meta.env.VITE_S3_PROD_BASE_URL}/footer/${id}.png`;

    const options = collect(FOOTER_TYPES)
        .values()
        .filter((value) => !Number.isNaN(Number(value)))
        .map((value: any) => ({
            value,
            label: (
                <Tooltip
                    overlayStyle={{ maxWidth: '500px' }}
                    title={
                        <div className="w-full h-fit relative">
                            <img
                                src={s3Url(value) ?? ''}
                                alt={`option_${value}`}
                                className="w-full h-full object-contain"
                            />
                        </div>
                    }
                    trigger="hover"
                >
                    <div>Option {value}</div>
                </Tooltip>
            ),
        }))
        .toArray() as DefaultOptionType[];

    const renderContactForm = (): JSX.Element => (
        <>
            <Form.Item name={['contact_form_props', 'is_visible']} label="Contact Form">
                <Switch defaultChecked={footerProps?.contact_form_props?.is_visible} />
            </Form.Item>
            <Form.Item name={['contact_form_props', 'send_button_props']} label="Send Button">
                <ButtonDesignSelect
                    title="Send Button"
                    button={store?.theme?.footer_props?.contact_form_props?.send_button_props}
                    setButton={(button) => form.setFieldValue(['contact_form_props', 'send_button_props'], button)}
                />
            </Form.Item>
            <div className="mb-small">
                {!store?.email ? (
                    <Alert
                        type="error"
                        message="No email saved. Please update the website's email"
                        action={
                            <Button href={`${PAGES.RESTAURANT_INFO}${SUB_PAGES.GENERAL_INFORMATION}`}>
                                Update email
                            </Button>
                        }
                    />
                ) : (
                    <Alert
                        type="info"
                        message={`The website is linked to following email: ${store?.email}`}
                        action={
                            <Button href={`${PAGES.RESTAURANT_INFO}${SUB_PAGES.GENERAL_INFORMATION}`}>
                                Update email
                            </Button>
                        }
                    />
                )}
            </div>
        </>
    );

    const renderGeneral = (): JSX.Element => (
        <>
            <Form.Item name="type" label="Layout">
                <Select options={options} className="w-fit" placeholder="Select layout" />
            </Form.Item>
            <Form.Item name="header_color" label="Header Color">
                <ColorSelect
                    initialColor={footerProps?.header_color}
                    onSelect={(value) => form?.setFieldValue('header_color', value)}
                />
            </Form.Item>
            <Form.Item name="description_color" label="Description Color">
                <ColorSelect
                    initialColor={footerProps?.description_color}
                    onSelect={(value) => form?.setFieldValue('description_color', value)}
                />
            </Form.Item>
            <Form.Item name="background_color" label="Background Color">
                <ColorSelect
                    initialColor={footerProps?.background_color}
                    onSelect={(value) => form?.setFieldValue('background_color', value)}
                />
            </Form.Item>
            <Form.Item name="card_color" label="Card Color">
                <ColorSelect
                    initialColor={footerProps?.card_color}
                    onSelect={(value) => form?.setFieldValue('card_color', value)}
                />
            </Form.Item>
            <Form.Item name="policy_color" label="Policy Color">
                <ColorSelect
                    initialColor={footerProps?.policy_color}
                    onSelect={(value) => form?.setFieldValue('policy_color', value)}
                />
            </Form.Item>
            <Form.Item name="bottom_bar_text_color" label="Bottom Bar Text Color">
                <ColorSelect
                    initialColor={footerProps?.bottom_bar_text_color}
                    onSelect={(value) => form?.setFieldValue('bottom_bar_text_color', value)}
                />
            </Form.Item>
            <Form.Item name="bottom_bar_background_color" label="Bottom Bar Background Color">
                <ColorSelect
                    initialColor={footerProps?.bottom_bar_background_color}
                    onSelect={(value) => form?.setFieldValue('bottom_bar_background_color', value)}
                />
            </Form.Item>
            <Form.Item name="scroll_back_up_button_props" label="Scroll Back Up Button">
                <ButtonDesignSelect
                    title="Scroll Back Up"
                    button={store?.theme?.footer_props?.scroll_back_up_button_props}
                    setButton={(button) => form.setFieldValue('scroll_back_up_button_props', button)}
                />
            </Form.Item>
            <Form.Item name="about_us_text" label="About Us Description">
                <Input.TextArea rows={3} placeholder="Enter about us description" />
            </Form.Item>
            <Form.Item name="learn_more_about_us_text" label="Learn more">
                <Input placeholder="Enter title" />
            </Form.Item>
            <Form.Item name="copy_right_text" label="Copy Right">
                <Input placeholder="Enter copy right" />
            </Form.Item>
        </>
    );

    const renderMedia = (): JSX.Element => (
        <div className="mb-small">
            <SelectedMediaList
                uploadCount={1}
                mediaType={MEDIA_TYPES.IMAGE}
                defaultMediaList={footerProps?.image ? [footerProps?.image] : []}
                mediaList={mediaList}
                setMediaList={setMediaList}
            />
        </div>
    );

    const items = collect([
        {
            key: '1',
            label: 'General',
            children: renderGeneral(),
            enabled: true,
        },
        {
            key: '2',
            label: 'Contact Form',
            children: renderContactForm(),
            enabled: Number(type) === FOOTER_TYPES.TWO,
        },
        {
            key: '3',
            label: 'Media',
            children: renderMedia(),
            enabled: true,
        },
    ])
        .where('enabled', true)
        .toArray() as TabsProps['items'];

    return (
        <SectionLayout title="Footer">
            <CustomForm>
                <Form
                    form={form}
                    onFinish={onFinish}
                    initialValues={{
                        remember: true,
                        ...footerProps,
                    }}
                >
                    <Tabs items={items} />
                    <Form.Item className="mb-none">
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </CustomForm>
        </SectionLayout>
    );
}

export default FooterForm;
