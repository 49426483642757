import { useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ContentLayout from '../../components/layouts/ContentLayout';
import { PAGES, SUB_PAGES } from '../../exports/Enums';
import OptionCard from '../../components/cards/OptionCard';
import { NavigationItemInterface } from '../../exports/Interfaces';
import usePages from '../../hooks/usePages';
import PageModal from '../../components/modals/page/PageModal';

function MiddleContent(): JSX.Element {
    const navigate = useNavigate();

    const { page: pageId } = useParams();

    const { navigationItems } = usePages();

    const onClick = (item: NavigationItemInterface): void => {
        navigate(`${PAGES.LAYOUT}${SUB_PAGES.PAGES}/${item?.page.id}`);
    };

    return (
        <div className="p-small">
            {navigationItems?.map((item) => (
                <div className="mb-small">
                    <OptionCard
                        title={item?.name || ''}
                        checked={Number(pageId) === item?.page?.id}
                        onClick={() => onClick(item)}
                    />
                    <div className="pl-medium mt-small">
                        {item?.children?.map((_item) => (
                            <OptionCard
                                title={_item?.name || ''}
                                checked={Number(pageId) === _item?.page?.id}
                                onClick={() => onClick(_item)}
                            />
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}

function HomePage(): JSX.Element {
    const { key } = useLocation();

    const [showPageModal, setShowPageModal] = useState<boolean>(false);

    const renderPageModal = useMemo(
        () => showPageModal && <PageModal open toggle={() => setShowPageModal(false)} />,
        [showPageModal],
    );

    return (
        <>
            <ContentLayout
                title="Pages"
                middleContent={<MiddleContent />}
                rightHeaderContent={<Button icon={<PlusOutlined />} onClick={() => setShowPageModal(true)} />}
            >
                <div className="w-full h-full">
                    <Outlet key={key} />
                </div>
            </ContentLayout>
            {renderPageModal}
        </>
    );
}

export default HomePage;
