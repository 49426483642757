import classnames from 'classnames';
import { OptionCardInterface } from '../../exports/Interfaces';

function OptionCard(props: OptionCardInterface): JSX.Element {
    const { title, description, checked, onClick } = props;
    return (
        <button
            className={classnames(
                'w-full p-small border border-brand-inkGrey-grey_2 border-solid border-small rounded-md shadow-section bg-background-inkWhite-white_0 flex justify-between items-start',
                {
                    'border-brand-primary': checked,
                },
            )}
            onClick={onClick}
            type="button"
        >
            <div>
                <h6>{title}</h6>
                {description && <p className="tiny text-brand-text-grey">{description}</p>}
            </div>
        </button>
    );
}

export default OptionCard;
