import { DB_MODELS, SECTION_TYPES } from './Enums';

export default function getSectionName(type?: SECTION_TYPES): string {
    switch (type) {
        case SECTION_TYPES.HERO_SECTION:
            return 'Hero';
        case SECTION_TYPES.CUSTOMIZABLE_SECTION:
            return 'Customizable';
        case SECTION_TYPES.GALLERY_SECTION:
            return 'Gallery';
        case SECTION_TYPES.LOCATION_TRACKER_SECTION:
            return 'Location Tracker';
        case SECTION_TYPES.GOOGLE_REVIEW_SECTION:
            return 'Google Review';
        case SECTION_TYPES.PRODUCT_SECTION:
            return 'Products';
        case SECTION_TYPES.YOUTUBE_SECTION:
            return 'Youtube';
        case SECTION_TYPES.MENU_SECTION:
            return 'Menu';
        case SECTION_TYPES.IFRAME_SECTION:
            return 'iFrame';
        case SECTION_TYPES.ANIMATED_SECTION:
            return 'Animated';
        case SECTION_TYPES.TIME_LINE_SECTION:
            return 'Timeline';
        case SECTION_TYPES.FAQ_SECTION:
            return 'FAQ';
        default:
            return '';
    }
}
export function getModel(type: SECTION_TYPES): DB_MODELS {
    switch (type) {
        case SECTION_TYPES.HERO_SECTION:
            return DB_MODELS.HERO_SECTION;
        case SECTION_TYPES.CUSTOMIZABLE_SECTION:
            return DB_MODELS.CUSTOMIZABLE_SECTION;
        default:
            return DB_MODELS.CUSTOMIZABLE_SECTION;
    }
}
