import { Button, Modal } from 'antd';
import useRdxStore from '../../hooks/useRdxStore';

interface Props {
    open: boolean;

    toggle: (open?: boolean) => void;
}

function PublishedWebsiteModal(props: Props): JSX.Element {
    const { open, toggle } = props;

    const { store } = useRdxStore();

    return (
        <Modal title="Congrats!" open={open} onCancel={() => toggle(false)} footer={null}>
            <div>
                <p className="mb-mini">You successfully published your website:</p>
                <p className="mini">{store?.domain}</p>
                <div className="flex items-center mt-small">
                    <Button type="primary" href={`https://${store?.domain}`} target="_blank">
                        Visit
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default PublishedWebsiteModal;
