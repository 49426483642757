import { useState } from 'react';
import { Form, Button } from 'antd';
import collect from 'collect.js';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import CustomForm from '../../forms/CustomForm';
import useRdxStore from '../../../hooks/useRdxStore';
import ThemeManager from '../../../services/api/ThemeManager';
import MediaManager from '../../../services/api/MediaManager';
import { DB_MODELS, MEDIA_TAGS, MEDIA_TYPES } from '../../../exports/Enums';
import { MediaInterface } from '../../../exports/Interfaces';
import useGallery from '../../../hooks/useGallery';
import ThemeLayout from '../../layouts/ThemeLayout';

function FontsForm(): JSX.Element {
    const { store } = useRdxStore();

    const { toggleGallery } = useGallery();

    const [loading, setLoading] = useState<boolean>(false);

    const [fonts, setFonts] = useState<MediaInterface[]>(store?.theme?.custom_fonts || []);

    const onFinish = async (): Promise<void> => {
        setLoading(true);

        const { success } = await ThemeManager.post({});

        if (success && typeof store?.theme?.id === 'number') {
            await MediaManager.post(
                DB_MODELS.THEME,
                store?.theme?.id,
                collect(fonts)
                    ?.filter((item) => !!item)
                    ?.toArray(),
                MEDIA_TAGS.CUSTOM_FONTS,
                '',
            );
        }
        setLoading(false);
    };

    const addFont = (font: MediaInterface): void => {
        const newCustomFonts = [...fonts];
        newCustomFonts.push(font);
        setFonts(newCustomFonts);
    };

    const removeFont = (font: MediaInterface): void => {
        const newCustomFonts = collect([...fonts])
            .where('id', '!=', font.id)
            .toArray() as MediaInterface[];
        setFonts(newCustomFonts);
    };

    const openGallery = (): void => {
        toggleGallery({
            open: true,
            maxSelect: 1,
            mediaType: MEDIA_TYPES.FONT,
            onSelect: (selectedItems) => addFont(collect(selectedItems)?.first()),
        });
    };

    return (
        <ThemeLayout title="Fonts">
            <CustomForm>
                <Form onFinish={onFinish} name="font">
                    <div className="flex gap-4 mb-small">
                        {fonts?.map((font) => (
                            <Button onClick={() => removeFont(font)} icon={<CloseOutlined />} key={font.id.toString()}>
                                {font?.name}
                            </Button>
                        ))}
                        <Button onClick={openGallery} type="dashed" icon={<PlusOutlined />}>
                            Font
                        </Button>
                    </div>
                    <Form.Item className="mb-none">
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </CustomForm>
        </ThemeLayout>
    );
}

export default FontsForm;
