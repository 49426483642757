import { useEffect, useState } from 'react';
import { Location, useLocation } from 'react-router-dom';

interface LocationChangeInterface extends Location {
    time: string;
}

function useLocationChange(): LocationChangeInterface {
    const [time, setTime] = useState<number>(0);
    const location = useLocation();

    useEffect(() => {
        setTime(Date.now());
    }, [location]);

    return {
        ...location,
        time: time.toString(),
    };
}

export default useLocationChange;
