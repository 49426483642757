import { SECTION_TYPES } from '../../../../exports/Enums';
import Hero from './hero';
import Customizable from './customizable';
import Menu from './menu';
import Gallery from './gallery';
import LocationTracker from './location-tracker';
import GoogleReview from './google-review';
import Products from './products';
import Youtube from './youtube';
import { ELEMENT_TYPES } from '../../../../exports/types';
import Popup from './popup';
import IFrame from './iframe';
import Animated from './animated';
import TimeLine from './time-line';
import FAQ from './faq';

interface Props {
    type?: ELEMENT_TYPES;
}

function Elements(props: Props): JSX.Element | null {
    const { type } = props;

    switch (type) {
        case SECTION_TYPES.HERO_SECTION:
            return <Hero />;
        case SECTION_TYPES.CUSTOMIZABLE_SECTION:
            return <Customizable />;
        case SECTION_TYPES.MENU_SECTION:
            return <Menu />;
        case SECTION_TYPES.GALLERY_SECTION:
            return <Gallery />;
        case SECTION_TYPES.LOCATION_TRACKER_SECTION:
            return <LocationTracker />;
        case SECTION_TYPES.GOOGLE_REVIEW_SECTION:
            return <GoogleReview />;
        case SECTION_TYPES.PRODUCT_SECTION:
            return <Products />;
        case SECTION_TYPES.YOUTUBE_SECTION:
            return <Youtube />;
        case SECTION_TYPES.IFRAME_SECTION:
            return <IFrame />;
        case SECTION_TYPES.ANIMATED_SECTION:
            return <Animated />;
        case SECTION_TYPES.TIME_LINE_SECTION:
            return <TimeLine />;
        case SECTION_TYPES.FAQ_SECTION:
            return <FAQ />;
        case 'Popup':
            return <Popup />;
        default:
            return null;
    }
}

export default Elements;
