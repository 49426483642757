import { useState, forwardRef, useImperativeHandle } from 'react';
import { Tabs, Select, Radio } from 'antd';
import { SettingOutlined, ShareAltOutlined } from '@ant-design/icons';
import { DB_MODELS, MEDIA_TAGS } from '../../../exports/Enums';
import { MediaInterface, SeoInterface } from '../../../exports/Interfaces';
import useRdxStore from '../../../hooks/useRdxStore';
import InitialDataManager from '../../../services/api/InitialDataManager';
import MediaManager from '../../../services/api/MediaManager';
import SeoManager from '../../../services/api/seo/SeoManager';
import CustomForm from '../../forms/CustomForm';
import SeoAnalysis from './SeoAnalysis';
import SeoPreview from './SeoPreview';
import SeoEditPreview from './SeoEditPreview';
import SeoSocialPreview from './SeoSocialPreview';
import SeoSocialEditPreview from './SeoSocialEditPreview';

const { TabPane } = Tabs;

function Section({ children }: { children: React.ReactNode }): JSX.Element {
    return <div className="py-small">{children}</div>;
}

interface Props {
    pageId: number;
    seo: SeoInterface | null;
    showAnalysis?: boolean;
}

const SeoForm = forwardRef((props: Props, ref: any): JSX.Element => {
    const { pageId, seo, showAnalysis = false } = props;

    const { store } = useRdxStore();

    const [loading, setLoading] = useState<boolean>(false);

    const [editPreview, setEditPreview] = useState<boolean>(false);

    const [editSocialPreview, setEditSocialPreview] = useState<boolean>(false);

    const [title, setTitle] = useState<string>(seo?.title ?? '');

    const [description, setDescription] = useState<string>(seo?.description ?? '');

    enum CLASSIFICATION {
        RESTAURANT = 'Restaurant',
        CAFE = 'Cafe',
    }

    const [classification, setClassification] = useState<string>(seo?.classification ?? CLASSIFICATION.RESTAURANT);

    const [socialTitle, setSocialTitle] = useState<string>(seo?.social_title ?? '');

    const [socialDescription, setSocialDescription] = useState<string>(seo?.social_description ?? '');

    const [socialImageFile, setSocialImageFile] = useState<MediaInterface | null>(seo?.image ?? null);

    const [keywords, setKeywords] = useState<string[]>(seo?.keywords ?? []);

    const onFinish = async (_data?: Partial<SeoInterface>): Promise<void> => {
        setLoading(true);
        const data: Partial<SeoInterface> = {
            title,
            description,
            keywords,
            classification,
            social_title: socialTitle,
            social_description: socialDescription,
        };

        const { response, success } = await SeoManager.post(pageId, _data ?? data);

        if (success && response?.data?.data) {
            await MediaManager.post(
                DB_MODELS.SEO,
                response?.data?.data?.id,
                socialImageFile ? [socialImageFile] : [],
                MEDIA_TAGS.IMAGES,
                '',
            );
            await InitialDataManager.get();
        }
        setEditPreview(false);
        setEditSocialPreview(false);
        setLoading(false);
    };

    useImperativeHandle(
        ref,
        () => ({
            onFinish,
        }),
        [],
    );

    function renderFocusKeyword(): JSX.Element {
        const handleKeywordChange = (value: string[]): void => {
            const trimmedKeywords = value.map((keyword) => keyword.replace(/^\s+/, ''));
            setKeywords(trimmedKeywords);
            onFinish({ keywords: trimmedKeywords });
        };
        return (
            <Select
                mode="tags"
                placeholder={`${store?.name}, Burger, Pizza, Sushi, etc.`}
                value={keywords}
                onChange={handleKeywordChange}
                tokenSeparators={[',']}
                className="w-full"
                showArrow={false}
                open={false}
            />
        );
    }

    return (
        <>
            <CustomForm>
                <Tabs>
                    <TabPane
                        key="general"
                        tab={
                            <span>
                                <SettingOutlined />
                                General
                            </span>
                        }
                    >
                        {!editPreview ? (
                            <SeoPreview
                                title={title}
                                description={description}
                                keywords={keywords}
                                setEditPreview={setEditPreview}
                            />
                        ) : (
                            <SeoEditPreview
                                title={title}
                                description={description}
                                loading={loading}
                                setTitle={setTitle}
                                setDescription={setDescription}
                                setEditPreview={setEditPreview}
                                onSave={onFinish}
                            />
                        )}
                    </TabPane>
                    <TabPane
                        key="social"
                        tab={
                            <span>
                                <ShareAltOutlined />
                                Social
                            </span>
                        }
                    >
                        {editSocialPreview ? (
                            <SeoSocialEditPreview
                                socialTitle={socialTitle}
                                socialDescription={socialDescription}
                                socialImageFile={socialImageFile}
                                loading={loading}
                                setSocialTitle={setSocialTitle}
                                setSocialDescription={setSocialDescription}
                                setSocialImageFile={setSocialImageFile}
                                setEditSocialPreview={setEditSocialPreview}
                                onSave={onFinish}
                                onCopy={() => {
                                    setSocialTitle(title);
                                    setSocialDescription(description);
                                    onFinish({ social_title: title, social_description: description });
                                }}
                            />
                        ) : (
                            <SeoSocialPreview
                                socialTitle={socialTitle}
                                socialDescription={socialDescription}
                                socialImageFile={socialImageFile}
                                setEditSocialPreview={setEditSocialPreview}
                            />
                        )}
                    </TabPane>
                </Tabs>
            </CustomForm>
            <div className="mt-small">
                <CustomForm>
                    <Section>
                        <h4 className="mb-small">Keywords</h4>
                        {renderFocusKeyword()}
                    </Section>
                </CustomForm>
            </div>
            <div className="mt-small">
                <CustomForm>
                    <Section>
                        <h4 className="mb-small">What type of store are you?</h4>
                        <Radio.Group
                            defaultValue={classification}
                            value={classification}
                            onChange={(e) => {
                                setClassification(e.target.value);
                                onFinish({ classification: e.target.value });
                            }}
                            size="large"
                        >
                            <Radio.Button value={CLASSIFICATION.RESTAURANT}>Restaurant</Radio.Button>
                            <Radio.Button value={CLASSIFICATION.CAFE}>Cafe</Radio.Button>
                        </Radio.Group>
                    </Section>
                </CustomForm>
            </div>
            {showAnalysis && (
                <div className="mt-small">
                    <CustomForm>
                        <Section>
                            <h4 className="mb-small">Analysis</h4>

                            <SeoAnalysis
                                title={title}
                                description={description}
                                keywords={keywords}
                                classification={classification}
                                socialTitle={socialTitle}
                                socialDescription={socialDescription}
                            />
                        </Section>
                    </CustomForm>
                </div>
            )}
        </>
    );
});

export default SeoForm;
