const RootReducerMiddleware = (
    state: any,
    actions: any,
    // eslint-disable-next-line no-unused-vars
    next: (state: any, actions: any) => void,
): void => {
    if (actions?.type === 'RESET_STORE') {
        // eslint-disable-next-line no-param-reassign
        state = undefined;
        localStorage.clear();
    }

    return next(state, actions);
};

export default RootReducerMiddleware;
