import { Drawer, Tabs, TabsProps } from 'antd';
import useModals from '../../../hooks/useModals';
import getSectionName from '../../../exports/Methods';
import Elements from './elements';
import ElementsTab from './tabs/ElementsTab';
import PluginsTab from './tabs/PluginsTab';

function FeatureModal(): JSX.Element {
    const { featureModal, setFeatureModal } = useModals();

    const section = featureModal?.section;

    const isUpdate = !!section;

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Elements',
            children: <ElementsTab />,
        },
        {
            key: '2',
            label: 'Plugins',
            children: <PluginsTab />,
        },
    ];

    function renderContent(): JSX.Element {
        if (isUpdate || featureModal?.type) {
            return <Elements type={featureModal?.type ?? section?.type} />;
        }
        return <Tabs defaultActiveKey="1" items={items} />;
    }

    return (
        <Drawer
            title={isUpdate ? getSectionName(section?.type) : 'Features'}
            open
            onClose={() => setFeatureModal({ open: false })}
            width={window.innerWidth}
            height={window.innerHeight}
            bodyStyle={{
                height: window.innerHeight,
                overflow: 'scroll',
            }}
            placement="bottom"
            footer={null}
            className="ant-drawer-header-title"
        >
            {renderContent()}
        </Drawer>
    );
}

export default FeatureModal;
