import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import OptionCard from '../components/cards/OptionCard';
import ContentLayout from '../components/layouts/ContentLayout';
import { PAGES, SUB_PAGES, SUB_PAGE_NAMES } from '../exports/Enums';

function MiddleContent(): JSX.Element {
    const navigate = useNavigate();

    const { pathname } = useLocation();

    const data = [
        {
            title: SUB_PAGE_NAMES.GENERAL_INFORMATION,
            page: SUB_PAGES.GENERAL_INFORMATION,
        },
        {
            title: SUB_PAGE_NAMES.LINKS,
            page: SUB_PAGES.LINKS,
        },
        {
            title: SUB_PAGE_NAMES.ORDER_METHODS,
            page: SUB_PAGES.ORDER_METHODS,
        },
        {
            title: SUB_PAGE_NAMES.PRODUCTS,
            page: SUB_PAGES.PRODUCTS,
        },
        {
            title: SUB_PAGE_NAMES.COMPANIES,
            page: SUB_PAGES.COMPANIES,
        },
        {
            title: SUB_PAGE_NAMES.GOOGLE_REVIEWS,
            page: SUB_PAGES.GOOGLE_REVIEWS,
        },
        {
            title: SUB_PAGE_NAMES.IMPRESSUMS,
            page: SUB_PAGES.IMPRESSUMS,
        },
    ];
    return (
        <div className="p-small">
            {data?.map((item) => (
                <div className="mb-small">
                    <OptionCard
                        title={item?.title}
                        checked={pathname.includes(item?.page)}
                        onClick={() => navigate(`${PAGES.RESTAURANT_INFO}${item.page}`)}
                    />
                </div>
            ))}
        </div>
    );
}

function RestaurantInfoPage(): JSX.Element {
    const { pathname } = useLocation();

    return (
        <ContentLayout title="Restaurant Information" middleContent={<MiddleContent />}>
            <div className="w-full h-full">
                <Outlet key={pathname} />
            </div>
        </ContentLayout>
    );
}

export default RestaurantInfoPage;
