import { DataWrapper, InitialDataInterface, Response } from '../../exports/Interfaces';
import actionCreators from '../../store/actions';
import ReduxResource from '../resources/ReduxResource';
import BackendApiClient from './BackendApiClient';

export default class InitialDataManager {
    public static get = async (): Promise<Response<DataWrapper<DataWrapper<InitialDataInterface>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<InitialDataInterface>>>({
            method: 'GET',
            url: `/initial-data`,
        });

        const { response, success, status } = request;

        if (response?.data?.data && success) {
            InitialDataManager.save(response?.data?.data);
        }
        if (status === 404) {
            window.location.href = import.meta.env.VITE_ADMIN_DASHBOARD_URL;
        }

        return request;
    };

    private static save = (data: InitialDataInterface): void => {
        ReduxResource.batchActions([actionCreators.store.setAll(data?.store), actionCreators.user.setAll(data?.user)]);
    };
}
