import { Form, Input, Button, Drawer, Spin } from 'antd';
import { useState } from 'react';
import useRdxStore from '../../hooks/useRdxStore';
import DomainManager from '../../services/api/domain/DomainManager';
import VercelConfig from '../domain/VercelConfig';
import useScreenType from '../../hooks/useScreenType';

interface Props {
    open: boolean;
    toggle: (open?: boolean) => void;
}

function DomainModal(props: Props): JSX.Element {
    const { open, toggle } = props;

    const { store } = useRdxStore();

    const { isDesktop } = useScreenType();

    const [form] = Form.useForm();

    const [loading, setLoading] = useState<boolean>(false);

    const onSave = async (values: any): Promise<void> => {
        setLoading(true);
        await DomainManager.post(values);
        setLoading(false);
    };

    function renderContent(): JSX.Element {
        if (loading) {
            return <Spin size="default" />;
        }
        return (
            <div>
                <Form form={form} name="basic" layout="vertical" initialValues={{ ...store }}>
                    <Form.Item name="domain" rules={[{ required: true }]}>
                        <Input placeholder="https://example.com" />
                    </Form.Item>
                </Form>
                {open && (
                    <>
                        <VercelConfig record="A" />
                        <VercelConfig record="CNAME" />
                    </>
                )}
            </div>
        );
    }

    return (
        <Drawer
            title="Domain"
            open={open}
            onClose={() => toggle(false)}
            footer={null}
            extra={
                <Button
                    type="primary"
                    loading={loading}
                    disabled={loading}
                    onClick={() => onSave(form.getFieldsValue())}
                >
                    Save
                </Button>
            }
            width={isDesktop ? window.innerWidth * 0.5 : window.innerWidth}
        >
            {renderContent()}
        </Drawer>
    );
}

export default DomainModal;
