import { DataWrapper, Response, FAQItemInterface } from '../../../exports/Interfaces';
import BackendApiClient from '../BackendApiClient';

export default class FAQSectionItemManager {
    public static post = async (
        faqSectionId: number,
        data: Partial<FAQItemInterface>,
    ): Promise<Response<DataWrapper<DataWrapper<FAQItemInterface>>>> =>
        BackendApiClient.request<DataWrapper<DataWrapper<FAQItemInterface>>>({
            method: 'POST',
            url: `/faq-sections/${faqSectionId}/faq-section-items`,
            data,
        });

    public static put = async (
        id: number,
        data: Partial<FAQItemInterface>,
    ): Promise<Response<DataWrapper<DataWrapper<FAQItemInterface>>>> =>
        BackendApiClient.request<DataWrapper<DataWrapper<FAQItemInterface>>>({
            method: 'PUT',
            url: `/faq-section-items/${id}`,
            data,
        });

    public static delete = async (id: number): Promise<Response<DataWrapper<DataWrapper<null>>>> =>
        BackendApiClient.request<DataWrapper<DataWrapper<null>>>({
            method: 'DELETE',
            url: `/faq-section-items/${id}`,
        });
}
