import collect from 'collect.js';
import { StoreInterface } from '../exports/Interfaces';
import useRdxStore from './useRdxStore';

interface Props {
    store: StoreInterface | null;
}

function useColors(props?: Props): {
    getColor: (color?: string | null) => string | undefined;
} {
    const { store: ctxStore } = useRdxStore();

    const store = props?.store ?? ctxStore;

    const getColor = (color?: string | null): string | undefined => {
        if (!color) {
            return undefined;
        }
        if (color.includes('#')) {
            return color;
        }
        return collect(
            store?.theme?.is_dark_mode ? store?.theme?.dark_mode_color_variables : store?.theme?.color_variables,
        ).get(color) as string;
    };

    return {
        getColor,
    };
}

export default useColors;
