import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { MediaInterface } from '../../../exports/Interfaces';
import useRdxStore from '../../../hooks/useRdxStore';

interface Props {
    socialTitle: string;
    socialDescription: string;
    socialImageFile: MediaInterface | null;
    setEditSocialPreview: (value: boolean) => void;
}

function SeoSocialPreview(props: Props): JSX.Element {
    const { socialTitle, socialDescription, socialImageFile, setEditSocialPreview } = props;

    const { store } = useRdxStore();

    return (
        <>
            <div className="flex items-center justify-between mb-small">
                <h4>Preview</h4>
                <Button onClick={() => setEditSocialPreview(true)} className="mr-small" icon={<EditOutlined />} />
            </div>

            <div className="lg:w-[400px] w-full bg-background-inkWhite-white_0 rounded-xl shadow p-small">
                <div className="w-full h-[200px] bg-brand-inkGrey-grey_1 mb-small">
                    <img
                        src={socialImageFile?.url ?? store?.home_page?.seo?.image?.url ?? ''}
                        alt="social_image"
                        className="h-full w-full object-cover"
                    />
                </div>
                <p className="mini text-brand-text-grey mb-mini">{store?.domain}</p>
                <h6>{socialTitle}</h6>
                <p>{socialDescription}</p>
            </div>
        </>
    );
}

export default SeoSocialPreview;
