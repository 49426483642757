interface Props {
    children: React.ReactNode;
}

function ElementsWrapper(props: Props): JSX.Element {
    const { children } = props;
    return (
        <div className="grid lg:grid-cols-3 grid-cols-1 gap-4 w-full lg:px-small lg:py-none py-small px-none">
            {children}
        </div>
    );
}

export default ElementsWrapper;
