import { AnimatedSectionFormPropsInterface } from '../../../exports/Interfaces';
import { ANIMATED_SECTION_TYPES } from '../../../exports/Enums';
import One from './type/one';
import Two from './type/two';

function AnimatedSectionForm(props: AnimatedSectionFormPropsInterface): JSX.Element {
    const { section } = props;

    switch (section?.content?.type) {
        case ANIMATED_SECTION_TYPES.TWO:
            return <Two {...props} />;
        default:
            return <One {...props} />;
    }
}

export default AnimatedSectionForm;
