import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { PAGES, SUB_PAGES } from '../../../../exports/Enums';

function EventSection(): JSX.Element {
    const navigate = useNavigate();

    return (
        <div className="border border-brand-inkGrey-grey_2 bg-background-inkWhite-white_0 rounded-md p-small flex items-center justify-between shadow-section mb-small">
            <h6>Events Form</h6>
            <Button
                onClick={() => navigate(`${PAGES.PLUGINS}${SUB_PAGES.EVENTS}${SUB_PAGES.SETTINGS}`)}
                icon={<EditOutlined />}
            />
        </div>
    );
}

export default EventSection;
