import { message } from 'antd';
import { DataWrapper, Response, ButtonThemeInterface } from '../../../exports/Interfaces';
import BackendApiClient from '../BackendApiClient';
import ReduxResource from '../../resources/ReduxResource';
import actionCreators from '../../../store/actions';

export default class ButtonManager {
    public static put = async (
        id: number,
        data: Partial<ButtonThemeInterface>,
    ): Promise<Response<DataWrapper<DataWrapper<ButtonThemeInterface>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<ButtonThemeInterface>>>({
            method: 'PUT',
            url: `/buttons/${id}`,
            data,
        });

        const { response, success } = request;

        if (response?.data?.data && success) {
            message.success('Updated');
            ReduxResource.dispatch(actionCreators.store.updateButton(response?.data?.data));
        }

        return request;
    };
}
