import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import AppLayout from '../components/layouts/AppLayout';
import { PAGES, SUB_PAGES } from '../exports/Enums';
import RestaurantInfoPage from '../pages/RestaurantInfoPage';
import ThemePage from '../pages/ThemePage';
import NewsForm from '../components/restaurantInfo/news/NewsForm';
import GeneralInfoForm from '../components/restaurantInfo/generalInfo/GeneralInfoForm';
import Links from '../components/restaurantInfo/links/index';
import OrderMethods from '../components/restaurantInfo/order-methods/index';
import LogoForm from '../components/theme/logo/LogoForm';
import ImpressumForm from '../components/restaurantInfo/impressum/ImpressumForm';
import ProductsForm from '../components/restaurantInfo/products/ProductsForm';
import GoogleReviews from '../components/restaurantInfo/google-reviews/index';
import CompaniesForm from '../components/restaurantInfo/companies/CompaniesForm';
import HomePage from '../pages/home/HomePage';
import PluginsPage from '../pages/PluginsPage';
import CateringGuard from './guards/CateringGuard';
import PopUpsForm from '../components/plugins/popups/PopUpsForm';
import CateringSettings from '../components/plugins/catering/settings/index';
import EventGuard from './guards/EventGuard';
import EventSettings from '../components/plugins/events/settings/index';
import PageLayout from '../pages/home/layout';
import useRdxStore from '../hooks/useRdxStore';
import Seo from '../components/layout/seo';
import NavigationBarForm from '../components/theme/navigation-bar/NavigationBarForm';
import NavigationItemsPage from '../components/layout/navigation-items';
import TemplatesForm from '../components/theme/templates/TemplatesForm';
import FontsForm from '../components/theme/fonts/FontsForm';
import ColorsForm from '../components/theme/colors/ColorsForm';
import TypographyForm from '../components/theme/typography/TypographyForm';
import ButtonForm from '../components/theme/buttons/ButtonForm';
import FooterForm from '../components/theme/footer/FooterForm';
import StyleForm from '../components/theme/style/StyleForm';
import InjectCodeForm from '../components/theme/code/InjectCodeForm';
import FloatingIconForm from '../components/theme/floating-icon/FloatingIconForm';
import PageLoadingForm from '../components/theme/page-loading/StyleForm';

function MainRouter(): JSX.Element {
    const { store } = useRdxStore();

    const router = createBrowserRouter([
        {
            element: <AppLayout />,
            children: [
                {
                    element: <HomePage />,
                    children: [
                        {
                            path: `${PAGES.LAYOUT}${SUB_PAGES.PAGES}/:page`,
                            element: <PageLayout />,
                        },
                        {
                            path: `${PAGES.LAYOUT}${SUB_PAGES.PAGES}/:page${SUB_PAGES.SEO}`,
                            element: <Seo />,
                        },
                        {
                            path: `${PAGES.LAYOUT}${SUB_PAGES.PAGES}/:page${SUB_PAGES.NEWS_BANNER}`,
                            element: <NewsForm />,
                        },
                        {
                            path: `${PAGES.LAYOUT}${SUB_PAGES.PAGES}/:page${SUB_PAGES.FLOATING_ICON}`,
                            element: <FloatingIconForm />,
                        },
                        {
                            path: `${PAGES.LAYOUT}${SUB_PAGES.NAVIGATION_ITEMS}`,
                            element: <NavigationItemsPage />,
                        },
                    ],
                },
            ],
        },
        {
            path: PAGES.RESTAURANT_INFO,
            element: <AppLayout />,
            children: [
                {
                    path: '',
                    element: <RestaurantInfoPage />,
                    children: [
                        {
                            path: `${PAGES.RESTAURANT_INFO}${SUB_PAGES.GENERAL_INFORMATION}`,
                            element: <GeneralInfoForm />,
                        },
                        {
                            path: `${PAGES.RESTAURANT_INFO}${SUB_PAGES.LINKS}`,
                            element: <Links />,
                        },
                        {
                            path: `${PAGES.RESTAURANT_INFO}${SUB_PAGES.ORDER_METHODS}`,
                            element: <OrderMethods />,
                        },
                        {
                            path: `${PAGES.RESTAURANT_INFO}${SUB_PAGES.PRODUCTS}`,
                            element: <ProductsForm />,
                        },
                        {
                            path: `${PAGES.RESTAURANT_INFO}${SUB_PAGES.COMPANIES}`,
                            element: <CompaniesForm />,
                        },
                        {
                            path: `${PAGES.RESTAURANT_INFO}${SUB_PAGES.GOOGLE_REVIEWS}`,
                            element: <GoogleReviews />,
                        },
                        {
                            path: `${PAGES.RESTAURANT_INFO}${SUB_PAGES.IMPRESSUMS}`,
                            element: <ImpressumForm />,
                        },
                        {
                            path: '*',
                            element: <Navigate to={PAGES.RESTAURANT_INFO} replace />,
                        },
                    ],
                },
            ],
        },
        {
            path: PAGES.THEME,
            element: <AppLayout />,
            children: [
                {
                    path: '',
                    element: <ThemePage />,
                    children: [
                        {
                            path: `${PAGES.THEME}${SUB_PAGES.LOGO}`,
                            element: <LogoForm />,
                        },
                        {
                            path: `${PAGES.THEME}${SUB_PAGES.TEMPLATES}`,
                            element: <TemplatesForm />,
                        },
                        {
                            path: `${PAGES.THEME}${SUB_PAGES.FONTS}`,
                            element: <FontsForm />,
                        },
                        {
                            path: `${PAGES.THEME}${SUB_PAGES.COLORS}`,
                            element: <ColorsForm />,
                        },
                        {
                            path: `${PAGES.THEME}${SUB_PAGES.TYPOGRAPHY}`,
                            element: <TypographyForm />,
                        },
                        {
                            path: `${PAGES.THEME}${SUB_PAGES.BUTTONS}`,
                            element: <ButtonForm />,
                        },
                        {
                            path: `${PAGES.THEME}${SUB_PAGES.NAVIGATION_BAR}`,
                            element: <NavigationBarForm />,
                        },
                        {
                            path: `${PAGES.THEME}${SUB_PAGES.FOOTER}`,
                            element: <FooterForm />,
                        },
                        {
                            path: `${PAGES.THEME}${SUB_PAGES.STYLE}`,
                            element: <StyleForm />,
                        },
                        {
                            path: `${PAGES.THEME}${SUB_PAGES.PAGE_LOADING}`,
                            element: <PageLoadingForm />,
                        },
                        {
                            path: `${PAGES.THEME}${SUB_PAGES.INJECT_CODE}`,
                            element: <InjectCodeForm />,
                        },
                        {
                            path: '*',
                            element: <Navigate to={PAGES.THEME} replace />,
                        },
                    ],
                },
            ],
        },
        {
            path: PAGES.PLUGINS,
            element: <AppLayout />,
            children: [
                {
                    path: '',
                    element: <PluginsPage />,
                    children: [
                        {
                            path: `${PAGES.PLUGINS}${SUB_PAGES.CATERING}${SUB_PAGES.SETTINGS}`,
                            element: <CateringGuard element={<CateringSettings />} />,
                        },
                        {
                            path: `${PAGES.PLUGINS}${SUB_PAGES.EVENTS}${SUB_PAGES.SETTINGS}`,
                            element: <EventGuard element={<EventSettings />} />,
                        },
                        {
                            path: `${PAGES.PLUGINS}${SUB_PAGES.POPUPS}`,
                            element: <PopUpsForm />,
                        },
                        {
                            path: '*',
                            element: <Navigate to={PAGES.PLUGINS} replace />,
                        },
                    ],
                },
            ],
        },
        {
            path: '*',
            element: <Navigate to={`${PAGES.LAYOUT}${SUB_PAGES.PAGES}/${store?.home_page?.id}`} replace />,
        },
    ]);

    return <RouterProvider router={router} />;
}

export default MainRouter;
