import { Spin } from 'antd';
import classnames from 'classnames';
import NavBar, { OnboardingNavigationBarProps } from '../../components/navigation/onboarding/NavBar';

interface Props extends OnboardingNavigationBarProps {
    title: string;
    description?: string;
    children: JSX.Element;
    loading?: boolean;
    rightColumn?: JSX.Element;
}

function Content(props: Props): JSX.Element {
    const { title, description, children, loading, rightColumn } = props;

    const sideBarRef = document.getElementById('sidebar');

    if (loading) {
        return (
            <div className="w-full h-full flex px-large pt-medium">
                <Spin size="large" />
            </div>
        );
    }

    return (
        <div className="h-screen w-full">
            <div className="sticky top-0 z-50">
                <NavBar {...props} />
            </div>
            <div
                className={classnames('fixed h-full overflow-contain overflow-scroll grid grid-cols-2 pb-[100px]', {
                    'grid grid-cols-3': !!rightColumn,
                })}
                style={{
                    width: window.innerWidth - (sideBarRef?.getBoundingClientRect()?.width || 0),
                }}
            >
                <div className="col-span-2 overflow-y-auto px-large pt-medium pb-[100px]">
                    <h2 className="mb-medium">{title}</h2>
                    {description && <p className="mb-medium text-brand-text-grey">{description}</p>}
                    <div className="pb-[50px]">{children}</div>
                </div>

                <div className="col-span-1">{rightColumn}</div>
            </div>
        </div>
    );
}

export default Content;
