import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import collect from 'collect.js';
import { REDUX_STORE_SLICES } from '../../exports/Enums';
import {
    ButtonThemeInterface,
    CateringSettingInterface,
    CompanyInterface,
    EventSettingInterface,
    GoogleReviewInterface,
    ImpressumInterface,
    PopupInterface,
    ProductInterface,
    ReservationSettingInterface,
    ScriptInterface,
    StoreInterface,
    StoreThemeInterface,
} from '../../exports/Interfaces';

const initialState: Partial<StoreInterface> = {};

export default createSlice({
    name: REDUX_STORE_SLICES.STORE,
    initialState,
    reducers: {
        setAll: (state: Partial<StoreInterface>, action: PayloadAction<StoreInterface>) => {
            try {
                Object.assign(state, action.payload);
            } catch (error) {}
        },
        setTheme: (state: Partial<StoreInterface>, action: PayloadAction<StoreThemeInterface>) => {
            try {
                state.theme = action.payload;
            } catch (error) {}
        },
        addImpressum: (state: Partial<StoreInterface>, action: PayloadAction<ImpressumInterface>) => {
            try {
                state.impressums = [...(state.impressums || []), action.payload];
            } catch (error) {}
        },
        updateImpressum: (state: Partial<StoreInterface>, action: PayloadAction<ImpressumInterface>) => {
            try {
                const index = collect(state.impressums)?.search((impressum) => impressum?.id === action.payload.id);
                if (index !== undefined && state.impressums) {
                    state.impressums[index] = action.payload;
                }
            } catch (error) {}
        },
        deleteImpressum: (state: Partial<StoreInterface>, action: PayloadAction<number>) => {
            try {
                state.impressums = collect(state.impressums)
                    ?.filter((impressum) => impressum?.id !== action.payload)
                    ?.toArray();
            } catch (error) {}
        },
        createProduct: (state: Partial<StoreInterface>, action: PayloadAction<ProductInterface>) => {
            try {
                state.products = [...(state.products ?? []), action.payload];
            } catch (error) {}
        },
        updateProduct: (state: Partial<StoreInterface>, action: PayloadAction<ProductInterface>) => {
            try {
                const index = collect(state.products)?.search((product) => product?.id === action.payload.id);
                if (index !== undefined && state.products) {
                    state.products[index] = action.payload;
                }
            } catch (error) {}
        },
        deleteProduct: (state: Partial<StoreInterface>, action: PayloadAction<number>) => {
            try {
                state.products = collect(state.products)
                    ?.filter((product) => product?.id !== action.payload)
                    ?.toArray();
            } catch (error) {}
        },
        addCompany: (state: Partial<StoreInterface>, action: PayloadAction<CompanyInterface>) => {
            try {
                state.companies = [...(state.companies || []), action.payload];
            } catch (error) {}
        },
        updateCompany: (state: Partial<StoreInterface>, action: PayloadAction<CompanyInterface>) => {
            try {
                const index = collect(state.companies)?.search((company) => company?.id === action.payload.id);
                if (index !== undefined && state.companies) {
                    state.companies[index] = action.payload;
                }
            } catch (error) {}
        },
        deleteCompany: (state: Partial<StoreInterface>, action: PayloadAction<number>) => {
            try {
                if (!state.companies) {
                    return;
                }
                state.companies = collect(state.companies)
                    ?.filter((company) => company?.id !== action.payload)
                    ?.toArray();
            } catch (error) {}
        },
        sortCompanies: (state: Partial<StoreInterface>, action: PayloadAction<number[]>) => {
            try {
                state.companies = collect(action.payload)
                    ?.map((id) => collect(state.companies || [])?.firstWhere('id', id))
                    ?.toArray();
            } catch (error) {}
        },
        createReview: (state: Partial<StoreInterface>, action: PayloadAction<GoogleReviewInterface>) => {
            try {
                const index = collect(state.companies)?.search(
                    (company) => company?.google_places_id === action.payload.google_places_id,
                );
                if (index !== undefined && state.companies) {
                    state.companies[index] = {
                        ...state.companies[index],
                        google_reviews: [action.payload, ...state.companies[index].google_reviews],
                    };
                }
            } catch (error) {}
        },
        updateReview: (state: Partial<StoreInterface>, action: PayloadAction<GoogleReviewInterface>) => {
            try {
                if (!state.companies) {
                    return;
                }

                const companyIndex = collect(state.companies).search(
                    (company) => company?.google_places_id === action.payload.google_places_id,
                );
                const index = collect(state.companies[companyIndex].google_reviews).search(
                    (item) => item.id === action.payload.id,
                );

                if (index !== undefined && state.companies) {
                    state.companies[companyIndex].google_reviews[index] = action.payload;
                }
            } catch (error) {}
        },
        deleteReview: (state: Partial<StoreInterface>, action: PayloadAction<GoogleReviewInterface>) => {
            try {
                if (!state.companies) {
                    return;
                }

                const companyIndex = collect(state.companies).search(
                    (company) => company?.google_places_id === action.payload.google_places_id,
                );

                state.companies[companyIndex].google_reviews = collect(state.companies[companyIndex].google_reviews)
                    .reject((item) => item.id === action.payload.id)
                    .toArray();
            } catch (error) {}
        },
        addReservationSettings: (
            state: Partial<StoreInterface>,
            action: PayloadAction<{ id: null; reservationSetting: ReservationSettingInterface }>,
        ) => {
            try {
                const index = collect(state.companies)?.search((company) => company?.id === action.payload.id);
                if (index !== undefined && state.companies) {
                    state.companies[index].reservation_settings = action.payload?.reservationSetting;
                }
            } catch (error) {}
        },
        updateReservationSettings: (
            state: Partial<StoreInterface>,
            action: PayloadAction<ReservationSettingInterface>,
        ) => {
            try {
                const companies = collect(state?.companies ?? [])
                    ?.map((company) => {
                        if (company?.reservation_settings?.id === action.payload?.id) {
                            return {
                                ...company,
                                reservation_settings: action.payload,
                            };
                        }
                        return company;
                    })
                    ?.toArray() as CompanyInterface[];
                state.companies = companies;
            } catch (error) {}
        },
        addCateringSettings: (
            state: Partial<StoreInterface>,
            action: PayloadAction<{ id: null; cateringSetting: CateringSettingInterface }>,
        ) => {
            try {
                const index = collect(state.companies)?.search((company) => company?.id === action.payload.id);
                if (index !== undefined && state.companies) {
                    state.companies[index].catering_settings = action.payload?.cateringSetting;
                }
            } catch (error) {}
        },
        updateCateringSettings: (state: Partial<StoreInterface>, action: PayloadAction<CateringSettingInterface>) => {
            try {
                const companies = collect(state?.companies ?? [])
                    ?.map((company) => {
                        if (company?.catering_settings?.id === action.payload?.id) {
                            return {
                                ...company,
                                catering_settings: action.payload,
                            };
                        }
                        return company;
                    })
                    ?.toArray() as CompanyInterface[];
                state.companies = companies;
            } catch (error) {}
        },
        addEventSettings: (
            state: Partial<StoreInterface>,
            action: PayloadAction<{ id: null; eventSetting: EventSettingInterface }>,
        ) => {
            try {
                const index = collect(state.companies)?.search((company) => company?.id === action.payload.id);
                if (index !== undefined && state.companies) {
                    state.companies[index].event_settings = action.payload?.eventSetting;
                }
            } catch (error) {}
        },
        updateEventSettings: (state: Partial<StoreInterface>, action: PayloadAction<EventSettingInterface>) => {
            try {
                const companies = collect(state?.companies ?? [])
                    ?.map((company) => {
                        if (company?.event_settings?.id === action.payload?.id) {
                            return {
                                ...company,
                                event_settings: action.payload,
                            };
                        }
                        return company;
                    })
                    ?.toArray() as CompanyInterface[];
                state.companies = companies;
            } catch (error) {}
        },
        updatePopup: (state: Partial<StoreInterface>, action: PayloadAction<PopupInterface>) => {
            try {
                if (!state.popups) {
                    return;
                }

                const index = collect(state.popups)?.search((popup) => popup?.id === action?.payload?.id);
                if (index !== undefined && state.popups) {
                    state.popups[index] = action.payload;
                }
            } catch (error) {}
        },
        deletePopup: (state: Partial<StoreInterface>, action: PayloadAction<number>) => {
            try {
                if (!state.popups) {
                    return;
                }
                state.popups = collect(state.popups)
                    ?.filter((popup) => popup?.id !== action.payload)
                    ?.toArray();
            } catch (error) {}
        },
        updateButton: (state: Partial<StoreInterface>, action: PayloadAction<ButtonThemeInterface>) => {
            try {
                if (!state.buttons) {
                    return;
                }
                const index = collect(state.buttons)?.search((button) => button?.id === action?.payload?.id);
                if (index !== undefined && state.popups) {
                    state.buttons[index] = action.payload;
                }
            } catch (error) {}
        },
        addScript: (state: Partial<StoreInterface>, action: PayloadAction<ScriptInterface>) => {
            try {
                state.scripts = collect(state?.scripts).push(action.payload).toArray();
            } catch (error) {}
        },
        updateScript: (state: Partial<StoreInterface>, action: PayloadAction<ScriptInterface>) => {
            try {
                const index = collect(state.scripts)?.search((script) => script?.id === action.payload.id);
                if (index !== undefined && state.scripts) {
                    state.scripts[index] = action.payload;
                }
            } catch (error) {}
        },
        deleteScript: (state: Partial<StoreInterface>, action: PayloadAction<number>) => {
            try {
                state.scripts = collect(state.scripts)
                    ?.filter((script) => script?.id !== action.payload)
                    ?.toArray();
            } catch (error) {}
        },
    },
});

export const props = (state: StoreInterface): StoreInterface => state;
