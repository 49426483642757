import { useMemo, useState } from 'react';
import { Button, Select } from 'antd';
import { getAvailableFonts } from '@remotion/google-fonts';
import collect from 'collect.js';
import { CloseOutlined } from '@ant-design/icons';
import useTheme from '../../hooks/useTheme';
import { FontInterface } from '../../exports/Interfaces';
import useRdxStore from '../../hooks/useRdxStore';

interface OptionInterface extends FontInterface {
    value: string;
    label: string;
}

interface Props {
    initialFont?: FontInterface;
    setFont: (font: FontInterface | null) => void;
}

function Option({ option, onClick }: { option: OptionInterface; onClick: () => void }): JSX.Element {
    return (
        <Button className="mb-mini" type="text" onClick={() => onClick()}>
            <p
                style={{
                    fontFamily: option.value,
                }}
            >
                {option?.label}
            </p>
        </Button>
    );
}

export default function FontPickerSearchInput(props: Props): JSX.Element {
    const { initialFont, setFont } = props;

    const { store } = useRdxStore();

    const theme = useTheme();

    const [query, setQuery] = useState<string>('');

    const [loading, setLoading] = useState<boolean>(false);

    const [showOptionList, setShowOptionList] = useState<boolean>(false);

    const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);

    const [value, setValue] = useState<string | null>(initialFont?.name || null);

    const fonts = getAvailableFonts();

    const defaultFonts = collect([...theme.fonts.customFonts, ...(store?.theme?.custom_fonts || [])])
        .map((_font) => ({
            ..._font,
            value: _font.name,
            label: _font?.name,
        }))
        .toArray() as OptionInterface[];

    const options = useMemo(
        () =>
            collect(fonts)
                ?.map((_font) => {
                    const fontLink = document.querySelector(`link[href*="${_font.fontFamily}"]`);
                    if (!fontLink) {
                        const link = document.createElement('link');
                        link.href = `https://fonts.googleapis.com/css2?family=${_font.fontFamily}&display=swap`;
                        link.rel = 'stylesheet';
                        document.head.appendChild(link);
                    }
                    return {
                        label: _font?.importName,
                        value: _font?.fontFamily,
                        name: _font?.fontFamily,
                        url: null,
                    };
                })
                .merge(defaultFonts)
                ?.toArray(),
        [],
    ) as OptionInterface[];

    const filteredOptions = useMemo(
        () =>
            collect(options)
                ?.filter((_option) => _option?.value?.toLowerCase()?.includes(query?.toLowerCase()))
                ?.toArray(),
        [options, query],
    ) as OptionInterface[];

    const loadingData = Array.from({ length: 15 }, (_, index) => index + 1);

    const toggleLoading = (): void => {
        setLoading(true);
        setTimeout(() => setLoading(false), 1000);
    };

    const toggleDropDown = (open: boolean): void => {
        setDropDownOpen(open);
        if (open) {
            toggleLoading();
            setTimeout(() => setShowOptionList(true), 300);
        } else {
            setLoading(false);
            setShowOptionList(false);
        }
    };

    const onSelectOption = (_option: OptionInterface): void => {
        setFont({
            name: _option.value,
            url: _option?.url,
        });
        setValue(_option.value);
        setDropDownOpen(false);
        setShowOptionList(false);
    };

    const onRemoveOption = (): void => {
        setFont(null);
        setValue(null);
    };

    return (
        <div className="flex items-center">
            <Select
                showSearch
                value={value}
                defaultValue={value}
                onChange={setValue}
                onSearch={setQuery}
                loading={loading}
                open={dropDownOpen}
                onDropdownVisibleChange={toggleDropDown}
                placeholder="Search Font"
                style={{ width: 300 }}
                // eslint-disable-next-line react/no-unstable-nested-components
                dropdownRender={() => (
                    <div className="overflow-auto w-full flex flex-col relative" style={{ height: 300 }}>
                        {showOptionList &&
                            filteredOptions?.map((_option) => (
                                <Option key={_option?.value} option={_option} onClick={() => onSelectOption(_option)} />
                            ))}
                        {loading && (
                            <div className="absolute inset-0 h-full w-full bg-background-inkWhite-white_0 z-10">
                                {loadingData?.map(() => (
                                    <div className="p-mini bg-background-inkWhite-white_0">
                                        <div className="w-full h-[15px] bg-brand-inkGrey-grey_1 rounded-md" />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            />
            {value && <Button className="ml-small" icon={<CloseOutlined />} onClick={onRemoveOption} />}
        </div>
    );
}
