import { Button, Form, Input } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import ColorSelect from '../../../../selects/ColorSelect';
import useModals from '../../../../../hooks/useModals';
import {
    SectionInterface,
    AnimatedSectionInterface,
    AnimatedSectionFormPropsInterface,
} from '../../../../../exports/Interfaces';
import SectionManager from '../../../../../services/api/SectionManager';
import SectionForm from '../../../../forms/SectionForm';
import FormGroup from '../../../../forms/structure/form-group';
import { SECTION_STYLE_ATTRIBUTES } from '../../../../../exports/Enums';

function One(props: AnimatedSectionFormPropsInterface): JSX.Element {
    const { section } = props;

    const { setSectionModal } = useModals();

    const [form] = Form.useForm();

    const [loading, setLoading] = useState<boolean>(false);

    const onSave = async (values: Partial<SectionInterface<AnimatedSectionInterface>>): Promise<void> => {
        if (typeof section?.id !== 'number') {
            return;
        }

        const { success } = await SectionManager.put<AnimatedSectionInterface>(section?.id, values);

        setLoading(false);
        if (success) {
            setSectionModal({ open: false });
        }
    };

    return (
        <SectionForm
            form={form}
            section={section}
            onSave={onSave}
            loading={loading}
            hideHeader
            initialValues={section}
            excludedStyles={[SECTION_STYLE_ATTRIBUTES.HEADER_COLOR, SECTION_STYLE_ATTRIBUTES.DESCRIPTION_COLOR]}
        >
            <Form.Item name={['content', 'type']} className="hidden" />
            <FormGroup label="Typography">
                <Form.List name={['content', 'data', 'items']}>
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field: any, index) => (
                                <div
                                    key={field.key}
                                    className="flex w-full items-center justify-center mb-medium gap-2"
                                >
                                    <Form.Item
                                        {...field}
                                        name={[field.name, 'title']}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: 'Please add a title or delete this field.',
                                            },
                                        ]}
                                        noStyle
                                        className="w-fit"
                                    >
                                        <Input placeholder="Enter title" />
                                    </Form.Item>

                                    <ColorSelect
                                        initialColor={form.getFieldValue(['content', 'data', 'items', index, 'color'])}
                                        onSelect={(color) =>
                                            form.setFieldValue(['content', 'data', 'items', index, 'color'], color)
                                        }
                                    />
                                    {fields.length > 0 ? (
                                        <div className="h-full">
                                            <MinusCircleOutlined
                                                className="dynamic-delete-button mt-none"
                                                onClick={() => remove(field.name)}
                                            />
                                        </div>
                                    ) : null}
                                </div>
                            ))}

                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                                    Add item
                                </Button>
                                <Form.ErrorList errors={errors} />
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </FormGroup>
        </SectionForm>
    );
}

export default One;
