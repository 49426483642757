import {
    CheckOutlined,
    EditOutlined,
    FileOutlined,
    FilePdfOutlined,
    FontSizeOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import classnames from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Button, Tooltip, Upload, UploadProps } from 'antd';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import collect from 'collect.js';
import { useState } from 'react';
import { MediaItemProps } from '../../exports/Interfaces';
import { MEDIA_TYPES } from '../../exports/Enums';
import MediaResource from '../../services/resources/MediaResource';
import ActionConfirmation from '../popover/ActionConfirmation';

function MediaItem(props: MediaItemProps): JSX.Element {
    const {
        item,
        selected,
        mediaType = MEDIA_TYPES.IMAGE,
        selectDisabled = false,
        editDisabled = false,
        onSelect,
        onDeselect,
        onEdit,
        onDelete,
    } = props;

    const [deleteClicked, setDeleteCLicked] = useState<boolean>(false);

    const dummyRequest = (e: UploadRequestOption<any>): void => {
        setTimeout(() => {
            if (typeof e?.onSuccess !== 'undefined') {
                e.onSuccess('ok');
            }
        }, 0);
    };

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }): void =>
        onEdit && onEdit(collect(newFileList)?.first(), item?.id);

    const _onDelete = (): void => {
        setDeleteCLicked(false);

        return onDelete && onDelete(item?.id);
    };

    const renderMediaType = (): JSX.Element | null => {
        switch (mediaType) {
            case MEDIA_TYPES.IMAGE:
                return <LazyLoadImage src={item?.url} alt="gallery_url" className="object-cover w-full h-full" />;
            case MEDIA_TYPES.VIDEO:
                return (
                    <video preload="metadata" className="w-full" key={item?.url ?? ''}>
                        <source src={item.url} />
                    </video>
                );
            case MEDIA_TYPES.PDF:
                return (
                    <div className="w-full h-full flex justify-center items-center">
                        <div className="-mt-[45px]">
                            <h1>
                                <FilePdfOutlined className="text-[#ff0000]" />
                            </h1>
                        </div>
                    </div>
                );
            case MEDIA_TYPES.FONT:
                return (
                    <div className="w-full h-full flex justify-center items-center">
                        <div className="-mt-[45px]">
                            <h1>
                                <FontSizeOutlined className="text-brand-text-grey" />
                            </h1>
                        </div>
                    </div>
                );
            case MEDIA_TYPES.DOCUMENT:
                return (
                    <div className="w-full h-full flex justify-center items-center">
                        <div className="-mt-[45px]">
                            <h1>
                                <FileOutlined className="text-brand-text-grey" />
                            </h1>
                        </div>
                    </div>
                );

            default:
                return null;
        }
    };

    return (
        <Tooltip title={item?.name}>
            <div className="relative">
                <div
                    className={classnames(
                        'w-full h-[250px] relative overflow-hidden rounded-md border border-solid border-brand-inkGrey-grey_2 shadow bg-background-inkWhite-white_0 p-none',
                        {
                            'border-brand-primary': selected,
                        },
                    )}
                >
                    <div className="flex items-center relative">
                        <p className="line-clamp-1 text-left ml-small flex-1">{item?.name}</p>

                        <div className="flex items-center shrink-0">
                            <ActionConfirmation
                                open={deleteClicked}
                                onOpenChange={setDeleteCLicked}
                                onConfirm={_onDelete}
                                onCancel={() => setDeleteCLicked(false)}
                            />
                            {!editDisabled && (
                                <Upload
                                    customRequest={dummyRequest}
                                    onChange={handleChange}
                                    fileList={[]}
                                    accept={MediaResource.getFileType(mediaType)}
                                    maxCount={1}
                                >
                                    <Button type="ghost" className="ml-mini">
                                        <EditOutlined />
                                    </Button>
                                </Upload>
                            )}

                            {!selectDisabled && (
                                <Button
                                    className={classnames(
                                        'w-[50px] h-[40px] flex justify-center items-center rounded-none ml-mini',
                                        {
                                            'bg-brand-primary': selected,
                                            'bg-background-inkWhite-white_0': !selected,
                                        },
                                    )}
                                    type="ghost"
                                    onClick={() =>
                                        selected ? onDeselect && onDeselect(item) : onSelect && onSelect(item)
                                    }
                                >
                                    {selected ? <CheckOutlined className="text-white" /> : <PlusOutlined />}
                                </Button>
                            )}
                        </div>
                    </div>
                    <Button
                        onClick={() => (selected ? onDeselect && onDeselect(item) : onSelect && onSelect(item))}
                        type="link"
                        className="w-full h-full p-none"
                    >
                        {renderMediaType()}
                    </Button>
                </div>
            </div>
        </Tooltip>
    );
}

export default MediaItem;
