import { DataWrapper, MediaInterface, Response } from '../../exports/Interfaces';
import BackendApiClient from './BackendApiClient';

export default class GalleryManager {
    public static get = async (): Promise<Response<DataWrapper<DataWrapper<MediaInterface[]>>>> =>
        BackendApiClient.request<DataWrapper<DataWrapper<MediaInterface[]>>>({
            method: 'GET',
            url: `/gallery`,
        });

    public static post = async (data?: any): Promise<Response<DataWrapper<DataWrapper<MediaInterface>>>> =>
        BackendApiClient.request<DataWrapper<DataWrapper<MediaInterface>>>(
            {
                method: 'POST',
                url: `/gallery`,
                data,
            },
            true,
        );

    public static put = async (id: string, data?: any): Promise<Response<DataWrapper<DataWrapper<MediaInterface>>>> =>
        BackendApiClient.request<DataWrapper<DataWrapper<MediaInterface>>>(
            {
                method: 'POST',
                url: `/gallery/${id}`,
                data,
            },
            true,
        );

    public static delete = async (id: string): Promise<Response<DataWrapper<DataWrapper<boolean>>>> =>
        BackendApiClient.request<DataWrapper<DataWrapper<boolean>>>(
            {
                method: 'DELETE',
                url: `/gallery/${id}`,
            },
            true,
        );
}
