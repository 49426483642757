import classnames from 'classnames';
import useScreenType from '../../hooks/useScreenType';
import SectionHeader from '../navigation/SectionHeader';

interface Props {
    title: string;
    middleContent?: JSX.Element;
    headerContent?: JSX.Element;
    children: JSX.Element | null;
    rightHeaderContent?: JSX.Element;
}

function ContentLayout(props: Props): JSX.Element {
    const { title, headerContent, middleContent, rightHeaderContent, children } = props;

    const { isDesktop } = useScreenType();

    const headerRef = document.getElementById('header');

    const sideBarRef = document.getElementById('sidebar');

    return (
        <div>
            {headerContent}
            <div
                className={classnames('fixed h-screen w-full', {
                    'grid grid-cols-12': isDesktop,
                })}
                style={{
                    width: window.innerWidth - (sideBarRef?.getBoundingClientRect()?.width ?? 0),
                }}
            >
                {isDesktop && (
                    <div
                        className="sticky top-0 col-span-2 w-full border-brand-inkGrey-grey_2 border-r-small border-t-none border-l-none border-b-none border-solid overflow-scroll overscroll-none bg-background-inkWhite-white_0 pb-[50px]"
                        style={{ height: window.innerHeight - (headerRef?.getBoundingClientRect()?.height ?? 0) }}
                        id="middle-content"
                    >
                        <div className="z-20 sticky top-0">
                            <SectionHeader title={title} hideClose rightContent={rightHeaderContent} />
                        </div>
                        {middleContent && middleContent}
                    </div>
                )}
                <div
                    className={classnames('w-full overflow-scroll overscroll-none scrollbar-hide col-span-10')}
                    style={{ height: window.innerHeight - (headerRef?.getBoundingClientRect()?.height ?? 0) }}
                >
                    {children}
                </div>
            </div>
        </div>
    );
}

export default ContentLayout;
