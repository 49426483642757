import { Descriptions } from 'antd';
import { DomainRecordInterface } from '../../exports/Interfaces';

interface Props {
    record: DomainRecordInterface;
}

function DNSRecord(props: Props): JSX.Element {
    const { record } = props;

    const items = [
        {
            key: '1',
            label: 'Type',
            value: record?.type,
        },
        {
            key: '1',
            label: 'Name',
            value: record?.name,
        },
        {
            key: '2',
            label: 'Value',
            value: record?.value,
        },
    ];

    return (
        <Descriptions bordered layout="vertical" size="small">
            {items.map((item: any) => (
                <Descriptions.Item label={item.label}>{item.value}</Descriptions.Item>
            ))}
        </Descriptions>
    );
}

export default DNSRecord;
