import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { REDUX_STORE_SLICES } from '../../exports/Enums';
import { AuthInterface } from '../../exports/Interfaces';

const initialState: AuthInterface = {
    token: '',
};

export default createSlice({
    name: REDUX_STORE_SLICES.AUTH,
    initialState,
    reducers: {
        setToken: (state: AuthInterface, action: PayloadAction<AuthInterface>) => {
            try {
                Object.assign(state, action.payload);
            } catch (error) {}
        },
    },
});

export const props = (state: AuthInterface): AuthInterface => state;
