import { AppstoreOutlined, LinkOutlined } from '@ant-design/icons';
import { Switch, Input, Form, Tag } from 'antd';
import classnames from 'classnames';
import { NavigationItemInterface } from '../../exports/Interfaces';
import NavigationItemManager from '../../services/api/NavigationItemManager';

interface Props {
    navigationItem: NavigationItemInterface;
    isDraggable?: boolean;
    isDisabled?: boolean;
    toggleVisibility: (id: number, checked: boolean) => void;
}

function DraggableNavigationItem(props: Props): JSX.Element | null {
    const { navigationItem, isDraggable = true, isDisabled = false, toggleVisibility } = props;

    const updateNavItem = async (values: any): Promise<void> => {
        NavigationItemManager.put(navigationItem?.id, values);
    };

    const getLinkType = (): JSX.Element => {
        if (navigationItem?.section_id) {
            return <Tag color="warning">Section</Tag>;
        }
        return <Tag color="processing">Page</Tag>;
    };

    return navigationItem ? (
        <div
            className={classnames('border border-brand-inkGrey-grey_2 rounded-md shadow-section overflow-hidden', {
                'bg-background-inkWhite-white_0': !navigationItem?.is_hidden,
                'bg-background-inkWhite-white_1': navigationItem?.is_hidden,
            })}
        >
            <div className="w-full py-tiny px-mini mb-tiny flex justify-between">
                <div className="flex items-center">
                    <LinkOutlined className="mr-tiny" />
                    <h6 className="tiny semibold">{getLinkType()}</h6>
                </div>
            </div>
            <div className="p-mini">
                <div className="flex items-center justify-between ">
                    <div className="flex items-center">
                        {isDraggable && <AppstoreOutlined className="mr-mini" />}

                        <Form
                            name={`navigation-item-form-${navigationItem?.id}`}
                            initialValues={{
                                name: navigationItem?.name,
                            }}
                            onFinish={updateNavItem}
                        >
                            <Form.Item
                                name="name"
                                className="mb-none"
                                rules={[{ required: true, message: 'Please add a navigation name' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="flex items-center">
                        <Switch
                            defaultChecked={!navigationItem?.is_hidden}
                            onChange={(checked) => toggleVisibility(navigationItem.id, !checked)}
                            unCheckedChildren="Off"
                            checkedChildren="On"
                            disabled={isDisabled}
                            className="ml-small"
                        />
                    </div>
                </div>
            </div>
        </div>
    ) : null;
}

export default DraggableNavigationItem;
