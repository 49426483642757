import { Modal, TabsProps, Tabs } from 'antd';
import CustomPage from './CustomPage';
import StaticPage from './StaticPage';

interface Props {
    open: boolean;
    toggle: () => void;
}

function PageModal(props: Props): JSX.Element | null {
    const { open, toggle } = props;

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Custom',
            children: <CustomPage />,
        },
        {
            key: '2',
            label: 'Static',
            children: <StaticPage />,
        },
    ];

    return (
        <Modal title="Add Page" open={open} onCancel={() => toggle()} footer={null}>
            <Tabs items={items} />
        </Modal>
    );
}

export default PageModal;
