import { useState } from 'react';
import { Spin } from 'antd';
import { SECTION_TYPES } from '../../../../../exports/Enums';
import ElementCard from '../../../../cards/ElementCard';
import SectionManager from '../../../../../services/api/SectionManager';
import useModals from '../../../../../hooks/useModals';
import ElementsWrapper from '../../tabs/ElementsWrapper';

function Youtube(): JSX.Element {
    const { featureModal, setFeatureModal } = useModals();

    const pageId = featureModal?.pageId;

    const [loading, setLoading] = useState<boolean>(false);

    const s3Url = (type: number): string => `${import.meta.env.VITE_S3_PROD_BASE_URL}/sections/youtube/${type}.png`;

    const create = async (): Promise<void> => {
        if (typeof pageId !== 'number') {
            return;
        }

        setLoading(true);
        const { success } = await SectionManager.post(pageId, {
            type: SECTION_TYPES.YOUTUBE_SECTION,
            name: 'Youtube Videos',
            content: {
                urls: [],
            },
        });

        setLoading(false);

        if (success) {
            setFeatureModal({ open: false });
        }
    };

    if (loading) {
        return (
            <div className="w-full h-full flex justify-center items-center">
                <Spin />
            </div>
        );
    }

    return (
        <ElementsWrapper>
            <ElementCard title="1" url={s3Url(1)} isActive onClick={create} />
        </ElementsWrapper>
    );
}

export default Youtube;
