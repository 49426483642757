import ReactDOM from 'react-dom/client';
import './styles/index.css';
import 'antd/dist/reset.css';
import 'react-phone-number-input/style.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { CookiesProvider } from 'react-cookie';
import App from './App';
import { store, persistor } from './store';
import reportWebVitals from './reportWebVitals';
import AuthProvider from './providers/AuthProvider';
import AppCookiesProvider from './providers/CookiesProvider';
import './services/localization/Localization';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <CookiesProvider>
                <AppCookiesProvider>
                    <AuthProvider>
                        <App />
                    </AuthProvider>
                </AppCookiesProvider>
            </CookiesProvider>
        </PersistGate>
    </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
