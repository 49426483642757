import { Form, Input, Button, Drawer, Switch, Select, Divider, Alert } from 'antd';
import { useState } from 'react';
import collect from 'collect.js';
import { ScriptInterface } from '../../exports/Interfaces';
import useScreenType from '../../hooks/useScreenType';
import ScriptManager from '../../services/api/script/ScriptManager';
import { SCRIPT_STRATEGIES } from '../../exports/Enums';

interface Props {
    script: ScriptInterface | null;
    toggle: () => void;
}

function ScriptModal(props: Props): JSX.Element {
    const { script, toggle } = props;

    const isUpdate = !!script;

    const { isDesktop } = useScreenType();

    const [form] = Form.useForm();

    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = async (): Promise<void> => {
        const values = await form.getFieldsValue();
        setLoading(true);
        let request;
        if (isUpdate) {
            request = await ScriptManager.put(script.id, values);
        } else {
            request = await ScriptManager.post(values);
        }

        setLoading(false);

        const { success } = request;

        if (success) {
            toggle();
        }
    };

    const strategyOptions = collect(SCRIPT_STRATEGIES)
        .values()
        .map((value) => ({
            label: value,
            value,
        }))
        .toArray() as {
        label: string;
        value: string;
    }[];

    return (
        <Drawer
            title={isUpdate ? 'Update Script' : 'Add Script'}
            open
            onClose={() => toggle()}
            footer={null}
            width={isDesktop ? window.innerWidth * 0.3 : window.innerWidth}
            extra={
                <Button type="primary" loading={loading} onClick={onFinish}>
                    Save
                </Button>
            }
        >
            <Form form={form} name="basic" onFinish={onFinish} layout="vertical" initialValues={script ?? {}}>
                <Form.Item
                    name="strategy"
                    label="Strategy"
                    initialValue={script?.strategy ?? SCRIPT_STRATEGIES.BEFORE_INTERACTIVE}
                >
                    <Select options={strategyOptions} placeholder="Select Strategy" />
                </Form.Item>
                <Divider />
                <Alert
                    message="Use case"
                    type="info"
                    showIcon
                    description={'<script async src="https://www.googletagmanager.com/gtag/js?id=G-AAA"/>'}
                    className="mb-small"
                />

                <Form.Item name="src" label="Source (src)">
                    <Input placeholder="https://www.googletagmanager.com/gtag/js?id=G-AAA" />
                </Form.Item>

                <Form.Item name="async" label="Async">
                    <Switch defaultChecked={script?.async} />
                </Form.Item>
                <Divider />
                <Alert
                    message="Use case"
                    type="info"
                    showIcon
                    description={
                        '<script> window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag("js", new Date()); gtag("config", "G-AAA"; </script>'
                    }
                    className="mb-small"
                />
                <p>{'<script>'}</p>
                <Form.Item name="content" className="mb-none">
                    <Input.TextArea
                        rows={10}
                        placeholder='window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag("js", new Date()); gtag("config", "G-AAA";'
                    />
                </Form.Item>
                <p>{'</script>'}</p>
            </Form>
        </Drawer>
    );
}

export default ScriptModal;
