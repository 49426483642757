export default class CookieResource {
    public static getCookies(): { [key: string]: string } {
        try {
            const allCookies = document.cookie;
            const cookiesObject: { [key: string]: string } = {};
            allCookies.split('; ').forEach((cookie) => {
                const [name, value] = cookie.split('=');
                cookiesObject[name] = decodeURIComponent(value);
            });

            return cookiesObject;
        } catch (error) {
            return {};
        }
    }

    public static getCookie(key: string): string {
        try {
            return this.getCookies()[key];
        } catch (error) {
            return '';
        }
    }
}
