import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { OnboardingStepInterface } from '../../../exports/Interfaces';

function StepItem(props: OnboardingStepInterface): JSX.Element {
    const { title, checked, isLastStep, path } = props;

    const navigate = useNavigate();

    return (
        <div className="flex justify-center relative">
            <div className="h-full absolute top-1/2 -mt-[13px] left-0 z-10">
                <div className="flex flex-col items-center h-full">
                    <div
                        className={classnames('h-[10px] w-[10px] rounded-full', {
                            'bg-brand-primary': checked,
                            'bg-brand-inkGrey-grey_2': !checked,
                        })}
                    />
                    {!isLastStep && <div className="h-full w-[2px] bg-brand-inkGrey-grey_2" />}
                </div>
            </div>
            <button
                className={classnames(
                    'ml-medium mb-small w-full p-small border border-brand-inkGrey-grey_2 border-solid border-small rounded-md shadow-section bg-background-inkWhite-white_0 flex justify-between items-start',
                    {
                        'border-brand-primary': checked,
                    },
                )}
                onClick={() => navigate(path)}
                type="button"
            >
                <div>
                    <h6>{title}</h6>
                </div>
            </button>
        </div>
    );
}

export default StepItem;
