import { DataWrapper, Response, PromoCodeSettingInterface } from '../../../exports/Interfaces';
import BackendApiClient from '../BackendApiClient';

export default class MarketingManager {
    public static getPromoCodeSetting = async (
        slug: string,
    ): Promise<Response<DataWrapper<DataWrapper<PromoCodeSettingInterface>>>> =>
        BackendApiClient.request<DataWrapper<DataWrapper<PromoCodeSettingInterface>>>({
            method: 'GET',
            url: `/api/franchises/${slug}/marketing-settings`,
            baseURL: import.meta.env.VITE_AMIGO_BACKEND_API_BASE_URL,
        });
}
