import { message } from 'antd';
import { DataWrapper, Response, StoreInterface } from '../../../exports/Interfaces';
import actionCreators from '../../../store/actions';
import ReduxResource from '../../resources/ReduxResource';
import BackendApiClient from '../BackendApiClient';

export default class EventManager {
    public static put = async (data?: {
        has_events: boolean;
    }): Promise<Response<DataWrapper<DataWrapper<StoreInterface>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<StoreInterface>>>({
            method: 'PUT',
            url: `/toggle-events`,
            data,
        });

        const { response, success } = request;

        if (response?.data?.data && success) {
            EventManager.save(response?.data?.data);
            message.success(response?.data?.data?.has_reservations ? 'Events Activated' : 'Events Deactivated');
        } else {
            message.error('Update failed');
        }

        return request;
    };

    private static save = (store: StoreInterface): void => {
        ReduxResource.dispatch(actionCreators.store.setAll(store));
    };
}
