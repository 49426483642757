import BaseCurrencyInput from 'react-currency-input-field';

interface Props {
    id: string;
    value?: string;
    onChange: (value?: string) => void;
    disabled?: boolean;
    intlConfig?: any;
}

function CurrencyInput({ value, onChange, disabled, intlConfig, id }: Props): JSX.Element {
    const handleChange = (_value?: string): void => {
        onChange(_value);
    };

    return (
        <BaseCurrencyInput
            id={id}
            value={value}
            disabled={disabled}
            defaultValue="0.00"
            onValueChange={handleChange}
            decimalSeparator="."
            disableGroupSeparators
            intlConfig={intlConfig ?? { locale: 'en-IN', currency: 'EUR' }}
            transformRawValue={(_value: string) => _value.replace(',', '.')}
            className="py-tiny px-mini rounded-md w-full border-[1px] border-solid border-brand-inkGrey-grey_3"
        />
    );
}

export default CurrencyInput;
