import { Form, Input, Button, DatePicker, Select, Drawer } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import collect from 'collect.js';
import dayjs from 'dayjs';
import { LinkInterface, MediaInterface, PageInterface, PopupInterface } from '../../../../exports/Interfaces';
import PopupManager from '../../../../services/api/PopupManager';
import { DB_MODELS, LINK_IDENTIFIERS, MEDIA_TAGS, STOREFRONT_PAGES } from '../../../../exports/Enums';
import MediaManager from '../../../../services/api/MediaManager';
import LinkManager from '../../../../services/api/LinkManager';
import LinkInput from '../../../links/LinkInput';
import SelectedMediaList from '../../../media/SelectedMediaList';
import useScreenType from '../../../../hooks/useScreenType';
import useCurrency from '../../../../hooks/useCurrency';
import useCustomTranslation from '../../../../hooks/useCustomTranslation';
import useRdxStore from '../../../../hooks/useRdxStore';
import useModals from '../../../../hooks/useModals';
import InitialDataManager from '../../../../services/api/InitialDataManager';

const { TextArea } = Input;

function Popup(): JSX.Element | null {
    const { popupModal, setPopupModal } = useModals();

    const { open } = popupModal;

    const popup = popupModal?.popup;

    const { store } = useRdxStore();

    const { t } = useCustomTranslation({ keyPrefix: 'Components:Modals:PopupModal' });

    const { isDesktop } = useScreenType();

    const { getPrice } = useCurrency();

    const isUpdate = !!popup;

    const [form] = Form.useForm();

    const startDate = Form.useWatch('start_date', form);

    const expirationDate = Form.useWatch('expiration_date', form);

    const [loading, setLoading] = useState<boolean>(false);

    const [mediaList, setMediaList] = useState<MediaInterface[]>([]);

    const [firstButtonLink, setFirstButtonLink] = useState<LinkInterface | null>(null);

    const [secondButtonLink, setSecondButtonLink] = useState<LinkInterface | null>(null);

    const [mounted, setMounted] = useState<boolean>(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        setFirstButtonLink(collect(popup?.links ?? []).firstWhere('identifier', LINK_IDENTIFIERS.FIRST));
        setSecondButtonLink(collect(popup?.links ?? []).firstWhere('identifier', LINK_IDENTIFIERS.SECOND));
        setMediaList(popup?.image ? [popup?.image] : []);
    }, [popup]);

    const onFinish = async (values: any): Promise<void> => {
        setLoading(true);
        const data: Partial<PopupInterface> = {
            ...(collect(values)
                ?.filter((value) => value !== undefined)
                .all() as Partial<PopupInterface>),
            page_id: values?.page_id ?? null,
            start_date: values?.start_date ? dayjs(values?.start_date)?.format('YYYY-MM-DD') : null,
            expiration_date: values?.expiration_date ? dayjs(values?.expiration_date)?.format('YYYY-MM-DD') : null,
        };

        let request;
        if (typeof popup?.id === 'number') {
            request = await PopupManager.put(popup?.id, data);
        } else {
            request = await PopupManager.post(data);
        }

        const { response, success } = request;

        if (success && response?.data?.data) {
            await MediaManager.post(DB_MODELS.POPUP, response?.data?.data?.id, mediaList, MEDIA_TAGS.IMAGES, '');

            await LinkManager.post(
                DB_MODELS.POPUP,
                response?.data?.data?.id,
                collect([firstButtonLink, secondButtonLink])
                    ?.filter((value) => !!value?.title)
                    ?.toArray() as LinkInterface[],
            );

            await InitialDataManager.get();
        }

        setLoading(false);

        if (success) {
            setPopupModal({ open: false });
            if (popupModal?.onSuccess) {
                popupModal.onSuccess();
            }
        }
    };

    function pageIncluded(page: PageInterface): boolean {
        switch (page?.href) {
            case STOREFRONT_PAGES.LOCATION_AND_HOURS:
            case STOREFRONT_PAGES.CATERING:
            case STOREFRONT_PAGES.EVENTS:
            case STOREFRONT_PAGES.RESERVATIONS:
                return false;
            default:
                return true;
        }
    }

    interface OptionInterface extends PageInterface {
        label: string;
        value: number | null;
    }

    const pageOptions = useMemo(
        () =>
            collect(store?.pages)
                .map((page) => ({
                    ...page,
                    value: page.id,
                    label: collect(store?.navigation_items).where('page.id', page.id).where('section_id', null).first()
                        ?.name,
                }))
                .filter((page) => pageIncluded(page))
                .toArray(),
        [store?.pages],
    ) as OptionInterface[];

    return mounted ? (
        <Drawer
            title={isUpdate ? 'Update Popup' : 'Add Popup'}
            open={open}
            onClose={() => setPopupModal({ open: false })}
            footer={null}
            width={isDesktop ? window.innerWidth * 0.3 : window.innerWidth}
            extra={
                <Form.Item className="mb-none">
                    <Button type="primary" onClick={() => onFinish(form.getFieldsValue())} loading={loading}>
                        Save
                    </Button>
                </Form.Item>
            }
        >
            <Form
                name="basic"
                onFinish={onFinish}
                layout="vertical"
                initialValues={{
                    ...popup,
                    title: isUpdate ? popup?.title : t('title', { price: getPrice(10, 0) }),
                    description: isUpdate ? popup?.description : t('description', { price: getPrice(10, 0) }),
                    start_date: popup?.start_date ? dayjs(popup?.start_date) : null,
                    expiration_date: popup?.expiration_date ? dayjs(popup?.expiration_date) : null,
                    page_id: popup?.page_id ?? popupModal?.pageId ?? collect(pageOptions).first()?.id,
                }}
                form={form}
            >
                <Form.Item name="page_id" label="Page">
                    <Select options={pageOptions} />
                </Form.Item>
                <div className="w-full my-small">
                    <SelectedMediaList
                        uploadCount={1}
                        defaultMediaList={popup?.image ? [popup?.image] : []}
                        mediaList={mediaList}
                        setMediaList={setMediaList}
                    />
                </div>

                <Form.Item name="title" label="Title" rules={[{ required: true, message: 'Please add a title' }]}>
                    <TextArea placeholder="Enter Title" autoSize={{ minRows: 3, maxRows: 5 }} />
                </Form.Item>

                <Form.Item name="description" label="Description">
                    <TextArea placeholder="Enter Description" autoSize={{ minRows: 3, maxRows: 5 }} />
                </Form.Item>

                <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                    <Form.Item name="start_date" label="Start Date">
                        <DatePicker
                            className="w-full"
                            disabledDate={(current) =>
                                (current && current?.isBefore(dayjs()?.startOf('day'))) ||
                                (expirationDate &&
                                    (current >= expirationDate || current?.isSame(expirationDate, 'day')))
                            }
                            format="DD/MM/YYYY"
                        />
                    </Form.Item>

                    <Form.Item name="expiration_date" label="Expiration Date">
                        <DatePicker
                            className="w-full"
                            disabledDate={(current) =>
                                (current && current?.isBefore(dayjs()?.startOf('day'))) ||
                                current?.isSame(startDate, 'day') ||
                                (startDate && current <= startDate)
                            }
                            format="DD/MM/YYYY"
                        />
                    </Form.Item>
                </div>

                <div className="mb-small">
                    <LinkInput
                        label="First Button"
                        identifier={LINK_IDENTIFIERS.FIRST}
                        link={firstButtonLink}
                        setLink={setFirstButtonLink}
                    />
                </div>

                <div className="mb-small">
                    <LinkInput
                        label="Second Button"
                        identifier={LINK_IDENTIFIERS.SECOND}
                        link={secondButtonLink}
                        setLink={setSecondButtonLink}
                    />
                </div>
            </Form>
        </Drawer>
    ) : null;
}

export default Popup;
