import SectionLayout from './SectionLayout';

interface Props {
    children: React.ReactNode;
    title?: string | JSX.Element;
    centerContent?: JSX.Element;
}

function RestaurantInfoLayout(props: Props): JSX.Element {
    const { children, title, centerContent } = props;

    return (
        <SectionLayout title={title ?? 'Edit'} hideClose centerContent={centerContent}>
            {children}
        </SectionLayout>
    );
}

export default RestaurantInfoLayout;
