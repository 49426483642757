import { useEffect, useState } from 'react';

function UnauthorizedPage(): JSX.Element {
    const [timer, setTimer] = useState(3);

    useEffect(() => {
        const countdown = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
        }, 1000);

        const redirectTimeout = setTimeout(() => {
            window.location.href = import.meta.env.VITE_ADMIN_DASHBOARD_URL ?? '';
        }, 3000);

        return () => {
            clearInterval(countdown);
            clearTimeout(redirectTimeout);
        };
    }, []);

    return (
        <div className="h-screen w-screen flex flex-col justify-center items-center">
            <h1 className="text-center">Unauthorized</h1>
            <p className="text-center text-brand-text-grey">Redirecting in {timer} seconds...</p>
        </div>
    );
}

export default UnauthorizedPage;
