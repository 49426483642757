import { SECTION_TYPES } from '../../exports/Enums';
import HeroSectionForm from './heroSection/HeroSectionForm';
import CustomizableSectionForm from './customizableSection/CustomizableSectionForm';
import GallerySectionForm from './gallerySection/GallerySectionForm';
import GoogleReviewSectionForm from './googleReviewSection/GoogleReviewSectionForm';
import MenuSectionForm from './menuSection/MenuSectionForm';
import ProductsSectionForm from './productsSection/ProductsSectionForm';
import YoutubeSectionForm from './youtubeSection/YoutubeSectionForm';
import useModals from '../../hooks/useModals';
import IFrameSectionForm from './iframe/IFrameSectionForm';
import LocationTrackerSectionForm from './locationTrackerSection/LocationTrackerSectionForm';
import AnimatedSectionForm from './animatedSection/AnimatedSectionForm';
import TimeLineSectionForm from './timeLineSection/TimeLineSectionForm';
import FAQSectionForm from './faqSection/FAQSectionForm';

function EditSection(): JSX.Element | null {
    const { sectionModal } = useModals();

    const section = sectionModal?.section;

    switch (section?.type) {
        case SECTION_TYPES.HERO_SECTION:
            return <HeroSectionForm section={section} />;
        case SECTION_TYPES.CUSTOMIZABLE_SECTION:
            return <CustomizableSectionForm section={section} />;
        case SECTION_TYPES.GALLERY_SECTION:
            return <GallerySectionForm section={section} />;
        case SECTION_TYPES.GOOGLE_REVIEW_SECTION:
            return <GoogleReviewSectionForm section={section} />;
        case SECTION_TYPES.MENU_SECTION:
            return <MenuSectionForm section={section} />;
        case SECTION_TYPES.PRODUCT_SECTION:
            return <ProductsSectionForm section={section} />;
        case SECTION_TYPES.YOUTUBE_SECTION:
            return <YoutubeSectionForm section={section} />;
        case SECTION_TYPES.IFRAME_SECTION:
            return <IFrameSectionForm section={section} />;
        case SECTION_TYPES.LOCATION_TRACKER_SECTION:
            return <LocationTrackerSectionForm section={section} />;
        case SECTION_TYPES.ANIMATED_SECTION:
            return <AnimatedSectionForm section={section} />;
        case SECTION_TYPES.TIME_LINE_SECTION:
            return <TimeLineSectionForm section={section} />;
        case SECTION_TYPES.FAQ_SECTION:
            return <FAQSectionForm section={section} />;
        default:
            return null;
    }
}

export default EditSection;
