import { Tabs, TabsProps, Alert, Spin, Button, Tag } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import collect from 'collect.js';
import { CheckCircleOutlined } from '@ant-design/icons';
import { DomainConfigInterface } from '../../exports/Interfaces';
import DomainManager from '../../services/api/domain/DomainManager';
import useRdxStore from '../../hooks/useRdxStore';
import DNSRecord from './DNSRecord';

interface Props {
    record: 'CNAME' | 'A';
}

function VercelConfig(props: Props): JSX.Element {
    const { record } = props;

    const { store } = useRdxStore();

    const [loading, setLoading] = useState<boolean>(false);

    enum TABS {
        RECORD = 'record',
        NAME_SERVERS = 'name_servers',
    }

    const [tab, setTab] = useState<TABS>(TABS.RECORD);

    const [data, setData] = useState<DomainConfigInterface | null>(null);

    const [refreshing, setRefreshing] = useState<boolean>(false);

    const isARecord = record === 'A';

    const domain = isARecord ? store?.domain : `www.${store?.domain}`;

    const vercelNameServers = ['ns1.vercel-dns.com', 'ns2.vercel-dns.com'];

    const requestDomainConfig = async (withLoading = true): Promise<void> => {
        if (withLoading) {
            setLoading(true);
        }
        const { response, success } = await DomainManager.getConfig(domain);
        setLoading(false);
        if (success && response?.data?.data) {
            setData(response.data.data);
        }
    };

    useEffect(() => {
        requestDomainConfig();
    }, []);

    const refresh = async (): Promise<void> => {
        setRefreshing(true);
        await requestDomainConfig(false);
        setRefreshing(false);
    };

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (data?.misconfigured) {
            const intervalId = setInterval(refresh, 10000);

            return () => clearInterval(intervalId);
        }
    }, [data?.misconfigured]);

    const hasCorrectNameServers = (): boolean =>
        collect(data?.nameservers).count() === vercelNameServers.length &&
        vercelNameServers.every((nameServer: string) => data?.nameservers?.includes(nameServer));

    function renderConfigurationStatus(): JSX.Element {
        if (data?.misconfigured) {
            return (
                <Alert
                    showIcon
                    type="error"
                    message="Invalid Configuration"
                    description={
                        <div>
                            <p className="mini">It takes up to 24hou rs for DNS records to propagate</p>
                            {collect(data?.conflicts).isNotEmpty() && (
                                <div className="mt-small">
                                    {data.conflicts.map((conflict, index) => (
                                        <div
                                            className={classnames({
                                                'mb-small': collect(data.conflicts).count() - 1 !== index,
                                            })}
                                        >
                                            <DNSRecord record={conflict} />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    }
                />
            );
        }
        return <Alert showIcon message="Valid Configuration" type="success" />;
    }

    const records = useMemo(
        () => (
            <div className="h-full w-full">
                <div className="mb-small">{renderConfigurationStatus()}</div>
                <div className="mb-small">
                    <p className="mini">Set the following record on your DNS provider to continue:</p>
                </div>

                <DNSRecord
                    record={{
                        type: isARecord ? 'A' : 'CNAME',
                        name: isARecord ? '@' : 'www',
                        value: isARecord ? '76.76.21.21' : 'cname.vercel-dns.com.',
                    }}
                />
            </div>
        ),
        [data, loading],
    );

    const nameServers = useMemo(
        () => (
            <div>
                <div className="mb-small">
                    <div className="mb-small">{renderConfigurationStatus()}</div>
                    <p className="mini">Set the nameservers of {domain} (apex domain) to:</p>
                    <div className="flex flex-col py-mini px-small rounded-md border border-solid border-brand-inkGrey-grey_2 mt-small">
                        {vercelNameServers.map((nameServer) => (
                            <p className="mini">{nameServer}</p>
                        ))}
                    </div>
                </div>

                {data?.misconfigured && (
                    <div className="mb-small">
                        <p className="mini">Current nameservers:</p>
                        <div
                            className={classnames(
                                'flex flex-col py-mini px-small rounded-md border border-solid border-brand-inkGrey-grey_2 mt-small',
                                {
                                    'border-brand-danger': !hasCorrectNameServers(),
                                },
                            )}
                        >
                            {data?.nameservers?.map((nameServer) => (
                                <p className="mini">{nameServer}</p>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        ),
        [data],
    );

    const items: TabsProps['items'] = [
        {
            key: TABS.RECORD,
            label: `${isARecord ? 'A' : 'CNAME'} (Recommended)`,
            children: records,
        },
        {
            key: TABS.NAME_SERVERS,
            label: 'Nameservers',
            children: nameServers,
        },
    ];

    function renderContent(): JSX.Element {
        if (!data?.misconfigured) {
            return (
                <div className="w-full flex items-center justify-between">
                    <h6>{domain}</h6>
                    <Tag icon={<CheckCircleOutlined />} color="success">
                        Connected
                    </Tag>
                </div>
            );
        }
        return (
            <div>
                <div className="w-full flex items-center justify-between">
                    <h6>{domain}</h6>
                    <Button loading={refreshing} disabled={refreshing} onClick={refresh}>
                        Refresh
                    </Button>
                </div>
                <Tabs defaultActiveKey={tab} activeKey={tab} onChange={(key) => setTab(key as TABS)} items={items} />
            </div>
        );
    }

    return (
        <div className="mb-large shadow bg-background-inkWhite-white_0 p-small rounded-lg">
            {loading ? <Spin /> : renderContent()}
        </div>
    );
}

export default VercelConfig;
