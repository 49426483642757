import { Helmet } from 'react-helmet';
import useRdxStore from '../hooks/useRdxStore';
import Favicon from '../assets/images/favicons/index';

interface Props {
    children: JSX.Element;
}
function HelmetProvider(props: Props): JSX.Element {
    const { children } = props;

    const { store } = useRdxStore();

    return (
        <>
            <div className="application">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Website Editor | {store?.name ?? 'FoodAmigos'}</title>
                    <meta name="description" content="Website Editor" />
                    <link rel="icon" id="favicon" href={Favicon} />
                    <meta property="og:image" content={Favicon} />
                    <meta name="twitter:image" content={Favicon} />
                </Helmet>
            </div>
            {children}
        </>
    );
}

export default HelmetProvider;
