import { Form } from 'antd';
import { useState } from 'react';
import useModals from '../../../../../hooks/useModals';
import {
    SectionInterface,
    AnimatedSectionInterface,
    AnimatedSectionFormPropsInterface,
    AnimatedSectionDataTwo,
} from '../../../../../exports/Interfaces';
import SectionManager from '../../../../../services/api/SectionManager';
import SectionForm from '../../../../forms/SectionForm';
import LottieSelect from '../../../../selects/LottieSelect';

function Two(props: AnimatedSectionFormPropsInterface<AnimatedSectionDataTwo>): JSX.Element {
    const { section } = props;

    const { setSectionModal } = useModals();

    const [form] = Form.useForm();

    const { content } = section;

    const [loading, setLoading] = useState<boolean>(false);

    const onSave = async (values: Partial<SectionInterface<AnimatedSectionInterface>>): Promise<void> => {
        if (typeof section?.id !== 'number') {
            return;
        }

        const { success } = await SectionManager.put<AnimatedSectionInterface>(section?.id, values);

        setLoading(false);
        if (success) {
            setSectionModal({ open: false });
        }
    };

    return (
        <SectionForm
            form={form}
            section={section}
            onSave={onSave}
            loading={loading}
            hideHeader
            initialValues={section}
            styleContent={<div />}
        >
            <Form.Item name={['content', 'type']} className="hidden" />
            <Form.Item name={['content', 'data', 'lottie_props']}>
                <LottieSelect
                    lottieProps={content?.data?.lottie_props}
                    setLottieProps={(values) => form.setFieldValue(['content', 'data', 'lottie_props'], values)}
                />
            </Form.Item>
        </SectionForm>
    );
}

export default Two;
