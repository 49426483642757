import { message } from 'antd';
import { DB_MODELS } from '../../exports/Enums';
import { DataWrapper, LinkInterface, Response } from '../../exports/Interfaces';
import BackendApiClient from './BackendApiClient';

export default class LinkManager {
    public static post = async (
        model: DB_MODELS,
        modelId: number,
        data: Partial<LinkInterface[]>,
    ): Promise<Response<DataWrapper<DataWrapper<LinkInterface[]>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<LinkInterface[]>>>({
            method: 'POST',
            url: `/links/${model}/${modelId}`,
            data,
        });

        const { success } = request;
        if (!success) {
            message.error('Failed to add button link');
        }
        return request;
    };
}
