import classnames from 'classnames';
import { Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import useTheme from '../../hooks/useTheme';
import { SeoCriteriaInterface } from '../../exports/Interfaces';

function SeoCriteriaItem(props: SeoCriteriaInterface): JSX.Element {
    const { criteria, values, successMessage, checked, required, href, className } = props;

    const theme = useTheme();

    function renderLabel(): JSX.Element {
        const getLabelColor = (): string => {
            if (checked) {
                return theme.color.brand.success;
            }
            if (required) {
                return theme.color.brand.danger;
            }
            return theme.color.brand.warning;
        };

        const getLabelText = (): string => {
            if (checked) {
                return 'Done';
            }
            if (required) {
                return 'Fix';
            }
            return 'Recommended';
        };

        return (
            <div
                className="ml-small text-white px-mini rounded-full h-fit"
                style={{
                    backgroundColor: getLabelColor(),
                }}
            >
                <h6 className="mini">{getLabelText()}</h6>
            </div>
        );
    }

    return (
        <div
            className={classnames(
                'flex w-full border border-solid border-r-none border-l-none border-t-none py-small border-brand-inkGrey-grey_2',
                className,
            )}
        >
            <div className="flex flex-1 flex-col">
                <p>{checked ? successMessage : criteria}</p>
                <div>
                    {values?.map((value) => (
                        <p className="text-brand-text-grey mini">{value}</p>
                    ))}
                </div>
            </div>
            {href && <Button href={href} icon={<ArrowRightOutlined />} />}
            {renderLabel()}
        </div>
    );
}

export default SeoCriteriaItem;
