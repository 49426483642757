import { FontInterface } from '../exports/Interfaces';

const s3Url = (url: string): string => `${import.meta.env.VITE_S3_PROD_BASE_URL}/custom-fonts${url}`;

const customFonts: FontInterface[] = [
    {
        name: 'WickedGrit',
        url: s3Url('/WickedGrit/WickedGrit.ttf'),
    },
    {
        name: 'ALoveOfThunder',
        url: s3Url('/ALoveOfThunder/ALoveOfThunder.ttf'),
    },
    {
        name: 'FuturaPT-Bold',
        url: s3Url('/FuturaPT/FuturaPT-Bold.ttf'),
    },
    {
        name: 'FuturaPT-BoldOblique',
        url: s3Url('/FuturaPT/FuturaPT-BoldOblique.ttf'),
    },
    {
        name: 'FuturaPT-BookOblique',
        url: s3Url('/FuturaPT/FuturaPT-BookOblique.ttf'),
    },
    {
        name: 'FuturaPT-DemiBold',
        url: s3Url('/FuturaPT/FuturaPT-DemiBold.ttf'),
    },
    {
        name: 'FuturaPT-DemiBoldOblique',
        url: s3Url('/FuturaPT/FuturaPT-DemiBoldOblique.ttf'),
    },
    {
        name: 'FuturaPT-HeavyOblique',
        url: s3Url('/FuturaPT/FuturaPT-HeavyOblique.ttf'),
    },
    {
        name: 'FuturaPT-LightOblique',
        url: s3Url('/FuturaPT/FuturaPT-LightOblique.ttf'),
    },
    {
        name: 'FuturaPT-MediumOblique',
        url: s3Url('/FuturaPT/FuturaPT-MediumOblique.ttf'),
    },
    {
        name: 'Hagrid2',
        url: s3Url('/HagridPT/Hagrid2.ttf'),
    },
    {
        name: 'HagridTrialRegular',
        url: s3Url('/HagridPT/Hagrid-Trial-Regular.ttf'),
    },
    {
        name: 'HagridTrialItalic',
        url: s3Url('/HagridPT/Hagrid-Trial-Italic.ttf'),
    },
    {
        name: 'HagridTextTrialExtrabold',
        url: s3Url('/HagridPT/Hagrid-Text-Trial-Extrabold.ttf'),
    },
    {
        name: 'HagridTextTrialExtrabold-Italic',
        url: s3Url('/HagridPT/Hagrid-Text-Trial-Extrabold-Italic.ttf'),
    },
    {
        name: 'SweetSansProThinItalic',
        url: s3Url('/SweetSans/SweetSansPro-ThinItalic.ttf'),
    },
    {
        name: 'SweetSansProThin',
        url: s3Url('/SweetSans/SweetSansPro-Thin.ttf'),
    },
    {
        name: 'SweetSansProRegular',
        url: s3Url('/SweetSans/SweetSansPro-Regular.ttf'),
    },
    {
        name: 'SweetSansProMediumItalic',
        url: s3Url('/SweetSans/SweetSansPro-MediumItalic.ttf'),
    },
    {
        name: 'SweetSansProLightItalic',
        url: s3Url('/SweetSans/SweetSansPro-LightItalic.ttf'),
    },
    {
        name: 'SweetSansProLight',
        url: s3Url('/SweetSans/SweetSansPro-Light.ttf'),
    },
    {
        name: 'SweetSansProItalic',
        url: s3Url('/SweetSans/SweetSansPro-Italic.ttf'),
    },
    {
        name: 'SweetSansProHeavyItalic',
        url: s3Url('/SweetSans/SweetSansPro-HeavyItalic.ttf'),
    },
    {
        name: 'SweetSansProHeavy',
        url: s3Url('/SweetSans/SweetSansPro-Heavy.ttf'),
    },
    {
        name: 'SweetSansProHairlineItalic',
        url: s3Url('/SweetSans/SweetSansPro-HairlineItalic.ttf'),
    },
    {
        name: 'SweetSansProHairline',
        url: s3Url('/SweetSans/SweetSansPro-Hairline.ttf'),
    },
    {
        name: 'SweetSansProExThinItalic',
        url: s3Url('/SweetSans/SweetSansPro-ExThinItalic.ttf'),
    },
    {
        name: 'SweetSansProExThin',
        url: s3Url('/SweetSans/SweetSansPro-ExThin.ttf'),
    },
    {
        name: 'SweetSansProExLightItalic',
        url: s3Url('/SweetSans/SweetSansPro-ExLightItalic.ttf'),
    },
    {
        name: 'SweetSansProExLight',
        url: s3Url('/SweetSans/SweetSansPro-ExLight.ttf'),
    },
    {
        name: 'SweetSansProBoldItalic',
        url: s3Url('/SweetSans/SweetSansPro-BoldItalic.ttf'),
    },
    {
        name: 'SweetSansProBold',
        url: s3Url('/SweetSans/SweetSansPro-Bold.ttf'),
    },
    {
        name: 'SweetSansLight',
        url: s3Url('/SweetSans/SweetSans-Light.ttf'),
    },
    {
        name: 'SweetSansExLight',
        url: s3Url('/SweetSans/SweetSans-ExLight.ttf'),
    },
    {
        name: 'LexiaAdvertising',
        url: s3Url('/LexiaAdvertising/Lexia-Advertising.ttf'),
    },
    {
        name: 'GrillmasterNarrow',
        url: s3Url('/GrillmasterNarrow/Grillmaster-Narrow-Regular.ttf'),
    },
    {
        name: 'OrpheusPro',
        url: s3Url('/Orpheus/Orpheus-Pro.ttf'),
    },
    {
        name: 'NeueHaasDisplayBlack',
        url: s3Url('/NeueHaasGroteskDisplayPro/NeueHaasDisplayBlack.ttf'),
    },
    {
        name: 'NeueHaasDisplayBlackItalic',
        url: s3Url('/NeueHaasGroteskDisplayPro/NeueHaasDisplayBlackItalic.ttf'),
    },
    {
        name: 'NeueHaasDisplayBold',
        url: s3Url('/NeueHaasGroteskDisplayPro/NeueHaasDisplayBold.ttf'),
    },
    {
        name: 'NeueHaasDisplayBoldItalic',
        url: s3Url('/NeueHaasGroteskDisplayPro/NeueHaasDisplayBoldItalic.ttf'),
    },
    {
        name: 'NeueHaasDisplayLight',
        url: s3Url('/NeueHaasGroteskDisplayPro/NeueHaasDisplayLight.ttf'),
    },
    {
        name: 'NeueHaasDisplayLightItalic',
        url: s3Url('/NeueHaasGroteskDisplayPro/NeueHaasDisplayLightItalic.ttf'),
    },
    {
        name: 'NeueHaasDisplayMedium',
        url: s3Url('/NeueHaasGroteskDisplayPro/NeueHaasDisplayMediu.ttf'),
    },
    {
        name: 'NeueHaasDisplayMediumItalic',
        url: s3Url('/NeueHaasGroteskDisplayPro/NeueHaasDisplayMediumItalic.ttf'),
    },
    {
        name: 'NeueHaasDisplayRoman',
        url: s3Url('/NeueHaasGroteskDisplayPro/NeueHaasDisplayRoman.ttf'),
    },
    {
        name: 'NeueHaasDisplayRomanItalic',
        url: s3Url('/NeueHaasGroteskDisplayPro/NeueHaasDisplayRomanItalic.ttf'),
    },
    {
        name: 'NeueHaasDisplayThin',
        url: s3Url('/NeueHaasGroteskDisplayPro/NeueHaasDisplayThin.ttf'),
    },
    {
        name: 'NeueHaasDisplayThinItalic',
        url: s3Url('/NeueHaasGroteskDisplayPro/NeueHaasDisplayThinItalic.ttf'),
    },
    {
        name: 'NeueHaasDisplayXThin',
        url: s3Url('/NeueHaasGroteskDisplayPro/NeueHaasDisplayXThin.ttf'),
    },
    {
        name: 'NeueHaasDisplayXThinItalic',
        url: s3Url('/NeueHaasGroteskDisplayPro/NeueHaasDisplayXThinItalic.ttf'),
    },
    {
        name: 'NeueHaasDisplayXXThin',
        url: s3Url('/NeueHaasGroteskDisplayPro/NeueHaasDisplayXXThin.ttf'),
    },
    {
        name: 'NeueHaasDisplayXXThinItalic',
        url: s3Url('/NeueHaasGroteskDisplayPro/NeueHaasDisplayXXThinItalic.ttf'),
    },
    {
        name: 'OmnesBlack',
        url: s3Url('/Omnes/Omnes-Black.ttf'),
    },
    {
        name: 'OmnesBoldRegular',
        url: s3Url('/Omnes/Omnes-Bold-Regular.ttf'),
    },
    {
        name: 'OmnesBold',
        url: s3Url('/Omnes/Omnes-Bold.ttf'),
    },
    {
        name: 'OmnesExtraLight',
        url: s3Url('/Omnes/Omnes-ExtraLight.ttf'),
    },
    {
        name: 'OmnesLight',
        url: s3Url('/Omnes/Omnes-Light.ttf'),
    },
    {
        name: 'OmnesMedium',
        url: s3Url('/Omnes/Omnes-Medium.ttf'),
    },
    {
        name: 'OmnesRegularRegular',
        url: s3Url('/Omnes/Omnes-Regular-Regular.ttf'),
    },
    {
        name: 'OmnesRegular',
        url: s3Url('/Omnes/Omnes-Regular.ttf'),
    },
];

export default customFonts;
