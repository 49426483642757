import { ThemeInterface } from '../exports/Interfaces';
import customFonts from './Fonts';

const Theme: ThemeInterface = {
    fonts: {
        fontFamily: {
            inter: 'Inter',
        },
        customFonts,
        fontSizes: {
            h1: 54,
            h2: 36,
            h3: 24,
            h4: 20,
            h5: 18,
            h6: 16,
            p: 16,
            mini: 14,
            tiny: 12,
            nano: 10,
        },
        fontWeights: {
            light: 300,
            regular: 400,
            medium: 500,
            semiBold: 600,
            bold: 700,
        },
        fontLineHeight: {
            h1: 75,
            h2: 44,
            h3: 24,
            h4: 24,
            h5: 24,
            h6: 24,
            p: 24,
            mini: 19,
            tiny: 19,
            nano: 12,
        },
    },
    color: {
        background: {
            inkWhite: {
                white_0: '#FFFFFF',
                white_1: '#FBFBFC',
            },
            inkDisabled: {
                disabled_1: '#ECF4F7',
                disabled_2: '#D5ECD6',
                disabled_3: '#E7E7E7',
            },
            inkGreen: {
                green_0: '#DBFAE2',
                green_1: '#B9DDC2',
                green_2: '#3E734C',
            },
            inkBlack: {
                black_0: '#272727',
                black_1: '#0D0D0D',
                black_2: '#1A1A1A',
            },
        },
        companyBrand: {
            primary: '#69CE8C',
            secondary: '#161616',
            background_color: '#FBFBFC',
        },
        brand: {
            primary: '#69CE8C',
            secondary: '#161616',
            success: '#69CE8C',
            notice: '#FDF3BB',
            warning: '#F2C94C',
            danger: '#EB5757',
            inkGrey: {
                grey_0: '#F5F9FA',
                grey_1: '#ECF4F7',
                grey_2: '#DAE9EF',
                grey_3: '#A7BAC5',
                grey_4: '#5F6974',
                grey_5: '#E0E0E0',
                grey_6: '#F2F2F2',
            },
            inkGreen: {
                green_0: '#DBFAE2',
                green_1: '#B9DDC2',
                green_2: '#3E734C',
            },
            inkPrimary: {
                primary_1: '#F0FAF3',
                primary_2: '#E1F5E8',
                primary_3: '#D2F0DD',
                primary_4: '#C3EBD1',
                primary_5: '#B4E6C5',
                primary_6: '#A5E2BA',
                primary_7: '#96DDAE',
                primary_8: '#87D8A3',
                primary_9: '#78D398',
            },
            inkWarning: {
                warning_1: '#FED0DA',
            },
            inkDanger: {
                danger_4: '#F9E3E2',
                danger_9: '#EB5757',
            },
            themeColors: {
                color_1: '#D9F9E6',
                color_2: '#F2994A',
            },
            gradients: {
                primary: ['#69CE8C', '#6ED792'],
            },
        },
        text: {
            default: '#161616',
            grey: '#5F6974',
            light: '#FBFBFC',
            disabled: '#F2F2F2',
            red: '#EB5757',
            warning: '#F45B7F',
            blue: '#2F80ED',
        },
        gradients: {
            primary: ['#69CE8C', '#6ED792'],
            secondary: ['#0E0E0E', '#0E0E0E'],
            third: ['#ED489E', '#FF7CDA'],
            forth: ['#F7C13A', '#FFFB62'],
            fifth: ['#CBE3ED', '#CBE3ED'],
        },
    },
    dimension: {
        padding: {
            large: 32,
            medium: 24,
            small: 16,
            xsmall: 12,
            mini: 8,
            xmini: 6,
            tiny: 4,
            xTiny: 2,
            section: 40,
        },
        borderRadius: {
            large: 32,
            small: 16,
            mini: 8,
            tiny: 4,
        },
        borderWidth: {
            default: 1.5,
            small: 1,
            medium: 2,
            large: 3,
        },
        hitSlop: {
            regular: {
                top: 20,
                bottom: 20,
                left: 20,
                right: 20,
            },
        },
        breakPoints: {
            sm: 640,
            md: 768,
            lg: 1024,
            xl: 1280,
        },
    },
    shadows: {
        shading1: {
            elevation: 10,
            shadowColor: '#A7BAC5',
            shadowOffset: {
                height: 3,
                width: 0,
            },
            shadowRadius: 5,
            shadowOpacity: 0.4,
        },
        shading2: {
            elevation: 20,
            shadowColor: '#A7BAC5',
            shadowOffset: {
                height: 5,
                width: 0,
            },
            shadowRadius: 10,
            shadowOpacity: 0.4,
        },
    },

    hitSloop: {
        regular: {
            top: 10,
            bottom: 10,
            left: 10,
            right: 10,
        },
        medium: {
            top: 15,
            bottom: 15,
            left: 15,
            right: 15,
        },
        large: {
            top: 15,
            bottom: 15,
            left: 15,
            right: 15,
        },
    },
};

export default Theme;
