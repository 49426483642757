import { useState } from 'react';
import Content from '../Content';
import useRdxStore from '../../../hooks/useRdxStore';
import CateringSettingsForm from '../../../components/plugins/catering/settings/CateringSettingsForm';
import CateringManager from '../../../services/api/catering/CateringManager';
import PluginCard from '../../../components/cards/PluginCard';

function Catering(): JSX.Element {
    const { store } = useRdxStore();

    const [saving, setSaving] = useState<boolean>(false);

    const toggleCatering = async (checked: boolean): Promise<void> => {
        setSaving(true);
        CateringManager.put({ has_catering: checked });
        setSaving(false);
    };

    return (
        <Content title="Need Catering?">
            <div className="">
                <div className="mb-medium">
                    <PluginCard
                        title="Catering"
                        description="The catering feature simplifies catering orders, enabling customers to effortlessly request catering services through your platform."
                        onClick={toggleCatering}
                        isPremium
                        enabled={!!store?.has_catering}
                        loading={saving}
                    />
                </div>
                {store?.has_catering && <CateringSettingsForm />}
            </div>
        </Content>
    );
}

export default Catering;
