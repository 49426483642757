import { useEffect, useState } from 'react';
import { Spin } from 'antd';
import useRdxStore from '../hooks/useRdxStore';
import InitialDataManager from '../services/api/InitialDataManager';
import useFocus from '../hooks/useFocus';
import ReduxResource from '../services/resources/ReduxResource';
import actionCreators from '../store/actions';
import useUrlParams from '../hooks/useUrlParams';

interface Props {
    children: JSX.Element;
}

function DataProvider(props: Props): JSX.Element {
    const { children } = props;

    const { store } = useRdxStore();

    const [loading, setLoading] = useState<boolean>(true);

    const urlParams = useUrlParams();

    useEffect(() => {
        if (urlParams?.showTemplates === 'true') {
            ReduxResource.dispatch(actionCreators.appStatus.setShowTemplates(true));
        }
    }, []);

    async function getInitialData(withLoading = true): Promise<void> {
        if (withLoading) {
            setLoading(true);
        }
        await InitialDataManager.get();

        setLoading(false);
    }

    useFocus({
        onFocus: () => {
            getInitialData(false);
        },
    });

    useEffect(() => {
        getInitialData();
    }, []);

    if (loading) {
        return (
            <div className="w-screen h-screen flex justify-center items-center">
                <Spin size="large" />
            </div>
        );
    }

    return store ? (
        children
    ) : (
        <div className="w-screen h-screen flex justify-center items-center">
            <p>No Store Found</p>
        </div>
    );
}

export default DataProvider;
