import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { SUB_PAGE_NAMES } from '../../../../exports/Enums';

interface Props {
    onEdit: () => void;
}

function FloatingIconSection(props: Props): JSX.Element {
    const { onEdit } = props;

    return (
        <div className="border border-brand-inkGrey-grey_2 bg-background-inkWhite-white_0 rounded-md p-small flex items-center justify-between shadow-section mb-small">
            <h6>{SUB_PAGE_NAMES.FLOATING_ICON}</h6>
            <Button onClick={onEdit} icon={<EditOutlined />} />
        </div>
    );
}

export default FloatingIconSection;
