import { useState } from 'react';
import { Alert, Button, Form } from 'antd';
import { LocationTrackerSectionInterface, SectionInterface } from '../../../exports/Interfaces';
import SectionForm from '../../forms/SectionForm';
import SectionManager from '../../../services/api/SectionManager';
import useModals from '../../../hooks/useModals';
import { LOCATION_TRACKER_SECTION_TYPES, PAGES, SUB_PAGES } from '../../../exports/Enums';
import SectionSelect from '../../selects/SectionSelect';

interface Props {
    section: SectionInterface<LocationTrackerSectionInterface>;
}

function LocationTrackerSectionForm(props: Props): JSX.Element {
    const { section } = props;

    const { setSectionModal } = useModals();

    const [form] = Form.useForm();

    const [loading, setLoading] = useState<boolean>(false);

    const onSave = async (values: Partial<SectionInterface<LocationTrackerSectionInterface>>): Promise<void> => {
        if (typeof section?.id !== 'number') {
            return;
        }
        setLoading(true);
        await SectionManager.put<LocationTrackerSectionInterface>(section?.id, values);
        setLoading(false);
        setSectionModal({ open: false });
    };

    return (
        <SectionForm section={section} onSave={onSave} loading={loading} initialValues={section} form={form}>
            <Form.Item
                name={['content', 'type']}
                rules={[{ required: true, message: 'Please select a Layout!' }]}
                className="mb-none"
            >
                <SectionSelect
                    section={section}
                    onSelect={(_type: LOCATION_TRACKER_SECTION_TYPES) => form.setFieldValue(['content', 'type'], _type)}
                />
            </Form.Item>
            <Alert
                type="info"
                description={
                    <div>
                        <p>Click the link below to edit the list of companies.</p>
                        <Button className="mt-mini" href={`${PAGES.RESTAURANT_INFO}${SUB_PAGES.COMPANIES}`}>
                            Edit Companies
                        </Button>
                    </div>
                }
                className="mb-small"
            />
            <Alert
                type="info"
                description={
                    <div>
                        <p>
                            Hours and Locations of a company are fully controlled by the Admin Dashboard Locations Page
                            / Storefront. Click the link below.
                        </p>
                        <Button className="mt-mini" href={`${import.meta.env.VITE_ADMIN_DASHBOARD_URL}/locations`}>
                            Edit Opening Hours & Locations
                        </Button>
                    </div>
                }
            />
        </SectionForm>
    );
}

export default LocationTrackerSectionForm;
