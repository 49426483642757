import { Sidebar as SidebarPro, Menu, MenuItem, sidebarClasses } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';
import { Button, Tooltip } from 'antd';
import {
    LayoutFilled,
    InfoCircleFilled,
    FontColorsOutlined,
    LoginOutlined,
    FileImageOutlined,
    StarOutlined,
} from '@ant-design/icons';
import { useContext } from 'react';
import { PAGES, PAGE_NAMES, SUB_PAGES } from '../../exports/Enums';
import useTheme from '../../hooks/useTheme';
import ColorResource from '../../services/resources/ColorResource';
import MediaContext from '../../context/MediaContext';
import useAppCookies from '../../hooks/useAppCookies';
import { COOKIES } from '../../exports/Constants';
import useRdxStore from '../../hooks/useRdxStore';

function Sidebar(): JSX.Element {
    const theme = useTheme();

    const location = useLocation();

    const { toggleGallery } = useContext(MediaContext);

    const { removeCookies } = useAppCookies();

    const { store } = useRdxStore();

    const fadedPrimaryColor = `rgba(${[
        ...(ColorResource.getRgbValue(theme.color.brand.primary)?.split(' ') ?? []),
        '0.8',
    ]?.join(',')})`;

    return (
        <SidebarPro
            className="h-screen"
            collapsed
            rootStyles={{
                [`.${sidebarClasses.container}`]: {
                    backgroundColor: fadedPrimaryColor,
                },
            }}
        >
            <Menu
                menuItemStyles={{
                    button: ({ active }): any => ({
                        backgroundColor: active ? theme.color.brand.primary : undefined,
                        '&:hover': {
                            backgroundColor: theme.color.brand.primary,
                            opacity: 0.5,
                            borderWidth: 10,
                        },
                    }),
                }}
            >
                <Tooltip title={PAGE_NAMES.LAYOUT} placement="right">
                    <MenuItem
                        component={<Link to={`${PAGES.LAYOUT}${SUB_PAGES.PAGES}/${store?.home_page?.id}`} />}
                        icon={<LayoutFilled className="text-white" />}
                        active={location.pathname === PAGES.LAYOUT}
                    />
                </Tooltip>
                <Tooltip title={PAGE_NAMES.RESTAURANT_INFORMATION} placement="right">
                    <MenuItem
                        component={<Link to={PAGES.RESTAURANT_INFO} />}
                        icon={<InfoCircleFilled className="text-white" />}
                        active={location.pathname === PAGES.RESTAURANT_INFO}
                    />
                </Tooltip>
                <Tooltip title={PAGE_NAMES.THEME} placement="right">
                    <MenuItem
                        component={<Link to={PAGES.THEME} />}
                        icon={<FontColorsOutlined className="text-white" />}
                        active={location.pathname === PAGES.THEME}
                    />
                </Tooltip>

                <Tooltip title={PAGE_NAMES.PLUGINS} placement="right">
                    <MenuItem
                        component={<Link to={PAGES.PLUGINS} />}
                        icon={<StarOutlined className="text-white" />}
                        active={location.pathname === PAGES.PLUGINS}
                    />
                </Tooltip>
                <Tooltip title={PAGE_NAMES.MEDIA} placement="right">
                    <MenuItem
                        component={<Button type="ghost" onClick={() => toggleGallery({ open: true })} />}
                        icon={<FileImageOutlined className="text-white" />}
                    />
                </Tooltip>
                <Tooltip title={PAGE_NAMES.LOGOUT} placement="right">
                    <MenuItem
                        component={<Button type="ghost" onClick={() => removeCookies([COOKIES.AUTH_TOKEN])} />}
                        icon={<LoginOutlined className="text-white" />}
                        active={location.pathname === PAGES.THEME}
                    />
                </Tooltip>
            </Menu>
        </SidebarPro>
    );
}

export default Sidebar;
