import { Alert, Button, Divider, Dropdown, Menu, Modal, Switch, Tag } from 'antd';
import {
    AppstoreOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    PlusOutlined,
    SettingOutlined,
} from '@ant-design/icons';
import collect from 'collect.js';
import { useMemo, useState } from 'react';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import { AnimatedSectionInterface, MediaInterface, MenuInterface, SectionInterface } from '../../exports/Interfaces';
import getSectionName from '../../exports/Methods';
import SectionManager from '../../services/api/SectionManager';
import useModals from '../../hooks/useModals';
import { ANIMATED_SECTION_TYPES, SECTION_TYPES } from '../../exports/Enums';

interface Props {
    isDraggable?: boolean;
    section?: SectionInterface<any>;
    isDeletable?: boolean;
    isHidable?: boolean;
    onRemove?: (id: number) => void;
    updateSection?: (section: SectionInterface) => void;
    toggleVisibility?: (id: number, checked: boolean) => void;
}

function SectionItem(props: Props): JSX.Element | null {
    const { section, isDraggable, isDeletable, isHidable, onRemove, updateSection, toggleVisibility } = props;

    const { setSectionModal } = useModals();

    const { page: pageId } = useParams();

    const [deleteClicked, setDeleteCLicked] = useState<boolean>(false);

    const [deleting, setDeleting] = useState<boolean>(false);

    const [addingAnimation, setAddingAnimation] = useState<boolean>(false);

    const onDelete = async (): Promise<void> => {
        if (typeof section?.id !== 'number') {
            return;
        }
        setDeleting(true);
        await SectionManager.delete(section?.id);
        setDeleting(true);
        if (onRemove) {
            onRemove(section.id);
        }
    };

    const addLottieAnimation = async (): Promise<void> => {
        if (typeof section?.id !== 'number' || !pageId) {
            return;
        }

        setAddingAnimation(true);
        const { success, response } = await SectionManager.post<AnimatedSectionInterface>(Number(pageId), {
            parent_id: section.id,
            type: SECTION_TYPES.ANIMATED_SECTION,
            name: 'Lottie',
            content: {
                type: ANIMATED_SECTION_TYPES.TWO,
                data: {
                    lottie_props: {
                        src: '',
                    },
                },
            },
        });

        setAddingAnimation(false);

        if (updateSection && success) {
            updateSection({
                ...section,
                child: response?.data?.data,
            });
        }
    };

    const renderDeleteSectionModal = useMemo(
        () => (
            <Modal
                title="Are you sure you want to delete the section?"
                open={deleteClicked}
                onOk={onDelete}
                okText="Yes, delete"
                okButtonProps={{ danger: true, loading: deleting }}
                onCancel={() => setDeleteCLicked(false)}
            />
        ),
        [deleteClicked, deleting],
    );

    function renderInfoMessage(): JSX.Element | null {
        switch (section?.type) {
            case SECTION_TYPES.MENU_SECTION:
                if (section?.content?.menu_items?.length === 0) {
                    return <Alert className="mt-small" message="Upload menus" type="warning" showIcon />;
                }
                return null;
            default:
                return null;
        }
    }

    function renderUploads(): JSX.Element | null {
        if (section?.type === SECTION_TYPES.MENU_SECTION) {
            if (section?.content?.menu_items?.length === 0) {
                return null;
            }
            return (
                <div className="flex gap-4 overflow-hidden overflow-x-scroll w-full mt-small">
                    {section?.content?.menu_items?.map((menuItem: MenuInterface, index: number) => (
                        <div className="w-[60px] h-[40px] overflow-hidden relative rounded-md flex-none flex justify-center items-center bg-brand-inkGrey-grey_0">
                            <p className="tiny">Menu {index + 1}</p>
                        </div>
                    ))}
                </div>
            );
        }

        const video = collect(section?.content?.video).first() as MediaInterface;

        if (video) {
            return (
                <div className="w-[100px] h-[100px] flex justify-center items-center overflow-hidden relative mr-small rounded-md flex-none bg-brand-inkGrey-grey_0 mt-small">
                    <video preload="metadata" className="w-full h-full" key={video.url ?? ''}>
                        <source src={video.url || ''} />
                    </video>
                </div>
            );
        }
        if (section?.content?.images?.length > 0) {
            return (
                <div className="flex gap-4 overflow-hidden overflow-x-scroll w-full mt-small">
                    {section?.content?.images?.map((image: MediaInterface) => (
                        <div className="w-[50px] h-[50px] overflow-hidden relative rounded-md flex-none">
                            <img alt="section_image" src={image?.url || ''} className="object-cover w-full h-full" />
                        </div>
                    ))}
                </div>
            );
        }
        return null;
    }

    const menu = (
        <Menu>
            {typeof section?.parent_id !== 'number' && (
                <Menu.Item
                    key="addLottieAnimation"
                    onClick={addLottieAnimation}
                    disabled={!!section?.child || addingAnimation}
                >
                    <PlusOutlined />
                    <span style={{ marginLeft: 8 }}>Lottie Animation</span>
                </Menu.Item>
            )}
            {isDeletable && (
                <>
                    <Divider className="my-none" />
                    <Menu.Item key="delete" danger onClick={() => setDeleteCLicked(true)}>
                        <DeleteOutlined />
                        <span style={{ marginLeft: 8 }}>Delete</span>
                    </Menu.Item>
                </>
            )}
        </Menu>
    );

    return section ? (
        <>
            <div
                className={classnames('shadow rounded-md flex flex-col p-small bg-background-inkWhite-white_0', {
                    'bg-brand-inkGrey-grey_0': section?.is_hidden,
                })}
            >
                <div className="flex flex-row justify-between">
                    <div className="flex flex-row items-center">
                        {isDraggable && <AppstoreOutlined className="mr-mini" />}
                        <Tag className="h-fit">{getSectionName(section?.type)}</Tag>
                    </div>
                    <div className="flex items-center gap-4">
                        {isHidable && toggleVisibility && (
                            <Switch
                                defaultChecked={!section?.is_hidden}
                                onChange={(checked) => toggleVisibility(section.id, !checked)}
                                checkedChildren={<EyeOutlined />}
                                unCheckedChildren={<EyeInvisibleOutlined />}
                            />
                        )}
                        <Button icon={<EditOutlined />} onClick={() => setSectionModal({ open: true, section })} />
                        <Dropdown overlay={menu} placement="bottomRight">
                            <Button icon={<SettingOutlined />} />
                        </Dropdown>
                    </div>
                </div>
                <div className="flex mt-mini">
                    <div className="flex flex-col shrink w-full mr-small">
                        <h5>{section?.content?.title ?? section?.name ?? ''}</h5>
                        <p className="mini">{section?.content?.description}</p>
                    </div>
                </div>
                {renderInfoMessage()}
                {renderUploads()}
            </div>
            {section?.child && (
                <div className="pl-section mt-small">
                    <SectionItem section={section?.child} isDeletable onRemove={onRemove} />
                </div>
            )}
            {renderDeleteSectionModal}
        </>
    ) : null;
}

export default SectionItem;
