import { message } from 'antd';
import { DataWrapper, Response } from '../../../../exports/Interfaces';
import BackendApiClient from '../../BackendApiClient';

export default class FAQSectionItemOrderManager {
    public static put = async (data?: number[]): Promise<Response<DataWrapper<DataWrapper<null>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<null>>>({
            method: 'PUT',
            url: `/faq-section-item-order`,
            params: {
                ids: data,
            },
        });

        const { response, success } = request;

        if (response?.data?.data && success) {
            message.success('Updated');
        }

        return request;
    };
}
