import { Form, Button, Select, Tooltip, Collapse } from 'antd';
import { useState } from 'react';
import collect from 'collect.js';
import { DefaultOptionType } from 'antd/es/select';
import { PageLoadingIndicatorInterface } from '../../../exports/Interfaces';
import useRdxStore from '../../../hooks/useRdxStore';
import ThemeManager from '../../../services/api/ThemeManager';
import CustomForm from '../../forms/CustomForm';
import SectionLayout from '../../layouts/SectionLayout';
import { PAGE_LOADING_INDICATOR_TYPES } from '../../../exports/Enums';
import ColorSelect from '../../selects/ColorSelect';
import LottieSelect from '../../selects/LottieSelect';

function PageLoadingForm(): JSX.Element {
    const { store } = useRdxStore();

    const [form] = Form.useForm();

    const type = Form.useWatch('page_loading_indicator_type', form);

    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = async (values: PageLoadingIndicatorInterface): Promise<void> => {
        setLoading(true);

        await ThemeManager.post({
            page_loading_indicator_props: values,
        });

        setLoading(false);
    };

    const s3Url = (id: number): string => `${import.meta.env.VITE_S3_PROD_BASE_URL}/page-loading/${id}.png`;

    return (
        <SectionLayout title="Page Loading">
            <CustomForm>
                <Form
                    form={form}
                    onFinish={onFinish}
                    initialValues={{
                        remember: true,
                        ...store?.theme?.page_loading_indicator_props,
                    }}
                >
                    <Form.Item name="page_loading_indicator_type" label="Type">
                        <Select
                            placeholder="Select Type"
                            defaultValue={store?.theme?.page_loading_indicator_props?.page_loading_indicator_type}
                            options={
                                collect(PAGE_LOADING_INDICATOR_TYPES)
                                    .values()
                                    .filter((value) => !Number.isNaN(Number(value)))
                                    ?.map((value, index) => ({
                                        value,
                                        label: (
                                            <Tooltip
                                                overlayStyle={{ maxWidth: '500px' }}
                                                title={
                                                    <div className="w-full h-fit relative">
                                                        <img
                                                            src={s3Url(index + 1) ?? ''}
                                                            alt={`option_${index}`}
                                                            className="w-full h-full object-contain"
                                                        />
                                                    </div>
                                                }
                                                trigger="hover"
                                            >
                                                <div>Option {index + 1}</div>
                                            </Tooltip>
                                        ),
                                    }))
                                    .toArray() as DefaultOptionType[]
                            }
                        />
                    </Form.Item>
                    <Form.Item name="background_color" label="Background Color">
                        <ColorSelect
                            initialColor={store?.theme?.page_loading_indicator_props?.background_color}
                            onSelect={(color) => form.setFieldValue('background_color', color)}
                        />
                    </Form.Item>
                    <Form.Item name="text_color" label="Text Color">
                        <ColorSelect
                            initialColor={store?.theme?.page_loading_indicator_props?.text_color}
                            onSelect={(color) => form.setFieldValue('text_color', color)}
                        />
                    </Form.Item>
                    {Number(type) === PAGE_LOADING_INDICATOR_TYPES.FOUR && (
                        <Collapse className="mb-large">
                            <Collapse.Panel key="1" header="Lottie Animation">
                                <Form.Item
                                    name="lottie_props"
                                    initialValue={store?.theme?.page_loading_indicator_props?.lottie_props}
                                >
                                    <LottieSelect
                                        type="plain"
                                        lottieProps={store?.theme?.page_loading_indicator_props?.lottie_props}
                                        setLottieProps={(values) => form.setFieldValue('lottie_props', values)}
                                    />
                                </Form.Item>
                            </Collapse.Panel>
                        </Collapse>
                    )}
                    <Form.Item className="mb-none">
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </CustomForm>
        </SectionLayout>
    );
}

export default PageLoadingForm;
