import { useTranslation } from 'react-i18next';
import { LANGUAGES } from '../exports/Enums';
import useRdxStore from './useRdxStore';

interface Props {
    keyPrefix: string;
}

function useCustomTranslation(props: Props): {
    t: (key: string, options?: any) => string;
} {
    const { keyPrefix } = props;
    const { t: translate } = useTranslation(undefined, { keyPrefix });

    const { store } = useRdxStore();

    const t = (key: string, options?: any): string =>
        translate(key, { lng: store?.legal_lang ?? LANGUAGES.DE, ...options }) as any;

    return { t };
}

export default useCustomTranslation;
