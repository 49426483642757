import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import collect from 'collect.js';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import SectionList from '../../../components/sections/SectionList';
import { STOREFRONT_PAGES, SUB_PAGES } from '../../../exports/Enums';
import useRdxStore from '../../../hooks/useRdxStore';
import { PageContentInterface, PopupInterface } from '../../../exports/Interfaces';
import NewsBannerSection from './elements/NewsBannerSection';
import SeoSection from './elements/SeoSection';
import PopupSection from './elements/PopupSection';
import CateringSection from './elements/CateringSection';
import EventSection from './elements/EventSection';
import ActionConfirmation from '../../../components/popover/ActionConfirmation';
import PageManager from '../../../services/api/page/PageManager';
import FloatingIconSection from './elements/FloatingIconSection';

function PageLayout({ page }: { page: PageContentInterface }): JSX.Element {
    const { pathname } = useLocation();

    const { page: pageId } = useParams();

    const navigate = useNavigate();

    const { store } = useRdxStore();

    const isDeletable =
        (page?.href?.length || 0) > 1
            ? !collect(STOREFRONT_PAGES)
                  .except(['HOME', 'MENU', 'ABOUT_US'])
                  .values()
                  .first((value: any) => !!page?.href?.includes(value))
            : false;

    const [deletePageClicked, setDeletePageCLicked] = useState<boolean>(false);

    const [deletingPage, setDeletingPage] = useState<boolean>(false);

    const onDeletePage = async (): Promise<void> => {
        setDeletePageCLicked(false);
        setDeletingPage(true);
        const { success } = await PageManager.delete(Number(pageId));
        setDeletingPage(false);
        if (success) {
            window.location.reload();
        }
    };

    const popups = useMemo(
        () =>
            collect(store?.popups)
                .filter((popup) => popup?.page_id === null || popup.page_id === Number(pageId))
                .toArray(),
        [store?.popups],
    ) as PopupInterface[];

    return (
        <>
            {isDeletable && (
                <ActionConfirmation
                    open={deletePageClicked}
                    onOpenChange={setDeletePageCLicked}
                    onConfirm={onDeletePage}
                    onCancel={() => setDeletePageCLicked(false)}
                >
                    <Button
                        className="mb-small"
                        icon={<DeleteOutlined />}
                        loading={deletingPage}
                        disabled={deletingPage}
                    >
                        Delete Page
                    </Button>
                </ActionConfirmation>
            )}

            <SeoSection seo={page?.seo ?? null} onEdit={() => navigate(`${pathname}${SUB_PAGES.SEO}`)} />
            {page?.href === STOREFRONT_PAGES.HOME && (
                <NewsBannerSection onEdit={() => navigate(`${pathname}${SUB_PAGES.NEWS_BANNER}`)} />
            )}
            <FloatingIconSection onEdit={() => navigate(`${pathname}${SUB_PAGES.FLOATING_ICON}`)} />
            {popups?.map((popup) => (
                <PopupSection popup={popup} />
            ))}
            {page?.href === STOREFRONT_PAGES.CATERING && <CateringSection />}
            {page?.href === STOREFRONT_PAGES.EVENTS && <EventSection />}
            <SectionList sections={page?.sections || []} />
        </>
    );
}

export default PageLayout;
