import { DataWrapper, Response, TimeLineItemInterface } from '../../../exports/Interfaces';
import BackendApiClient from '../BackendApiClient';

export default class TimeLineSectionItemManager {
    public static post = async (
        timeLineSectionId: number,
        data: Partial<TimeLineItemInterface>,
    ): Promise<Response<DataWrapper<DataWrapper<TimeLineItemInterface>>>> =>
        BackendApiClient.request<DataWrapper<DataWrapper<TimeLineItemInterface>>>({
            method: 'POST',
            url: `/time-line-sections/${timeLineSectionId}/time-line-section-items`,
            data,
        });

    public static put = async (
        id: number,
        data: Partial<TimeLineItemInterface>,
    ): Promise<Response<DataWrapper<DataWrapper<TimeLineItemInterface>>>> =>
        BackendApiClient.request<DataWrapper<DataWrapper<TimeLineItemInterface>>>({
            method: 'PUT',
            url: `/time-line-section-items/${id}`,
            data,
        });

    public static delete = async (id: number): Promise<Response<DataWrapper<DataWrapper<null>>>> =>
        BackendApiClient.request<DataWrapper<DataWrapper<null>>>({
            method: 'DELETE',
            url: `/time-line-section-items/${id}`,
        });
}
