import { useEffect, useMemo, useState } from 'react';
import { Button, Form, Input, InputNumber, Select, Switch } from 'antd';
import collect from 'collect.js';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import CustomForm from '../../../forms/CustomForm';
import useRdxStore from '../../../../hooks/useRdxStore';
import { CompanyInterface, EventSettingInterface } from '../../../../exports/Interfaces';
import { DEFAULT_GUEST_RANGE } from '../../../../exports/Constants';
import EventSettingsManager from '../../../../services/api/event/EventSettingsManager';

function EventSettingsForm(): JSX.Element {
    const { store } = useRdxStore();

    const [form] = Form.useForm();

    const [loading, setLoading] = useState<boolean>(false);

    const [selectedCompanyId, setSelectedCompanyId] = useState<string>(
        collect(store?.companies)?.first()?.id?.toString() ?? '',
    );

    const options = useMemo(
        () =>
            collect(store?.companies)
                .map((_company) => ({
                    value: _company.id?.toString(),
                    label: _company.name,
                }))
                .toArray(),
        [store?.companies],
    ) as any[];

    const company = useMemo(
        () => collect(store?.companies)?.firstWhere('id', Number(selectedCompanyId)),
        [selectedCompanyId, store?.companies],
    ) as CompanyInterface;

    const onChangeCompany = (value: string): void => {
        setSelectedCompanyId(value);
    };

    useEffect(() => {
        form.setFieldsValue({
            ...company?.event_settings,
            guest_range: company?.event_settings?.guest_range ?? DEFAULT_GUEST_RANGE,
        });
    }, [company]);

    const onFinish = async (values: any): Promise<void> => {
        setLoading(true);

        const data = {
            ...(collect(values)
                ?.filter((value) => value !== undefined)
                .all() as Partial<EventSettingInterface>),
        };

        if (typeof company?.event_settings?.id === 'number') {
            await EventSettingsManager.put(company?.event_settings?.id, data);
        } else if (typeof company?.id === 'number') {
            await EventSettingsManager.post(company?.id, data);
        }

        setLoading(false);
    };

    return (
        company && (
            <>
                <Select
                    value={company?.id?.toString() ?? ''}
                    onChange={onChangeCompany}
                    options={options}
                    className="mb-small"
                />

                <div key={JSON.stringify(company?.event_settings)}>
                    <CustomForm>
                        <Form
                            form={form}
                            name="basic"
                            initialValues={{
                                remember: true,
                                ...company?.event_settings,
                                guest_range: company?.event_settings?.guest_range ?? DEFAULT_GUEST_RANGE,
                            }}
                            onFinish={onFinish}
                            layout="horizontal"
                        >
                            <div className="flex items-center justify-between mb-small" key={selectedCompanyId}>
                                <h4>Settings</h4>
                                <Form.Item label="Enabled" name="has_events" className="mb-none">
                                    <Switch defaultChecked={company?.event_settings?.has_events} />
                                </Form.Item>
                            </div>

                            <Form.Item
                                label="Email for incoming event requests"
                                name="email"
                                rules={[{ required: true, message: 'Please select an email address.' }]}
                            >
                                <Input placeholder="restaurant@gmail.com" />
                            </Form.Item>

                            <p className="text-sm mb-mini">Guest Range</p>
                            <Form.List name="guest_range">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field: any) => (
                                            <div key={field.key} className="mb-small">
                                                <div className="grid grid-cols-7 gap-2">
                                                    <div className="flex items-center col-span-6">
                                                        <Form.Item
                                                            name={[field.name, 'from']}
                                                            initialValue={field.from}
                                                            className="mb-none w-full"
                                                            rules={[{ required: true, message: 'Value is missing' }]}
                                                        >
                                                            <InputNumber placeholder="0" className="w-full" />
                                                        </Form.Item>
                                                        <div className="mx-mini">
                                                            <p> - </p>
                                                        </div>
                                                        <Form.Item
                                                            name={[field.name, 'to']}
                                                            initialValue={field.to}
                                                            className="mb-none w-full"
                                                        >
                                                            <InputNumber placeholder="50" className="w-full" />
                                                        </Form.Item>
                                                    </div>
                                                    <Button onClick={() => remove(field.name)} className="col-span-1">
                                                        <DeleteOutlined />
                                                    </Button>
                                                </div>
                                            </div>
                                        ))}

                                        <Form.Item className="mt-medium">
                                            <Button onClick={() => add()} icon={<PlusOutlined />}>
                                                Add
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                            <Form.Item className="mb-none">
                                <Button
                                    type="primary"
                                    onClick={() => onFinish(form.getFieldsValue())}
                                    loading={loading}
                                >
                                    Save
                                </Button>
                            </Form.Item>
                        </Form>
                    </CustomForm>
                </div>
            </>
        )
    );
}

export default EventSettingsForm;
