import { StarFilled } from '@ant-design/icons';
import { Switch } from 'antd';

interface Props {
    title: string;
    description?: string;
    enabled?: boolean;
    isPremium?: boolean;
    loading?: boolean;
    onClick: (checked: boolean) => void;
    children?: JSX.Element | null;
}

function PluginCard(props: Props): JSX.Element {
    const { title, description, enabled, isPremium, children, loading, onClick } = props;
    return (
        <div className="w-full p-small border border-brand-inkGrey-grey_2 border-solid border-small rounded-md shadow-section bg-background-inkWhite-white_0 flex flex-col items-start">
            <div className="flex justify-between w-full">
                <div>
                    <div className="flex items-center">
                        {isPremium && <StarFilled className="text-[#F2C94C] mr-mini" />}
                        <h6 className="text-left">{title}</h6>
                    </div>
                    {description && <p className="tiny text-brand-text-grey text-left">{description}</p>}
                </div>
                <div>
                    <Switch defaultChecked={enabled} onChange={onClick} loading={loading} disabled={loading} />
                </div>
            </div>
            {children}
        </div>
    );
}

export default PluginCard;
