import { Button, Form, InputNumber, Select, Switch, Tabs } from 'antd';
import collect from 'collect.js';
import { DeleteOutlined, DesktopOutlined, EyeInvisibleOutlined, EyeOutlined, MobileOutlined } from '@ant-design/icons';
import { DefaultOptionType } from 'antd/es/select';
import classnames from 'classnames';
import { useEffect } from 'react';
import { LottieDeviceSpecificInterface, LottiePropsInterface } from '../../exports/Interfaces';
import useGallery from '../../hooks/useGallery';
import {
    MEDIA_TYPES,
    MEDIA_EXTENSION,
    HORIZONTAL_ALIGNMENT_TYPES,
    VERTICAL_ALIGNMENT_TYPES,
} from '../../exports/Enums';
import FormGroup from '../forms/structure/form-group';

interface Props {
    type?: 'group' | 'plain';
    lottieProps?: LottiePropsInterface | null;
    setLottieProps: (data: LottiePropsInterface) => void;
}

function LottieSelect(props: Props): JSX.Element {
    const { type = 'group', lottieProps, setLottieProps } = props;

    const { toggleGallery } = useGallery();

    const [form] = Form.useForm();

    const src = Form.useWatch('src', form);

    const allValues = Form.useWatch([], form);

    const desktop = Form.useWatch('desktop', form) ?? lottieProps?.desktop;

    const mobile = Form.useWatch('mobile', form) ?? lottieProps?.mobile;

    const device = {
        desktop,
        mobile,
    };

    useEffect(() => {
        form.validateFields();
        setLottieProps(allValues);
    }, [allValues]);

    const horizontalAlignmentOptions = collect(HORIZONTAL_ALIGNMENT_TYPES)
        .values()
        .map((value) => ({
            label: value,
            value,
        }))
        .toArray() as DefaultOptionType[];

    const verticalAlignmentOptions = collect(VERTICAL_ALIGNMENT_TYPES)
        .values()
        .map((value) => ({
            label: value,
            value,
        }))
        .toArray() as DefaultOptionType[];

    function renderGridPreview(layout: 'mobile' | 'desktop', data: LottieDeviceSpecificInterface): JSX.Element {
        const height = data?.height || 100;
        const width = data?.width || 100;
        return (
            <div
                className={classnames(
                    'flex mb-small flex-col bg-brand-inkGrey-grey_1 border border-solid border-brand-inkGrey-grey_2 p-tiny',
                    {
                        'justify-start': data?.align_vertical === VERTICAL_ALIGNMENT_TYPES.TOP,
                        'justify-end': data?.align_vertical === VERTICAL_ALIGNMENT_TYPES.BOTTOM,
                        'justify-center': data?.align_vertical === VERTICAL_ALIGNMENT_TYPES.CENTER,
                        'items-start': data?.align_horizontal === HORIZONTAL_ALIGNMENT_TYPES.LEFT,
                        'items-center': data?.align_horizontal === HORIZONTAL_ALIGNMENT_TYPES.CENTER,
                        'items-end': data?.align_horizontal === HORIZONTAL_ALIGNMENT_TYPES.RIGHT,
                        'w-[100px] h-[200px]': layout === 'mobile',
                        'w-[200px] h-[100px]': layout === 'desktop',
                    },
                )}
            >
                <div
                    style={{
                        width: `${width}%`,
                        height: `${height}%`,
                    }}
                    className="bg-companyBrand-primary relative"
                >
                    <div className="flex items-end justify-center absolute inset-0">
                        <div className="w-full relative flex justify-center items-center">
                            <div className="h-[2px] bg-black/50 w-full absolute" />
                            <div className="bg-white rounded-sm w-fit px-[2px] relative h-[16px] flex justify-center items-center">
                                <p className="text-center relative p-none m-none" style={{ fontSize: 8 }}>
                                    {width}%
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-end absolute inset-0">
                        <div className="h-full relative flex justify-center items-center">
                            <div className="h-full bg-black/50 w-[2px] absolute" />
                            <div className="bg-white rounded-sm w-fit px-[2px] relative h-[16px] flex justify-center items-center">
                                <p className="text-center relative p-none m-none" style={{ fontSize: 8 }}>
                                    {height}%
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function renderOverview(): JSX.Element {
        return (
            <>
                <Form.Item label="Lottie file" name="src">
                    <div className="flex gap-2">
                        <Button
                            className="overflow-hidden flex-1"
                            onClick={() =>
                                toggleGallery({
                                    open: true,
                                    maxSelect: 1,
                                    mediaType: MEDIA_TYPES.DOCUMENT,
                                    mediaExtension: MEDIA_EXTENSION.JSON,
                                    onSelect: (media) => form.setFieldValue('src', collect(media).first()?.url),
                                })
                            }
                        >
                            <p className="mini">{src ?? 'Select Lottie file'}</p>
                        </Button>

                        <Button
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() => form.setFieldValue('src', null)}
                            className="flex-shrink-0"
                            disabled={!src}
                        />
                    </div>
                </Form.Item>
                <Form.Item name="playback_speed_on_scroll" label="Playback speed on scroll" className="w-full">
                    <InputNumber placeholder="Enter playback speed" className="w-full" />
                </Form.Item>
                <Form.Item name="loop" label="Loop" className="w-full">
                    <Switch defaultChecked={lottieProps?.loop} />
                </Form.Item>
                <Form.Item name="auto_play" label="Auto play" className="w-full">
                    <Switch defaultChecked={lottieProps?.auto_play} />
                </Form.Item>
                <Form.Item name="play_on_scroll" label="Play on scroll" className="w-full">
                    <Switch defaultChecked={lottieProps?.play_on_scroll} />
                </Form.Item>
                <Form.Item name="speed" label="Speed" className="w-full">
                    <InputNumber placeholder="Enter speed" className="w-full" />
                </Form.Item>
                <Form.Item name="is_debug" label="Show outline for debug" className="w-full">
                    <Switch defaultChecked={lottieProps?.is_debug} />
                </Form.Item>
            </>
        );
    }

    function renderDevice(key: 'desktop' | 'mobile'): JSX.Element {
        return (
            <>
                {renderGridPreview(key, device[key])}
                <Form.Item name={[key, 'align_horizontal']} label="Align Horizontal" className="w-full">
                    <Select options={horizontalAlignmentOptions} placeholder="Select alignment" className="w-full" />
                </Form.Item>
                <Form.Item name={[key, 'align_vertical']} label="Align Vertical" className="w-full">
                    <Select options={verticalAlignmentOptions} placeholder="Select alignment" className="w-full" />
                </Form.Item>
                <Form.Item name={[key, 'width']} label="Width (0-100%)" className="w-full">
                    <InputNumber min={0} max={100} placeholder="Enter width" className="w-full" addonAfter="%" />
                </Form.Item>
                <Form.Item name={[key, 'height']} label="Height (0-100%)" className="w-full">
                    <InputNumber min={0} max={100} placeholder="Enter width" className="w-full" addonAfter="%" />
                </Form.Item>
                <Form.Item name={[key, 'is_hidden']} label="Visibility" className="w-full">
                    <Switch
                        defaultChecked={!lottieProps?.[key]?.is_hidden}
                        checkedChildren={<EyeOutlined />}
                        unCheckedChildren={<EyeInvisibleOutlined />}
                        onChange={(checked) => form.setFieldValue([key, 'is_hidden'], !checked)}
                    />
                </Form.Item>
                <Form.Item
                    name={[key, 'renderer_settings', 'preserve_aspect_ratio']}
                    label="Aspect Ratio"
                    className="w-full"
                >
                    <Select
                        options={[
                            { label: 'Cover', value: 'xMidYMid slice' },
                            { label: 'Contain', value: 'xMidYMid meet' },
                        ]}
                        placeholder="Resizing mode"
                        className="w-full"
                    />
                </Form.Item>
            </>
        );
    }

    function renderContent(): JSX.Element {
        const items = [
            {
                key: '1',
                label: 'Overview',
                children: renderOverview(),
            },
            {
                key: '2',
                label: 'Desktop',
                icon: <DesktopOutlined />,
                children: renderDevice('desktop'),
            },
            {
                key: '3',
                label: 'Mobile',
                icon: <MobileOutlined />,
                children: renderDevice('mobile'),
            },
        ];
        return (
            <Tabs defaultActiveKey={items[0].key}>
                {items.map((tab) => (
                    <Tabs.TabPane
                        key={tab.key}
                        tab={
                            <>
                                {tab?.icon} {tab.label}
                            </>
                        }
                    >
                        {tab.children}
                    </Tabs.TabPane>
                ))}
            </Tabs>
        );
    }

    if (type === 'plain') {
        return (
            <Form form={form} initialValues={lottieProps ?? {}}>
                {renderContent()}
            </Form>
        );
    }

    return (
        <FormGroup label="Animation">
            <Form form={form} initialValues={lottieProps ?? {}}>
                {renderContent()}
            </Form>
        </FormGroup>
    );
}

export default LottieSelect;
