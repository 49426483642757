import { Response } from '../../../exports/Interfaces';
import BackendApiClient from '../BackendApiClient';

export default class CacheManager {
    public static post = async (): Promise<Response<null>> =>
        BackendApiClient.request({
            method: 'POST',
            url: `/cache-store`,
        });
}
