import { Form, Input, Button, Modal } from 'antd';
import collect from 'collect.js';
import { useState } from 'react';
import { MediaInterface, ProductInterface } from '../../exports/Interfaces';
import ProductManager from '../../services/api/ProductManager';
import CurrencyInput from '../inputs/CurrencyInput';
import SelectedMediaList from '../media/SelectedMediaList';
import MediaManager from '../../services/api/MediaManager';
import { DB_MODELS, MEDIA_TAGS, MEDIA_TYPES } from '../../exports/Enums';

interface Props {
    open: boolean;
    product?: ProductInterface | null;
    toggle: (open?: boolean) => void;
}

function ProductModal(props: Props): JSX.Element {
    const { open, product, toggle } = props;

    const [loading, setLoading] = useState<boolean>(false);

    const [price, setPrice] = useState<string | undefined>(product?.price ?? undefined);

    const [mediaList, setMediaList] = useState<MediaInterface[]>([]);

    const onFinish = async (values: any): Promise<void> => {
        setLoading(true);
        const data = {
            price,
            ...(collect(values)
                ?.filter((value) => value !== undefined)
                .all() as Partial<ProductInterface>),
        };

        let request;

        if (product) {
            request = await ProductManager.put(product?.id, data);
        } else {
            request = await ProductManager.post(data);
        }

        const { response, success } = request;

        if (success && typeof response?.data?.data?.id === 'number') {
            await MediaManager.post(DB_MODELS.PRODUCT, response?.data?.data?.id, mediaList, MEDIA_TAGS.IMAGES, '');
            await ProductManager.get(response?.data?.data?.id);
        }

        setLoading(false);

        if (success) {
            toggle();
        }
    };

    return (
        <Modal
            title={product ? 'Update Product' : 'Add Product'}
            open={open}
            onCancel={() => toggle(false)}
            footer={null}
        >
            <Form
                name="basic"
                onFinish={onFinish}
                layout="vertical"
                initialValues={{
                    ...product,
                }}
            >
                <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please add a product name' }]}>
                    <Input placeholder="Enter Name" />
                </Form.Item>

                <Form.Item name="description" label="Description">
                    <Input.TextArea placeholder="Enter Description" />
                </Form.Item>

                <Form.Item
                    name="price"
                    label="Price"
                    rules={[
                        { required: true },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (value || getFieldValue('price')) {
                                    return Promise.resolve();
                                }
                                return Promise.reject();
                            },
                        }),
                    ]}
                >
                    <CurrencyInput id="price" value={price} onChange={setPrice} />
                </Form.Item>

                <div className="mb-medium w-full">
                    <SelectedMediaList
                        uploadCount={1}
                        defaultMediaList={product?.image ? [product?.image] : []}
                        mediaList={mediaList}
                        setMediaList={setMediaList}
                        mediaType={MEDIA_TYPES.IMAGE}
                    />
                </div>

                <Form.Item className="mb-none">
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default ProductModal;
