import { useMemo, useState } from 'react';
import { Button, List } from 'antd';
import collect from 'collect.js';
import { EditOutlined } from '@ant-design/icons';
import useRdxStore from '../../../hooks/useRdxStore';
import { ButtonThemeInterface } from '../../../exports/Interfaces';
import { BUTTON_VARIANT } from '../../../exports/Enums';
import ButtonDesignModal from '../../modals/ButtonDesignModal';

function Design(): JSX.Element {
    const { store } = useRdxStore();

    const [button, setButton] = useState<ButtonThemeInterface | null>(null);

    const renderButtonDesignModal = useMemo(
        () => button && <ButtonDesignModal open button={button} toggle={() => setButton(null)} />,
        [button],
    );

    const getTitle = (variant: BUTTON_VARIANT): string => {
        switch (variant) {
            case BUTTON_VARIANT.SECONDARY_FILL:
                return 'Secondary Fill';
            case BUTTON_VARIANT.LIGHT_OUTLINE:
                return 'Light Outline';
            case BUTTON_VARIANT.DARK_OUTLINE:
                return 'Dark Outline';
            default:
                return 'Primary Fill';
        }
    };

    const fillButtons = useMemo(
        () =>
            collect(store?.buttons)
                .filter(
                    (_button) =>
                        _button.variant === BUTTON_VARIANT.PRIMARY_FILL ||
                        _button.variant === BUTTON_VARIANT.SECONDARY_FILL,
                )
                .toArray() as ButtonThemeInterface[],
        [store?.buttons],
    );

    const outlineButtons = useMemo(
        () =>
            collect(store?.buttons)
                .filter(
                    (_button) =>
                        _button.variant === BUTTON_VARIANT.LIGHT_OUTLINE ||
                        _button.variant === BUTTON_VARIANT.DARK_OUTLINE,
                )
                .toArray() as ButtonThemeInterface[],
        [store?.buttons],
    );

    function renderListItem(_button: ButtonThemeInterface): JSX.Element {
        return (
            <List.Item>
                <div className="flex items-center justify-between w-full">
                    <h6>{getTitle(_button?.variant)}</h6>
                    <Button icon={<EditOutlined />} onClick={() => setButton(_button)} />
                </div>
            </List.Item>
        );
    }

    return (
        <>
            <div>
                <List
                    className="bg-white mb-small shadow"
                    header={<div>Fill buttons</div>}
                    bordered
                    dataSource={fillButtons}
                    renderItem={(_button) => renderListItem(_button)}
                />

                <List
                    className="bg-white mb-small shadow"
                    header={<div>Outline Buttons</div>}
                    bordered
                    dataSource={outlineButtons}
                    renderItem={(_button) => renderListItem(_button)}
                />
            </div>
            {renderButtonDesignModal}
        </>
    );
}

export default Design;
