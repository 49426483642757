import { message } from 'antd';
import { DataWrapper, Response, SectionInterface } from '../../../../exports/Interfaces';
import BackendApiClient from '../../BackendApiClient';

export default class SectionOrderManager {
    public static put = async (data?: number[]): Promise<Response<DataWrapper<DataWrapper<SectionInterface[]>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<SectionInterface[]>>>({
            method: 'PUT',
            url: `/section-order`,
            params: {
                ids: data,
            },
        });

        const { response, success } = request;

        if (response?.data?.data && success) {
            message.success('Updated');
        }

        return request;
    };
}
