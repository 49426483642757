import { createContext } from 'react';
import { AppModalsContextInterface } from '../exports/Interfaces';

const AppModalContext = createContext<AppModalsContextInterface>({
    navigationItemModal: {
        open: false,
    },
    popupModal: {
        open: false,
    },
    sectionModal: {
        open: false,
    },
    featureModal: {
        open: false,
    },
    setNavigationItemModal: () => null,
    setPopupModal: () => null,
    setSectionModal: () => null,
    setFeatureModal: () => null,
});

export default AppModalContext;
