import { DataWrapper, Response, SeoInterface } from '../../../exports/Interfaces';
import BackendApiClient from '../BackendApiClient';
import InitialDataManager from '../InitialDataManager';

export default class SeoManager {
    public static post = async (
        pageId: number,
        data?: Partial<SeoInterface>,
    ): Promise<Response<DataWrapper<DataWrapper<SeoInterface>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<SeoInterface>>>({
            method: 'POST',
            url: `/pages/${pageId}/seo`,
            data,
        });

        const { response, success } = request;

        if (response?.data?.data && success) {
            InitialDataManager.get();
        }

        return request;
    };
}
