import { Button, Form, Select, Switch, Tooltip } from 'antd';
import { useState } from 'react';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import collect from 'collect.js';
import { DefaultOptionType } from 'antd/es/select';
import useRdxStore from '../../../hooks/useRdxStore';
import SectionLayout from '../../layouts/SectionLayout';
import { NAVIGATION_BAR_POSITION, NAVIGATION_BAR_TYPES } from '../../../exports/Enums';
import { NavigationBarInterface } from '../../../exports/Interfaces';
import CustomForm from '../../forms/CustomForm';
import ThemeManager from '../../../services/api/ThemeManager';
import ButtonDesignSelect from '../../selects/ButtonDesignSelect';
import ColorSelect from '../../selects/ColorSelect';

function NavigationBarForm(): JSX.Element {
    const { store } = useRdxStore();

    const [form] = Form.useForm();

    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = async (values: NavigationBarInterface): Promise<void> => {
        setLoading(true);
        await ThemeManager.post({
            navigation_bar_props: {
                ...store?.theme?.navigation_bar_props,
                ...values,
            },
        });
        setLoading(false);
    };

    const s3Url = (id: number): string => `${import.meta.env.VITE_S3_PROD_BASE_URL}/navigation-bar/${id}.png`;

    const positions = [
        {
            value: NAVIGATION_BAR_POSITION.FIXED,
            label: 'Fixed',
        },
        {
            value: NAVIGATION_BAR_POSITION.ABSOLUTE,
            label: 'Absolute',
        },
    ];

    return (
        <SectionLayout title="Navigation Bar">
            <CustomForm>
                <Form
                    form={form}
                    onFinish={onFinish}
                    initialValues={{
                        remember: true,
                        ...store?.theme?.navigation_bar_props,
                    }}
                >
                    <Form.Item name="navigation_bar_type" label="Type">
                        <Select
                            placeholder="Select Type"
                            defaultValue={store?.theme?.navigation_bar_props?.navigation_bar_type}
                            options={
                                collect(NAVIGATION_BAR_TYPES)
                                    .values()
                                    .filter((value) => !Number.isNaN(Number(value)))
                                    ?.map((value, index) => ({
                                        value,
                                        label: (
                                            <Tooltip
                                                overlayStyle={{ maxWidth: '500px' }}
                                                title={
                                                    <div className="w-full h-fit relative">
                                                        <img
                                                            src={s3Url(index + 1) ?? ''}
                                                            alt={`option_${index}`}
                                                            className="w-full h-full object-contain"
                                                        />
                                                    </div>
                                                }
                                                trigger="hover"
                                            >
                                                <div>Option {index + 1}</div>
                                            </Tooltip>
                                        ),
                                    }))
                                    .toArray() as DefaultOptionType[]
                            }
                        />
                    </Form.Item>
                    <Form.Item name="navigation_bar_position" label="Position">
                        <Select
                            placeholder="Select Position"
                            options={positions}
                            defaultValue={store?.theme?.navigation_bar_props?.navigation_bar_position}
                        />
                    </Form.Item>
                    <Form.Item name="background_color" label="Background Color">
                        <ColorSelect
                            initialColor={store?.theme?.navigation_bar_props?.background_color}
                            onSelect={(color) => form.setFieldValue('background_color', color)}
                        />
                    </Form.Item>
                    <Form.Item name="text_color" label="Text Color">
                        <ColorSelect
                            initialColor={store?.theme?.navigation_bar_props?.text_color}
                            onSelect={(color) => form.setFieldValue('text_color', color)}
                        />
                    </Form.Item>
                    <Form.Item name="order_online_button_hidden" label="Online Ordering Button" className="mb-small">
                        <Switch
                            checkedChildren={<EyeOutlined />}
                            unCheckedChildren={<EyeInvisibleOutlined />}
                            defaultChecked={!store?.theme?.navigation_bar_props?.order_online_button_hidden}
                            onChange={(checked) => form.setFieldValue('order_online_button_hidden', !checked)}
                        />
                    </Form.Item>
                    <Form.Item label="Order Online Button" name="order_online_button_props">
                        <ButtonDesignSelect
                            title="Order Online"
                            button={store?.theme?.navigation_bar_props?.order_online_button_props}
                            setButton={(button) => form.setFieldValue('order_online_button_props', button)}
                        />
                    </Form.Item>

                    <Form.Item className="mb-none">
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </CustomForm>
        </SectionLayout>
    );
}

export default NavigationBarForm;
