import SectionLayout from './SectionLayout';

interface Props {
    children: React.ReactNode;
    title?: string;
    rightContent?: JSX.Element;
}

function ThemeLayout(props: Props): JSX.Element {
    const { children, title, rightContent } = props;

    return (
        <SectionLayout title={title ?? 'Edit'} hideClose rightContent={rightContent}>
            {children}
        </SectionLayout>
    );
}

export default ThemeLayout;
