import ElementCard from '../../../../cards/ElementCard';
import useModals from '../../../../../hooks/useModals';
import ElementsWrapper from '../../tabs/ElementsWrapper';
import { POPUP_TYPES } from '../../../../../exports/Enums';

function Popup(): JSX.Element {
    const { featureModal, setPopupModal, setFeatureModal } = useModals();

    const section = featureModal?.section;

    const contentType = featureModal?.contentType;

    const isUpdate = !!section;

    const s3Url = (type: string): string => `${import.meta.env.VITE_S3_PROD_BASE_URL}/popups/${type}.png`;

    const _onSelect = (_contentType: POPUP_TYPES): void => {
        setFeatureModal({ open: false });
        setTimeout(() => {
            setPopupModal({ open: true, popup: null, type: _contentType });
        });
    };

    const data = [
        {
            type: POPUP_TYPES.DEFAULT,
            label: 'Standard',
        },
        {
            type: POPUP_TYPES.COLLECT_EMAILS,
            label: 'Collect Emails',
        },
    ];

    return (
        <ElementsWrapper>
            {data.map((item) => (
                <ElementCard
                    title={item.label}
                    url={s3Url(item.type)}
                    isActive={item.type === contentType}
                    onClick={() => _onSelect(item.type)}
                    isUpdate={isUpdate}
                />
            ))}
        </ElementsWrapper>
    );
}

export default Popup;
