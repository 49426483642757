import { Form, Button, Select, Switch } from 'antd';
import { useState } from 'react';
import collect from 'collect.js';
import { DefaultOptionType } from 'antd/es/select';
import { MediaInterface, StoreThemeInterface } from '../../../exports/Interfaces';
import useRdxStore from '../../../hooks/useRdxStore';
import ThemeManager from '../../../services/api/ThemeManager';
import CustomForm from '../../forms/CustomForm';
import SectionLayout from '../../layouts/SectionLayout';
import { BORDER_RADIUS_TYPES, BORDER_WIDTH_TYPES, DB_MODELS, MEDIA_TAGS, MEDIA_TYPES } from '../../../exports/Enums';
import SelectedMediaList from '../../media/SelectedMediaList';
import MediaManager from '../../../services/api/MediaManager';

function StyleForm(): JSX.Element {
    const { store } = useRdxStore();

    const [form] = Form.useForm();

    const [loading, setLoading] = useState<boolean>(false);

    const [mediaList, setMediaList] = useState<MediaInterface[]>(store?.theme?.image ? [store.theme.image] : []);

    const onFinish = async (values: Partial<StoreThemeInterface>): Promise<void> => {
        setLoading(true);

        const { response, success } = await ThemeManager.post(values);

        if (success) {
            if (typeof response?.data?.data?.id === 'number') {
                await MediaManager.post(DB_MODELS.THEME, response?.data?.data?.id, mediaList, MEDIA_TAGS.IMAGE, '');
            }
        }

        setLoading(false);
    };

    return (
        <SectionLayout title="Style">
            <CustomForm>
                <Form
                    form={form}
                    onFinish={onFinish}
                    initialValues={{
                        remember: true,
                        ...store?.theme?.footer_props,
                    }}
                >
                    <Form.Item name="border_radius_type" label="Border Radius">
                        <Select
                            placeholder="Select Border Radius"
                            options={
                                collect(BORDER_RADIUS_TYPES)
                                    .values()
                                    .map((value) => ({ label: value, value }))
                                    .toArray() as DefaultOptionType[]
                            }
                            defaultValue={store?.theme?.border_radius_type}
                        />
                    </Form.Item>
                    <Form.Item name="border_width_type" label="Border Width">
                        <Select
                            placeholder="Select Border Width"
                            options={
                                collect(BORDER_WIDTH_TYPES)
                                    .values()
                                    .map((value) => ({ label: value, value }))
                                    .toArray() as DefaultOptionType[]
                            }
                            defaultValue={store?.theme?.border_width_type}
                        />
                    </Form.Item>
                    <Form.Item name="has_section_borders" label="Section Borders">
                        <Switch
                            defaultChecked={store?.theme?.has_section_borders}
                            onChange={(checked) => form.setFieldValue('has_section_borders', checked)}
                        />
                    </Form.Item>

                    <Form.Item>
                        <SelectedMediaList
                            title="Background Image"
                            uploadCount={1}
                            mediaType={MEDIA_TYPES.IMAGE}
                            defaultMediaList={store?.theme?.image ? [store?.theme?.image] : []}
                            mediaList={mediaList}
                            setMediaList={setMediaList}
                        />
                    </Form.Item>

                    <Form.Item className="mb-none">
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </CustomForm>
        </SectionLayout>
    );
}

export default StyleForm;
