import useAuth from './hooks/useAuth';
import UnauthorizedPage from './pages/UnauthorizedPage';
import AntConfigProvider from './providers/AntConfigProvider';
import DataProvider from './providers/DataProvider';
import MediaProvider from './providers/MediaProvider';
import HelmetProvider from './providers/HelmetProvider';
import ThemeProvider from './providers/ThemeProvider';
import MainRouter from './routes/MainRouter';
import GoogleServicesProvider from './providers/GoodeServicesProvider';
import ScreenTypeProvider from './providers/ScreenTypeProvider';
import EventProvider from './providers/EventProvider';
import useRdxStore from './hooks/useRdxStore';
import AppModalProvider from './providers/AppModalProvider';
import OnboardingRouter from './routes/onboarding/OnboardingRouter';

function App(): JSX.Element {
    const { isLoggedIn } = useAuth();

    const { store } = useRdxStore();

    return (
        <ThemeProvider>
            <AntConfigProvider>
                <HelmetProvider>
                    <GoogleServicesProvider>
                        <ScreenTypeProvider>
                            <EventProvider>
                                {isLoggedIn ? (
                                    <DataProvider>
                                        <MediaProvider>
                                            <AppModalProvider>
                                                {store?.onboarding_completed ? <MainRouter /> : <OnboardingRouter />}
                                            </AppModalProvider>
                                        </MediaProvider>
                                    </DataProvider>
                                ) : (
                                    <UnauthorizedPage />
                                )}
                            </EventProvider>
                        </ScreenTypeProvider>
                    </GoogleServicesProvider>
                </HelmetProvider>
            </AntConfigProvider>
        </ThemeProvider>
    );
}

export default App;
