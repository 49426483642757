import { useState } from 'react';
import { Button, Form, Input } from 'antd';
import useRdxStore from '../../../hooks/useRdxStore';
import SectionManager from '../../../services/api/SectionManager';

interface Props {
    onSuccess: () => void;
}

function RestaurantDescription(props: Props): JSX.Element {
    const { onSuccess } = props;

    const { store } = useRdxStore();

    const [saving, setSaving] = useState<boolean>(false);

    const [form] = Form.useForm();

    const save = async (values: any): Promise<void> => {
        await form.validateFields();
        setSaving(true);
        const { success } = await SectionManager.put(store?.about_us_section.id, values);
        setSaving(false);
        if (success) {
            onSuccess();
        }
    };

    return (
        <div className="grid grid-cols-2 gap-4">
            <Form
                form={form}
                initialValues={{
                    ...store?.about_us_section,
                }}
                layout="vertical"
                onFinish={save}
            >
                <Form.Item name={['content', 'description']} rules={[{ required: true }]}>
                    <Input.TextArea placeholder="Enter description" rows={10} />
                </Form.Item>
                <Button type="primary" htmlType="submit" loading={saving} disabled={saving}>
                    Save
                </Button>
            </Form>
            <div>
                <h6 className="mb-mini">Enter into ChatGTP 🔑</h6>
                <p className="text-mini">
                    This is the name of my restaurant Tongking’s Asia Haus - Vietnamesisches Restaurant and it is
                    located in Bonn. I am writing a google business description for my google business page. Write me an
                    optimised description about my restaurant. The description should be between 600 - 650 characters
                    and 3-5 sentences. The description should use google business seo guidelines and best practices.
                    Optimise for relevant keywords for my restaurant, restaurant category and city. Write the
                    description in German using simple language and the german du form.
                </p>
            </div>
        </div>
    );
}

export default RestaurantDescription;
