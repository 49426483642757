import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import Highlighter from 'react-highlight-words';
import useRdxStore from '../../../hooks/useRdxStore';

interface Props {
    title: string;
    description: string;
    keywords: string[];
    setEditPreview: (value: boolean) => void;
}

function Highlight({ children }: any): any {
    return <strong>{children}</strong>;
}

function SeoPreview(props: Props): JSX.Element {
    const { title, description, keywords, setEditPreview } = props;

    const { store } = useRdxStore();

    return (
        <>
            <div className="flex items-center justify-between mb-small">
                <h4>Preview</h4>
                <Button onClick={() => setEditPreview(true)} className="mr-small" icon={<EditOutlined />} />
            </div>
            <div className="mb-mini">
                <p>https://{store?.domain}</p>
            </div>
            <div className="mb-mini">
                <h5 className="regular" style={{ color: '#000093' }}>
                    <Highlighter highlightTag={Highlight} searchWords={keywords} textToHighlight={title} />
                </h5>
            </div>
            <p>
                <Highlighter highlightTag={Highlight} searchWords={keywords} textToHighlight={description} />
            </p>
        </>
    );
}

export default SeoPreview;
