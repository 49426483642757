import { useState, useEffect } from 'react';
import { Button, Spin } from 'antd';
import useRdxStore from '../../../hooks/useRdxStore';
import ThemeLayout from '../../layouts/ThemeLayout';
import { TemplateDataInterface } from '../../../exports/Interfaces';
import TemplateManager from '../../../services/api/template/TemplateManager';
import TemplateItem from '../../list/TemplateItem';
import { TEMPLATE_TYPES } from '../../../exports/Enums';

function TemplatesForm(): JSX.Element {
    const { store } = useRdxStore();

    const [loading, setLoading] = useState<boolean>(false);

    const [saving, setSaving] = useState<boolean>(false);

    const [selectedTemplateType, setSelectedTemplateType] = useState<TEMPLATE_TYPES | null>(
        store?.theme?.template_type ?? TEMPLATE_TYPES.STANDARD,
    );

    const [data, setData] = useState<TemplateDataInterface[]>([]);

    const getTemplates = async (): Promise<void> => {
        setLoading(true);
        const { response, success } = await TemplateManager.get();
        setLoading(false);
        if (success && response?.data) {
            setData(response.data);
        }
    };

    useEffect(() => {
        getTemplates();
    }, []);

    const onSave = async (): Promise<void> => {
        if (!selectedTemplateType) {
            return;
        }

        setSaving(true);
        await TemplateManager.post(selectedTemplateType);
        setSaving(false);
    };

    return (
        <ThemeLayout
            title="Select Template"
            rightContent={
                <div className="flex items-center gap-4">
                    <Button type="primary" onClick={onSave} loading={saving} disabled={saving}>
                        Save
                    </Button>
                </div>
            }
        >
            {loading ? (
                <Spin />
            ) : (
                <div className="grid lg:grid-cols-4 grid-cols-1 gap-4 overflow-y-auto pb-[100px]">
                    {data.map((item) => (
                        <TemplateItem
                            item={item}
                            isActive={item.type === selectedTemplateType}
                            setTemplateType={setSelectedTemplateType}
                        />
                    ))}
                </div>
            )}
        </ThemeLayout>
    );
}

export default TemplatesForm;
