export default class CurrencyResource {
    public static formatCurrency(price?: number | null, currency?: string, withoutSpace?: boolean): string {
        try {
            const formattedPrice = Number(price)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,');
            const newCurrency = currency ?? '€';
            return `${formattedPrice}${withoutSpace ? '' : ' '}${newCurrency}`;
        } catch (error) {
            return '';
        }
    }
}
