import { Libraries, useJsApiLoader } from '@react-google-maps/api';

interface Props {
    children: JSX.Element;
}

const mapLibraries = ['places', 'geometry', 'drawing'] as Libraries;

function GoogleServicesProvider(props: Props): JSX.Element {
    const { children } = props;

    useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: import.meta.env.VITE_GOOGLE_API_KEY,
        libraries: mapLibraries,
    });

    return children;
}

export default GoogleServicesProvider;
