import { REDUX_STORE_SLICES } from '../../../exports/Enums';
import { DataWrapper, Response, ScriptInterface } from '../../../exports/Interfaces';
import actionCreators from '../../../store/actions';
import ReduxResource from '../../resources/ReduxResource';
import BackendApiClient from '../BackendApiClient';

export default class ScriptManager {
    public static post = async (
        data: ScriptInterface,
    ): Promise<Response<DataWrapper<DataWrapper<ScriptInterface>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<ScriptInterface>>>({
            method: 'POST',
            url: '/scripts',
            data,
        });

        const { success, response } = request;

        if (success && response?.data?.data) {
            ReduxResource.dispatch(actionCreators[REDUX_STORE_SLICES.STORE].addScript(response.data.data));
        }

        return request;
    };

    public static put = async (
        id: number,
        data: ScriptInterface,
    ): Promise<Response<DataWrapper<DataWrapper<ScriptInterface>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<ScriptInterface>>>({
            method: 'PUT',
            url: `/scripts/${id}`,
            data,
        });

        const { success, response } = request;

        if (success && response?.data?.data) {
            ReduxResource.dispatch(actionCreators[REDUX_STORE_SLICES.STORE].updateScript(response.data.data));
        }

        return request;
    };

    public static delete = async (id: number): Promise<Response<DataWrapper<DataWrapper<null>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<null>>>({
            method: 'DELETE',
            url: `/scripts/${id}`,
        });

        const { success } = request;

        if (success) {
            ReduxResource.dispatch(actionCreators[REDUX_STORE_SLICES.STORE].deleteScript(id));
        }

        return request;
    };
}
