import { Button } from 'antd';
import { AppstoreOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import collect from 'collect.js';
import { useMemo, useState } from 'react';
import { TimeLineItemProps } from '../../../exports/Interfaces';
import EditTimeLineSectionItem from './EditTimeLineSectionItem';
import TimeLineSectionItemManager from '../../../services/api/time-line-section-item/TimeLineSectionItemManager';

function TimeLineSectionItem(props: TimeLineItemProps): JSX.Element {
    const { timeLine, onRemove } = props;

    const isEmpty = collect([!!timeLine?.title, !!timeLine?.description]).every((value) => value === false);

    const [showEdit, setShowEdit] = useState<boolean>(isEmpty);

    const [deleting, setDeleting] = useState<boolean>(false);

    const remove = async (): Promise<void> => {
        setDeleting(true);
        const { success } = await TimeLineSectionItemManager.delete(timeLine.id);
        setDeleting(false);
        if (success) {
            onRemove(timeLine.id);
        }
    };

    function renderHeader(): JSX.Element {
        return (
            <div className="flex items-center justify-between mb-small">
                <AppstoreOutlined className="mr-mini" />
                <div className="flex items-center gap-4">
                    <Button loading={deleting} danger icon={<DeleteOutlined />} onClick={remove} />
                </div>
            </div>
        );
    }

    const renderEditMenuSectionItem = useMemo(
        () =>
            showEdit && <EditTimeLineSectionItem {...props} toggleEdit={() => setShowEdit(false)} onRemove={remove} />,
        [showEdit],
    );

    return (
        <>
            <div className="mb-medium w-full rounded-md border-brand-inkGrey-grey_2 border-small border-solid bg-background-inkWhite-white_0 shadow-section">
                <div className="p-small">
                    {renderHeader()}
                    <div>
                        <div className="h-[100px] w-full flex items-center justify-between bg-brand-inkGrey-grey_0 rounded-lg mr-small overflow-hidden">
                            <div className="w-full h-full flex items-center">
                                {timeLine?.image?.url && (
                                    <div className="h-full aspect-square">
                                        <img
                                            alt="timeLine"
                                            src={timeLine.image.url}
                                            className="w-full h-full object-cover"
                                        />
                                    </div>
                                )}

                                <div className="h-full w-full flex flex-col justify-center p-small">
                                    {timeLine?.milestone && <h6 className="mini">{timeLine?.milestone}</h6>}
                                    {timeLine?.title && <h6>{timeLine?.title}</h6>}
                                    {timeLine?.description && (
                                        <p className="mini text-brand-text-grey">{timeLine?.description}</p>
                                    )}
                                </div>
                            </div>
                            <div className="p-small">
                                <Button icon={<EditOutlined />} onClick={() => setShowEdit(true)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {renderEditMenuSectionItem}
        </>
    );
}

export default TimeLineSectionItem;
