import { ReactElement, useMemo } from 'react';
import collect from 'collect.js';
import { Select } from 'antd';
import { CompanyInterface } from '../../exports/Interfaces';
import useRdxStore from '../../hooks/useRdxStore';

interface Props {
    value: null | string;
    onChange: any;
}

interface OptionInterface {
    value: string;
    label: string;
    company: CompanyInterface;
}

export default function GooglePlaceSelect(props: Props): ReactElement {
    const { value, onChange } = props;
    const { store } = useRdxStore();

    const options = useMemo(
        () =>
            collect(store?.companies)
                ?.map((company) => ({
                    value: company?.google_places_id,
                    label: `${company?.google_places_id} - (${company?.name})`,
                }))
                ?.toArray() as OptionInterface[],
        [store?.companies],
    );

    return <Select value={value} options={options} placeholder="Select Google Places Id" onChange={onChange} />;
}
