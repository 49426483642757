import { useMemo, useState } from 'react';
import { Avatar, Button, Table } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import collect from 'collect.js';
import dayjs from 'dayjs';
import { CompanyInterface, GoogleReviewInterface } from '../../../exports/Interfaces';
import useRdxStore from '../../../hooks/useRdxStore';
import ReviewModal from '../../modals/ReviewModal';
import GoogleReviewsManager from '../../../services/api/GoogleReviewsManager';

function GoogleReviewsForm(): JSX.Element {
    const { store } = useRdxStore();

    const googlePlaces = useMemo(
        () =>
            collect(store?.companies ?? []).mapWithKeys((item: CompanyInterface) => [item.google_places_id, item.name]),
        [store?.companies],
    );
    const reviews = useMemo<GoogleReviewInterface[]>(
        () =>
            collect(store?.companies ?? [])
                .pluck('google_reviews')
                .flatten(1)
                .toArray(),
        [store?.companies],
    );

    const [deletingReviewId, setDeletingReviewId] = useState<number | null>(null);
    const [reviewModal, setReviewModal] = useState({
        open: false,
        review: null,
    });

    const deleteReview = async (review: GoogleReviewInterface): Promise<void> => {
        setDeletingReviewId(review?.id);
        await GoogleReviewsManager.delete(review);
        setDeletingReviewId(null);
    };

    const renderReviewModal = useMemo(
        () =>
            reviewModal?.open ? (
                <ReviewModal
                    open={reviewModal?.open}
                    review={reviewModal?.review}
                    toggle={() =>
                        setReviewModal({
                            open: false,
                            review: null,
                        })
                    }
                />
            ) : null,
        [reviewModal],
    );

    return (
        <>
            <div className="flex justify-end mb-small">
                <Button type="primary" onClick={() => setReviewModal({ open: true, review: null })}>
                    Add Review
                </Button>
            </div>
            <Table
                columns={[
                    {
                        title: 'Restaurant',
                        dataIndex: 'google_places_id',
                        render: (id) => googlePlaces.get(id) ?? 'Unknown',
                        filters: googlePlaces
                            .map((item, key) => ({
                                text: item,
                                value: key,
                            }))
                            .toArray(),
                        onFilter: (value, record: GoogleReviewInterface) => record.google_places_id === value,
                    },
                    {
                        title: 'Avatar',
                        dataIndex: 'profile_photo_url',
                        render: (url) => <Avatar src={url} alt="avatar" />,
                    },
                    {
                        title: 'Name',
                        dataIndex: 'author_name',
                    },
                    {
                        title: 'Text',
                        dataIndex: 'text',
                    },
                    {
                        title: 'Created',
                        dataIndex: 'time',
                        render: (value) => moment.unix(value).format('DD.MM.YYYY HH:mm'),
                    },
                    {
                        title: 'Rating',
                        dataIndex: 'rating',
                    },
                    {
                        title: 'Edit',
                        render: (review) => (
                            <Button
                                onClick={() =>
                                    setReviewModal({
                                        open: true,
                                        review: {
                                            ...review,
                                            time: dayjs(moment.unix(review.time).toDate()),
                                        },
                                    })
                                }
                            >
                                <EditOutlined />
                            </Button>
                        ),
                    },
                    {
                        title: 'Delete',
                        render: (review) => (
                            <Button onClick={() => deleteReview(review)} loading={review?.id === deletingReviewId}>
                                <DeleteOutlined />
                            </Button>
                        ),
                    },
                ]}
                dataSource={reviews}
                className="w-full overscroll-x-auto overflow-scroll"
            />
            {renderReviewModal}
        </>
    );
}

export default GoogleReviewsForm;
