import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { REDUX_STORE_SLICES } from '../../exports/Enums';
import { UserInterface } from '../../exports/Interfaces';

const initialState: Partial<UserInterface> = {};

export default createSlice({
    name: REDUX_STORE_SLICES.MERCHANT,
    initialState,
    reducers: {
        setAll: (state: Partial<UserInterface>, action: PayloadAction<UserInterface>) => {
            try {
                Object.assign(state, action.payload);
            } catch (error) {}
        },
    },
});

export const props = (state: UserInterface): UserInterface => state;
