import { DataWrapper, Response, MenuInterface } from '../../../exports/Interfaces';
import BackendApiClient from '../BackendApiClient';

export default class MenuSectionItemManager {
    public static post = async (
        menuSectionId: number,
        data: Partial<MenuInterface>,
    ): Promise<Response<DataWrapper<DataWrapper<MenuInterface>>>> =>
        BackendApiClient.request<DataWrapper<DataWrapper<MenuInterface>>>({
            method: 'POST',
            url: `/menu-sections/${menuSectionId}/menu-section-items`,
            data,
        });

    public static put = async (
        id: number,
        data: Partial<MenuInterface>,
    ): Promise<Response<DataWrapper<DataWrapper<MenuInterface>>>> =>
        BackendApiClient.request<DataWrapper<DataWrapper<MenuInterface>>>({
            method: 'PUT',
            url: `/menu-section-items/${id}`,
            data,
        });

    public static delete = async (id: number): Promise<Response<DataWrapper<DataWrapper<null>>>> =>
        BackendApiClient.request<DataWrapper<DataWrapper<null>>>({
            method: 'DELETE',
            url: `/menu-section-items/${id}`,
        });
}
