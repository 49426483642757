import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { REDUX_STORE_SLICES } from '../../exports/Enums';
import { AppStatusInterface } from '../../exports/Interfaces';

const initialState: AppStatusInterface = {
    navBarOpen: false,
    featureTabIndex: 0,
    showTemplates: false,
};

export default createSlice({
    name: REDUX_STORE_SLICES.APP_STATUS,
    initialState,
    reducers: {
        toggleNavBar: (state: AppStatusInterface, action: PayloadAction<boolean>) => {
            try {
                state.navBarOpen = action.payload;
            } catch (error) {}
        },
        setFeatureTabIndex: (state: AppStatusInterface, action: PayloadAction<number>) => {
            try {
                state.featureTabIndex = action.payload;
            } catch (error) {}
        },
        setShowTemplates: (state: AppStatusInterface, action: PayloadAction<boolean>) => {
            try {
                state.showTemplates = action.payload;
            } catch (error) {}
        },
    },
});

export const props = (state: AppStatusInterface): AppStatusInterface => state;
