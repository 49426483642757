import { message } from 'antd';
import { DataWrapper, Response, SectionInterface } from '../../exports/Interfaces';
import BackendApiClient from './BackendApiClient';
import InitialDataManager from './InitialDataManager';

export default class SectionManager {
    public static post = async <Type>(
        pageId: number,
        data?: Partial<SectionInterface>,
    ): Promise<Response<DataWrapper<DataWrapper<SectionInterface<Type>>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<SectionInterface<Type>>>>({
            method: 'POST',
            url: `/pages/${pageId}/sections`,
            data,
        });

        const { response, success } = request;

        if (response?.data?.data && success) {
            message.success('Saved');
            InitialDataManager.get();
        }

        return request;
    };

    public static put = async <Type>(
        id: number,
        data?: Partial<SectionInterface>,
    ): Promise<Response<DataWrapper<DataWrapper<SectionInterface<Type>>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<SectionInterface<Type>>>>({
            method: 'PUT',
            url: `/sections/${id}`,
            data,
        });

        const { response, success } = request;

        if (response?.data?.data && success) {
            message.success('Updated');
        }

        return request;
    };

    public static delete = async (id: number): Promise<Response<boolean>> => {
        const request = await BackendApiClient.request<boolean>({
            method: 'DELETE',
            url: `/sections/${id}`,
        });

        const { success } = request;

        if (success) {
            message.success('Deleted');
            InitialDataManager.get();
        }

        return request;
    };
}
