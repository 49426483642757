import { Form, Input, Button, Modal, Select, DatePicker, Avatar } from 'antd';
import { useState } from 'react';
import collect from 'collect.js';
import { EditOutlined } from '@ant-design/icons';
import { GoogleReviewInterface } from '../../exports/Interfaces';
import GooglePlaceSelect from '../inputs/GooglePlaceSelect';
import GoogleReviewsManager from '../../services/api/GoogleReviewsManager';
import useGallery from '../../hooks/useGallery';
import { MEDIA_TYPES } from '../../exports/Enums';

interface Props {
    open: boolean;
    review?: GoogleReviewInterface | null;
    toggle: (open?: boolean) => void;
}

function ReviewModal(props: Props): JSX.Element {
    const { open, review, toggle } = props;

    const { toggleGallery } = useGallery();

    const [form] = Form.useForm<GoogleReviewInterface>();

    const avatar = Form.useWatch('profile_photo_url', form);

    const [loading, setLoading] = useState<boolean>(false);

    const [googlePlacesId, setGooglePlacesId] = useState(review?.google_places_id ?? null);

    const [rating, setRating] = useState(review?.rating ?? 5);

    const onFinish = async (values: any): Promise<void> => {
        setLoading(true);

        const data = {
            ...values,
            google_places_id: googlePlacesId,
            rating,
            language: 'en',
            time: values.time.unix(),
        };

        const { success } = review
            ? await GoogleReviewsManager.put(review.id, data)
            : await GoogleReviewsManager.post(data);

        setLoading(false);

        if (success) {
            toggle();
        }
    };

    return (
        <Modal title={review ? 'Update Review' : 'Add Review'} open={open} onCancel={() => toggle(false)} footer={null}>
            <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                initialValues={{
                    ...review,
                    google_places_id: { google_places_id: googlePlacesId },
                    rating: { rating },
                }}
            >
                <Form.Item
                    name={['google_places_id', 'google_places_id']}
                    label="Google place"
                    rules={[{ required: true, message: 'Please select a Google Place ID!' }]}
                >
                    <GooglePlaceSelect value={googlePlacesId} onChange={setGooglePlacesId} />
                </Form.Item>

                <Form.Item label="Avatar">
                    <div className="flex w-full">
                        <Form.Item
                            name="profile_photo_url"
                            rules={[{ required: true, message: 'Please add avatar url' }, { type: 'url' }]}
                            className="mb-none"
                        >
                            <Avatar src={avatar} alt="avatar" size="large" />
                        </Form.Item>
                        <Button
                            className="ml-small"
                            onClick={() =>
                                toggleGallery({
                                    open: true,
                                    maxSelect: 1,
                                    mediaType: MEDIA_TYPES.IMAGE,
                                    onSelect: (media) =>
                                        form.setFieldValue('profile_photo_url', collect(media).first()?.url),
                                })
                            }
                            icon={<EditOutlined />}
                        >
                            Edit
                        </Button>
                    </div>
                </Form.Item>

                <Form.Item
                    name="author_name"
                    label="Name"
                    rules={[{ required: true, message: 'Please add author name' }]}
                >
                    <Input placeholder="Author name" />
                </Form.Item>

                <Form.Item name="text" label="Text" rules={[{ required: true, message: 'Please add review text' }]}>
                    <Input.TextArea rows={4} placeholder="Enter text" />
                </Form.Item>

                <Form.Item
                    name={['rating', 'rating']}
                    label="Rating"
                    rules={[{ required: true, message: 'Please select rating' }]}
                >
                    <Select
                        placeholder="Select rating"
                        options={[
                            { value: 4, label: 4 },
                            { value: 5, label: 5 },
                        ]}
                        value={rating}
                        onChange={setRating}
                    />
                </Form.Item>

                <Form.Item name="time" label="Time" rules={[{ required: true, message: 'Please select time' }]}>
                    <DatePicker showTime className="w-full" />
                </Form.Item>

                <Form.Item className="mb-none">
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default ReviewModal;
