import { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import PageManager from '../../../services/api/page/PageManager';
import { PageDataInterface } from '../../../exports/Interfaces';
import useRdxStore from '../../../hooks/useRdxStore';
import { LANGUAGES } from '../../../exports/Enums';

function CustomPage(): JSX.Element | null {
    const { store } = useRdxStore();

    const [form] = Form.useForm();

    const [loading, setLoading] = useState<boolean>(false);

    const onSave = async (values: PageDataInterface): Promise<void> => {
        await form.validateFields();
        setLoading(true);
        const { success, response, status } = await PageManager.post({
            ...values,
            navigation_item: {
                ...values?.navigation_item,
                is_hidden: false,
            },
            seo: {
                title: 'Page Title',
                description: 'Page Description',
            },
        });
        setLoading(false);

        if (success) {
            window.location.reload();
            return;
        }
        if (status === 422) {
            message.error((response as any).response.data.message);
        }
    };

    const hrefValidator = (_: any, value: string): Promise<void> => {
        if (!value || value?.length < 1 || !value.match(/^\/.+/)) {
            return Promise.reject(new Error('Href must start with /'));
        }

        return Promise.resolve();
    };

    const getLanguage = (): string => (store.legal_lang === LANGUAGES.EN ? 'English' : 'German');

    return (
        <Form form={form} name="basic" layout="vertical" initialValues={store}>
            <Form.Item
                name="href"
                label={`Href in ${getLanguage()}`}
                rules={[
                    {
                        required: true,
                        validator: hrefValidator,
                    },
                ]}
            >
                <Input placeholder="/page" />
            </Form.Item>
            <Form.Item
                name={['navigation_item', 'name']}
                label={`Name in ${getLanguage()}`}
                rules={[{ required: true }]}
            >
                <Input placeholder="Enter name" />
            </Form.Item>
            <Form.Item className="mb-none">
                <Button type="primary" loading={loading} onClick={() => onSave(form.getFieldsValue())}>
                    Save
                </Button>
            </Form.Item>
        </Form>
    );
}

export default CustomPage;
