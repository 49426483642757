import collect from 'collect.js';
import { useMemo } from 'react';
import { Tabs } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import { PAGES, SUB_PAGES } from '../../../exports/Enums';
import { SeoCriteriaInterface, SectionInterface } from '../../../exports/Interfaces';
import useRdxStore from '../../../hooks/useRdxStore';
import { SEO_DESCRIPTION_MAX_LENGTH, SEO_TITLE_MAX_LENGTH } from '../../../exports/Constants';
import SeoCriteriaItem from '../../list/SeoCriteriaItem';

interface Props {
    title: string;
    description: string;
    keywords: string[];
    classification: string;
    socialTitle: string;
    socialDescription: string;
}

function SeoAnalysis(props: Props): JSX.Element {
    const { title, description, keywords, classification, socialTitle, socialDescription } = props;

    const { store } = useRdxStore();

    const requiredSeoFixes: SeoCriteriaInterface[] = [
        {
            criteria: 'SEO title needs to be provided',
            successMessage: `Hurray! You added a SEO title`,
            checked: title?.length > 0,
            className: 'mb-mini',
            required: true,
        },
        {
            criteria: 'SEO description needs to be provided',
            successMessage: `Hurray! You added a SEO description`,
            checked: description?.length > 0,
            className: 'mb-mini',
            required: true,
        },
        {
            criteria: 'A keyword is not used inside SEO Title',
            successMessage: 'Hurray! A keyword is used inside SEO Title',
            checked: collect(keywords)?.some((keyword: string) =>
                title?.toLowerCase()?.includes(keyword?.toLowerCase()),
            ),
            className: 'mb-mini',
            required: true,
        },
        {
            criteria: 'A keyword is not used inside SEO description',
            successMessage: 'Hurray! A Keyword is used inside SEO description',
            checked: collect(keywords)?.some((keyword: string) =>
                description?.toLowerCase()?.includes(keyword?.toLowerCase()),
            ),
            className: 'mb-mini',
            required: true,
        },
        {
            criteria: 'SEO title does not start with a keyword',
            successMessage: 'Hurray! SEO title starts with a keyword',
            checked: collect(keywords)?.some((keyword: string) =>
                title?.toLowerCase()?.startsWith(keyword?.toLowerCase()),
            ),
            className: 'mb-mini',
            required: true,
        },
        {
            criteria: 'SEO description does not start with a keyword',
            successMessage: 'Hurray! SEO description starts with a keyword',
            checked: collect(keywords)?.some((keyword: string) =>
                description?.toLowerCase()?.startsWith(keyword?.toLowerCase()),
            ),
            className: 'mb-mini',
            required: true,
        },
        {
            criteria: 'No keyword appears in the first 10% of the description',
            successMessage: 'Hurray! A keyword appears in the first 10% of the description',
            checked: collect(keywords)?.some((keyword: string) =>
                description
                    ?.toLowerCase()
                    ?.substring(0, Math.ceil(SEO_DESCRIPTION_MAX_LENGTH * 0.25))
                    ?.includes(keyword?.toLowerCase()),
            ),
            className: 'mb-mini',
            required: true,
        },
        {
            criteria: `SEO title has to be less than ${SEO_TITLE_MAX_LENGTH} characters long.`,
            successMessage: `Hurray! SEO title is ${title?.length ?? 0} characters long. Good job!`,
            checked: title?.length > 0 && title?.length <= SEO_TITLE_MAX_LENGTH,
            className: 'mb-mini',
            required: true,
        },
        {
            criteria: `SEO description has to be less than ${SEO_DESCRIPTION_MAX_LENGTH} characters long.`,
            successMessage: `Hurray! SEO description is ${description?.length ?? 0} characters long. Good job!`,
            checked: description?.length > 0 && description?.length <= SEO_DESCRIPTION_MAX_LENGTH,
            className: 'mb-mini',
            required: true,
        },
        {
            criteria: 'A minimum of 3 keywords need to be provided.',
            successMessage: `Hurray! We found ${keywords?.length ?? 0} keywords.`,
            checked: (keywords?.length ?? 0) > 2,
            className: 'mb-mini',
            required: true,
        },
        {
            criteria: 'Logo needs to be uploaded.',
            successMessage: `Hurray! You have uploaded a logo.`,
            checked: !!store?.logo,
            className: 'mb-mini',
            required: true,
            href: `${PAGES.THEME}${SUB_PAGES.LOGO}`,
        },
        {
            criteria: 'Store name needs to be provided',
            successMessage: `Hurray! You have provided a store name: ${store?.name}`,
            checked: !!store?.logo,
            className: 'mb-mini',
            required: true,
            href: `${PAGES.RESTAURANT_INFO}${SUB_PAGES.GENERAL_INFORMATION}`,
        },
        {
            criteria: 'SEO social title needs to be provided.',
            successMessage: `Hurray! You have added a SEO social title.`,
            checked: socialTitle?.length > 0,
            className: 'mb-mini',
            required: true,
        },
        {
            criteria: 'SEO description title needs to be provided',
            successMessage: `Hurray! You have added a SEO social description.`,
            checked: socialDescription?.length > 0,
            className: 'mb-mini',
            required: true,
        },
        {
            criteria: 'A store type (Restaurant or Café) needs to be provided.',
            successMessage: `Hurray! You have added a SEO classification.`,
            checked: !!classification,
            className: 'mb-mini',
            required: true,
        },
        {
            criteria: 'A domain for your store needs to be provided.',
            successMessage: `Hurray! You have added a domain.`,
            checked: !!store?.domain,
            className: 'mb-mini',
            required: true,
            href: `${PAGES.RESTAURANT_INFO}${SUB_PAGES.GENERAL_INFORMATION}`,
        },
    ];

    const errorTasks = useMemo(
        () =>
            collect(requiredSeoFixes)
                ?.filter((item) => !item?.checked)
                ?.toArray(),
        [props, store],
    ) as SeoCriteriaInterface[];

    const seoSuggestions = useMemo(
        () => [
            ...[],
            ...(collect(store?.home_page?.sections)
                ?.map((section: SectionInterface<any>) => ({
                    criteria: 'We recommend to add a keyword',
                    values: collect([section?.name ?? undefined, section?.content?.title ?? undefined])
                        ?.filter((value) => !!value)
                        ?.toArray(),
                    successMessage: 'Hurray! We found at least one keyword.',
                    checked:
                        collect(keywords)?.some((keyword: string) =>
                            section?.name?.toLowerCase()?.includes(keyword?.toLowerCase()),
                        ) ||
                        collect(keywords)?.some((keyword: string) =>
                            section?.content?.title?.toLowerCase()?.includes(keyword?.toLowerCase()),
                        ),
                    className: 'mb-mini',
                    href: `${PAGES.LAYOUT}${section?.type}/${section?.id}`,
                }))
                ?.toArray() ?? []),
        ],
        [store?.home_page?.sections, keywords],
    ) as SeoCriteriaInterface[];

    const seoUncompletedSuggestion = useMemo(
        () =>
            collect(seoSuggestions)
                ?.filter((item) => !item?.checked)
                ?.toArray(),
        [seoSuggestions],
    ) as SeoCriteriaInterface[];

    const completedSeoTasks: SeoCriteriaInterface[] = collect([...requiredSeoFixes, ...seoSuggestions])
        ?.filter((criteria: SeoCriteriaInterface) => criteria.checked)
        ?.toArray();

    return (
        <Tabs>
            <TabPane key="Completed" tab={<span>Completed ({completedSeoTasks?.length ?? 0})</span>}>
                {completedSeoTasks?.length > 0 ? (
                    completedSeoTasks?.map((item) => <SeoCriteriaItem {...item} />)
                ) : (
                    <p>No completed tasks</p>
                )}
            </TabPane>
            <TabPane key="Errors" tab={<span>Errors ({errorTasks?.length ?? 0})</span>}>
                {errorTasks?.length > 0 ? (
                    errorTasks?.map((item) => <SeoCriteriaItem {...item} />)
                ) : (
                    <p>You're good!</p>
                )}
            </TabPane>
            <TabPane key="Recommendations" tab={<span>Recommendation ({seoUncompletedSuggestion?.length ?? 0})</span>}>
                {seoUncompletedSuggestion?.length > 0 ? (
                    seoUncompletedSuggestion?.map((item) => <SeoCriteriaItem {...item} />)
                ) : (
                    <p>You're good!</p>
                )}
            </TabPane>
        </Tabs>
    );
}

export default SeoAnalysis;
