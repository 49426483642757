import { useState } from 'react';
import Content from '../Content';
import useRdxStore from '../../../hooks/useRdxStore';
import EventManager from '../../../services/api/event/EventManager';
import PluginCard from '../../../components/cards/PluginCard';
import EventSettingsForm from '../../../components/plugins/events/settings/EventSettingsForm';

function Events(): JSX.Element {
    const { store } = useRdxStore();

    const [saving, setSaving] = useState<boolean>(false);

    const toggleEvents = async (checked: boolean): Promise<void> => {
        setSaving(true);
        EventManager.put({ has_events: checked });
        setSaving(false);
    };

    return (
        <Content title="Are you hosting events?">
            <div className="">
                <div className="mb-medium">
                    <PluginCard
                        title="Events"
                        description="Facilitate event organization at the restaurant with our event feature, allowing customers to easily request event services through your platform."
                        onClick={toggleEvents}
                        isPremium
                        enabled={!!store?.has_events}
                        loading={saving}
                    />
                </div>
                {store?.has_events && <EventSettingsForm />}
            </div>
        </Content>
    );
}

export default Events;
