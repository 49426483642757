import { useMemo } from 'react';
import AuthContext from '../context/AuthContext';
import { COOKIES } from '../exports/Constants';
import useAppCookies from '../hooks/useAppCookies';

interface Props {
    children: JSX.Element;
}

function AuthProvider(props: Props): JSX.Element {
    const { children } = props;

    const { getCookie } = useAppCookies();

    const token = getCookie(COOKIES.AUTH_TOKEN);

    const context = useMemo(
        () => ({
            // isLoggedIn: !!token,
            isLoggedIn: true,
        }),
        [token],
    );

    return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
