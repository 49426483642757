import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './languages/en';
import de from './languages/de';
import { LANGUAGES } from '../../exports/Enums';

const languageDetector: any = {
    type: 'languageDetector',
    async: true,
    detect: (callback: any) => {
        callback(LANGUAGES.DE);
    },
};

i18n.use(languageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            de,
            en,
        },
        react: {
            useSuspense: false,
        },
        interpolation: {
            escapeValue: false,
        },
    });
