import { useEffect, useState, useRef } from 'react';
import { usePageVisibility } from 'react-page-visibility';
import useWindowFocus from 'use-window-focus';

function useFocus({ onFocus, onBlur }: { onFocus: () => void; onBlur?: () => void }): boolean {
    const pageFocused = usePageVisibility();

    const windowFocused = useWindowFocus();

    const [mounted, setMounted] = useState<boolean>(false);

    const mountedRef = useRef(mounted);
    mountedRef.current = mounted;

    const focused = pageFocused || windowFocused;

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (!mountedRef.current) {
            return;
        }
        if (pageFocused) {
            onFocus();
        } else if (onBlur) {
            onBlur();
        }
    }, [pageFocused]);

    useEffect(() => {
        if (!mountedRef.current) {
            return;
        }
        if (windowFocused) {
            onFocus();
        } else if (onBlur) {
            onBlur();
        }
    }, [windowFocused]);

    return focused;
}

export default useFocus;
