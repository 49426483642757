import { useMemo, useState } from 'react';
import { Button, Table } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import RestaurantInfoLayout from '../../layouts/RestaurantInfoLayout';
import { ProductInterface } from '../../../exports/Interfaces';
import useRdxStore from '../../../hooks/useRdxStore';
import ProductManager from '../../../services/api/ProductManager';
import CurrencyResource from '../../../services/resources/CurrencyResource';
import ProductModal from '../../modals/ProductModal';

function ProductsForm(): JSX.Element {
    const { store } = useRdxStore();

    const [deletingProductId, setDeletingProductId] = useState<number | null>(null);

    const [showProductModal, setShowProductModal] = useState({
        open: false,
        product: null,
    });

    const deleteProduct = async (product: ProductInterface): Promise<void> => {
        setDeletingProductId(product?.id);
        await ProductManager.delete(product?.id);
        setDeletingProductId(null);
    };

    const renderProductModal = useMemo(
        () =>
            showProductModal?.open ? (
                <ProductModal
                    open={showProductModal?.open}
                    product={showProductModal?.product}
                    toggle={() =>
                        setShowProductModal({
                            open: false,
                            product: null,
                        })
                    }
                />
            ) : null,
        [showProductModal],
    );

    return (
        <RestaurantInfoLayout title="Products">
            <div className="flex justify-end mb-small">
                <Button type="primary" onClick={() => setShowProductModal({ open: true, product: null })}>
                    Add Product
                </Button>
            </div>
            <Table
                columns={[
                    {
                        title: 'Image',
                        dataIndex: 'image',
                        render: (image) => (
                            <div className="w-[50px] h-[50px] relative border border-solid border-brand-inkGrey-grey_2 rounded-md bg-background-inkWhite-white_0 shadow">
                                <img src={image?.url ?? ''} alt="product" className="h-full w-full object-cover" />
                            </div>
                        ),
                    },
                    {
                        title: 'Name',
                        dataIndex: 'name',
                    },
                    {
                        title: 'Description',
                        dataIndex: 'description',
                    },
                    {
                        title: 'Price',
                        dataIndex: 'price',
                        render: (value) => CurrencyResource.formatCurrency(value),
                    },
                    {
                        title: 'Edit',
                        render: (product) => (
                            <Button onClick={() => setShowProductModal({ open: true, product })}>
                                <EditOutlined />
                            </Button>
                        ),
                    },
                    {
                        title: 'Delete',
                        render: (product) => (
                            <Button onClick={() => deleteProduct(product)} loading={product?.id === deletingProductId}>
                                <DeleteOutlined />
                            </Button>
                        ),
                    },
                ]}
                dataSource={store?.products}
                className="w-full overscroll-x-auto overflow-scroll"
            />
            {renderProductModal}
        </RestaurantInfoLayout>
    );
}

export default ProductsForm;
