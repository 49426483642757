import { message } from 'antd';
import { DataWrapper, ProductInterface, Response } from '../../exports/Interfaces';
import actionCreators from '../../store/actions';
import ReduxResource from '../resources/ReduxResource';
import BackendApiClient from './BackendApiClient';

export default class ProductManager {
    public static get = async (id: number): Promise<Response<DataWrapper<DataWrapper<ProductInterface>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<ProductInterface>>>({
            method: 'GET',
            url: `/products/${id}`,
        });

        const { response, success } = request;

        if (response?.data?.data && success) {
            ReduxResource.dispatch(actionCreators.store.updateProduct(response?.data?.data));
        }

        return request;
    };

    public static post = async (
        data?: Partial<ProductInterface>,
    ): Promise<Response<DataWrapper<DataWrapper<ProductInterface>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<ProductInterface>>>({
            method: 'POST',
            url: `/products`,
            data,
        });

        const { response, success } = request;

        if (response?.data?.data && success) {
            ReduxResource.dispatch(actionCreators.store.createProduct(response?.data?.data));
            message.success('Saved');
        }

        return request;
    };

    public static put = async (
        id: number,
        data?: Partial<ProductInterface>,
    ): Promise<Response<DataWrapper<DataWrapper<ProductInterface>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<ProductInterface>>>({
            method: 'PUT',
            url: `/products/${id}`,
            data,
        });

        const { response, success } = request;

        if (response?.data?.data && success) {
            ReduxResource.dispatch(actionCreators.store.updateProduct(response?.data?.data));
            message.success('Updated');
        }

        return request;
    };

    public static delete = async (id: number): Promise<Response<boolean>> => {
        const request = await BackendApiClient.request<boolean>({
            method: 'DELETE',
            url: `/products/${id}`,
        });

        const { success } = request;

        if (success) {
            ReduxResource.dispatch(actionCreators.store.deleteProduct(id));
            message.success('Deleted');
        }

        return request;
    };
}
