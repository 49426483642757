import { Button, Table, Tag } from 'antd';
import { useMemo, useState } from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import useRdxStore from '../../../hooks/useRdxStore';
import ActionConfirmation from '../../popover/ActionConfirmation';
import ScriptModal from '../../modals/ScriptModal';
import ScriptManager from '../../../services/api/script/ScriptManager';

function HeadScripts(): JSX.Element {
    const { store } = useRdxStore();

    const [deletingScriptId, setDeletingScriptId] = useState<number | null>(null);

    const [deleteClickedId, setDeleteCLickedId] = useState<number | null>(null);

    const [showScriptModal, setShowScriptModal] = useState({
        open: false,
        script: null,
    });

    const deleteScript = async (id: number): Promise<void> => {
        setDeletingScriptId(id);
        await ScriptManager.delete(id);
        setDeletingScriptId(null);
    };

    const renderScriptModal = useMemo(
        () =>
            showScriptModal.open && (
                <ScriptModal
                    script={showScriptModal?.script}
                    toggle={() => setShowScriptModal({ open: false, script: null })}
                />
            ),
        [showScriptModal],
    );

    return (
        <div>
            <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setShowScriptModal({ open: true, script: null })}
            >
                Add Script
            </Button>

            <Table
                columns={[
                    {
                        title: 'Strategy',
                        dataIndex: 'strategy',
                    },
                    {
                        title: 'Source',
                        dataIndex: 'src',
                    },
                    {
                        title: 'Async',
                        dataIndex: 'async',
                        render: (value) => <Tag color={value ? 'success' : 'error'}>{(!!value).toString()}</Tag>,
                    },
                    {
                        title: 'Content',
                        dataIndex: 'content',
                    },
                    {
                        title: 'Edit',
                        render: (script) => (
                            <Button onClick={() => setShowScriptModal({ open: true, script })}>
                                <EditOutlined />
                            </Button>
                        ),
                    },
                    {
                        title: 'Delete',
                        render: (script) => (
                            <ActionConfirmation
                                open={deleteClickedId === script?.id}
                                onOpenChange={() => setDeleteCLickedId(null)}
                                onConfirm={() => deleteScript(script?.id)}
                                onCancel={() => setDeleteCLickedId(null)}
                            >
                                <Button
                                    onClick={() => setDeleteCLickedId(script?.id)}
                                    loading={deletingScriptId === script?.id}
                                >
                                    <DeleteOutlined />
                                </Button>
                            </ActionConfirmation>
                        ),
                    },
                ]}
                dataSource={store?.scripts || []}
                className="w-full overscroll-x-auto overflow-scroll"
                key={store?.scripts?.length?.toString()}
            />
            {renderScriptModal}
        </div>
    );
}

export default HeadScripts;
