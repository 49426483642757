import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import OptionCard from '../components/cards/OptionCard';
import ContentLayout from '../components/layouts/ContentLayout';
import { PAGES, SUB_PAGES, SUB_PAGE_NAMES } from '../exports/Enums';

function MiddleContent(): JSX.Element {
    const navigate = useNavigate();

    const { pathname } = useLocation();

    const data = [
        {
            title: SUB_PAGE_NAMES.TEMPLATES,
            page: SUB_PAGES.TEMPLATES,
        },
        {
            title: SUB_PAGE_NAMES.COLORS,
            page: SUB_PAGES.COLORS,
        },
        {
            title: SUB_PAGE_NAMES.STYLE,
            page: SUB_PAGES.STYLE,
        },
        {
            title: SUB_PAGE_NAMES.FONTS,
            page: SUB_PAGES.FONTS,
        },
        {
            title: SUB_PAGE_NAMES.TYPOGRAPHY,
            page: SUB_PAGES.TYPOGRAPHY,
        },
        {
            title: SUB_PAGE_NAMES.BUTTONS,
            page: SUB_PAGES.BUTTONS,
        },
        {
            title: SUB_PAGE_NAMES.LOGO,
            page: SUB_PAGES.LOGO,
        },
        {
            title: SUB_PAGE_NAMES.NAVIGATION_BAR,
            page: SUB_PAGES.NAVIGATION_BAR,
        },
        {
            title: SUB_PAGE_NAMES.PAGE_LOADING,
            page: SUB_PAGES.PAGE_LOADING,
        },
        {
            title: SUB_PAGE_NAMES.FOOTER,
            page: SUB_PAGES.FOOTER,
        },
        {
            title: SUB_PAGE_NAMES.INJECT_CODE,
            page: SUB_PAGES.INJECT_CODE,
        },
        {
            title: SUB_PAGE_NAMES.FLOATING_ICON,
            page: SUB_PAGES.FLOATING_ICON,
        },
    ];
    return (
        <div className="p-small">
            {data?.map((item) => (
                <div className="mb-small">
                    <OptionCard
                        title={item?.title}
                        checked={pathname.includes(item?.page)}
                        onClick={() => navigate(`${PAGES.THEME}${item.page}`)}
                    />
                </div>
            ))}
        </div>
    );
}

function ThemePage(): JSX.Element {
    const { pathname } = useLocation();
    return (
        <ContentLayout title="Theme" middleContent={<MiddleContent />}>
            <div className="w-full h-full">
                <Outlet key={pathname} />
            </div>
        </ContentLayout>
    );
}

export default ThemePage;
