import { ConfigProvider } from 'antd';
import useTheme from '../hooks/useTheme';

interface Props {
    children: JSX.Element;
}

function AntConfigProvider(props: Props): JSX.Element {
    const { children } = props;

    const theme = useTheme();

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: theme.color.brand.primary,
                    fontSizeHeading1: theme.fonts.fontSizes.h1,
                    fontSizeHeading2: theme.fonts.fontSizes.h2,
                    fontSizeHeading3: theme.fonts.fontSizes.h3,
                    fontSizeHeading4: theme.fonts.fontSizes.h4,
                    fontSizeHeading5: theme.fonts.fontSizes.h5,
                    fontFamily: theme.fonts.fontFamily.inter,
                },
            }}
        >
            {children}
        </ConfigProvider>
    );
}

export default AntConfigProvider;
