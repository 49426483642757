import { useMemo, useState } from 'react';
import { Button, Form, Input, Modal, Tabs, TabsProps } from 'antd';
import collect from 'collect.js';
import { DB_MODELS, LINK_IDENTIFIERS, MEDIA_TAGS, MEDIA_TYPES, MENU_SECTION_TYPES } from '../../../exports/Enums';
import { LinkInterface, MediaInterface, MenuItemProps } from '../../../exports/Interfaces';
import SelectedMediaList from '../../media/SelectedMediaList';
import LinkInput from '../../links/LinkInput';
import MenuSectionItemManager from '../../../services/api/menu-section-item/MenuSectionItemManager';
import MediaManager from '../../../services/api/MediaManager';
import LinkManager from '../../../services/api/LinkManager';
import ColorSelect from '../../selects/ColorSelect';

function EditMenuSectionItem(props: MenuItemProps): JSX.Element {
    const { type, menu, onUpdate, toggleEdit, onRemove } = props;

    const [form] = Form.useForm();

    const title = Form.useWatch('title', form);

    const description = Form.useWatch('description', form);

    const [updating, setUpdating] = useState<boolean>(false);

    const [mediaList, setMediaList] = useState<MediaInterface[]>(menu?.image ? [menu?.image] : []);

    const [buttonLink, setButtonLink] = useState<LinkInterface | null>(collect(menu?.links).first() ?? null);

    const isEmpty = collect([!!title, !!description, !!mediaList.length]).every((value) => value === false);

    const update = async (): Promise<void> => {
        setUpdating(true);
        const media = MediaManager.post(DB_MODELS.MENU_SECTION_ITEM, menu?.id, mediaList, MEDIA_TAGS.IMAGES, '');

        const link = LinkManager.post(
            DB_MODELS.MENU_SECTION_ITEM,
            menu?.id,
            collect([buttonLink])
                ?.filter((value) => !!value)
                .toArray(),
        );
        await Promise.all([media, link]);

        const values = form.getFieldsValue();
        const { response, success } = await MenuSectionItemManager.put(menu.id, values);

        if (success) {
            onUpdate(response.data.data);
            if (toggleEdit && !isEmpty) {
                toggleEdit();
            }
        }
        setUpdating(false);
    };

    const titleDisabled = useMemo(() => {
        if (type === MENU_SECTION_TYPES.FIVE) {
            return true;
        }
        return false;
    }, [type]);

    const descriptionDisabled = useMemo(() => {
        if (type === MENU_SECTION_TYPES.FIVE) {
            return true;
        }
        return false;
    }, [type]);

    function renderOverview(): JSX.Element {
        return (
            <div>
                <Form.Item label="Title" name="title">
                    <Input placeholder="Enter Title" disabled={titleDisabled} />
                </Form.Item>
                <Form.Item label="Description" name="description">
                    <Input.TextArea rows={3} placeholder="Enter Description" disabled={descriptionDisabled} />
                </Form.Item>
                <div className="mb-small">
                    <SelectedMediaList
                        title="Select Image"
                        uploadCount={1}
                        mediaType={MEDIA_TYPES.IMAGE}
                        defaultMediaList={menu?.image ? [menu?.image] : []}
                        mediaList={mediaList}
                        setMediaList={setMediaList}
                    />
                </div>

                <LinkInput
                    label="Button"
                    identifier={LINK_IDENTIFIERS.FIRST}
                    link={buttonLink}
                    setLink={setButtonLink}
                    validation={{ hrefOptional: true }}
                />
            </div>
        );
    }

    function renderStyle(): JSX.Element {
        return (
            <div>
                <Form.Item name="header_color" label="Header Color" className="w-full mr-small flex-1 flex-shrink-0">
                    <ColorSelect
                        initialColor={menu?.header_color}
                        onSelect={(value) => form?.setFieldValue('header_color', value)}
                    />
                </Form.Item>
                <Form.Item
                    name="description_color"
                    label="Description Color"
                    className="w-full mr-small flex-1 flex-shrink-0"
                >
                    <ColorSelect
                        initialColor={menu?.description_color}
                        onSelect={(value) => form?.setFieldValue('description_color', value)}
                    />
                </Form.Item>
                <Form.Item
                    name="background_color"
                    label="Background Color"
                    className="w-full mr-small flex-1 flex-shrink-0"
                >
                    <ColorSelect
                        initialColor={menu?.background_color}
                        onSelect={(value) => form?.setFieldValue('background_color', value)}
                    />
                </Form.Item>
            </div>
        );
    }

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Overview',
            children: renderOverview(),
        },
        {
            key: '2',
            label: 'Style',
            children: renderStyle(),
        },
    ];

    return (
        <>
            <div className="fixed inset-0 blur-background z-100" style={{ zIndex: 1000 }} />
            <Modal
                title="Menu Item"
                onCancel={() => toggleEdit && toggleEdit()}
                open
                footer={
                    <div className="flex items-center justify-between pt-xsmall border-brand-inkGrey-grey_2 border-small border-solid border-b-none border-r-none border-l-none">
                        <div className="flex items-center gap-4 ">
                            <Button type="primary" onClick={update} loading={updating}>
                                Save
                            </Button>
                        </div>
                        <Button onClick={() => onRemove && onRemove(menu.id)} danger>
                            Delete
                        </Button>
                    </div>
                }
            >
                <Form
                    form={form}
                    initialValues={{
                        remember: true,
                        ...menu,
                    }}
                >
                    <Tabs defaultActiveKey="1" items={items} />
                </Form>
            </Modal>
        </>
    );
}

export default EditMenuSectionItem;
