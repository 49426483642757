import { useState } from 'react';
import useRdxStore from '../../../hooks/useRdxStore';
import { TextInterface, TextStyleInterface } from '../../../exports/Interfaces';
import ThemeManager from '../../../services/api/ThemeManager';
import ThemeLayout from '../../layouts/ThemeLayout';
import TypographyItem from '../../typography/TypographyItem';

function TypographyForm(): JSX.Element {
    const { store } = useRdxStore();

    const [loadingIndex, setLoadingIndex] = useState<number | null>(null);

    const ITEMS = [
        {
            key: 'heading',
            title: 'Heading (H1 - H6)',
        },
        {
            key: 'description',
            title: 'Paragraph',
        },
    ] as ItemInterface[];

    const onSave = async (index: number, values: TextInterface): Promise<void> => {
        setLoadingIndex(index);
        await ThemeManager.post({
            text_style: {
                ...store?.theme.text_style,
                [ITEMS[index].key]: values,
            },
        });
        setLoadingIndex(null);
    };

    interface ItemInterface {
        key: string;
        title: string;
    }

    return (
        <ThemeLayout title="Typography">
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                {ITEMS.map((item, index) => {
                    const initialValue = store?.theme?.text_style?.[item.key as keyof TextStyleInterface];
                    return (
                        <TypographyItem
                            title={item.title}
                            initialValues={initialValue}
                            loading={loadingIndex === index}
                            onSave={(values) => onSave(index, values)}
                        />
                    );
                })}
            </div>
        </ThemeLayout>
    );
}

export default TypographyForm;
