import RestaurantInfoLayout from '../../../layouts/RestaurantInfoLayout';
import CateringSettingsForm from './CateringSettingsForm';

function CateringSettings(): JSX.Element {
    return (
        <RestaurantInfoLayout title="Settings">
            <CateringSettingsForm />
        </RestaurantInfoLayout>
    );
}

export default CateringSettings;
