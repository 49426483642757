export default {
    sections: {
        menu: {
            name: 'Menü',
        },
        hero: {
            content: {
                title: '"Bestes Taco-Restaurant in Bonn"',
                description:
                    'Bestellen Sie direkt über unsere Website, um Geld zu sparen und lokale Unternehmen zu unterstützen. Erhalten Sie den besten Preis, den schnellsten Service und kostenlose Speisen über Treuepunkte.',
            },
        },
        customizable: {
            content: {
                title: 'Bestellen Sie direkt auf unserer Website',
                description:
                    'Erhalten Sie Treuepunkte, sparen Sie Geld, erhalten Sie Ihr Essen schneller und unterstützen Sie unser Unternehmen.',
                button_text: 'Jetzt bestellen',
            },
        },
        google_review: {
            content: {
                title: 'Das sagen zufriedene Kunden',
            },
        },
        location_tracker: {
            name: 'Standort',
            locations: 'Unsere Standorte',
        },
        products: {
            name: 'Favoriten',
        },
        opening_hours: {
            name: 'Öffnungszeiten',
        },
        animated: {
            name: 'Animiert',
        },
        time_line: {
            name: 'Zeitstrahl',
        },
        faq: {
            name: 'Häufig gestellte Fragen',
        },
    },
};
