import LinksForm from '../../../components/restaurantInfo/links/LinksForm';
import Content from '../Content';

function Social(): JSX.Element {
    return (
        <Content title="Social Links">
            <LinksForm />
        </Content>
    );
}

export default Social;
