import { DataWrapper, Response, MenuInterface, HeroSectionItemInterface } from '../../../exports/Interfaces';
import BackendApiClient from '../BackendApiClient';

export default class HeroSectionItemManager {
    public static post = async (
        heroSectionId: number,
        data: Partial<HeroSectionItemInterface>,
    ): Promise<Response<DataWrapper<DataWrapper<HeroSectionItemInterface>>>> =>
        BackendApiClient.request<DataWrapper<DataWrapper<HeroSectionItemInterface>>>({
            method: 'POST',
            url: `/hero-sections/${heroSectionId}/hero-section-items`,
            data,
        });

    public static put = async (
        id: number,
        data: Partial<MenuInterface>,
    ): Promise<Response<DataWrapper<DataWrapper<HeroSectionItemInterface>>>> =>
        BackendApiClient.request<DataWrapper<DataWrapper<HeroSectionItemInterface>>>({
            method: 'PUT',
            url: `/hero-section-items/${id}`,
            data,
        });

    public static delete = async (id: number): Promise<Response<DataWrapper<DataWrapper<null>>>> =>
        BackendApiClient.request<DataWrapper<DataWrapper<null>>>({
            method: 'DELETE',
            url: `/hero-section-items/${id}`,
        });
}
