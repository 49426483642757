import { Spin } from 'antd';
import { useState } from 'react';
import { PAGES, SUB_PAGES } from '../../../../exports/Enums';
import useRdxStore from '../../../../hooks/useRdxStore';
import CateringManager from '../../../../services/api/catering/CateringManager';
import EventManager from '../../../../services/api/event/EventManager';
import PluginCard from '../../../cards/PluginCard';

function PluginsTab(): JSX.Element {
    const { store } = useRdxStore();

    const { state } = window.history;

    const [loading, setLoading] = useState<boolean>(false);

    const toggleCatering = async (checked: boolean): Promise<void> => {
        setLoading(true);
        const { response } = await CateringManager.put({
            has_catering: checked,
        });
        if (response?.data?.data?.has_catering) {
            window.location.href = `${PAGES.PLUGINS}${SUB_PAGES.CATERING}${SUB_PAGES.SETTINGS}`;
        } else {
            window.location.href = state.previousRoute;
        }
        setLoading(false);
    };

    const toggleEvents = async (checked: boolean): Promise<void> => {
        setLoading(true);
        const { response } = await EventManager.put({
            has_events: checked,
        });
        if (response?.data?.data?.has_events) {
            window.location.href = `${PAGES.PLUGINS}${SUB_PAGES.EVENTS}${SUB_PAGES.SETTINGS}`;
        } else {
            window.location.href = state.previousRoute;
        }
        setLoading(false);
    };

    if (loading) {
        return (
            <div className="h-full w-full p-small bg-background-inkWhite-white_1/80">
                <Spin size="large" />
            </div>
        );
    }

    return (
        <div className="h-full lg:w-1/2 w-full relative">
            <div className="grid gap-4">
                <PluginCard
                    title="Catering"
                    description="The catering feature simplifies catering orders, enabling customers to effortlessly request catering services through your platform."
                    onClick={toggleCatering}
                    isPremium
                    enabled={!!store?.has_catering}
                />
                <PluginCard
                    title="Events"
                    description="Facilitate event organization at the restaurant with our event feature, allowing customers to easily request event services through your platform."
                    onClick={toggleEvents}
                    isPremium
                    enabled={!!store?.has_events}
                />
            </div>
        </div>
    );
}

export default PluginsTab;
