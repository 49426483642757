import { Button, Tag } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { SUB_PAGE_NAMES } from '../../../../exports/Enums';
import { SeoInterface } from '../../../../exports/Interfaces';
import useRdxStore from '../../../../hooks/useRdxStore';

interface Props {
    seo: SeoInterface | null;
    onEdit: () => void;
}

function SeoSection(props: Props): JSX.Element {
    const { seo, onEdit } = props;

    const { store } = useRdxStore();

    return (
        <div className="border border-brand-inkGrey-grey_2 bg-background-inkWhite-white_0 rounded-md p-small shadow-section mb-small">
            <div className="flex items-center justify-between mb-mini">
                <Tag>{SUB_PAGE_NAMES.SEO}</Tag>
                <Button onClick={onEdit} icon={<EditOutlined />}>
                    Edit
                </Button>
            </div>
            <div className="flex">
                <div className="w-[70px] h-[70px] overflow-hidden relative mr-small rounded-md flex-none border border-brand-inkGrey-grey_2 bg-brand-inkGrey-grey_0">
                    <img
                        alt="seo_image"
                        src={seo?.image?.url ?? store?.home_page?.seo?.image?.url ?? ''}
                        className="object-cover w-full h-full"
                    />
                </div>

                <div className="flex flex-col shrink w-full mr-small">
                    <h5 className="mini semibold">{seo?.title}</h5>
                    <p className="tiny text-brand-text-grey">{seo?.description}</p>
                </div>
            </div>
        </div>
    );
}

export default SeoSection;
