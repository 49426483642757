import { message } from 'antd';
import { DataWrapper, Response, StoreInterface, TemplateDataInterface } from '../../../exports/Interfaces';
import BackendApiClient from '../BackendApiClient';
import ReduxResource from '../../resources/ReduxResource';
import actionCreators from '../../../store/actions';
import { TEMPLATE_TYPES } from '../../../exports/Enums';

export default class TemplateManager {
    public static get = async (): Promise<Response<DataWrapper<TemplateDataInterface[]>>> =>
        BackendApiClient.request<DataWrapper<TemplateDataInterface[]>>({
            method: 'GET',
            url: `/templates`,
        });

    public static post = async (type: TEMPLATE_TYPES): Promise<Response<DataWrapper<DataWrapper<StoreInterface>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<StoreInterface>>>({
            method: 'POST',
            url: `/templates`,
            data: {
                template_type: type,
            },
        });

        const { response, success } = request;

        if (response?.data?.data && success) {
            message.success('Updated');
            ReduxResource.dispatch(actionCreators.store.setAll(response?.data?.data));
        }

        return request;
    };
}
