import { useState } from 'react';
import { Modal } from 'antd';
import StoreManager from '../../services/api/StoreManager';
import useRdxStore from '../../hooks/useRdxStore';

interface Props {
    open: boolean;
    toggle: (open: boolean) => void;
}

function CompleteOnboardingModal(props: Props): JSX.Element | null {
    const { open, toggle } = props;

    const { store } = useRdxStore();

    const [loading, setLoading] = useState<boolean>(false);

    const completeOnboarding = async (): Promise<void> => {
        setLoading(true);
        StoreManager.put(store.id, {
            onboarding_completed: true,
        });
        setLoading(false);
    };

    return (
        <Modal
            title="Complete Onboarding?"
            open={open}
            onOk={completeOnboarding}
            confirmLoading={loading}
            onCancel={() => toggle(false)}
        >
            <p>
                Have you reviewed all information and are ready to quit the onboarding? You can still modify all
                information in the Dashboard.
            </p>
        </Modal>
    );
}

export default CompleteOnboardingModal;
