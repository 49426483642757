import { message } from 'antd';
import { DB_MODELS } from '../../exports/Enums';
import { DataWrapper, MediaInterface, Response } from '../../exports/Interfaces';
import BackendApiClient from './BackendApiClient';

export default class MediaManager {
    public static post = async (
        model: DB_MODELS,
        modelId: number,
        data: MediaInterface[],
        tag: string,
        successMessage = 'Updated',
    ): Promise<Response<DataWrapper<DataWrapper<MediaInterface>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<MediaInterface>>>({
            method: 'POST',
            url: `/media/${model}/${modelId}/${tag}`,
            data,
        });

        const { success } = request;
        if (success && successMessage?.length > 0) {
            message.success(successMessage);
        }
        return request;
    };
}
