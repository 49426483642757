import { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import collect from 'collect.js';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import DragNDropHelpers from '../../../helpers/DragNDropHelpers';
import { TIME_LINE_SECTION_TYPES } from '../../../exports/Enums';
import { TimeLineItemInterface, TimeLineSectionInterface, SectionInterface } from '../../../exports/Interfaces';
import SectionForm from '../../forms/SectionForm';
import useModals from '../../../hooks/useModals';
import TimeLineSectionItem from './TimeLineSectionItem';
import SectionManager from '../../../services/api/SectionManager';
import MenuSectionItemOrderManager from '../../../services/api/menu-section-item/order/MenuSectionItemOrderManager';
import SectionSelect from '../../selects/SectionSelect';
import TimeLineSectionItemManager from '../../../services/api/time-line-section-item/TimeLineSectionItemManager';
import FormGroup from '../../forms/structure/form-group';
import FormItem from '../../forms/structure/form-item';

interface Props {
    section: SectionInterface<TimeLineSectionInterface>;
}

function TimeLineSectionForm(props: Props): JSX.Element {
    const { section } = props;

    const { setSectionModal } = useModals();

    const [form] = Form.useForm<TimeLineSectionInterface>();

    const { type } = Form.useWatch('content', form) ?? { type: TIME_LINE_SECTION_TYPES.ONE };

    const [loading, setLoading] = useState<boolean>(false);

    const [adding, setAdding] = useState<boolean>(false);

    const [timeLineItems, setTimeLineItems] = useState<TimeLineItemInterface[]>(
        section?.content?.time_line_items || [],
    );

    const onSave = async (values: Partial<SectionInterface<TimeLineSectionInterface>>): Promise<any> => {
        if (typeof section?.content?.id !== 'number') {
            return;
        }
        setLoading(true);

        const request = await SectionManager.put<TimeLineSectionInterface>(section.id, {
            ...values,
            content: {
                ...values?.content,
                type,
            },
        });

        const { success } = request;

        setLoading(false);
        if (success) {
            setSectionModal({ open: false });
        }

        // eslint-disable-next-line consistent-return
        return request;
    };

    const updateOrder = async (list: TimeLineItemInterface[]): Promise<void> => {
        setTimeLineItems(list);
        MenuSectionItemOrderManager.put(collect(list)?.pluck('id')?.toArray() as number[]);
    };

    const onDragEnd = (result: DropResult): void =>
        DragNDropHelpers.onDragEnd(timeLineItems, result, (res: TimeLineItemInterface[]) => updateOrder(res));

    const addTimeLineItem = async (): Promise<void> => {
        setAdding(true);
        const { response, success } = await TimeLineSectionItemManager.post(section.content.id, {});
        if (success) {
            const newTimeLineItems = [...timeLineItems];
            newTimeLineItems.push(response.data.data);
            setTimeLineItems(newTimeLineItems);
        }
        setAdding(false);
    };

    const removeMenuItem = (id: number): void => {
        setTimeLineItems(collect(timeLineItems).where('id', '!=', id).toArray());
    };

    const updateMenuItem = (menu: TimeLineItemInterface): void => {
        const newTimeLineItems = [...timeLineItems];
        const index = collect(newTimeLineItems).search((menuItem) => menuItem.id === menu.id);
        if (typeof index === 'number') {
            newTimeLineItems[index] = menu;
            setTimeLineItems(newTimeLineItems);
        }
    };

    return (
        <SectionForm
            section={section}
            onSave={onSave}
            loading={loading}
            initialValues={{
                ...section,
                content: {
                    ...section?.content,
                    type: section?.content?.type ?? TIME_LINE_SECTION_TYPES.ONE,
                },
            }}
            form={form}
            advancedContentTitle="Timeline Items"
            advancedContent={
                <>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="list">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {timeLineItems?.map((item, index) => (
                                        <Draggable
                                            draggableId={`time-line-item-${item?.id}`}
                                            index={index}
                                            key={`time-line-item-${item?.id}`}
                                        >
                                            {(_provided) => (
                                                <div ref={_provided.innerRef} {..._provided.draggableProps}>
                                                    <span {..._provided.dragHandleProps}>
                                                        <div className="mb-small">
                                                            <div key={item.id.toString()}>
                                                                <TimeLineSectionItem
                                                                    type={type}
                                                                    timeLine={item}
                                                                    onRemove={removeMenuItem}
                                                                    onUpdate={updateMenuItem}
                                                                />
                                                            </div>
                                                        </div>
                                                    </span>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <Button loading={adding} icon={<PlusOutlined />} onClick={addTimeLineItem}>
                        Add Item
                    </Button>
                </>
            }
        >
            <Form.Item
                name={['content', 'type']}
                rules={[{ required: true, message: 'Please select a Layout!' }]}
                className="mb-none"
            >
                <SectionSelect
                    section={section}
                    onSelect={(_type: TIME_LINE_SECTION_TYPES) => form.setFieldValue(['content', 'type'], _type)}
                />
            </Form.Item>
            <FormGroup label="Typography">
                <>
                    <FormItem label="Title">
                        <Form.Item name={['content', 'title']}>
                            <Input placeholder="Title" />
                        </Form.Item>
                    </FormItem>
                    <FormItem label="Description">
                        <Form.Item name={['content', 'description']}>
                            <Input.TextArea rows={6} placeholder="Description" />
                        </Form.Item>
                    </FormItem>
                </>
            </FormGroup>
        </SectionForm>
    );
}

export default TimeLineSectionForm;
