import { message } from 'antd';
import { CompanyInterface, DataWrapper, Response } from '../../exports/Interfaces';
import actionCreators from '../../store/actions';
import ReduxResource from '../resources/ReduxResource';
import BackendApiClient from './BackendApiClient';

export default class CompanyManager {
    public static post = async (
        data?: Partial<CompanyInterface>,
    ): Promise<Response<DataWrapper<DataWrapper<CompanyInterface>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<CompanyInterface>>>({
            method: 'POST',
            url: `/companies`,
            data,
        });

        const { response, success } = request;

        if (response?.data?.data && success) {
            ReduxResource.dispatch(actionCreators.store.addCompany(response?.data?.data));
            message.success('Saved');
        } else {
            CompanyManager.handleErrors(response);
        }

        return request;
    };

    public static put = async (
        id: number,
        data?: Partial<CompanyInterface>,
        hideSuccessMessage?: boolean,
    ): Promise<Response<DataWrapper<DataWrapper<CompanyInterface>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<CompanyInterface>>>({
            method: 'PUT',
            url: `/companies/${id}`,
            data,
        });

        const { response, success } = request;

        if (response?.data?.data && success) {
            ReduxResource.dispatch(actionCreators.store.updateCompany(response?.data?.data));
            if (!hideSuccessMessage) {
                message.success('Updated');
            }
        } else {
            CompanyManager.handleErrors(response);
        }

        return request;
    };

    private static handleErrors(error: any): void {
        switch (error?.response?.status) {
            case 417:
                message.error('The provided Google Place Id does not exist');
                break;
            case 422:
                message.error(error?.response?.data?.message);
                break;
            default:
                message.error('Saving Failed');
        }
    }

    public static delete = async (id: number): Promise<Response<DataWrapper<DataWrapper<CompanyInterface>>>> => {
        const request = await BackendApiClient.request<DataWrapper<DataWrapper<CompanyInterface>>>({
            method: 'DELETE',
            url: `/companies/${id}`,
        });

        const { success } = request;

        if (success) {
            ReduxResource.dispatch(actionCreators.store.deleteCompany(id));
            message.success('Deleted');
        } else {
            message.error('Deleting failed');
        }

        return request;
    };
}
