import { MEDIA_TYPES } from '../../exports/Enums';

export default class MediaResource {
    public static getFileType = (mediaType?: MEDIA_TYPES): string => {
        switch (mediaType) {
            case MEDIA_TYPES.VIDEO:
                return '.mp4, .m4v, .mov, .ogv, .webm';
            case MEDIA_TYPES.PDF:
                return '.pdf';
            case MEDIA_TYPES.FONT:
                return '.ttf, .otf';
            case MEDIA_TYPES.DOCUMENT:
                return '.json';
            default:
                return '.jpg, .png, .jpeg';
        }
    };
}
