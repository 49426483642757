import SectionHeader, { SectionHeaderInterfaceProps } from '../navigation/SectionHeader';

interface Props extends SectionHeaderInterfaceProps {
    children: React.ReactNode;
}

function PopupLayout(props: Props): JSX.Element {
    const { children, title, centerContent, rightContent } = props;

    return (
        <div className="h-full w-full">
            <SectionHeader title={title} centerContent={centerContent} rightContent={rightContent} />
            <div className="w-full h-full">{children}</div>
        </div>
    );
}

export default PopupLayout;
