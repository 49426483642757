function Unauthorized(): JSX.Element {
    return (
        <div className="w-full h-full flex flex-col justify-center items-center p-large">
            <h2 className="mb-small">Not Authorized ✋</h2>
            <p className="text-brand-text-grey">
                Sorry, you have don't have permission to access this page. Please check if you have activated the
                plugin.
            </p>
        </div>
    );
}

export default Unauthorized;
