import { PlusOutlined } from '@ant-design/icons';
import { Button, Tag } from 'antd';
import classnames from 'classnames';

interface Props {
    title: string;
    url?: string;
    isActive: boolean;
    tag?: string;
    isUpdate?: boolean;
    onClick: () => void;
}

function ElementCard(props: Props): JSX.Element {
    const { title, url, isActive, tag, isUpdate, onClick } = props;
    return (
        <div
            className={classnames(
                'w-full h-fit p-small border border-brand-inkGrey-grey_2 border-solid rounded-md shadow-section bg-background-inkWhite-white_0 flex flex-col justify-center items-center',
                {
                    'border-brand-primary': isActive,
                },
            )}
        >
            <div className="w-full flex justify-between items-center mb-small">
                <h6>{title}</h6>
                <Tag>{tag}</Tag>
                {isUpdate ? (
                    <Button
                        type="default"
                        className="rounded-full w-[30px] h-[30px] p-none m-none flex items-center justify-center"
                        onClick={onClick}
                    >
                        {isActive && <div className="w-[18px] h-[18px] bg-brand-primary rounded-full" />}
                    </Button>
                ) : (
                    <Button type="default" icon={<PlusOutlined />} onClick={onClick} />
                )}
            </div>
            <div className="w-full h-[150px] bg-brand-inkGrey-grey_0">
                {url && <img src={url} alt="feature_image" className="w-full h-full object-contain" />}
            </div>
        </div>
    );
}

export default ElementCard;
