import RestaurantInfoLayout from '../../layouts/RestaurantInfoLayout';
import LinksForm from './LinksForm';

function Links(): JSX.Element {
    return (
        <RestaurantInfoLayout title="Links">
            <LinksForm />
        </RestaurantInfoLayout>
    );
}

export default Links;
