import collect from 'collect.js';
import { useLocation } from 'react-router-dom';
import { onboardingRouter } from '../../../routes/onboarding/OnboardingRouter';
import StepItem from '../../cards/onboarding/StepItem';

function Sidebar(): JSX.Element {
    const { pathname } = useLocation();
    const routes = collect(collect(onboardingRouter).first().children);

    return (
        <div className="w-full h-full overflow-y-auto p-small pb-[100px]">
            {routes.map((route, index) => (
                <StepItem {...route} checked={pathname === route.path} isLastStep={routes.count() - 1 === index} />
            ))}
        </div>
    );
}

export default Sidebar;
