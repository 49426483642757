import { Button } from 'antd';
import { PlusOutlined, DeleteOutlined, FilePdfOutlined, AppstoreOutlined } from '@ant-design/icons';
import collect from 'collect.js';
import classnames from 'classnames';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useEffect, useState } from 'react';
import { MediaInterface } from '../../exports/Interfaces';
import useGallery from '../../hooks/useGallery';
import { MEDIA_TYPES } from '../../exports/Enums';
import ActionConfirmation from '../popover/ActionConfirmation';
import DragNDropHelpers from '../../helpers/DragNDropHelpers';

interface Props {
    uploadCount: number;
    mediaList: MediaInterface[];
    defaultMediaList?: MediaInterface[];
    title?: string;
    description?: string;
    mediaType?: MEDIA_TYPES;
    setMediaList: (mediaList: MediaInterface[]) => void;
}

function SelectedMediaList(props: Props): JSX.Element {
    const {
        uploadCount,
        defaultMediaList,
        mediaList,
        title,
        description,
        mediaType = MEDIA_TYPES.IMAGE,
        setMediaList,
    } = props;

    const [showDeleteConfirmationFor, setShowDeleteConfirmationFor] = useState<number[]>([]);

    useEffect(() => {
        setMediaList(
            collect(defaultMediaList || [])
                ?.filter((media) => media?.type === (mediaType || MEDIA_TYPES.IMAGE))
                ?.toArray(),
        );
    }, [mediaType]);

    const toggleDeleteConfirmation = (index: number): void => {
        if (showDeleteConfirmationFor.includes(index)) {
            return setShowDeleteConfirmationFor((current) =>
                collect(current)
                    .reject((item) => item === index)
                    .toArray(),
            );
        }

        return setShowDeleteConfirmationFor((current) => [...current, index]);
    };

    const { toggleGallery } = useGallery();

    const deleteMediaItem = (index: number): void => {
        const newMediaList = [...mediaList];
        newMediaList.splice(index, 1);
        setMediaList(newMediaList);
        toggleDeleteConfirmation(index);
    };

    const addMediaItems = (list: MediaInterface[]): void => {
        const newMediaList = [...(mediaList ?? []), ...list];
        setMediaList(newMediaList);
    };

    const updateMediaItem = (updatedItem: MediaInterface, index: number): void => {
        const updatedList = [...mediaList];
        updatedList[index] = updatedItem;
        setMediaList(updatedList);
    };

    const renderMediaType = (media: MediaInterface): JSX.Element | null => {
        switch (media.type) {
            case MEDIA_TYPES.IMAGE:
                return <img src={media.url} className="object-cover h-full w-full" alt="media url" />;
            case MEDIA_TYPES.VIDEO:
                return (
                    <video preload="metadata" className="w-full">
                        <source src={media.url} />
                    </video>
                );
            case MEDIA_TYPES.PDF:
                return (
                    <div className="w-full h-full flex justify-center items-center">
                        <FilePdfOutlined className="text-[#ff0000]" />
                    </div>
                );
            default:
                return null;
        }
    };

    const onDragEnd = (result: DropResult): void =>
        DragNDropHelpers.onDragEnd(mediaList, result, (res) => setMediaList(res));

    return (
        <div
            className={classnames(
                'bg-background-inkWhite-white_1 p-small rounded-md border border-solid border-brand-inkGrey-grey_2',
                {
                    'border-brand-success': mediaList?.length === uploadCount,
                },
            )}
        >
            <div className="flex justify-between">
                <div className="mb-small">
                    <p>
                        {title ?? 'Select'} ({mediaList?.length ?? 0}/{uploadCount})
                    </p>
                    {description && <p className="text-brand-text-grey mini">{description}</p>}
                </div>

                <Button
                    className="w-fit bg-background-inkWhite-white_0 shadow rounded-md mb-small"
                    onClick={() =>
                        toggleGallery({
                            open: true,
                            maxSelect: uploadCount - (mediaList?.length ?? 0),
                            mediaType,
                            onSelect: addMediaItems,
                        })
                    }
                    icon={<PlusOutlined />}
                    disabled={uploadCount === mediaList?.length}
                >
                    Add
                </Button>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="list">
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {mediaList?.map((media, index) => (
                                <Draggable draggableId={`media-${media.id}`} index={index} key={`media-${media.id}`}>
                                    {(_provided) => (
                                        <div
                                            ref={_provided.innerRef}
                                            {..._provided.draggableProps}
                                            className="flex items-center rounded-md border border-solid border-brand-inkGrey-grey_2 mb-small"
                                        >
                                            <Button
                                                className="w-full flex-1 flex items-center h-[40px] border-none p-none relative overflow-hidden"
                                                type="ghost"
                                                onClick={() =>
                                                    toggleGallery({
                                                        open: true,
                                                        maxSelect: 1,
                                                        mediaType,
                                                        onSelect: (list) =>
                                                            updateMediaItem(collect(list)?.first(), index),
                                                    })
                                                }
                                            >
                                                <div className="h-full w-[70px] bg-brand-inkGrey-grey_1 flex items-center justify-center">
                                                    {renderMediaType(media)}
                                                </div>
                                                <p className="mini truncate overflow-hidden text-left px-small">
                                                    {`${media?.name?.substring(0, 30)}${
                                                        (media?.name?.length ?? 0) > 30 ? '...' : ''
                                                    }`}
                                                </p>
                                            </Button>
                                            <span {..._provided.dragHandleProps} className="mr-mini">
                                                <AppstoreOutlined />
                                            </span>
                                            <ActionConfirmation
                                                open={showDeleteConfirmationFor.includes(index)}
                                                onOpenChange={() => toggleDeleteConfirmation(index)}
                                                onConfirm={() => deleteMediaItem(index)}
                                                onCancel={() => toggleDeleteConfirmation(index)}
                                            >
                                                <Button
                                                    type="ghost"
                                                    className="flex-none m-none rounded-none border border-solid border-r-none border-t-none border-b-none border-brand-inkGrey-grey_2"
                                                    icon={<DeleteOutlined />}
                                                />
                                            </ActionConfirmation>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
}

export default SelectedMediaList;
