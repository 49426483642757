import { useState } from 'react';
import collect from 'collect.js';
import { Table, Switch, Form } from 'antd';
import { ProductSectionInterface, SectionInterface } from '../../../exports/Interfaces';
import SectionForm from '../../forms/SectionForm';
import useRdxStore from '../../../hooks/useRdxStore';
import SectionManager from '../../../services/api/SectionManager';
import CurrencyResource from '../../../services/resources/CurrencyResource';
import useModals from '../../../hooks/useModals';
import SectionSelect from '../../selects/SectionSelect';
import { PRODUCT_SECTION_TYPES } from '../../../exports/Enums';

interface Props {
    section: SectionInterface<ProductSectionInterface>;
}

function ProductsSectionForm(props: Props): JSX.Element {
    const { section } = props;

    const { store } = useRdxStore();

    const { setSectionModal } = useModals();

    const [form] = Form.useForm();

    const content = section?.content;

    const [loading, setLoading] = useState<boolean>(false);

    const [hiddenProductIds, setHiddenProductIds] = useState<number[]>(content?.hidden_products ?? []);

    const onSave = async (values: Partial<SectionInterface<ProductSectionInterface>>): Promise<void> => {
        if (typeof section?.id !== 'number') {
            return;
        }
        setLoading(true);
        await SectionManager.put<ProductSectionInterface>(section?.id, {
            ...values,
            content: {
                ...values?.content,
                hidden_products: hiddenProductIds,
            },
        });
        setLoading(false);
        setSectionModal({ open: false });
    };

    const onChangeVisibility = (_id: number, checked: boolean): void => {
        if (!checked) {
            setHiddenProductIds(
                collect([...hiddenProductIds])
                    ?.push(_id)
                    ?.unique()
                    ?.toArray(),
            );
        } else {
            setHiddenProductIds(
                collect([...hiddenProductIds])
                    ?.filter((productId) => productId !== _id)
                    ?.toArray(),
            );
        }
    };

    return (
        <SectionForm section={section} onSave={onSave} loading={loading} initialValues={section} form={form}>
            <Form.Item
                name={['content', 'type']}
                rules={[{ required: true, message: 'Please select a Layout!' }]}
                className="mb-none"
            >
                <SectionSelect
                    section={section}
                    onSelect={(_type: PRODUCT_SECTION_TYPES) => form.setFieldValue(['content', 'type'], _type)}
                />
            </Form.Item>
            <Table
                columns={[
                    {
                        title: 'Image',
                        dataIndex: 'image',
                        render: (image) => (
                            <div className="w-[50px] h-[50px] relative border border-solid border-brand-inkGrey-grey_2 rounded-md bg-background-inkWhite-white_0 shadow">
                                <img src={image?.url ?? ''} alt="product" className="h-full w-full object-cover" />
                            </div>
                        ),
                    },
                    {
                        title: 'Name',
                        dataIndex: 'name',
                    },
                    {
                        title: 'Description',
                        dataIndex: 'description',
                    },
                    {
                        title: 'Price',
                        dataIndex: 'price',
                        render: (value) => CurrencyResource.formatCurrency(value),
                    },
                    {
                        title: 'Visible',
                        dataIndex: 'id',
                        render: (_id) => (
                            <Switch
                                defaultChecked={!collect(hiddenProductIds)?.contains(_id)}
                                onChange={(checked) => onChangeVisibility(_id, checked)}
                            />
                        ),
                    },
                ]}
                dataSource={store?.products}
            />
        </SectionForm>
    );
}

export default ProductsSectionForm;
