import collect from 'collect.js';
import { useState, useEffect, useRef } from 'react';
import { Button } from 'antd';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { STOREFRONT_PAGES } from '../../../../exports/Enums';
import { PageContentInterface } from '../../../../exports/Interfaces';
import useRdxStore from '../../../../hooks/useRdxStore';
import PageManager from '../../../../services/api/page/PageManager';
import Content from '../../Content';
import SectionList from '../../../../components/sections/SectionList';
import useModals from '../../../../hooks/useModals';
import RestaurantDescription from '../../restaurant-description';
import InitialDataManager from '../../../../services/api/InitialDataManager';

function AboutUs(): JSX.Element {
    const { store } = useRdxStore();

    const { sectionModal } = useModals();

    const [page, setPage] = useState<PageContentInterface | null>(null);

    const [loading, setLoading] = useState<boolean>(true);

    const [editAboutUs, setEditAboutUs] = useState<boolean>(false);

    const [mounted, setMounted] = useState<boolean>(false);

    const mountedRef = useRef(mounted);
    mountedRef.current = mounted;

    const requestPage = async (showLoading = true): Promise<void> => {
        if (showLoading) {
            setLoading(true);
        }
        const { response, success } = await PageManager.get(
            collect(store?.pages).firstWhere('href', STOREFRONT_PAGES.ABOUT_US)?.id,
        );
        await InitialDataManager.get();
        setLoading(false);
        setMounted(true);
        if (success) {
            setPage(response?.data?.data);
        }
    };

    useEffect(() => {
        requestPage();
    }, []);

    useEffect(() => {
        if (!mountedRef.current) {
            return;
        }
        if (!sectionModal?.open || !editAboutUs) {
            requestPage(false);
        }
    }, [sectionModal?.open, editAboutUs]);

    const toggleEditAboutUs = (): void => setEditAboutUs(!editAboutUs);

    return (
        <Content title="About Us Page" description="To confirm/ edit images / copy" loading={loading}>
            <>
                <div className="shadow rounded-md flex flex-col p-small bg-background-inkWhite-white_0 mb-small">
                    <div className="flex items-center justify-between">
                        <h4>Tell us about your restaurant</h4>
                        <Button
                            icon={!editAboutUs ? <EditOutlined /> : <CloseOutlined />}
                            onClick={toggleEditAboutUs}
                        />
                    </div>
                    {editAboutUs && (
                        <div key={JSON.stringify(store?.about_us_section)} className="mt-small">
                            <RestaurantDescription onSuccess={toggleEditAboutUs} />
                        </div>
                    )}
                </div>
                <SectionList sections={page?.sections || []} />
            </>
        </Content>
    );
}

export default AboutUs;
