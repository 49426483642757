import { ENVIRONMENTS } from '../../exports/Enums';

function EnvironmentBanner(): JSX.Element | null {
    const show = import.meta.env.VITE_NODE_ENV !== ENVIRONMENTS.PROD;
    function getBackgroundColor(): string {
        switch (import.meta.env.VITE_NODE_ENV) {
            case ENVIRONMENTS.DEV:
                return '#000000';
            case ENVIRONMENTS.STAGING:
                return '#F89A2A';
            default:
                return '';
        }
    }

    return show ? (
        <div
            className="w-full flex items-center justify-center py-tiny"
            style={{ backgroundColor: getBackgroundColor() }}
            id="env-banner"
        >
            <p className="tiny semibold text-brand-text-light">{import.meta.env.VITE_MODE}</p>
        </div>
    ) : null;
}

export default EnvironmentBanner;
