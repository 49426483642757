import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import useScreenType from '../../hooks/useScreenType';
import ReduxResource from '../../services/resources/ReduxResource';
import actionCreators from '../../store/actions';

export interface SectionHeaderInterfaceProps {
    title: string | JSX.Element;
    to?: string;
    centerContent?: JSX.Element;
    rightContent?: JSX.Element;
    hideClose?: boolean;
}

function SectionHeader(props: SectionHeaderInterfaceProps): JSX.Element {
    const { title, to, centerContent, rightContent, hideClose = false } = props;

    const { isDesktop } = useScreenType();

    const navigate = useNavigate();

    return isDesktop ? (
        <div className="z-10 flex justify-between items-center bg-background-inkWhite-white_0 sticky top-0 px-small h-[66px] border-b border-solid border-small border-t-none border-r-none border-l-none border-brand-inkGrey-grey_2">
            <h6>{title}</h6>
            {centerContent && centerContent}
            {!hideClose && !rightContent ? (
                <Button onClick={() => (to ? navigate(to) : navigate(-1))}>
                    <CloseOutlined />
                </Button>
            ) : (
                rightContent && rightContent
            )}
        </div>
    ) : (
        <div
            className={classnames(
                'z-10 grid grid-cols-4 gap-4 w-full bg-background-inkWhite-white_0 sticky top-0 px-small py-mini border-b border-solid border-small border-t-none border-r-none border-l-none border-brand-inkGrey-grey_2',
                {
                    'h-[66px]': !centerContent,
                    'py-xsmall': !!centerContent,
                },
            )}
        >
            <div className="col-span-1 w-fit flex items-center">
                <Button
                    onClick={() => {
                        ReduxResource.dispatch(actionCreators.appStatus.toggleNavBar(true));
                    }}
                >
                    <MenuOutlined />
                </Button>
            </div>
            <div className="col-span-2 flex items-center justify-center">
                <h6 className="text-center truncate">{title}</h6>
            </div>
            <div className="flex justify-end items-center col-span-1">
                {!hideClose && !rightContent ? (
                    <Button onClick={() => (to ? navigate(to) : navigate(-1))}>
                        <CloseOutlined />
                    </Button>
                ) : (
                    rightContent && rightContent
                )}
            </div>
        </div>
    );
}

export default SectionHeader;
