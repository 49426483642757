import { useState } from 'react';
import { SectionInterface } from '../../exports/Interfaces';
import useModals from '../../hooks/useModals';
import FormGroup from '../forms/structure/form-group';

interface Props {
    section: SectionInterface<any>;
    onSelect: (type: any | null) => void;
}

function SectionSelect(props: Props): JSX.Element {
    const { section, onSelect } = props;

    const { setFeatureModal } = useModals();

    const [contentType, setContentType] = useState<any>(section?.content?.type);

    const _onSelect = (type: any): void => {
        setContentType(type);
        onSelect(type);
    };

    return (
        <FormGroup
            label={`Layout: ${contentType}`}
            onClick={() =>
                setFeatureModal({
                    open: true,
                    section,
                    contentType,
                    onSelect: _onSelect,
                })
            }
        />
    );
}

export default SectionSelect;
