/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
    EyeOutlined,
    FileImageOutlined,
    FontColorsOutlined,
    InfoCircleFilled,
    LayoutFilled,
    LinkOutlined,
    LoginOutlined,
    RollbackOutlined,
    StarOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Menu } from 'antd';
import { AnimatePresence, motion, useAnimate } from 'framer-motion';
import collect from 'collect.js';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import cryptoRandomString from 'crypto-random-string';
import useRdxStore from '../../hooks/useRdxStore';
import ReduxResource from '../../services/resources/ReduxResource';
import actionCreators from '../../store/actions';
import { ENVIRONMENTS, PAGES, PAGE_NAMES, SUB_PAGES, SUB_PAGE_NAMES } from '../../exports/Enums';
import MediaContext from '../../context/MediaContext';
import useAppCookies from '../../hooks/useAppCookies';
import { COOKIES } from '../../exports/Constants';
import usePages from '../../hooks/usePages';
import DomainModal from '../modals/DomainModal';
import PublishedWebsiteModal from '../modals/PublishedWebsiteModal';
import CacheManager from '../../services/api/cache/CacheManager';

type MenuItem = Required<MenuProps>['items'][number];

function getItem({
    label,
    icon,
    children,
    type,
    callback,
}: {
    label: React.ReactNode;
    icon?: React.ReactNode;
    children?: MenuItem[];
    type?: 'group';
    callback?: () => void;
}): MenuItem {
    return {
        key: cryptoRandomString({ length: 10 }),
        icon,
        children,
        label,
        type,
        callback,
    } as MenuItem;
}

function MobileSideBar(): JSX.Element {
    const { store, appStatus } = useRdxStore();

    const navigate = useNavigate();

    const { navigationItems } = usePages();

    const [scope, animate] = useAnimate();

    const { toggleGallery } = useContext(MediaContext);

    const { removeCookies } = useAppCookies();

    const [sideBarShown, setSideBarShown] = useState(appStatus?.navBarOpen);

    const [showDomainModal, setShowDomainModal] = useState<boolean>(false);

    const [showPublishedWebsiteModal, setShowPublishedWebsiteModal] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);

    function animateIn(): void {
        setSideBarShown(true);
        animate(scope.current, { x: 0 }, { duration: 0.3 });
    }

    async function animateOut(): Promise<void> {
        await animate(scope.current, { x: -window.innerWidth }, { duration: 0.3 });
        setSideBarShown(false);
    }

    useEffect(() => {
        if (appStatus?.navBarOpen) {
            animateIn();
        } else {
            animateOut();
        }
    }, [appStatus?.navBarOpen]);

    const cacheStore = async (): Promise<void> => {
        setLoading(true);
        await CacheManager.post();
        setTimeout(() => {
            setLoading(false);
            setShowPublishedWebsiteModal(true);
        }, 2000);
    };

    const items = useMemo(
        () =>
            collect([
                getItem({
                    label: PAGE_NAMES.LAYOUT,
                    icon: <LayoutFilled />,
                    children: [
                        getItem({
                            label: SUB_PAGE_NAMES.NAVIGATION_ITEMS,
                            callback: () => navigate(`${PAGES.LAYOUT}${SUB_PAGES.NAVIGATION_ITEMS}`),
                        }),
                        ...navigationItems.map((item) =>
                            getItem({
                                label: item?.name,
                                icon: <LinkOutlined />,
                                callback: () => navigate(`${PAGES.LAYOUT}${SUB_PAGES.PAGES}/${item?.page.id}`),
                            }),
                        ),
                    ],
                }),
                getItem({
                    label: PAGE_NAMES.RESTAURANT_INFORMATION,
                    icon: <InfoCircleFilled />,
                    children: [
                        getItem({
                            label: SUB_PAGE_NAMES.GENERAL_INFORMATION,
                            callback: () => navigate(`${PAGES.RESTAURANT_INFO}${SUB_PAGES.GENERAL_INFORMATION}`),
                        }),
                        getItem({
                            label: SUB_PAGE_NAMES.LINKS,
                            callback: () => navigate(`${PAGES.RESTAURANT_INFO}${SUB_PAGES.LINKS}`),
                        }),
                        getItem({
                            label: SUB_PAGE_NAMES.SETTINGS,
                            callback: () => navigate(`${PAGES.RESTAURANT_INFO}${SUB_PAGES.SETTINGS}`),
                        }),
                        getItem({
                            label: SUB_PAGE_NAMES.PRODUCTS,
                            callback: () => navigate(`${PAGES.RESTAURANT_INFO}${SUB_PAGES.PRODUCTS}`),
                        }),
                        getItem({
                            label: SUB_PAGE_NAMES.COMPANIES,
                            callback: () => navigate(`${PAGES.RESTAURANT_INFO}${SUB_PAGES.COMPANIES}`),
                        }),
                        getItem({
                            label: SUB_PAGE_NAMES.GOOGLE_REVIEWS,
                            callback: () => navigate(`${PAGES.RESTAURANT_INFO}${SUB_PAGES.GOOGLE_REVIEWS}`),
                        }),
                        getItem({
                            label: SUB_PAGE_NAMES.OPENING_HOURS,
                            callback: () => navigate(`${PAGES.RESTAURANT_INFO}${SUB_PAGES.OPENING_HOURS}`),
                        }),
                        getItem({
                            label: SUB_PAGE_NAMES.LOCATIONS,
                            callback: () => navigate(`${PAGES.RESTAURANT_INFO}${SUB_PAGES.LOCATIONS}`),
                        }),
                        getItem({
                            label: SUB_PAGE_NAMES.IMPRESSUMS,
                            callback: () => navigate(`${PAGES.RESTAURANT_INFO}${SUB_PAGES.IMPRESSUMS}`),
                        }),
                        getItem({
                            label: SUB_PAGE_NAMES.SEO,
                            callback: () => navigate(`${PAGES.RESTAURANT_INFO}${SUB_PAGES.SEO}`),
                        }),
                    ],
                }),
                getItem({
                    label: PAGE_NAMES.THEME,
                    icon: <FontColorsOutlined />,
                    children: [
                        getItem({
                            label: SUB_PAGE_NAMES.LOGO,
                            callback: () => navigate(`${PAGES.THEME}${SUB_PAGES.LOGO}`),
                        }),
                        getItem({
                            label: SUB_PAGE_NAMES.TEMPLATES,
                            callback: () => navigate(`${PAGES.THEME}${SUB_PAGES.TEMPLATES}`),
                        }),
                        getItem({
                            label: SUB_PAGE_NAMES.FONTS,
                            callback: () => navigate(`${PAGES.THEME}${SUB_PAGES.FONTS}`),
                        }),
                        getItem({
                            label: SUB_PAGE_NAMES.COLORS,
                            callback: () => navigate(`${PAGES.THEME}${SUB_PAGES.COLORS}`),
                        }),
                        getItem({
                            label: SUB_PAGE_NAMES.STYLE,
                            callback: () => navigate(`${PAGES.THEME}${SUB_PAGES.STYLE}`),
                        }),
                        getItem({
                            label: SUB_PAGE_NAMES.TYPOGRAPHY,
                            callback: () => navigate(`${PAGES.THEME}${SUB_PAGES.TYPOGRAPHY}`),
                        }),
                        getItem({
                            label: SUB_PAGE_NAMES.BUTTONS,
                            callback: () => navigate(`${PAGES.THEME}${SUB_PAGES.BUTTONS}`),
                        }),
                        getItem({
                            label: SUB_PAGE_NAMES.NAVIGATION_BAR,
                            callback: () => navigate(`${PAGES.THEME}${SUB_PAGES.NAVIGATION_BAR}`),
                        }),
                        getItem({
                            label: SUB_PAGE_NAMES.FOOTER,
                            callback: () => navigate(`${PAGES.THEME}${SUB_PAGES.FOOTER}`),
                        }),
                    ],
                }),
                getItem({
                    label: PAGE_NAMES.PLUGINS,
                    icon: <StarOutlined />,
                    children: [
                        getItem({
                            label: SUB_PAGE_NAMES.CATERING,
                            children: [
                                getItem({
                                    label: SUB_PAGE_NAMES.SETTINGS,
                                    callback: () =>
                                        navigate(`${PAGES.PLUGINS}${SUB_PAGES.CATERING}${SUB_PAGES.SETTINGS}`),
                                }),
                            ],
                        }),
                        getItem({
                            label: SUB_PAGE_NAMES.POPUPS,
                            callback: () => navigate(`${PAGES.PLUGINS}${SUB_PAGES.POPUPS}`),
                        }),
                    ],
                }),
                getItem({
                    label: PAGE_NAMES.MEDIA,
                    icon: <FileImageOutlined />,
                    callback: () => toggleGallery({ open: true }),
                }),

                { type: 'divider' },
                getItem({
                    label: 'Admin Dashboard',
                    icon: <RollbackOutlined />,
                    callback: () => {
                        window.location.href = import.meta.env.VITE_ADMIN_DASHBOARD_URL;
                    },
                }),
                getItem({
                    label: 'Preview Website',
                    icon: <EyeOutlined />,
                    callback: () => window.open(`https://${store?.preview_domain}`, '_blank'),
                }),
                getItem({
                    label: PAGE_NAMES.LOGOUT,
                    icon: <LoginOutlined />,
                    callback: () => removeCookies([COOKIES.AUTH_TOKEN]),
                }),
            ])
                ?.reject((item) => typeof item === 'undefined')
                ?.toArray() as any,
        [store?.navigation_items],
    );

    const onClick: MenuProps['onClick'] = (e: any): void => {
        try {
            e?.item?.props?.callback();
            ReduxResource.dispatch(actionCreators.appStatus.toggleNavBar(false));
        } catch (error) {}
    };

    const renderConnectDomainModal = useMemo(
        () => <DomainModal open={showDomainModal} toggle={() => setShowDomainModal(false)} />,
        [showDomainModal],
    );

    const renderPublishedWebsiteModal = useMemo(
        () => (
            <PublishedWebsiteModal
                open={showPublishedWebsiteModal}
                toggle={() => setShowPublishedWebsiteModal(false)}
            />
        ),
        [showPublishedWebsiteModal],
    );

    return (
        <>
            <div
                className={classnames('h-screen w-screen fixed inset-0', { hidden: !sideBarShown })}
                style={{ zIndex: 1000 }}
            >
                <motion.div className="h-full w-full relative">
                    <AnimatePresence>
                        {appStatus?.navBarOpen && (
                            <motion.div
                                className="bg-black/50 h-full inset-0 fixed z-10"
                                onClick={() => ReduxResource.dispatch(actionCreators.appStatus.toggleNavBar(false))}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.3 }}
                            />
                        )}
                    </AnimatePresence>
                    <motion.div ref={scope} className="h-full absolute z-50" initial={{ x: -256 }}>
                        <Menu
                            onClick={onClick}
                            style={{ width: 256 }}
                            mode="inline"
                            items={items}
                            className="h-full overflow-y-scroll"
                        />
                        {import.meta.env.VITE_NODE_ENV === ENVIRONMENTS.PROD && (
                            <div
                                className="absolute w-full bottom-0 px-small py-mini border-t border-r-none border-l-none border-b-none border-brand-inkGrey-grey_2 border-solid"
                                style={{ zIndex: 1000, width: 256 }}
                            >
                                <Button
                                    type="primary"
                                    className="w-full"
                                    onClick={cacheStore}
                                    loading={loading}
                                    disabled={loading}
                                >
                                    Publish Website changes
                                </Button>
                            </div>
                        )}
                    </motion.div>
                </motion.div>
            </div>
            {renderConnectDomainModal}
            {renderPublishedWebsiteModal}
        </>
    );
}

export default MobileSideBar;
